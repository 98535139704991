import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { PolicyService } from '../../common-services/policy.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductService } from 'src/app/common-services/product.service';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { DigiShieldDevices } from 'src/app/model/DigiShieldDevices';
import { LoginService } from 'src/app/components/user/login/login.service';
import { AppConstants } from 'src/app/app.constants';
import { PartnerOnBoardingConstants } from 'src/app/partner/partner-onboarding.constants';
import { PartnerOnboardingService } from 'src/app/partner/partner-onboarding.service';

@Component({
  selector: 'app-pre-partner-onboarding',
  templateUrl: './partner-failed-onboarding.component.html',
  styleUrls: ['./partner-failed-onboarding.component.css']
})
export class PartnerFailedOnboardingComponent implements OnInit {

  constructor(public service: AppService,
    private spinner: NgxSpinnerService,
    private productService: ProductService,
    public dialog: MatDialog, private cdr: ChangeDetectorRef,
    private loginService: LoginService,
    private policyService: PolicyService,
    public partnerOnboardingService: PartnerOnboardingService) {

      this.loginService.showAppLandingPage.next(AppConstants.FALSE);
      this.loginService.showDigiOnboardingApp.next(AppConstants.FALSE);
      this.loginService.showDigiFailedOnboarding.next(AppConstants.TRUE);
    }

  ngOnInit(): void { }

  changeTabLanding(){
    this.loginService.showAppLandingPage.next(AppConstants.TRUE);
    this.loginService.showDigiOnboardingApp.next(AppConstants.FALSE);
    this.loginService.showDigiFailedOnboarding.next(AppConstants.FALSE);
  }
}
