
import packageInfo from '../../../../../package.json';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoginCredentials } from '../../../model/login.credentials';
import { Router, ActivatedRoute, NavigationEnd, RoutesRecognized } from '@angular/router';
import { LoginService } from './login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FlowableConstants } from '../../../flowable.constants';
import { WebSocketAPI } from '../../../websockets/webSocketAPI';
import { WebSocketTopics } from '../../../websockets/websocket.topics.constants';
import { MatDialog } from '@angular/material/dialog';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { AppRoutingConstants } from '../../../app-routing.constants';
import { AppConstants } from '../../../app.constants';
import { BrowserdisclaimerComponent } from '../../browserdisclaimer/browserdisclaimer.component';
import { AppService } from '../../../app.service';
import { IdentityService } from 'src/app/user-management/identity.service';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  appVersion: string;
  loginForm: UntypedFormGroup;
  hide: boolean = true;
  auth: LoginCredentials = {
    userName: '',
    password: ''
  };
  responseMessage: {};
  responseAvailable: boolean = false;
  responseFailed: boolean = false;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private loginService: LoginService,
    private spinner: NgxSpinnerService,
    private webSocketAPI: WebSocketAPI,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private keycloakService: KeycloakService,

  ) {
    this.appVersion = packageInfo.version;
    this.loginForm = this.fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.responseMessage = {
      status: '',
      message: ''
    };
  }

  ngOnInit() {
      if (navigator.userAgent.indexOf("Edge") != -1 || navigator.userAgent.indexOf(' OPR/') != -1 || this.isInternetExplorer()) {
        this.openDialog("browserMismatch");
      } else if (navigator.userAgent.indexOf("Chrome") != -1) {
        if (parseInt(navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./)[2], 10) < 80) {
          this.openDialog("versionChrome");
        }
      } else if (navigator.userAgent.indexOf("Firefox") != -1) {
        if (parseInt(window.navigator.userAgent.match(/Firefox\/([0-9]+)\./)[1]) < 72) {
          this.openDialog("versionFirefox");
        }
      } else if (navigator.userAgent.indexOf("Safari") != -1) {
        if (parseInt(window.navigator.userAgent.match(/Safari\/([0-9]+)\./)[1]) < 6) {
          this.openDialog("browserMismatch");
        }
      }
      this.login();
  }

  openForgotPasswordModal() {
    this.dialog.open(ForgotPasswordComponent);
  }

  login(): void {
    this.spinner.show();
    //this.webSocketAPI.connect(WebSocketTopics.topicOfClaimLogs, this.auth.userName);
    setTimeout(() => {
    }, 10000);
    this.loginService.getUser(this.auth).subscribe(
      res => {
        this.spinner.hide();
        this.responseAvailable = true;
        if (res && res.sub) {
          this.loginForm.reset();
          // Store the auth as a b64 token
         // this.storeFlowableAuth(this.auth)
          //localStorage.setItem(FlowableConstants.FLOWABLE_BASIC_AUTH_KEY, btoa([this.auth.userName, this.auth.password].join(':')));
          localStorage.setItem(AppConstants.ACCESSED_AT, ''+this.appService.getCurrentTime());
          let forcedPasswordResetRequired = localStorage.getItem(AppConstants.HIDE_HEADERS_TAB) === AppConstants.TRUE;
          if (!forcedPasswordResetRequired) {
            let unauthenticatedRoute=localStorage.getItem(AppConstants.UN_AUTHENTICATED_ROUTE);
            if(unauthenticatedRoute!=undefined && unauthenticatedRoute){
              localStorage.setItem(AppConstants.UN_AUTHENTICATED_ROUTE,null);
              let splitRoute=unauthenticatedRoute.split('/');
              let resultantRoute=[], j=0;
              for(let i=splitRoute.indexOf('#')+1;i<splitRoute.length;i++){
                resultantRoute[j]=splitRoute[i];
                j++;
              }
              this.router.navigate(resultantRoute);
            }else{
              this.loginService.showPartnersTab.subscribe(res5 => {
                if (!(res5 === AppConstants.TRUE ? true : false)) {
                  this.filterRouting();
                }
              });
            }
          } else {
            let firstLogin = localStorage.getItem(AppConstants.USER_FIRST_LOGIN) === AppConstants.TRUE;
            this.openDialog(AppConstants.FORCED_PASSWORD_RESET_ALERT,firstLogin);
          }
        }
        this.responseMessage = {
          status: res.status,
          message: res.message
        };
      },
      err => {
        this.spinner.hide();
        this.responseAvailable = false;
        this.responseFailed = true;
        this.responseMessage = {
          message: err.message
        };
      }
    );
  }
  private storeFlowableAuth(auth){
    var payload = btoa([auth.userName, auth.password].join(':'))
    this.appService.rsaEncrypt(payload).subscribe(res =>{
      if (res != null){
        localStorage.setItem(FlowableConstants.FLOWABLE_BASIC_AUTH_KEY,res);
      }
    })
  }

  private filterRouting() {
    if (!(window.location.href.includes(AppRoutingConstants.POLICY_SEARCH) ||
      (window.location.href.includes(AppRoutingConstants.CHANGE_PASSWORD)))) {
      this.router.navigate(['home']);
      //this.router.navigate();
    }
  }
  openDialog(errorType: string, isFirstLogin : boolean = false) {
    const dialogRef = this.dialog.open(BrowserdisclaimerComponent, {
      height: '200px',
      width: '600px',
      data: { errorType: errorType, isFirstLogin:isFirstLogin}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (errorType === AppConstants.FORCED_PASSWORD_RESET_ALERT) {
        if (!localStorage.getItem("RESET_PASSWORD_PROCEED")) {
          this.appService.logout();
        }
      }
      this.openDialog = result;
    });
  }

  isInternetExplorer(){
    return navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
  }
}
