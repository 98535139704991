import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppConstants } from '../app.constants';

@Injectable({
    providedIn: 'root'
  })
export class BlanccoService {
    constructor(private http: HttpClient, private appService: AppService) {}

    uploadPricing(formData) {
        return <any>this.http.post(environment.blanccoUrl + '/uploadPricing/upload', formData, {
          headers: new HttpHeaders({
            'Authorization': localStorage.getItem(AppConstants.JWT_TOKEN)
          })
        });
    }

}