<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" color="#FFFFFF" fullScreen="false" size="large" type="ball-fussion">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px" fxLayoutGap.xs="0">
  <div class="leftHalf" fxFlex="4%">
    <!-- <img src="assets/images/LG.png" style="margin-top: 50px;width: 77%;display: block;margin-left: 10%;" /> -->
  </div>
  <div class="rightHalf" fxFlex="70%">

    <div>
      <div class="row logo-container">
        <span>
          <div class="logo-container">
            <ng-container [ngSwitch]="service.getCurrentLoggedInPartner()">
              <ng-container *ngSwitchCase="'MYDIGIMTELNA01'">
                <img src="../../../assets/images/digiLogo.png" class="img-container" />
              </ng-container>
              <ng-container *ngSwitchCase="'MYAEONCRETCF01'">
                <img class="center" src="../../../assets/images/aeonLogo.png" style="margin-top:20px;height: 100px;">
              </ng-container>
              <ng-container *ngSwitchCase="'MYDIGIMTELNA02'">
                <img src="../../../assets/images/digiLogo.png" class="img-container"/>
              </ng-container>
            </ng-container>
          </div>
          <h1 class="heading-container">
            {{partnerOnboardingService.fetchHeaders(currentActiveTab)}}
          </h1>
        </span>
      </div>
      <div class="row">
        <form [formGroup]="partnerOnboardingForm">
          <ng-container *ngIf="partnerOnboardingService.
          isPanelToBeShown('PARTNER_SALES_REGISTRATION_PANEL' , currentActiveTab)">
            <mat-accordion>
              <mat-card>
                <mat-expansion-panel formGroupName="customerDetailPanel">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <b>Customer Details</b>
                      <mat-error *ngIf="customerInfoValidationFailed" class="mat-error-panel">
                        <b>{{panelErrorMessage}}</b></mat-error>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div style="overflow-y:auto !important;">
                    <table class="alignTable">
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">Customer's First Name</mat-label>
                            <input (input)="partnerPolicy.customerName=$event.target.value"
                              formControlName="customerName" placeholder="Customer's Name" matInput required
                              type="text" maxlength="100">
                            <mat-error
                              *ngIf="partnerOnboardingForm.get('customerDetailPanel').get('customerName').hasError('emptyCheckFailed')">
                              First Name cannot be empty
                            </mat-error>
                            <mat-error
                              *ngIf="partnerOnboardingForm.get('customerDetailPanel').get('customerName').hasError('numberCheckFailed')">
                              No Number allowed
                            </mat-error>
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">Customer's Last Name</mat-label>
                            <input (input)="partnerPolicy.customerLastName=$event.target.value"
                              formControlName="customerLastName" placeholder="Customer's Last Name" matInput required
                              type="text" maxlength="100">
                            <mat-error
                              *ngIf="partnerOnboardingForm.get('customerDetailPanel').get('customerLastName').hasError('emptyCheckFailed')">
                              Last Name cannot be empty
                            </mat-error>
                            <mat-error
                              *ngIf="partnerOnboardingForm.get('customerDetailPanel').get('customerLastName').hasError('numberCheckFailed')">
                              No Number allowed
                            </mat-error>
                          </mat-form-field>
                        </td>
                      </tr>
                      <ng-container [ngSwitch]="service.getCurrentLoggedInPartner()">
                        <ng-container *ngSwitchCase="'MYAEONCRETCF01'">
                          <tr>
                            <td>
                              <mat-form-field>
                                <mat-label class="label-policy">Agreement Number</mat-label>
                                <input (input)="partnerPolicy.agreementNumber=$event.target.value"
                                  formControlName="agreementNumber" placeholder="Agreement Number" matInput required
                                  type="text">
                                <mat-error
                                  *ngIf="partnerOnboardingForm.get('customerDetailPanel').get('agreementNumber').hasError('required')">
                                  Agreement Number cannot be empty
                                </mat-error>
                                <mat-error
                                  *ngIf="partnerOnboardingForm.get('customerDetailPanel').get('agreementNumber').hasError('validationErrors')">
                                  {{partnerOnboardingService.controlErrorMsg}}
                                </mat-error>
                              </mat-form-field>
                            </td>
                          </tr>
                        </ng-container>
                      </ng-container>
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">ID Type</mat-label>
                            <ng-container [ngSwitch]="service.getCurrentLoggedInPartner()">
                              <ng-container *ngSwitchCase="'MYDIGIMTELNA01'">
                                <mat-select [(value)]="partnerPolicy.idType" formControlName="idType">
                                  <mat-option value="IC">IC</mat-option>
                                  <mat-option value="Passport">Passport</mat-option>
                                </mat-select>
                              </ng-container>
                              <ng-container *ngSwitchCase="'MYDIGIMTELNA02'">
                                <mat-select [(value)]="partnerPolicy.idType" formControlName="idType">
                                  <mat-option value="IC">IC</mat-option>
                                  <mat-option value="Passport">Passport</mat-option>
                                </mat-select>
                              </ng-container>
                              <ng-container *ngSwitchCase="'MYAEONCRETCF01'">
                                <mat-select [(value)]="partnerPolicy.idType" formControlName="idType" required>
                                  <mat-option value="IC">IC</mat-option>
                                </mat-select>
                              </ng-container>
                            </ng-container>
                            <mat-error
                              *ngIf="partnerOnboardingForm.get('customerDetailPanel').get('idType').hasError('required')">
                              Id type cannot be empty
                            </mat-error>
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">ID No.</mat-label>
                            <ng-container [ngSwitch]="service.getCurrentLoggedInPartner()">
                              <ng-container *ngSwitchCase="'MYDIGIMTELNA01'">
                                <input (input)="partnerPolicy.custIdNumber=$event.target.value"
                                  placeholder="Customer Id Number" formControlName="customerId" matInput type="text">
                              </ng-container>
                              <ng-container *ngSwitchCase="'MYDIGIMTELNA02'">
                                <input (input)="partnerPolicy.custIdNumber=$event.target.value"
                                  placeholder="Customer Id Number" formControlName="customerId" matInput type="text">
                              </ng-container>
                              <ng-container *ngSwitchCase="'MYAEONCRETCF01'">
                                <input (input)="partnerPolicy.custIdNumber=$event.target.value"
                                  placeholder="Customer Id Number" formControlName="customerId" matInput type="text"
                                  required>
                              </ng-container>
                            </ng-container>
                            <mat-error
                              *ngIf="partnerOnboardingForm.get('customerDetailPanel').get('customerId').hasError('required')">
                              Id Number cannot be left blank
                            </mat-error>
                            <mat-error
                              *ngIf="partnerOnboardingForm.get('customerDetailPanel').get('customerId').hasError('validationErrors')">
                              {{partnerOnboardingService.controlErrorMsg}}
                            </mat-error>
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-form-field class="example-full-width">
                            <mat-label class="label-policy">Mobile</mat-label>
                            <span matPrefix>60 &nbsp;</span>
                            <input type="text" matInput placeholder="xxx-xxxx-xxxx"
                              (input)="partnerPolicy.custMobileNumber=$event.target.value"
                              formControlName="custMobileNumber" required maxlength="11" minlength="9">
                            <mat-error
                              *ngIf="partnerOnboardingForm.get('customerDetailPanel').get('custMobileNumber').hasError('emptyCheckFailed')">
                              Mobile no. cannot be empty
                            </mat-error>
                            <mat-error
                              *ngIf="partnerOnboardingForm.get('customerDetailPanel').get('custMobileNumber').hasError('nonNumberCheckFailed')">
                              Only Number allowed
                            </mat-error>
                            <mat-error
                              *ngIf="partnerOnboardingForm.get('customerDetailPanel').get('custMobileNumber').hasError('minlength') &&
                              !partnerOnboardingForm.get('customerDetailPanel').get('custMobileNumber').hasError('nonNumberCheckFailed')">
                              Minimum phone length is 9
                            </mat-error>
                            <mat-error
                              *ngIf="partnerOnboardingForm.get('customerDetailPanel').get('custMobileNumber').hasError('prefixError') &&
                              !partnerOnboardingForm.get('customerDetailPanel').get('custMobileNumber').hasError('minlength')">
                              Phone number must start from "1" after countryCode (60)
                            </mat-error>
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">Email</mat-label>
                            <input (input)="partnerPolicy.customerEmail=$event.target.value"
                              placeholder="Customer Email" formControlName="customerEmail" matInput type="text">
                            <mat-error
                              *ngIf="partnerOnboardingForm.get('customerDetailPanel').get('customerEmail').hasError('emailValidationFailed')">
                              Email does not follow valid pattern
                            </mat-error>
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <mat-form-field appearance="outline">
                          <input (keyup)="autoCompleteSearch($event)" matInput placeholder="Search Address"
                            [matAutocomplete]="auto">
                          <mat-icon matSuffix>search</mat-icon>
                        </mat-form-field>
                        <mat-autocomplete #auto="matAutocomplete">
                          <mat-option *ngFor=" let item of searchList" matTooltip="{{item.description}}"
                            matTooltipClass="autocomplete-tooltip"
                            (onSelectionChange)="setAddress(item.placeId,item.country)">
                            {{item.description}}
                          </mat-option>
                        </mat-autocomplete>
                      </tr>
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">Address</mat-label>
                            <input (input)="partnerPolicy.custAddress.address1=$event.target.value"
                              placeholder="Customer Address" formControlName="custAddress" matInput type="text" maxlength="250"> 
                            <!-- <mat-error>Address cannot be empty</mat-error> -->
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">Postal Code</mat-label>
                            <input (input)="partnerPolicy.custAddress.postalCode=$event.target.value"
                              placeholder="Customer Postal Code" formControlName="postalCode" matInput type="text" maxlength="20">
                            <!-- <mat-error>Postal code cannot be empty</mat-error> -->
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">City</mat-label>
                            <input (input)="partnerPolicy.custAddress.customerCity=$event.target.value"
                              placeholder="Customer City" formControlName="customerCity" matInput type="text" maxlength="50">
                            <!-- <mat-error>City cannot be empty</mat-error> -->
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">State</mat-label>
                            <input placeholder="Customer State"
                              (input)="partnerPolicy.custAddress.customerState=$event.target.value"
                              formControlName="customerState" matInput type="text" maxlength="50">
                            <!-- <mat-error>State cannot be empty</mat-error> -->
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-select (selectionChange)="resetCountry($event)" formControlName="customerCountryCode"
                              placeholder="Country">
                              <mat-option *ngFor="let country of countries" [value]="country.twoCharCode">
                                {{country.name}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </td>
                      </tr>
                    </table>
                  </div>
                </mat-expansion-panel>

              </mat-card>
              <mat-card>
                <mat-expansion-panel formGroupName="deviceDetailPanel">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <b>Device Details</b>
                      <mat-error *ngIf="deviceInfoValidationFailed" class="mat-error-panel">
                        <b>{{panelErrorMessage}}</b></mat-error>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div style="overflow-y:auto !important;">
                    <table class="alignTable">
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">IMEI</mat-label>
                            <input (input)="partnerPolicy.policyIMEINumber=$event.target.value"
                              placeholder="Service Contract IMEI No." formControlName="policyIMEINumber" matInput
                              type="text" required maxlength="15">
                            <mat-error
                              *ngIf="partnerOnboardingForm.get('deviceDetailPanel').get('policyIMEINumber').hasError('bothIMEIandSerialNumberEmpty') ||
                                            partnerOnboardingForm.get('deviceDetailPanel').get('policyIMEINumber').hasError('required')">
                              IMEI cannot be left blank
                            </mat-error>
                            <mat-error
                              *ngIf="partnerOnboardingForm.get('deviceDetailPanel').get('policyIMEINumber').hasError('validationErrors')">
                              {{partnerOnboardingService.controlErrorMsg}}
                            </mat-error>
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">Serial No.</mat-label>
                            <input (input)="partnerPolicy.deviceSerialNo=$event.target.value"
                              placeholder="Device Serial No." formControlName="deviceSerialNo" matInput type="text">
                          </mat-form-field>
                        </td>
                      </tr>
                      <ng-container [ngSwitch]="service.getCurrentLoggedInPartner()">
                        <ng-container *ngSwitchCase="'MYDIGIMTELNA02'"></ng-container>
                        <ng-container *ngSwitchDefault>
                          <tr>
                            <td>
                              <mat-form-field>
                                <mat-label class="label-policy">Make *</mat-label>
                                  <input (input)="partnerPolicy.deviceMake=$event.target.value" placeholder="Device Make"
                                  formControlName="deviceMake" matInput type="text" [readonly]="true"/>
                                  <mat-error
                                    *ngIf="partnerOnboardingForm.get('deviceDetailPanel').get('deviceMake').hasError('makeCheckFailed')">
                                    Device Make canot be empty
                                  </mat-error>
                              </mat-form-field>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <mat-form-field>
                                <mat-label class="label-policy">Model *</mat-label>
                                <input (input)="partnerPolicy.deviceModel=$event.target.value"
                                  placeholder="Device Model No." formControlName="deviceModel" matInput type="text" maxlength="100"
                                  [readonly]="true" />
                                <mat-error
                                  *ngIf="partnerOnboardingForm.get('deviceDetailPanel').get('deviceModel').hasError('modelCheckFailed')">
                                  Device Model canot be empty
                                </mat-error>
                              </mat-form-field>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <mat-form-field>
                                <mat-label class="label-policy">Device Capacity (GB)</mat-label>
                                <input (input)="partnerPolicy.deviceCapacity=$event.target.value"
                                  placeholder="Device Capacity (GB)" formControlName="deviceCapacity" matInput required
                                  type="text">
                                <mat-error
                                  *ngIf="partnerOnboardingForm.get('deviceDetailPanel').get('deviceCapacity').hasError('emptyCheckFailed')">
                                  Device capacity cannot be empty
                                </mat-error>
                                <mat-error
                                  *ngIf="partnerOnboardingForm.get('deviceDetailPanel').get('deviceCapacity').hasError('nonNumberCheckFailed')">
                                  Only numeric value can be entered e.g. 32, 64, 128
                                </mat-error>
                              </mat-form-field>
                            </td>
                          </tr>
                        </ng-container>
                      </ng-container>
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">Color</mat-label>
                            <input (input)="partnerPolicy.deviceColor=$event.target.value" placeholder="Device Color"
                              formControlName="deviceColor" matInput required type="text">
                            <mat-error
                              *ngIf="partnerOnboardingForm.get('deviceDetailPanel').get('deviceColor').hasError('emptyCheckFailed')">
                              Device color cannot be empty
                            </mat-error>
                            <mat-error
                              *ngIf="partnerOnboardingForm.get('deviceDetailPanel').get('deviceColor').hasError('nonAlphabeticCheckFailed')">
                              No special character or number allowed, Only alphabets
                            </mat-error>
                          </mat-form-field>
                        </td>
                      </tr>
                    </table>
                  </div>
                </mat-expansion-panel>
              </mat-card>
              <mat-card>
                <mat-expansion-panel formGroupName="staffDetailPanel">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <b>Staff Details</b>
                      <mat-error *ngIf="staffInfoValidationFailed" class="mat-error-panel">
                        <b>{{panelErrorMessage}}</b></mat-error>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div style="overflow-y:auto !important;">
                    <table class="alignTable">
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">Staff Name</mat-label>
                            <input (input)="partnerPolicy.salesRepName=$event.target.value" placeholder="Staff Name"
                              formControlName="staffName" matInput type="text" required maxlength="255">
                            <mat-error>
                              Staff Name cannot be left blank
                            </mat-error>
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <ng-container [ngSwitch]="service.getCurrentLoggedInPartner()">
                            <ng-container *ngSwitchCase="'MYDIGIMTELNA01'">
                              <mat-form-field>
                                <mat-label class="label-policy">Store Name</mat-label>
                                <mat-select [(value)]="partnerPolicy.storeName" formControlName="storeName"
                                  placeholder="Select Store Name" required>
                                  <mat-option *ngFor="let store of partnerStoresName" [value]="store">
                                    {{store}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </ng-container>
                            <ng-container *ngSwitchCase="'MYDIGIMTELNA02'">
                              <mat-form-field>
                                <mat-label class="label-policy">Store Name</mat-label>
                                <mat-select [(value)]="partnerPolicy.storeName" formControlName="storeName"
                                  placeholder="Select Store Name" required>
                                  <mat-option *ngFor="let store of partnerStoresName" [value]="store">
                                    {{store}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </ng-container>
                            <ng-container *ngSwitchCase="'MYAEONCRETCF01'">
                              <mat-form-field>
                                <mat-label>Merchant Name</mat-label>
                                <mat-select required formControlName="storeName" [(value)]="partnerPolicy.storeName"
                                (selectionChange)="setStoreInfo($event)">
                                  <mat-option *ngFor="let store of partnerStoresName" [value]="store">
                                    {{store}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </ng-container>
                          </ng-container>
                        </td>
                      </tr>
                    </table>
                  </div>
                </mat-expansion-panel>
              </mat-card>

            </mat-accordion>
          </ng-container>
          <ng-container [ngSwitch]="service.getCurrentLoggedInPartner()" *ngIf="partnerOnboardingService.
          isPanelToBeShown('PARTNER_PRODUCT_SELECTION_PANEL' , currentActiveTab)">
            <ng-container *ngSwitchCase="'MYDIGIMTELNA02'">
              <mat-form-field appearance="fill">
                <mat-label>Select Digi Main Postpaid Product</mat-label>
                <mat-select [(value)]="selectedDigiPackage" required (selectionChange)="setDigiProductsBasedOnSuppliedPackage($event)">
                  <mat-option *ngFor="let packages of digiPackagesList" 
                               [value]="packages.packageId">{{packages.packageName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="fill" *ngIf="selectedDigiPackage">
                <mat-label>Select Digi Postpaid Sub Product Based on Main Product</mat-label>
                <mat-select [(value)]="selectedDigiProduct" required (selectionChange)="setDigiSubProductsBasedOnSuppliedDigiProductId($event)" formGroupName="productDetailPanel">
                  <mat-option *ngFor="let digiProduct of digiProductList" 
                               [value]="digiProduct.digiPackageProductId">{{digiProduct.digiPackageProductName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="fill" *ngIf="selectedDigiProduct">
                <mat-select [(value)]="selectedDigiSubProduct" required (selectionChange)="setCrmzProductwithPlansBasedOnProductCode($event)" formGroupName="productDetailPanel">
                  <mat-option *ngFor="let subProduct of digiSubProductList" 
                               [value]="subProduct">{{subProduct.digiPackageSubProductName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              
              <ng-container *ngIf="selectedDigiSubProduct">
                <mat-accordion *ngFor=" let product of productDetailList">
                  <mat-card>
                    <mat-expansion-panel (opened)="getDigiDeviceList(product.productCoverage, product)" [expanded]="true" class="cover-header" formGroupName="productDetailPanel" disabled>
                      <mat-expansion-panel-header [collapsedHeight]="'85px'" [expandedHeight]="'100px'">
                        <mat-panel-title class="ver-title" [ngStyle]="{'background': this.partnerOnboardingService.partnerOnboardingConfigModel.fontColor ,
                        'color': this.partnerOnboardingService.partnerOnboardingConfigModel.themeColor}">
                          <div>
                            <b
                              style="writing-mode: vertical-rl;text-orientation: mixed;height: 60px;transform: rotate(-180deg);">
                              {{product.tenureType}}
                            </b>
                          </div>
                        </mat-panel-title>
                        <mat-panel-title>
                          <h3 style="color: rgba(0,0,0,.87);">{{product.description}}</h3>
                        </mat-panel-title>
                        <mat-panel-description>
                          <h3>{{product.clientProductDesciption}}</h3>
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <div style="overflow-y:auto !important;">
                        <mat-card>
                          <mat-card-content>
                            <ul>
                              <li *ngFor=" let term of product.termsAndConditions">
                                {{term}}
                              </li>
                            </ul>
                            
                            <mat-form-field appearance="fill">
                              <mat-label><b>Select Device</b></mat-label>
                              <mat-select [(value)]="selectedDigiDevice" required (selectionChange)="digiDisplayProductPrice(selectedDigiDevice,product)">
                                <mat-option *ngFor="let digiDevices of digiShieldDevicesList" 
                                            [value]="digiDevices">{{digiDevices.makeName}} {{digiDevices.modelName}} | {{digiDevices.currencyCode}} {{digiDevices.deviceRetailPrice}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <ng-container *ngIf="selectedDigiDevice">
                              <div fxLayout="row">
                                <h3>Device RRP: </h3>
                                <h4>RM {{selectedDigiDevice.deviceRetailPrice}}</h4>
                              </div>
                              <div fxLayout="row">
                                <h3>Total Product Price: </h3>
                                <h4 [id]="generateDeviceRrpIdentifier(product.productCode,'000')"></h4>
                              </div>
                              <div fxLayout="row" fxFlexAlign="center">
                                <button mat-raised-button (click)='confirmContract(product);' [ngStyle]="{'color': this.partnerOnboardingService.partnerOnboardingConfigModel.fontColor ,
                                'background': this.partnerOnboardingService.partnerOnboardingConfigModel.themeColor}"
                                  class="product-btn">
                                  Confirm Contract
                                </button>
                              </div>
                            </ng-container><!-- selectedDigiDevice closed -->
                          </mat-card-content>
                        </mat-card>
                      </div>
                    </mat-expansion-panel>
                  </mat-card>
                </mat-accordion>
              </ng-container>
              
            </ng-container><!-- For Only DigiShield New Devices-->
          
            <ng-container *ngSwitchDefault>
              <mat-accordion *ngFor=" let product of productDetailList">
                <mat-card>
                  <mat-expansion-panel class="cover-header" formGroupName="productDetailPanel">
                    <mat-expansion-panel-header [collapsedHeight]="'85px'" [expandedHeight]="'100px'">
                      <mat-panel-title class="ver-title" [ngStyle]="{'background': this.partnerOnboardingService.partnerOnboardingConfigModel.fontColor ,
                      'color': this.partnerOnboardingService.partnerOnboardingConfigModel.themeColor}">
                        <div>
                          <b
                            style="writing-mode: vertical-rl;text-align:center; text-orientation: mixed;height: 60px;transform: rotate(-180deg);">
                            {{product.tenureType}}
                          </b>
                        </div>
                      </mat-panel-title>
                      <mat-panel-title>
                        <h3 style="padding-left:20px">{{product.description}}</h3>
                      </mat-panel-title>
                      <mat-panel-description>
                        <h3>{{product.clientProductDesciption}}</h3>
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div style="overflow-y:auto !important;">
                      <mat-card>
                        <mat-card-content>
                          <ul>
                            <li *ngFor=" let term of product.termsAndConditions">
                              {{term}}
                            </li>
                          </ul>
                          <ng-container [ngSwitch]="service.getCurrentLoggedInPartner()">
                            <ng-container *ngSwitchCase="'MYAEONCRETCF01'">
                              <div [id]="generateDeviceRrpIdentifier(product.productCode,product.productName)"
                                style="border:1px solid; padding: 10px;">
                                <mat-form-field>
                                  <mat-label class="label-policy">Device RRP (RM)</mat-label>
                                  <input [id]="product.productCode"
                                    *ngIf="policyProductDeviceRRP.get(product.productCode) != undefined"
                                    (blur)="setDeviceRRP(product.productCode , product.productName)" matInput type="text"
                                    [value]="policyProductDeviceRRP.get(product.productCode)" required>
                                  <input [id]="product.productCode"
                                    *ngIf="policyProductDeviceRRP.get(product.productCode) == undefined"
                                    (blur)="setDeviceRRP(product.productCode , product.productName)" matInput type="text"
                                    required>
                                </mat-form-field>
                              </div>
                              <span [id]="product.productName" style="color: red;">

                              </span>
                              <div fxLayout="row">
                                <h3>Total Product Price:</h3>
                                <h4 [id]="generateDeviceRrpIdentifier(product.productCode,'000')">

                                </h4>
                              </div>
                            </ng-container>
                          </ng-container>
                          <div fxLayout="row" fxFlexAlign="center">
                            <button mat-raised-button (click)='confirmContract(product);' [ngStyle]="{'color': this.partnerOnboardingService.partnerOnboardingConfigModel.fontColor ,
                            'background': this.partnerOnboardingService.partnerOnboardingConfigModel.themeColor}"
                              class="product-btn">
                              Confirm Contract
                            </button>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </div>
                  </mat-expansion-panel>
                </mat-card>
              </mat-accordion>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="partnerOnboardingService.
          isPanelToBeShown('PARTNER_DEVICE_CAPTURE_PANEL' , currentActiveTab)">
            <mat-accordion>
              <mat-card>
                <mat-expansion-panel (afterExpand)="enableWebCam(true)">
                  <mat-expansion-panel-header [collapsedHeight]="'100px'" [expandedHeight]="'100px'">
                    <mat-panel-title>
                      <b>Front Image - Take a picture with the device IMEI displaying on the screen. Dial *#06# to
                        display the IMEI</b>
                      <mat-error *ngIf="frontImageValidationFailed" class="mat-error-panel">
                        <b>{{panelErrorMessage}}</b></mat-error>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div #parentDiv style="overflow-y:auto !important;">
                    <table class="alignTable">
                      <tr>
                        <td class="image-container">
                          <webcam [width]="imagePanelWidth" [height]="imagePanelHeight" [trigger]="triggerObservable"
                            (imageCapture)="handleImage($event)" *ngIf="imageCapturePanel && frontImageUrl == null"
                            [imageQuality]="1" (initError)="handleInitError($event)">
                          </webcam>
                          <img *ngIf="frontImageUrl != null" [src]=frontImageUrl [style.width.px]="imagePanelWidth"
                            [style.height.px]="imagePanelHeight" />
                        </td>
                      </tr>
                      <tr>
                        <td class="image-container">
                          <button *ngIf="frontImageUrl == null" mat-raised-button (click)="triggerSnapshot();"
                            [ngStyle]="{'color': partnerColorCode}">Take
                            A
                            Snapshot</button>
                          <button *ngIf="frontImageUrl != null" mat-raised-button
                            [ngStyle]="{'color': partnerColorCode}" (click)="recaptureImage();">Recapture</button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </mat-expansion-panel>
              </mat-card>
              <mat-card>
                <mat-expansion-panel (afterExpand)="enableWebCam(false)">
                  <mat-expansion-panel-header [collapsedHeight]="'100px'" [expandedHeight]="'100px'">
                    <mat-panel-title>
                      <b>Take a picture of the back of the device WITHOUT casing or protection</b>
                      <mat-error *ngIf="backImageValidationFailed" class="mat-error-panel">
                        <b>{{panelErrorMessage}}</b></mat-error>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div style="overflow-y:auto !important;">
                    <table class="alignTable">
                      <tr>
                        <td class="image-container">
                          <webcam [width]="imagePanelWidth" [height]="imagePanelHeight" [trigger]="triggerObservable"
                            (imageCapture)="handleImage($event)" *ngIf="!imageCapturePanel && backImageUrl == null"
                            [imageQuality]="1" (initError)="handleInitError($event)"></webcam>
                          <img *ngIf="backImageUrl != null" [style.width.px]="imagePanelWidth"
                            [style.height.px]="imagePanelHeight" [src]=backImageUrl />
                        </td>
                      </tr>
                      <tr>
                        <td class="image-container">
                          <button *ngIf="backImageUrl== null" mat-raised-button [ngStyle]="{'color': partnerColorCode}"
                            (click)="triggerSnapshot();">Take
                            A
                            Snapshot</button>
                          <button *ngIf="backImageUrl != null" mat-raised-button [ngStyle]="{'color': partnerColorCode}"
                            (click)="recaptureImage(true);">Recapture</button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </mat-expansion-panel>
              </mat-card>
            </mat-accordion>
          </ng-container>
          <ng-container *ngIf="this.partnerOnboardingService.
          isPanelToBeShown('PARTNER_CONFIRMATION_PANEL' , currentActiveTab)">
            <mat-accordion>
              <mat-card>
                <mat-expansion-panel formGroupName="customerDetailPanel">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <b>Customer Detail</b>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div style="overflow-y:auto !important;">
                    <table class="alignTable">
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">Name</mat-label>
                            <input [readonly]="true" formControlName="customerName" matInput type="text">
                          </mat-form-field>
                        </td>
                      </tr>
                      <ng-container [ngSwitch]="service.getCurrentLoggedInPartner()">
                        <ng-container *ngSwitchCase="'MYAEONCRETCF01'">
                          <tr>
                            <td>
                              <mat-form-field>
                                <mat-label class="label-policy">Agreement Number</mat-label>
                                <input [readonly]="true" formControlName="agreementNumber" matInput type="text">
                              </mat-form-field>
                            </td>
                          </tr>
                        </ng-container>
                      </ng-container>
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">ID Type</mat-label>
                            <input [readonly]="true" formControlName="idType" matInput type="text">
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">ID No.</mat-label>
                            <input [readonly]="true" formControlName="customerId" matInput type="text">
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">Mobile No.</mat-label>
                            <span matPrefix>60 &nbsp;</span>
                            <input [readonly]="true" formControlName="custMobileNumber" matInput type="text"
                              [value]="partnerPolicy.custMobileNumber">
                          </mat-form-field>
                        </td>
                      <tr>

                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">Email</mat-label>
                            <input [readonly]="true" formControlName="customerEmail" matInput type="text">
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">Address</mat-label>
                            <input [readonly]="true" formControlName="custAddress" matInput type="text">
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">Postal Code</mat-label>
                            <input [readonly]="true" formControlName="postalCode" matInput type="text">
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">City</mat-label>
                            <input [readonly]="true" formControlName="customerCity" matInput type="text">
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">State</mat-label>
                            <input [readonly]="true" formControlName="customerState" matInput type="text">
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">Country</mat-label>
                            <input [readonly]="true" formControlName="customerCountryCode" matInput ype="text"
                              [value]="partnerPolicy.custAddress.customerCountry">
                          </mat-form-field>
                        </td>
                      </tr>
                    </table>
                  </div>
                </mat-expansion-panel>

              </mat-card>
              <mat-card>
                <mat-expansion-panel formGroupName="deviceDetailPanel">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <b>Device Detail</b>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div style="overflow-y:auto !important;">
                    <table class="alignTable">
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">IMEI</mat-label>
                            <input [readonly]="true" formControlName="policyIMEINumber" matInput ype="text">
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">Serial No.</mat-label>
                            <input [readonly]="true" formControlName="deviceSerialNo" matInput ype="text">
                          </mat-form-field>
                        </td>
                      </tr>
                      <ng-container [ngSwitch]="service.getCurrentLoggedInPartner()">
                        <ng-container *ngSwitchCase="'MYDIGIMTELNA02'">
                          <tr><td>&nbsp;</td></tr>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                          <tr>
                            <td>
                              <mat-form-field>
                                <mat-label class="label-policy">Make</mat-label>
                                <input [readonly]="true" formControlName="deviceMake" matInput ype="text">
                              </mat-form-field>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <mat-form-field>
                                <mat-label class="label-policy">Model</mat-label>
                                <input [readonly]="true" formControlName="deviceModel" matInput ype="text">
                              </mat-form-field>
                            </td>
                          </tr>
                          <tr>
    
                            <td>
                              <mat-form-field>
                                <mat-label class="label-policy">Capacity</mat-label>
                                <input [readonly]="true" formControlName="deviceCapacity" matInput ype="text">
                              </mat-form-field>
                            </td>
                          </tr>
                        </ng-container>  
                      </ng-container>
                      
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">Color</mat-label>
                            <input [readonly]="true" formControlName="deviceColor" matInput ype="text">
                          </mat-form-field>
                        </td>
                      </tr>
                    </table>
                  </div>
                </mat-expansion-panel>
              </mat-card>

              <mat-card>
                <mat-expansion-panel formGroupName="staffDetailPanel">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <b>Staff Detail</b>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div style="overflow-y:auto !important;">
                    <table class="alignTable">
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">Staff Name</mat-label>
                            <input [readonly]="true" formControlName="staffName" matInput ype="text" maxlength="255">
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-form-field>
                            <mat-label class="label-policy">Store Name</mat-label>
                            <input [readonly]="true" formControlName="storeName" matInput ype="text">
                          </mat-form-field>
                        </td>
                      </tr>
                    </table>
                  </div>
                </mat-expansion-panel>
              </mat-card>
            </mat-accordion>
            <mat-accordion>
              <mat-card>
                <mat-expansion-panel class="cover-header">
                  <mat-expansion-panel-header [collapsedHeight]="'85px'" [expandedHeight]="'90px'">
                    <mat-panel-title class="ver-title" [ngStyle]="{'background': this.partnerOnboardingService.partnerOnboardingConfigModel.fontColor ,
                    'color': this.partnerOnboardingService.partnerOnboardingConfigModel.themeColor}">
                      <b style="transform: rotate(-90deg);width: 60px;text-align: center;">{{partnerPolicy.productsModel.tenureType}}</b>
                    </mat-panel-title>
                    <mat-panel-title>
                      <h3 style="padding-left:20px">{{partnerPolicy.productsModel.description}}</h3>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div style="overflow-y:auto !important;">
                    <mat-card>
                      <ul>
                        <li *ngFor=" let term of partnerPolicy.productsModel.termsAndConditions">
                          {{term}}
                        </li>
                      </ul>
                      <ng-container [ngSwitch]="service.getCurrentLoggedInPartner()">
                        <ng-container *ngSwitchCase="'MYAEONCRETCF01'">
                          <mat-form-field appearance="outline">
                            <mat-label class="label-policy">Device RRP (RM)</mat-label>
                            <input [readonly]="true" [value]="partnerPolicy.productsModel.deviceRRP" matInput
                              type="text" required>
                          </mat-form-field>
                        </ng-container>
                      </ng-container>
                    </mat-card>
                  </div>
                </mat-expansion-panel>
              </mat-card>
            </mat-accordion>
            
            <mat-accordion>
              <ng-container *ngIf="partnerPolicy.deviceFrontImage != null">
                <mat-card>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <b>Front Image</b>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <table class="alignTable">
                      <tr>
                        <td class="image-container">
                          <img *ngIf="frontImageUrl != null" [style.width.px]="imagePanelWidth"
                            [style.height.px]="imagePanelHeight" [src]=frontImageUrl class="image-panel" />
                        </td>
                      </tr>
                    </table>
                  </mat-expansion-panel>
                </mat-card>
              </ng-container>
              <ng-container *ngIf="partnerPolicy.deviceBackImage != null">
                <mat-card>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <b>Back Image</b>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <table class="alignTable">
                      <tr>
                        <td class="image-container">
                          <img *ngIf="backImageUrl != null" [style.width.px]="imagePanelWidth"
                            [style.height.px]="imagePanelHeight" [src]=backImageUrl class="image-panel" />
                        </td>
                      </tr>
                    </table>
                  </mat-expansion-panel>
                </mat-card>
              </ng-container>
            </mat-accordion>
            <mat-accordion>
              <mat-card>
                <mat-expansion-panel formGroupName="confirmationPanel">
                  <mat-expansion-panel-header [expandedHeight]="'100px'" [collapsedHeight]="'100px'">
                    <mat-panel-title>
                      <b>Customer <br>
                        Acknowledgement</b>
                      <mat-error *ngIf="customerAcknowledgementValidationFailed" class="mat-error-panel">
                        <b>Please <br>confirm</b></mat-error>
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <mat-card>
                    <table class="alignTable">
                      <tr>
                        <td>
                          <div class="wrap-text align-left">
                            <b class="success-text">
                              DECLARATION AND AUTHORIZATION<br>
                              <br>
                              <mat-checkbox color="primary" formControlName="customerAcknowledgement"
                                (click)="confirmAcknowledgement('CUSTOMER')" required="true">
                                I hereby declare and agree that:
                              </mat-checkbox>
                            </b>
                          </div>
                          <br>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <ng-container [ngSwitch]="service.getCurrentLoggedInPartner()">
                            <ng-container *ngSwitchCase="'MYDIGIMTELNA02'">
                              a) All information provided by me in this application, 
                              or any formal questionnaire or other documents signed by me 
                              in conjunction with this application, and statements and answers 
                              so made to AmTrust Mobile Solutions Malaysia Sdn. Bhd. ("Company") 
                              are full, complete, true, accurate and correct, and I understand 
                              and agree that the Company, believing them to be such, 
                              will rely and act on them, otherwise the protection 
                              granted by the Company may be void at the Company’s sole option
                              <br>
                             </ng-container>
                            <ng-container *ngSwitchDefault>
                              a) All information provided by me in this application,
                              or any formal questionnaire or other documents signed
                              by me in conjunction with this application, and statements
                              and answers so made to
                              {{partnerOnboardingService.fetchDisclaimerLabel('storeDisclaimerLabel')}} (“Company”) are
                              full, complete, true, accurate
                              and correct,and I understand and agree that the Company,
                              believing them to be such, will rely and act on them,
                              otherwise the protection granted by the Company may be
                              void at the Company’s sole option.
                              <br>
                              <br>
                            </ng-container>
                          </ng-container>
                         </td>
                      </tr>
                      <tr>
                        <td>
                          b) In compliance with the Personal Data Protection Act 2010
                          and its regulations enacted thereunder, any personal
                          information or data collected or held by the Company (whether
                          contained in this application or otherwise obtained) is provided
                          to the Company and consented that such data and personal
                          information may be processed, held, stored, administered,
                          used and disclosed by the Company to individuals, service
                          providers and organizations associated with the Company or
                          any other selected third parties (within or outside of Malaysia)
                          for the purpose of processing this application and providing
                          subsequent service for this application.I understand that I have
                          the right to obtain access to and to request correction of any
                          personal information held by the Company concerning me.
                          Such requests can be made to any of the Company’s Customer
                          Service Centres.
                          <br>
                          <br>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          c) By this registration, I hereby agree to
                          {{partnerOnboardingService.fetchDisclaimerLabel('customerDisclaimerLabel')}}
                          Terms & Conditions and Privacy Notice.
                        </td>
                      </tr>
                    </table>
                  </mat-card>
                </mat-expansion-panel>
              </mat-card>
            </mat-accordion>
            <mat-accordion>
              <mat-card>
                <mat-expansion-panel formGroupName="confirmationPanel">
                  <mat-expansion-panel-header [expandedHeight]="'100px'" [collapsedHeight]="'100px'">
                    <mat-panel-title>
                      <b>Declaration by The<br>
                        Authorized Sales <br>Person</b>
                      <mat-error *ngIf="staffAcknowledgementValidationFailed" class="mat-error-panel">
                        <b>Please<br> confirm</b></mat-error>
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <mat-card>
                    <table class="alignTable">
                      <tr>
                        <td>
                          <div class="wrap-text align-left">
                            <mat-checkbox color="primary" formControlName="staffAcknowledgement"
                              (change)="confirmAcknowledgement('STAFF')">
                              <b>I hereby declare that :</b>
                              <br>
                            </mat-checkbox>
                          </div>
                          <br>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <ng-container [ngSwitch]="service.getCurrentLoggedInPartner()">
                            <ng-container *ngSwitchCase="'MYDIGIMTELNA01'">
                              On the date of this customer registration,
                              the device being covered is not more than 24 Months old,
                              present at the time of registration, and is not loss nor stolen,
                              and is free of any damage, liquid damage, and or mechanical failure.
                            </ng-container>
                            <ng-container *ngSwitchCase="'MYDIGIMTELNA02'">
                              On the date of this customer registration,
                              the device being registered is a BRAND NEW device,
                              present at the time of registration, and is not loss nor stolen,
                              and is free of any damage, liquid damage, and or mechanical failure.
                            </ng-container>
                            <ng-container *ngSwitchCase="'MYAEONCRETCF01'">
                              On the date of this customer registration,
                              the device being registered is a BRAND NEW device,
                              present at the time of registration, and is not loss nor stolen,
                              and is free of any damage, liquid damage, and or mechanical failure.
                              <br>
                              <br>
                              *FOR SALES OUTLET USE ONLY.
                            </ng-container>
                          </ng-container>
                        </td>
                      </tr>
                    </table>
                  </mat-card>
                </mat-expansion-panel>
              </mat-card>
            </mat-accordion>
          </ng-container>
          <ng-container *ngIf="this.partnerOnboardingService.
          isPanelToBeShown('PARTNER_SUCCESS_PANEL' , currentActiveTab)">
            <mat-card>
              <ng-container [ngSwitch]="service.getCurrentLoggedInPartner()">
                <ng-container *ngSwitchCase="'MYDIGIMTELNA02'">
                  <ng-container *ngIf="policyCreationStatus!='ERROR'">
                    <mat-card-content>
                      <img src="../../../assets/images/correct.png" class="check-img" />
                    </mat-card-content>
                    <mat-card-content>
                      <b class="success-text">
                        <p>The request for device protection has been successfully submitted.</p>
                        <p>Customer will be receiving notification once the registration and the 1st charging is
                          successful.
                        </p>
                      </b>
                    </mat-card-content>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'MYDIGIMTELNA01'">
                  <mat-card-content>
                    <img src="../../../assets/images/correct.png" class="check-img" />
                  </mat-card-content>
                  <mat-card-content>
                    <b class="success-text">
                      <p>The request for device protection has been successfully submitted.</p>
                      <p>Customer will be receiving notification once the registration and the 1st charging is
                        successful.
                      </p>
                    </b>
                  </mat-card-content>
                </ng-container>
                <ng-container *ngSwitchCase="'MYAEONCRETCF01'">
                  <mat-card-content>
                    <img src="../../../assets/images/correct.png" class="check-img" />
                  </mat-card-content>
                  <mat-card-content>
                    <b class="success-text">
                      <p>The registration for AEON Protect has been successfully submitted.</p>
                      <p>Customer will be receiving Terms of Service (TOS) via SMS or email in 7 days.</p>
                      <p>Customer may call 03-92124314 for assistance. Thank you.</p>
                    </b>
                  </mat-card-content>
                </ng-container>
              </ng-container>
            </mat-card>
          </ng-container>

          <mat-card>
            <ng-container *ngIf="this.partnerOnboardingService.fetchActionLabel('NEXT' , currentActiveTab)">
              <button mat-raised-button style="width: 100%;" [ngStyle]="{'color': this.partnerOnboardingService.partnerOnboardingConfigModel.fontColor ,
                        'background': this.partnerOnboardingService.partnerOnboardingConfigModel.themeColor}"
                (click)="changeTab(this.partnerOnboardingService.fetchActionCommand('NEXT' , currentActiveTab));">
                {{this.partnerOnboardingService.fetchActionLabel('NEXT' , currentActiveTab)}}
              </button>
            </ng-container>
            <ng-container *ngIf="this.partnerOnboardingService.fetchActionLabel('BACK' , currentActiveTab)">
              <button mat-raised-button class="back-btn" [ngStyle]="{'color': this.partnerOnboardingService.partnerOnboardingConfigModel.fontColor ,
              'background': this.partnerOnboardingService.partnerOnboardingConfigModel.themeColor}"
                (click)="changeTab(this.partnerOnboardingService.fetchActionCommand('BACK' , currentActiveTab));">
                {{partnerOnboardingService.fetchActionLabel('BACK' , currentActiveTab)}}
              </button>
            </ng-container>
          </mat-card>
        </form>
      </div>
    </div>
  </div>
</div>
<mat-card>
  <mat-card-content>
    <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="center">
      <img class="center" src="../../../assets/images/bolttech_logo.png" style="margin-top:80px;height: auto; margin-left: auto; margin-right: auto; width: 15%;">
    </div>
  </mat-card-content>
</mat-card>
