import {APP_INITIALIZER, NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatButtonModule} from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material/radio';
import {MatDividerModule} from '@angular/material/divider';
import {MatPasswordStrengthModule} from '@angular-material-extensions/password-strength';
import {MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule} from '@angular/material/tooltip';

import {AppComponent} from './app.component';
import {DemoMaterialModule} from '../material-module';

import {NgxPaginationModule} from 'ngx-pagination';
import {ToastrModule} from 'ngx-toastr';
import {NgxSpinnerModule} from 'ngx-spinner';
import {AmazingTimePickerModule} from 'amazing-time-picker';
import {BnNgIdleService} from 'bn-ng-idle';
import {WebcamModule} from 'ngx-webcam';
import {DeviceDetectorModule} from 'ngx-device-detector';

import {ClaimConstants} from './claim/claim.constants';
import {AppRoutingModule} from './app-routing.module';
import {ActivityModule} from './activity/activity.module';
import {CommonServicesModule} from './common-services/common-services.module';
import {CustomDirectivesModule} from './custom-directives/custom-directives.module';

import {RoleMap} from './model/roles.map';

import {LoginComponent} from './components/user/login/login.component';
import {ChangePasswordComponent} from './components/user/change-password/change-password.component';
import {ForgotPasswordComponent} from './components/user/forgot-password/forgot-password.component';

import {HeaderComponent} from './components/header/header.component';
import {HomeComponent} from './components/home/home.component';

import {PartnerLandingComponent} from './partner/partner-landing/partner-landing.component';
import {PartnerOnboardingComponent} from './partner/partner-onboarding/partner-onboarding.component';
import {PartnerFailedOnboardingComponent} from './partner/partner-failed-onboarding/partner-failed-onboarding.component';
import { PartnerMYOnboardingComponent } from './partner/partner-my-onboarding/partner-my-onboarding.component';

import {RepairComponent} from './components/repair/repair.component';
import {NotificationComponent} from './components/notification/notification.component';
import {BrowserdisclaimerComponent} from './components/browserdisclaimer/browserdisclaimer.component';
import {FiltersearchComponent} from './components/filtersearch/filtersearch.component';
import {ConfirmationDialogComponent} from './components/shared/confirmation-dialog/confirmation-dialog.component';
import {InputDialogComponent} from './components/shared/input-dialog/input-dialog.component';
import { OnsiteClaimComponent } from './claim/onsite-claim/onsite-claim.component';
import { ApprovalRequestComponent } from './approval-dashboard/approval-request/approval-request.component';
import { DummyCustomerPopupComponent } from './policy/dummy-customer-popup/dummy-customer-popup.component';
import { DatePipe } from '@angular/common';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializeKeycloak } from './init/keycloak-init.factory';

export const myCustomTooltipDefaults: MatTooltipModule = {
  showDelay: 500,
  hideDelay: 10000,
  touchendHideDelay: 6000,
};


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        RepairComponent,
        ConfirmationDialogComponent,
        InputDialogComponent,
        PartnerLandingComponent,
        ChangePasswordComponent,
        ForgotPasswordComponent,
        NotificationComponent,
        HomeComponent,
        BrowserdisclaimerComponent,
        FiltersearchComponent,
        HeaderComponent,
        PartnerOnboardingComponent,
        OnsiteClaimComponent,
        PartnerFailedOnboardingComponent,
        PartnerMYOnboardingComponent
    ],
    imports: [
        MatPaginatorModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        DemoMaterialModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        NgxPaginationModule,
        FlexLayoutModule,
        ActivityModule,
        MatRadioModule,
        MatIconModule,
        MatFormFieldModule,
        MatSelectModule,
        MatDialogModule,
        MatDividerModule,
        MatCheckboxModule,
        NgxSpinnerModule,
        ScrollingModule,
        AmazingTimePickerModule,
        ActivityModule,
        ToastrModule.forRoot(),
        WebcamModule,
        MatPasswordStrengthModule,
        DeviceDetectorModule.forRoot(),
        MatDialogModule,
        MatInputModule,
        CommonServicesModule,
        CustomDirectivesModule,
        MatTableExporterModule,
        MatTooltipModule,
        NgxIntlTelInputModule,
        KeycloakAngularModule
    ],
    providers: [BrowserAnimationsModule, RoleMap, BnNgIdleService, ClaimConstants,
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService],
          },
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
