import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { AppConstants } from '../app.constants';
import {HasPermissions} from './hasPermissions';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private hasPermissions: HasPermissions) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let isPermissionGranted = this.hasPermissions.hasPermissions(next.data.module, next.data.permissions, next.data.gidNumber, next.data.postalAddress);
    if (isPermissionGranted != undefined && isPermissionGranted) {
      return true;
    } else if (isPermissionGranted != undefined && !isPermissionGranted) {
      localStorage.setItem(AppConstants.UN_AUTHENTICATED_ROUTE,window.location.href);
      this.router.navigate(['']);
      return false;
    }
  }
}
