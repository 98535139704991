import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AlphabetOnlyDirective} from './alphabet-only.directive';
import {ConcurrentEditControlDirective} from './concurrent-edit-control.directive';
import {DisableUnauthorizedDirective} from './disable-unauthorized.directive';
import {HideUnauthorizedDirective} from './hide-unauthorized.directive';
import {NumbersOnlyDirective} from './numbers-only.directive';
import {RoundoffDecimalDirective} from './roundoff-decimal.directive';
import {BlockCopyPasteDirective} from './block-copy-paste.directive';
import {RemoveLeadingZerosDirective} from './remove-leading-zeros.directive';
import {ShowForAsiaDirective} from './show-for-asia-directive';

@NgModule({
  declarations: [
    AlphabetOnlyDirective,
    ConcurrentEditControlDirective,
    DisableUnauthorizedDirective,
    HideUnauthorizedDirective,
    NumbersOnlyDirective,
    RoundoffDecimalDirective,
    BlockCopyPasteDirective,
    RemoveLeadingZerosDirective,
    ShowForAsiaDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AlphabetOnlyDirective,
    ConcurrentEditControlDirective,
    DisableUnauthorizedDirective,
    HideUnauthorizedDirective,
    NumbersOnlyDirective,
    RoundoffDecimalDirective,
    BlockCopyPasteDirective,
    RemoveLeadingZerosDirective,
    ShowForAsiaDirective
  ]
})
export class CustomDirectivesModule { }
