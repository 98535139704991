import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PolicySearchCriteria } from '../../model/policy.search';
import { AppService } from '../../app.service';
import { ClaimConstants } from '../../claim/claim.constants';
import {UntypedFormControl, NgForm} from '@angular/forms';
import { PolicyConstants } from "../../policy/policy.constants";
import { TypeTable } from '../../model/typeTable';
import { PolicyEnum } from "../../model/policy.enum";

@Component({
  selector: 'app-filtersearch',
  templateUrl: './filtersearch.component.html',
  styleUrls: ['./filtersearch.component.scss']
})
export class FiltersearchComponent implements OnInit {
  isLoading: boolean = false;
  maxDate: Date = new Date();
  policySearch: PolicySearchCriteria = PolicySearchCriteria.newPolicySearchCriteria();
  clientDetails: any;
  statusTypesMap = new Map<String, String>();
  policyStatus: any = TypeTable.policyStatus;
  coverageTypeCode: any = TypeTable.productCoverage;
  customerEmailCtrl = new UntypedFormControl('');
  productCodeCtrl = new UntypedFormControl('');
  productNameCtrl = new UntypedFormControl('');
  isPartnerMandatoryForProductCode: Boolean = false;
  isPartnerMandatoryForProductName: Boolean = false;
  constructor(public dialogRef: MatDialogRef<FiltersearchComponent>,
    private appService: AppService,
    public filterdia: MatDialog,
    @Inject(MAT_DIALOG_DATA) public inputData: any) {

  }

  ngOnInit() {
    this.policySearch.partnerId = this.inputData.allFormData.partnerId;
    this.policySearch.coverage = this.inputData.allFormData.coverage;
    this.policySearch.policyStatus = this.inputData.allFormData.policyStatus;
    this.policySearch.dateCreated = this.inputData.allFormData.dateCreated;
    this.policySearch.policyIMEINumber = this.inputData.allFormData.policyIMEINumber;

    this.policySearch.custMobileNumber = this.inputData.allFormData.custMobileNumber;
    this.policySearch.customerId = this.inputData.allFormData.customerId;
    this.policySearch.clientTransactionNumber = this.inputData.allFormData.clientTransactionNumber;
    this.policySearch.exactMatch = this.inputData.allFormData.exactMatch;
    this.policySearch.customerFirstName = this.inputData.allFormData.customerFirstName;
    this.policySearch.customerLastName = this.inputData.allFormData.customerLastName;
    this.policySearch.customerEmail = this.inputData.allFormData.customerEmail;
    this.policySearch.brokerContractNum = this.inputData.allFormData.brokerContractNum;
    this.clientDetails = this.inputData.clients;
    this.statusTypesMap = this.inputData.statusTypes;
    this.policySearch.brokerOrderId = this.inputData.allFormData.brokerOrderId;
    this.policySearch.partnerContractId = this.inputData.allFormData.partnerContractId;
    this.policySearch.productCode = this.inputData.allFormData.productCode;
    this.policySearch.productName = this.inputData.allFormData.productName;
  }

  search(form: NgForm) {
    if(this.policySearch.customerEmail!='' && this.policySearch.customerEmail!=null && this.policySearch.customerEmail.indexOf('@')<0){
      this.customerEmailCtrl.setErrors({"mismatched":true});
      return;
    }
    // with product name or product code, partnerId is mandatory for search policies
    if (this.policySearch.productCode != '' && this.policySearch.partnerId == '') {
      this.isPartnerMandatoryForProductCode = true;
      return;
    }
    if (this.policySearch.productName != '' && this.policySearch.partnerId == '') {
      this.isPartnerMandatoryForProductName = true;
      return;
    }
    let allValuesEmpty = true;
    // Check if a search term has been provided
    Object.keys(this.policySearch).forEach(k => {
      // Ignore these fields since they evaluate to true regardless
      if (['pageRecordCount'].includes(k)) {
        return;
      }

      // Evaluate truthiness for array
      if (Array.isArray(this.policySearch[k])) {
        if (this.policySearch[k].length > 0) {
          allValuesEmpty = false;
        }
      } else if (this.policySearch[k]) {
        allValuesEmpty = false;
      }
    });
    // Throw error
    if (allValuesEmpty) {
      this.appService.showInfo(PolicyConstants.NO_SEARCH_CRITERIA_SPECIFIED, ClaimConstants.MANDATORY_FILL);
    } else {
      let timezoneDate = this.policySearch.dateCreated;
      let isCreationDateEmpty = (timezoneDate == null || timezoneDate === '' || timezoneDate === undefined);
      this.policySearch.dateCreated = isCreationDateEmpty ? '' : this.fetchFormattedDate(timezoneDate);
      this.dialogRef.close(this.policySearch);
    }
  }

  selectCreationDate(event) {
    this.policySearch.dateCreated = event.value;
  }

  fetchFormattedDate(date) {
    const d = new Date(date);
    const fmDate = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1).toISOString();
    return fmDate.substr(0, 10);
  }

  isPartnerMemoXpress() {
    return this.policySearch.partnerId === PolicyEnum.PARTNER_MEMOEXPRESS_PHILIPPINES;
  }
}
