import { Injectable } from '@angular/core';
import { PolicyEnum } from './../model/policy.enum';

@Injectable({
  providedIn: 'root'
})
export class PartnerOnBoardingConstants {
  public static CUSTOMER_NAME = "CUSTOMER_NAME";
  public static ID_TYPE = "ID_TYPE";
  public static CUSTOMER_ID = "CUSTOMER_ID";
  public static CUSTOMER_EMAIL = "CUSTOMER_EMAIL";
  public static CUSTOMER_MOBILE_NUMBER = "CUSTOMER_MOBILE_NUMBER";
  public static POLICY_IMEI_NUMBER = "POLICY_IMEI_NUMBER";
  public static INVOICE_NUMBER = "INVOICE_NUMBER";
  public static DEVICE_CAPACITY = "DEVICE_CAPACITY";
  public static DEVICE_COLOR = "DEVICE_COLOR";
  public static DEVICE_TYPE = "DEVICE_TYPE";
  public static DEVICE_MAKE = "DEVICE_MAKE";
  public static DEVICE_MODEL = "DEVICE_MODEL";
  public static DEVICE_RRP = "DEVICE_RRP";
  public static PAYMENT_METHOD = "PAYMENT_METHOD";
  public static PRODUCT_NAME = "PRODUCT_NAME";
  public static PRODUCT_MODEL = "PRODUCT_MODEL";
  public static STAFF_ID = "STAFF_ID";
  public static STAFF_NAME = "STAFF_NAME";
  public static STORE_NAME = "STORE_NAME";
  public static CUSTOMER_ACKNOWLEDGEMENT = "CUSTOMER_ACKNOWLEDGEMENT";
  public static STAFF_ACKNOWLEDGEMENT = "STAFF_ACKNOWLEDGEMENT";
  public static SKU_CODE = "SKU_CODE";
  public static PARTNER_ID_DIGI = "MYDIGIMTELNA01";
  public static PARTNER_ID_AEON = "MYAEONCRETCF01";
  public static PARTNER_ID_TMNEWA = "TWTMNEWFINNA01";

  public static PARTNER_ID_AEON_RETAIL = "MYAEONRRETNA01";
  public static PARTNER_ID_DIGI_SHIELD = "MYDIGIMTELNA02";
  public static PARTNER_LANDING_PANEL = "PARTNER_LANDING_PANEL";
  public static PARTNER_SALES_REGISTRATION_PANEL = "PARTNER_SALES_REGISTRATION_PANEL";
  public static PARTNER_PRODUCT_SELECTION_PANEL = "PARTNER_PRODUCT_SELECTION_PANEL";
  public static PARTNER_COVERAGE_SELECTION_PANEL = "PARTNER_COVERAGE_SELECTION_PANEL"
  public static PARTNER_DEVICE_CAPTURE_PANEL = "PARTNER_DEVICE_CAPTURE_PANEL";
  public static PARTNER_CONFIRMATION_PANEL = "PARTNER_CONFIRMATION_PANEL";
  public static PARTNER_SUCCESS_PANEL = "PARTNER_SUCCESS_PANEL";
  public static PARTNER_FAILED_PANEL = "PARTNER_FAILED_PANEL";
  public static PARTNER_SALES_PLAN_DETAILS_PANEL ="PARTNER_SALES_PLAN_DETAILS_PANEL";
  public static PARTNER_DEVIE_PRICE_PANEL = "PARTNER_DEVICE_PRICE_PANEL";
  public static PARTNER_BUNDLE_SELECTION_PANEL = "PARTNER_BUNDLE_SELECTION_PANEL";
  public static PARTNER_PLAN_DETAIL_PANEL = "PARTNER_PLAN_DETAIL_PANEL";
  public static PARTNER_SALES_REVIEW_PANEL = "PARTNER_SALES_REVIEW_PANEL";
  public static PARTNER_ONBORADING_SALES_REGISTRATION_HEADER = "Sales Registration";
  public static PARTNER_ONBORADING_PRODUCT_SELECTION_HEADER = "Select Product";
  public static PARTNER_ONBORADING_DEVICE_DAPTURE_HEADER = "Capture Device Image";
  public static PARTNER_ONBORADING_CONFIRMATION_HEADER = "Confirmation";
  public static PARTNER_ONBORADING_SUCCESS_HEADER = "Successful";
  public static PARTNER_ONBORADING_NEXT_BUTTON_LABEL = "Next";
  public static PARTNER_ONBORADING_BACK_BUTTON_LABEL = "Back";
  public static PARTNER_ONBORADING_CONFIRM_BUTTON_LABEL = "Confirm";
  public static PARTNER_ONBORADING_BACK_HOME_BUTTON_LABEL = "Back to home";
  public static PARTNER_ONBORADING_BUTTON_ACTION_METHOD_CHANGE_TAB= "changeTab";
  public static PARTNER_ONBORADING_BUTTON_ACTION_METHOD_ONBOARD_PARTNER_POLICY= "onboardPartnerPolicy";
  public static PARTNER_ONBORADING_CHANGE_TAB_INC_METHOD_LABEL = "changeTabInc";
  public static PARTNER_ONBORADING_CHANGE_TAB_DEC_METHOD_LABEL = "changeTabDec";
  public static PARTNER_ONBORADING_CHANGE_TAB_SUBMIT_METHOD_LABEL = "onboardPartnerPolicy";
  public static PARTNER_ONBOARDING_CHANGE_TAB_VALIDATE_SKU_METHOD_LABEL = "validateSkuCode";
  public static PARTNER_ONBORADING_CHANGE_TAB_LANDING_METHOD_LABEL = "changeTabLanding";
  public static PARTNER_ONBOARDING_CHANGE_TAB_FIRST_TAB = "changeFirstTab";
  public static AGREEMENT_ID = "AGREEMENT_ID";
  public static AMTRUST_COMPANY_LABEL = " AmTrust Mobile Solutions Malaysia \
  Sdn. Bhd. ";
  public static BOLTECH_COMPANY_LABEL = " Bolttech Device Protection Malaysia \
  Sdn Bhd (“bolttech”) ";
  public static AMTRUST_DISCLAIMER_LABEL = " AmTrust Mobile Solution’s ";
  public static BOLTECH_DISCLAIIMER_LABEL = "bolttech’s "
  public static DIGI_BYOD_PARTNER_PRODUCT_ID = "DIGI_SHIELD_BYOD_39_ADLD";

  public static PARTNER_ONBOARDING_FAILED_TAB = 8;

  public static AEON_SMALL_APPLIANCE_CODE = "SMALL_APPLIANCE";
  public static AEON_LARGE_APPLIANCE_CODE = "LARGE_APPLIANCE";

  public static PARTNER_ONBOARDING_MY_SERVICE_PORTAL = [
    PolicyEnum.PARTNER_ASCENDSP_MALAYSIA,
    PolicyEnum.PARTNER_TMHGP_MALAYSIA,
    PolicyEnum.PARTNER_TMUNIFI_MALAYSIA,
    PolicyEnum.PARTNER_ULTIMATE_MALAYSIA,
    PolicyEnum.PARTNER_AEON_RET_MALAYSIA,
    PolicyEnum.PARTNER_ALLIT_MALAYSIA,
    PolicyEnum.PARTNER_ASHITA_MALAYSIA
  ]

  public static PARTNER_ONBOARDING_TW = [
    PolicyEnum.PARTNER_MSIG_TAIWAN,
    PolicyEnum.PARTNER_TMNEWA_TAIWAN
  ]
}
