import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppConstants {
  public static ENABLE_POLICY_TAB: string = 'showPolicyTab';
  public static IS_ADMIN: string = 'isAdmin';
  public static ENABLE_LOGIN: string = 'showTabs';
  public static ENABLE_CLAIM_TAB: string = 'showClaimTab';
  public static ENABLE_PRODUCTS_TAB: string = 'showProductsTab';
  public static ENABLE_USER_MANAGEMENT_TAB: string = 'showUserManagementTab';
  public static ROLE: string = 'role';
  public static TRUE: string = 'true';
  public static FALSE: string = 'false';
  public static LOGGED_IN_USER: string = 'loggedInUser';
  public static TITLE: string = 'CRMZ';
  public static GROUP = 'gidNumber';
  public static COUNTRY_CODE = 'postalAddress';
  public static JWT_TOKEN = 'jwtToken';
  public static ALL_COUNTRIES_ACCESS = 'regional';
  public static POLICY_MODULE = 'policy';
  public static CLAIM_MODULE = 'claim';
  public static PRODUCT_MODULE = 'product';
  public static USER_MANAGEMENT_MODULE = 'usermanagement';
  public static PARTNER_ID = 'partnerId';
  public static MULTIPLE_PARTNER_ID = 'multiplePartnerId';
  public static NONE = 'none';
  public static POLICY_LISTING_MANDATORY_CONDITION = 'Service Contract IMEI or Number or Status or Customer Identification No must be provided';
  public static RESPONSE_FAILED = 'Failed';
  public static RESPONSE_ERROR = 'ERROR';
  public static POLICY_NUMBER = 'policyNumber';
  public static CUSTOMER_NAME = 'customerName';
  public static POLICY_IMEI_NUMBER = 'policyIMEINumber';
  public static POLICY_STATUS = 'policyStatus';
  public static CUSTOMER_MOBILE_NUMBER = 'custMobileNumber';
  public static MOBILE_NUMBER = 'mobileNumber';
  public static ID_NUMBER = 'idNumber';
  public static CUST_COUNTRY_CODE = 'countryCode';
  public static CUSTOMER_ID = 'customerId';
  public static FIRST_NAME = 'firstName';
  public static LAST_NAME = 'lastName';
  public static CUSTOMER_FIRST_NAME = 'customerFirstName';
  public static CUSTOMER_LAST_NAME = 'customerLastName';
  public static POLICY_COVERAGE = 'coverage';
  public static CUSTOMER_EMAIL = 'customerEmail';
  public static MAIL = 'email';
  public static CLIENT_TRANSACTION_NUMBER = 'clientTransactionNumber';
  public static BROKER_CONTRACT_NUM = 'brokerContractNum';
  public static BROKER_ORDER_ID = 'brokerOrderId';
  public static PARTNER_CONTRACT_ID = 'partnerContractId';
  public static STATUS_COMPLETE = 'complete';
  public static CURRENT_PAGE = 'currentPage';
  public static CLIENT_NAME = 'Please select the Client name';
  public static AUDIT_MODULE_MANDATORY = 'Please select a Module';
  public static POLICY_REFUND_TYPE_MANDATORY = 'Refund type is mandatory.';
  public static POLICY_CANCEL_MODULE_MANDATORY = 'Reason for cancel is mandatory.';
  public static POLICY_CANCEL_REQUESTED_BY_PARTNER_MANDATORY = 'Cancellation requested date is mandatory.';
  public static POLICY_CANCEL_MODULE_MIN_LENGTH = 'Min length of cancel reason must be 50.';
  public static POLICY_WITHDRAWN_REQUESTED_MANDATORY = 'Withdrawn requested date is mandatory.';
  public static POLICY_VOID_MODULE_MANDATORY = 'Reason for void is mandatory.';
  public static POLICY_VOID_MODULE_MIN_LENGTH = 'Min length of void reason must be 50.';
  public static HEADERS = ['Client Transaction No', 'Policy Number', 'Contract Sold Date/Policy Purchase date', 'Product Name', 'Customer Name',
    'CUST ID NUMBER', 'Customer Mobile Number', 'Customer Email', 'Customer Address', 'Customer City',
    'Store Code', 'Store Name', 'Store Address', 'Store City', 'Sales Rep id', 'Sales Rep Name',
    'Device Retail Price(RRP)', 'Device Type', 'Device Make', 'Device Model', 'Device Color', 'IMEI', 'Name Goods Type',
    'Policy Start Date', 'Policy End Date', 'Device value Sum Covered', 'Serial No', 'Validation Failures', "Alert Error"];
  public static TAB_NAME = 'Policy-Logs';
  public static DATE_CREATED = 'dateCreated';
  public static NO_DATA_FOUND = 'No Records Found';
  public static RECORD_DELETED_SUCCESSFULLY = 'Record deleted successfully';
  public static ENABLE_PARTNERS_TAB: string = 'showPartnersTab';

  public static PASSWORD_RESET_SUCCESS_MESSAGE: string = "Your password has been reset.";
  public static FAILURE_MESSAGE: string = "Something went wrong";

  public static SUCCESS_STATUS_IN_RESPONSE: string = "SUCCESS";
  public static SUCCESS_STATUS: string = "Success";
  public static PASSWORD_RESET_TITLE_LABEL: string = "Password Reset";

  public static PASSWORD_SENT_TO_EMAIL_MESSAGE: string = "Password reset link has been sent to your registered email and it will be valid for next 15 minutes"


  public static STATUS_FIELD_IN_RESPONSE: string = "status";
  public static MESSAGE_FIELD_IN_RESPONSE: string = "message";

  public static EMAIL_NOT_FOUND_RESPONSE: string = "EMAIL NOT FOUND";

  public static PASSWORD_REGEX: any = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()\[{}\]\-:;',?/*~$^+=<>_%."`|]).{8,20}$/;

  public static ROLE_UPLOAD = 'Upload';
  public static ROLE_CONFIG = 'Config';
  public static ROLE_CREATE = 'Create';
  public static ROLE_UPDATE = 'Update';
  public static ROLE_READ = 'Read';
  public static RESPONSE_STATUS_200: string = "200";
  public static CUSTOMER_UPDATE_SUCCESS_RESPONSE: string = "CUSTOMER_UPDATED";
  public static CUSTOMER_UPDATED_SUCCESSFULLY_MESSAGE: string = "Customer Updated Successfully";
  public static CUSTOMER_COULD_NOT_BE_UPDATED_MESSAGE: string = "Customer could not be Updated";
  public static REQUEST_ERROR_MESSAGE: string = "Request could not be completed";
  public static BLANK_STRING: string = "";

  public static HIDE_HEADERS_TAB = 'hideHeaderTab';
  public static FORCED_PASSWORD_RESET_ALERT = 'forcedPasswordResetAlert';
  public static VERSION_FIREFOX = "versionFirefox";
  public static VERSION_CHROME = "versionChrome";
  public static BROWSER_MISMATCH = "browserMismatch";
  public static BROWSER_CHROME = "Chrome";
  public static BROWSER_FIREFOX = "Firefox";
  public static BROWSER_SAFARI = "Safari";
  public static USER_ACCESS_DENIED_CODE = "200.005";
  public static SESSION_EXPIRED = "Session Expired";

  public static OLD_PASSWORD_NOT_MATCH = "The Old Password does not match";
  public static NUMERIC_REGEX = /[0-9]/
  public static MATCHED_RESPONSE_STRING: string = "Matched";
  public static FORCED_PASSWORD_RESET_SUCCESS_MESSAGE: string = "Password is changed now, Now you can login in to CRMZ with changed password.";
  public static YES = "Yes";
  public static NO = "No";
  public static NEW = "NEW";
  public static OLD = "OLD";
  public static DEVICE_SERIAL_NUMBER = "deviceSerialNo";
  public static STAMP = "stamp";
  public static USER_ATTR = "userAttr";
  public static IS_CALLED_FROM_FORCED_PASSWORD_RESET = "isCalledFromForcedPasswordReset";
  public static IS_CALLED_FROM_FORGOT_PASSWORD_RESET = "isCalledFromForgotPasswordReset";
  public static ACCESSED_AT = "accessedAt";
  public static MESSAGE_NEW_PASSWORD_SAME_AS_OLD_PASSWORD: string = "You can not set the last password as current password.";
  public static CUSTOMER_ID_MANDATORY = 'Please enter customer id';
  public static OPERATOR_OR = "OR";
  public static OPERATOR_AND = "AND";
  public static VALIDATION_RULE_OBV_001 = "OBV_001";
  public static VALIDATION_RULE_OBV_002 = "OBV_002";
  public static VALIDATION_RULE_OBV_003 = "OBV_003";
  public static VALIDATION_RULE_OBV_004 = "OBV_004";
  public static VALIDATION_RULE_OBV_005 = "OBV_005";
  public static VALIDATION_RULE_OBV_006 = "OBV_006";
  public static REQUEST_COMMANDS = "commands";
  public static REQUEST_OPERATOR = "operator";
  public static REQUEST_AGREEMENT_NUMBER = "agreementNumber";
  public static REQUEST_ONBOARDING_FIELDS = "onboardingFields";
  public static PRODUCT_SELECTION_LABEL: string = "Product Selection";
  public static ONBOARDING_FIELD_PRODUCT_NAME: string = "productName";
  public static ONBOARDING_FIELD_CUSTOMER_ID: string = "customerId"
  public static FORM_SUBMISSION_VALIDATION_FAILED = "Validation Failed";
  public static PRODUCT_EXIST = "Product name has been used in the system. Please use another product name.";
  public static DEFAULT_DATE_SEPARATOR = "/";
  public static PARTNER_ID_SAMSUNG_THAILAND = "THSAMSUOEMNA01";
  public static FIRST_ACTIVE_YEAR = "FIRST_ACTIVE_YEAR";
  public static SECOND_ACTIVE_YEAR = "SECOND_ACTIVE_YEAR";
  public static FIRST_ACTIVE_YEAR_BOUND = 5;
  public static SECOND_ACTIVE_YEAR_BOUND = 10;
  public static ONSITE_CLAIM_COUNT_BOUND_ERROR = "Warning: On Site Claim limit of this Service Contract has been breach. Click Proceed to proceed the claim anyways, click Cancel to cancel the claim";
  public static ONSITE_CLAIM_BALANCE_RRP_ERROR = "Warning: Balance RRP Claim limit of this Service Contract has been breach. Click Proceed to proceed the claim anyways, click Cancel to cancel the claim";
  public static CLAIM_STATUS_COMPLETED = "COMPLETED";
  public static CLAIM_STATUS_CODE_COMPLETED = "CS_007";
  public static CLAIM_SUB_STATUS_CLOSED = "CLOSED";
  public static CLAIM_SUB_STATUS_CODE_CLOSED = "CSS_039";
  public static DEFAULT_UID_NUMBER = '1001';
  public static DEFAULT_STATUS = 'Pending Request Approval';
  public static COUNTRY_PENDING_APPROVAL = 'Pending Approval';
  public static COUNTRY_APPROVED = 'Approved';
  public static LDAP_HOME_DIRECTORY_PATH = '/home/users/';
  public static USER_FIRST_LOGIN = 'firstLogin';
  public static DATE_FORMAT_DD_MM_YYYY = 'dd/MM/yyyy';
  public static DATE_TIME_FORMAT_DD_MM_YY_HH_MM = 'dd/MM/yyyy HH:mm:ss';
  public static DATE_FORMAT_YYYY_MM_DD_T_HH_MM_SS_SSS = 'YYYY-MM-DDTHH:mm:ss.SSS';
  public static DATE_FORMATE_YYYY_MM_DD_T_HH_MM_SS_SSS_Z = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
  public static DATE_FORMATE_YYYYMMDDHHmmss = 'YYYYMMDDHHmmss';
  public static POLICY_CANCELLATION_DEFAULT_NOTE = "The service contract will turn cancelled on [Grace Period End Date].";
  public static POLICY_CANCELLATION_IMMEDIATELY = "The service contract will be withdrawn /terminated  immediately";
  public static UN_AUTHENTICATED_ROUTE = "unauthenticatedRoute";
  public static USER_IDENTIFICATION_MAX_LENGTH = 'Max Length For Identification Number must be 50.';
  public static userApiKey = 'apiKey'
  public static EXACT_MATCH = 'exactMatch'
  public static SHOW_PAYMENT_DETAIL = 'showPaymentDetail'
  public static NO_SEARCH_CRITERIA_SPECIFIED = 'No search criteria specified';
  public static MANDATORY_FILL = 'Please fill all the mandatory fields.';

  public static P = 'p'
  public static OK = "OK"
  public static INVALID_CHECKER_URL = 'INVALID ACTION URL'
  public static EXPIRED_CHECKER_URL = 'ACTION URL EXPIRED'
  public static EXPIRED_STATUS_IN_RESPONSE: string = "EXPIRED";
  public static APPROVED: string = "APPROVED";
  public static REJECTED: string = "REJECTED";
  public static ACTIVE: string = "Active";
  public static INACTIVE: string = "InActive";
  public static IN_ACTIVE: string = "In Active";
  public static URL_EXPIRED: string = "URL_EXPIRED";
  public static MAKER_REQUEST: string = "Maker request";
  public static MAKER_REQUEST_UPDATE_FAIL: string = "Fail to update maker request";
  public static CHECKER_STATUS = "checkerStatus";
  public static TYPE = "type";
  public static LOGGED_IN_USER_MAIL: string = 'loggedInUserEmail';

  public static REJECTION_REASON_MIN_LENGTH = 'Min length of rejection reason must be 30.';
  public static REJECTION_REASON = ' Approve or Rejection reason can not be empty or min length must be 30.';

  public static TP_HEADERS = ['Policy Number', 'Policy IMEI Number', 'TP Payment Status', 'TP Payment Ref',
      'TP Payment Date', 'Partner TP Payment Status', 'Partner TP Payment Ref', 'Partner TP Payment Date','Validation Failures'];
  public static BACKDATEDROLES = [505,503,501];
  public static CLAIM = 'CLAIM';
  public static PRODUCT = 'PRODUCT';

  public static FILE_SIZE_ERROR = 'File size exceeds 10mb max file size';
  public static INVALID_FILE_ERROR = 'Please select a valid file.';
  public static UPLOAD_FILE_ERROR = 'Error occurred while uploading invoice docuement. Please go back and upload file.';
  public static ADD_BUTTON_TEXT = 'Add';
  public static REUPLOAD_BUTTON_TEXT = 'Re-upload';

  public static AUTOMATED_LOGISTICS = ["lalamove", "borzo", "rpx", "ghtk", "grab express", "consegnia", "ahamove"];
  public static AUTOMATED_LOGISTICS_COUNTRIES = ["ID", "PH", "MY", "VN"];
  public static LALAMOVE_COURIER = "lalamove";
  public static PICKUP_NOTES_FOR_PH = 'DRIVER PLEASE READ:\nORDER PLACED AND PAID BY "BOLTTECH" PAYMENT VIA WALLET\nCALL BOLTTECH 0917-637-2333 FOR ANY CONCERN\nSHIPPER NAME: #custName#\nPICK UP: SIRANG CELLPHONE, DO NOT COLLECT ANY PAYMENT FROM SHIPPER.\nDERECHO NA SA DROP OFF - WAG NA TAWAGAN\nKAILANGAN NG PICTURE NG MAGRERECEIVE BAGO ICOMPLETE ANG ORDER\nSHIPPER NUMBER #custContact#\nSR# #claimId#';
  public static ALLOWED_CANCEL_ORDER_COURIER_STATUSES = ['Booked', 'Driver Assigned', 'OutForPickUP', 'PickedUp', 'OutForDelivery', 'NoPackage', 'Failed delivery - NCI'];
  public static SUPPLIER_SECTION_COUNTRIES = ["ID", "VN", "TH", "PH", "MY", "HK", "TW"];
  public static SUPPLIER_SECTION_OUTCOME = ["BER", "THEFT", "SWAP"];
  public static SUPPLIER_SWITCH_PROGRAMME_OUTCOME = ["AE", "AB"];
  public static OMS_PICKUP = "Customer Pickup";
  public static OMS_DELIVERY = "Repair Center Pickup";
  public static BLANCCO_PRICING_UPLOAD_SUCCESS = "Pricing file uploaded successfully";
  public static BLANCCO_PRICING_UPLOAD_FAILED = "Pricing file upload failed";
  public static CLAIM_DEACTIVATION_MODULE_MANDATORY = 'Reason for claim deactivation is mandatory.';
  public static CLAIM_DEACTIVATION_MODULE_MIN_LENGTH = 'Min length of claim deactivation reason must be 50.';
  public static CLAIM_ID_DELETED = ' is deleted from the system';
  public static CREATE_CLAIM_PIN_CODE_REQUIRED_COUNTRIES = ["ID", "TH", "PH", "MY"];
  public static POLICY_MODULE_REASON_FOR_CHANGE_MANDATORY = 'Reason for IMEI/SNO change is mandatory.';
  public static POLICY_MODULE_REASON_FOR_CHANGE_MIN_LENGTH = 'Min length of IMEI/SNO change reason must be 20.';
  public static MAKER_CHECKER_REJECTION_REASON = "REJECTED_BY_SAMSUNG_SWAP_API";
  public static COUNTRIES = {
    ID: "ID",
    TH: "TH",
    PH: "PH",
    VN: "VN"
  }

  public static HINGE_CREASE_SUPPORT_COUNTRIES = ["PH", "ID", "VN", "TH"];

  public static ASIA_COUNTRIES = ["HK", "ID", "MO", "MY", "PH", "TW", "VN", "TH"];
  public static EUROPE_COUNTRIES = ["AT", "BE", "DK", "FI", "FR", "IE", "NL", "NO", "SE"];

  public static HINGE_CREASE_HELP_TEXT = "Are you experiencing any unusual sounds, stiffness, or irregular movements when folding or unfolding the phone?\n\nAre there any signs of any signs of damage, misalignment, or unusual wear at the hinge?\n\nAre you noticing any glitches or abnormalities on the display when using the phone, especially around the hinge or crease area?";

  public static COUNTRY_WISE_SAMSUNG_PARTNERS = {
    PH: ["PHSAMSUOEMDG01", "PHSAMSUOEMCF01", "PHSAMSUOEMNA01"],
    ID: ["IDSAMSUOEMCF01", "IDSAMSUOEMNA01", "IDSAMSUOEMCE01"],
    VN: ["VNSAMSUOEMNA01"],
    TH: ["THSAMSUOEMNA01"]
  }
  public static CHECKER_APPROVING_RIGHTS =  [
    { "departmentNumber":12,"name":"Imei/Sn","fieldName":["policyIMEINumber","deviceSerialNo"],"module":"policy"},
    { "departmentNumber":13,"name":"PolicyStartEndDate","fieldName":["policyStartDate","policyEndDate"],"module":"policy"},
    { "departmentNumber":14,"name":"PolicyStatus","fieldName":["policyStatus"],"module":"policy"},
    { "departmentNumber":15,"name":"ClaimStatus","fieldName":["status,claimSubStatus"],"module":"claim"},
    { "departmentNumber":16,"name":"RRP","fieldName":["deviceRRP"],"module":"claim"},
    { "departmentNumber":17,"name":"Device Info","fieldName":["deviceType","deviceMake","deviceModel"],"module":"policy"},
    { "departmentNumber":18,"name":"Tenure In Months","fieldName":["tenureInMonths"],"module":"product"},
    { "departmentNumber":19,"name":"claims fields","fieldName":["assignee", "freezeStartDate", "freezeStartTime", "freezeEndDate", "freezeEndTime", "freezeStartDate2", "freezeStartTime2", "freezeEndDate2", "freezeEndTime2", "customerAgreeRejectSwap", "repairCompleteDate", "repairCompleteTime", "completeDocumentReceivedDate", "completeDocumentReceivedTime"],"module":"claim"},
  ];

  public static COURIER_CODE_MAPPING = {
    "PH": {
      "lbc express": "LBEXVIS",
      "cargo king tacloban": "CKTAC",
      "jayzertech bacolod odl": "JZRBDCODL",
      "www express": "WEXVIS",
      "raycon cainta odl": "RAYODL",
      "borzo": "BRZMM",
      "consegnia": "CLC",
      "rc goldline odl": "RCGLCEB",
      "jayzertech rizal odl": "JZRRIZODL",
      "lalamove": "LLMM",
      "jayzertech odl cebu": "JZRCEBODL",
      "techq odl": "TECQODL",
      "abtek odl": "ABTODL",
      "cellactive palawan odl": "CELLACODL",
      "jayzertech batangas odl": "JZRBATODL",
      "jazzy cavite odl": "JAZCAVODL",
      "cargo king cdo": "CKCDO",
      "jayzertech cavite odl": "JZRCAVODL",
      "cellphone kingdom odl": "CELLKINGODL",
      "abest express": "ABEXLUZ",
      "jayzertech bulacan odl": "JZRBULODL",
      "cargo king palawan": "CKPPS",
      "jayzertech laguna odl": "JZRLAGODL",
      "jazzy odl": "JAZODL",
      "fixst la union odl": "FIXLUODL",
      "jayzertech pampanga odl": "JZRPPGODL",
      "cargo king bacolod": "CKBCD",
      "cargo king iloillo": "CKILO",
      "cargo king davao": "CKDVO",
      "chronicles odl": "CHROMM",
      "jazzy iloilo odl": "JAZODL",
      "jayzertech nueva ecija odl": "JZRNEODL",
      "321 electronics odl": "321ODL",
      "highlands odl": "HILANDODL",
      "jayzertech pangasinan odl": "JZRPANODL",
      "nebsklie quezon odl": "NEBODL",
      "jayzertech tarlac odl": "JZRTRLODL"
    },
    "TH": {
      "grab express": "GEPHU",
      "kerry express": "KETH"
    },
    "MY": {
      "gdex": "GDEXMY",
      "llmy": "LLMY",
      "lalamove": "LLMY",
      "captive rider": "CRMY"
    },
    "ID": {
      "lalamove": "LLMTAN",
      "rpx": "RPXID",
      "borzo": "BORID",
      "grab express": "GEID",
      "dhl": "DHLID"
    },
    "VN": {
      "grab express": "GEHAN",
      "ghtk": "GHTK",
      "ghn": "GHN",
      "lalamove": "LLMHP",
      "ahamove": "AHM"
    }
  };
  public static PERMANENT_DISABLED = "Permanent Disabled";

  public static PRIORITY_FEE_COUNTRIES = ["PH", "MY", "ID"];
  public static PRIORITY_FEES = {
    PH: 50,
    MY: 0,
    ID: 0
  }

  public static COUNTRY_SPECIFIC_ADDR_FORMAT = ["PH", "VN"];
}
