import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../components/user/login/login.service';
import { AppConstants } from '../../app.constants';
import { AppService } from '../../app.service';
import { PartnerOnboardingService } from '../partner-onboarding.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductService } from '../../common-services/product.service';

@Component({
  selector: 'app-partner-landing',
  templateUrl: './partner-landing.component.html',
  styleUrls: ['./partner-landing.component.css']
})
export class PartnerLandingComponent implements OnInit {

  constructor(private loginService: LoginService , public service: AppService ,
    private spinner : NgxSpinnerService , private productService:ProductService,private partnerOnboardingService : PartnerOnboardingService) {
      this.loginService.showDigiFailedOnboarding.next(AppConstants.FALSE);
      this.loginService.showDigiOnboardingApp.next(AppConstants.FALSE);
      this.loginService.showAppLandingPage.next(AppConstants.TRUE);
     }

  ngOnInit() {
    let getPartnerIds = this.service.getCurrentLoggedInPartnerids();
    if(getPartnerIds != null)
    {
      localStorage.setItem(AppConstants.PARTNER_ID, getPartnerIds);
    }
  }

  showAppLandingPage() {
    this.spinner.show();
    let partnerId = this.service.getCurrentLoggedInPartner();
    this.productService.findPartnerOnboardingConfig(partnerId).subscribe((response) => {
      if(response && response.obj != null){
        let configModel = response.obj;
        this.partnerOnboardingService.setOnboardingConfig(configModel);
        this.loginService.showDigiOnboardingApp.next(AppConstants.TRUE);
        this.spinner.hide();
      }
    });
  }

  showDigiSheildNewDeviceOrByodApp(partnerId:string) {
    this.spinner.show();
    this.productService.findPartnerOnboardingConfig(partnerId).subscribe((response) => {
      if(response && response.obj != null){
        this.service.extractOnePartnerIdAndSetInLocalStorage(partnerId);

        let configModel = response.obj;
        this.partnerOnboardingService.setOnboardingConfig(configModel);
        this.loginService.showDigiOnboardingApp.next(AppConstants.TRUE);
        this.spinner.hide();
      }
    });
  }
}
