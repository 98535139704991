import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppConstants } from '../app.constants';
import { AppService } from '../app.service';
import { AuditReportConstants } from '../audit/audit-report/audit-report.constant';

@Injectable({
  providedIn: 'root'
})
export class AuditService {
  header: any;

  constructor(private http: HttpClient) {
    //IFS-559 - Removed the local storage x-api-key
    this.header = '';
  }

  public claimAuditSearch(formData): Observable<any> {
    return this.http.post(environment.claimUrl + 'auditLogs', formData,{
    }
    );
  }

  public repairAuditSearch(formData): Observable<any> {
    return this.http.post(environment.repairUrl + 'auditLogs', formData, this.header);
  }

  public policyAuditSearch(formData): Observable<any> {
    return this.http.post(environment.policyUrl + 'auditLogs', formData, this.header);
  }

  public parentPolicyAuditSearch(formData): Observable<any> {
    return this.http.post(environment.policyUrl + 'auditLogs/parent', formData, this.header);
  }

  public policyAuditReport(formData): Observable<any> {
    return this.http.post(environment.policyUrl + 'audit-report', formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  public claimAuditReport(formData): Observable<any> {
    return this.http.post(environment.claimUrl + 'audit-report', formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  public customerAuditSearch(formData): Observable<any> {
    return this.http.post(environment.customerServiceUrl + '/auditLogs', formData, this.header);
  }

  public repairAuditReport(formData): Observable<any> {
    return this.http.post(environment.repairUrl + 'audit-report', formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  public customerAuditReport(formData): Observable<any> {
    return this.http.post(environment.customerServiceUrl + '/audit-report', formData, {
      reportProgress: true,
      observe: 'events'
    });
  }
  public planAndSkuReport(formData): Observable<any> {
    return this.http.post(environment.productUrl + 'audit-report', formData, {
      reportProgress: true,
      observe: 'events'
    });
  }
  public userApprovalReport(formData): Observable<any> {
    return this.http.post(environment.productUrl + 'audit-report', formData, {
      reportProgress: true,
      observe: 'events'
    });
  }
  public EoConfigurationReport(formData): Observable<any> {
    return this.http.post(environment.productUrl + 'audit-report', formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  public getDaysDiffBetweenTwoDates(toDate, fromDate) {
    const date1 = new Date(toDate);
    const date2 = new Date(fromDate);
    const timeDiff = date1.getTime() - date2.getTime();
    const daysDiff = timeDiff / (1000 * 3600 * 24);
    return daysDiff;
  }

  public getAuditJSON(filePath): Observable<any> {
    return this.http.get(environment.s3Url+filePath).pipe(
      map(data => { return data as string []; }));
  }

  public activityAuditSearch(formData): Observable<any> {
    return this.http.post(environment.activityUrl + 'auditLogs', formData, this.header);
  }

  public productAuditSearch(formData): Observable<any> {
    return this.http.post(environment.productUrl + 'auditLogs', formData, this.header);
  }

  public userApprovalAuditSearch(formData): Observable<any> {
    return this.http.post(environment.productUrl + 'auditLogs/userApproval', formData, this.header);
  }

  public notificationAuditSearch(formData): Observable<any> {
    return this.http.post(environment.notificationUrl + 'auditLogs', formData, this.header);
  }
}
