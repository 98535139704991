import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';
import { AppConstants } from '../../../app.constants';
import { IdentityService } from '../../../user-management/identity.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: UntypedFormGroup;

  constructor(public dialogRef: MatDialogRef<ForgotPasswordComponent>, private service: AppService,
    private formBuilder: UntypedFormBuilder, private identityService: IdentityService) {

  }

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: new UntypedFormControl('', {
        validators: [Validators.required, this.emailValidator.bind(this)],
        updateOn: "change"
      }),
      username: new UntypedFormControl('', {
        validators: [Validators.required],
        updateOn: "change"
      })
    })
  }

  emailValidator(control: AbstractControl): { [key: string]: boolean } | null {
    let emailRegexPattern = /^(([^<>()\[\]\\.,;:\s@“]+(\.[^<>()\[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (control.value != '' && !emailRegexPattern.test(control.value)) {
      return { 'emailPattern': true };
    }
    return null;
  }

  refreshValidity() {
    Object.keys(this.forgotPasswordForm.controls).forEach(field => {
      let control = this.forgotPasswordForm.get(field);
      control.updateValueAndValidity();
    })
  }

  onSubmit() {
    this.refreshValidity();
    if (this.forgotPasswordForm.valid) {
      let email = this.forgotPasswordForm.get('email');
      let username = this.forgotPasswordForm.get('username');
      this.identityService.generateAndSendNewPassword(email.value, username.value).subscribe(res => {
        let response: any = res;
        if (response && response.status === 'Success') {
          this.service.showInfo(AppConstants.PASSWORD_SENT_TO_EMAIL_MESSAGE, AppConstants.PASSWORD_RESET_TITLE_LABEL);
          this.dialogRef.close();
        } else {
          this.service.showInfo(response.message, AppConstants.PASSWORD_RESET_TITLE_LABEL);
        }
      })
    }
  }
}
