import { PolicyEnum } from '../model/policy.enum';

export class PolicyConstants {
  public static EDIT_LOCK_MESSAGE = 'Editing of policy';
  public static UPLOAD_ACTION_CANCEL = "CANCEL_UPLOAD";
  public static UPLOAD_ACTION_NEW_SALES = "NEW_SALES";
  public static UPLOAD_LICENSE_PRE_ONBOARDING = "LICENSE_PRE_ONBOARDING";
  public static UPLOAD_ACTION_VOID = "VOID_UPLOAD";
  public static UPLOAD_ACTION_EXTENSION = "EXTENSION";
  public static UPLOAD_ACTION_UPDATE = "UPDATE";
  public static PARTNER_PRODUCT_CODE_DIGI = "DIGI";
  public static PARTNER_PRODUCT_CODE_AEON = "AEON";
  public static PARTNER_PRODUCT_CODE_DIGI_SHIELD = "DIGI";
  public static BATCHID_IS_REQUIRED = 'BatchId Is Required';
  static MANDATORY_FILL = 'BatchId should be alphanumeric';
  static NO_SEARCH_CRITERIA_SPECIFIED = 'No search criteria specified';
  static ACTIVE_CLAIM_EXIST_MESSAGE = 'Active claim already exist for policyNumber: ';
  static ACTIVE_CLAIM_TITLE = 'Active claims';
  static ACTIVE = 'Active';
  static ACTIVE_CODE = '10001';
  static CANCELLED = 'Cancelled';
  static CANCELLED_CODE = '90002';
  public static SAMSUNG_BE = 'BE';
  public static SAMSUNG_NL = 'NL';
  public static SAMSUNG_DK = 'DK';
  public static SAMSUNG_NO = 'NO';
  public static SAMSUNG_SE = 'SE';
  public static SAMSUNG_FI = 'FI';
  public static EVOLLIS = 'EVOLLIS';
  public static FULFILLMENT_COMPLETED = 'fulfillment-completed';

  // add withdraw
  static WITHDRAWN = 'Withdrawn';
  static WITHDRAWN_CODE = '90003';
  static REGEX_COMMA = ',(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)';

  // add void
  static VOID = 'Void';
  static VOID_CODE = '90006';
  static MAX_INVOICE_CHAR_LEN = 18;

  //button action
  static BUTTON_VOID = 'void';
  static BUTTON_CANCEL = 'cancel';
  static BUTTON_WITHDRAWN = 'withdrawn';
  static BUTTON_SOFT_DELETE_WITH_CANCELLATION = 'soft_delete_with_cancellation';

  static OUTSTANDING_PAYMENT = 'outstanding_payment';

  public static samsungPartners = [
    PolicyEnum.PARTNER_SAMSUNG_PHILLIPINES_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_PHILLIPINES_D2C,
    PolicyEnum.PARTNER_SAMSUNG_INDONESIA_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_INDONESIA_CF,
    PolicyEnum.PARTNER_SAMSUNG_INDONESIA_CE,
    PolicyEnum.PARTNER_SAMSUNG_PHILLIPINES_CF,
    PolicyEnum.PARTNER_SAMSUNG_VIETNAM_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_TSE,
    PolicyEnum.PARTNER_SAMSUNG_VIETNAM_RETAIL_GRAB,
    PolicyEnum.PARTNER_SAMSUNG_SINGAPORE

  ];

  public static euPartners = [
    PolicyEnum.PARTNER_AUSTRIA_DREI,
    PolicyEnum.PARTNER_AUSTRIA_MTEL,
    PolicyEnum.PARTNER_EIR,
    PolicyEnum.PARTNER_THREE_SWEDEN
  ];

  public static isSamsungEU = [
    PolicyEnum.PARTNER_SAMSUNG_BELGIUM,
    PolicyEnum.PARTNER_SAMSUNG_NETHERLANDS,
    PolicyEnum.PARTNER_SAMSUNG_SWEDEN,
    PolicyEnum.PARTNER_SAMSUNG_NORWAY,
    PolicyEnum.PARTNER_SAMSUNG_DENMARK,
    PolicyEnum.PARTNER_SAMSUNG_FINLAND,
    PolicyEnum.PARTNER_SAMSUNG_B2B_BELGIUM,
    PolicyEnum.PARTNER_SAMSUNG_B2B_NETHERLANDS,
    PolicyEnum.PARTNER_SAMSUNG_B2B_SWEDEN,
    PolicyEnum.PARTNER_SAMSUNG_B2B_NORWAY,
    PolicyEnum.PARTNER_SAMSUNG_B2B_DENMARK,
    PolicyEnum.PARTNER_SAMSUNG_B2B_FINLAND,
  ];

  public static isSamsungEUB2B = [
    PolicyEnum.PARTNER_SAMSUNG_B2B_BELGIUM,
    PolicyEnum.PARTNER_SAMSUNG_B2B_NETHERLANDS,
    PolicyEnum.PARTNER_SAMSUNG_B2B_SWEDEN,
    PolicyEnum.PARTNER_SAMSUNG_B2B_NORWAY,
    PolicyEnum.PARTNER_SAMSUNG_B2B_DENMARK,
    PolicyEnum.PARTNER_SAMSUNG_B2B_FINLAND,
  ];

  public static isSamsungEUB2C = [
    PolicyEnum.PARTNER_SAMSUNG_BELGIUM,
    PolicyEnum.PARTNER_SAMSUNG_NETHERLANDS,
    PolicyEnum.PARTNER_SAMSUNG_SWEDEN,
    PolicyEnum.PARTNER_SAMSUNG_NORWAY,
    PolicyEnum.PARTNER_SAMSUNG_DENMARK,
    PolicyEnum.PARTNER_SAMSUNG_FINLAND,
  ];

  public static isSamsungEUD2C = [
    PolicyEnum.PARTNER_SAMSUNG_BELGIUM,
    PolicyEnum.PARTNER_SAMSUNG_NETHERLANDS,
    PolicyEnum.PARTNER_SAMSUNG_FINLAND,
  ];

  public static isEvollis = [
    PolicyEnum.PARTNER_SAMSUNG_BELGIUM,
    PolicyEnum.PARTNER_SAMSUNG_NETHERLANDS,
  ];

  public static isSamsungSC = [
    PolicyEnum.PARTNER_SAMSUNG_BELGIUM,
    PolicyEnum.PARTNER_SAMSUNG_NETHERLANDS,
    PolicyEnum.PARTNER_SAMSUNG_SWEDEN,
    PolicyEnum.PARTNER_SAMSUNG_NORWAY,
    PolicyEnum.PARTNER_SAMSUNG_DENMARK,
    PolicyEnum.PARTNER_SAMSUNG_FINLAND,
    PolicyEnum.PARTNER_SAMSUNG_B2B_BELGIUM,
    PolicyEnum.PARTNER_SAMSUNG_B2B_NETHERLANDS,
    PolicyEnum.PARTNER_SAMSUNG_B2B_SWEDEN,
    PolicyEnum.PARTNER_SAMSUNG_B2B_NORWAY,
    PolicyEnum.PARTNER_SAMSUNG_B2B_DENMARK,
    PolicyEnum.PARTNER_SAMSUNG_B2B_FINLAND,
    PolicyEnum.PARTNER_SAMSUNG_VIETNAM_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_PHILLIPINES_CF,
    PolicyEnum.PARTNER_SAMSUNG_INDONESIA_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_INDONESIA_CF,
    PolicyEnum.PARTNER_SAMSUNG_PHILLIPINES_D2C,
    PolicyEnum.PARTNER_SAMSUNG_VIETNAM_RETAIL_GRAB,
    PolicyEnum.PARTNER_SAMSUNG_TAIWAN_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_TSE,
    PolicyEnum.PARTNER_SAMSUNG_PHILLIPINES_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_MALAYSIA_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_HONGKONG_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_MACAU,
    PolicyEnum.PARTNER_SAMSUNG_MALAYSIA_TUNE,
    PolicyEnum.PARTNER_SAMSUNG_PHILLIPINES_CF

  ];

  public static asiaShopeePartner = [
    PolicyEnum.PARTNER_SHOPEE_THAILAND,
  ];

  public static isSamsungSEIN = [
    PolicyEnum.PARTNER_SAMSUNG_INDONESIA_RETAIL
  ];

  public static isSamsungSEHK = [
    PolicyEnum.PARTNER_SAMSUNG_HONGKONG_RETAIL
  ];

  public static isSamsungEUCountries = [
    this.SAMSUNG_BE, this.SAMSUNG_NL, this.SAMSUNG_DK, this.SAMSUNG_NO, this.SAMSUNG_FI, this.SAMSUNG_SE,
  ];

  public static isSamsungPartner(partnerId : string) : boolean{
    return (PolicyConstants.samsungPartners.findIndex(value => value === partnerId) >= 0);
  }

  public static isSEIN(partnerId : string) : boolean{
    return (PolicyConstants.isSamsungSEIN.findIndex(value => value === partnerId) >= 0);
  }

  public static isSEHK(partnerId : string) : boolean{
    return (PolicyConstants.isSamsungSEHK.findIndex(value => value === partnerId) >= 0);
  }

  public static isEuPartners(partnerId : string) : boolean{
    return (PolicyConstants.euPartners.findIndex(value => value === partnerId) >= 0);
  }

  public static isEIR_IE_Partner(partnerId : string) : boolean{
    return (PolicyConstants.euEIR_IE.findIndex(value => value === partnerId) >= 0);
  }

  public static isShopeeTH(partnerId : string) : boolean{
    return (PolicyConstants.asiaShopeePartner.findIndex(value => value === partnerId) >= 0);
  }

  public static isSamsungEUPartner(partnerId : string) : boolean{
    return (PolicyConstants.isSamsungEU.findIndex(value => value === partnerId) >= 0);
  }

  public static isSamsungEUB2BPartner(partnerId : string) : boolean{
    return (PolicyConstants.isSamsungEUB2B.findIndex(value => value === partnerId) >= 0);
  }

  public static isSamsungEUB2CPartner(partnerId : string) : boolean{
    return (PolicyConstants.isSamsungEUB2C.findIndex(value => value === partnerId) >= 0);
  }

  public static isSamsungSCPartners(partnerId : string) : boolean{
    return (PolicyConstants.isSamsungSC.findIndex(value => value === partnerId) >= 0);
  }

  public static isSKUUploadPartners(partnerId : string) : boolean{
    return PolicyConstants.isSamsungSCPartners(partnerId);
  }

  public static isSamsungEUCountryCode(countryCode : string) : boolean {
    return (PolicyConstants.isSamsungEUCountries.findIndex(value => value === countryCode) >= 0);
  }

  public static euEIR_IE = [
    PolicyEnum.PARTNER_EIR,
  ];

  public static isEvollisPartner(countryCode : string) : boolean {
    return (PolicyConstants.isEvollis.findIndex(value => value === countryCode) >= 0);
  }
}

