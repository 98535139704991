export class ClaimDocument {
  [x: string]: any;
  /* policy */
  rrp: string = '';
  balanceRRP: string = '';
  oldBalanceRRP: string = '';
  claimNo: string = '';
  policyNo: string = '';
  productName: string = '';
  productDesc: string = '';
  coverage: string = '';
  productCode: string = '';
  policyStartDate: string = '';
  policyEndDate: string = '';
  policyStatus: string = '';
  policyType: string = '';
  ecertUrl: string = '';
  brokerContractNum: string = '';
  retailerName: string = '';
  band: string = '';
  /* device detail */
  deviceType: string = '';
  imei: string = '';
  deviceSerialNo: string = '';
  make: string = '';
  model: string = '';
  modelName: string = '';
  colour: string = '';
  memoryStorage: number = 0;
  purchaseDate: string = '';
  deviceCost: number = 0;
  /* customer detail */
  customerName: string = '';
  customerLastName: string = '';
  customerContact: string = '';
  customerIdentityNo: string = '';
  customerAlternateContact: string = '';
  customerEmail: string = '';
  customerId: string;
  idType?:string;
  custIdNumber?:string;
  relationship: string = '';
  claimantName: string = '';
  claimantLastName: string = '';
  claimantContact: string = '';
  claimantAlternateContact: string = '';
  claimantEmail: string = '';
  claimantContactPrefix: string = '';
  claimantIdType?:string;
  claimantCustIdNumber?:string;
  channel: string = '';
  /* Claim detail */
  locale: string = '';
  collectContactPersonName: string='';
  collectContactPersonPhone: string='';
  claimType: string = '';
  damageType: string = '';
  vasMbgTAT: string = '';
  serviceTAT: string = '';
  claimFiveYear: boolean;
  extraProtection: boolean;
  issueDesc: string = '';
  additionalDetails: string = '';
  loanPhoneCondition: string = '';
  loanPhoneReceivedBy: string = '';
  checkedBy: string = '';
  loanConditionWhenReturn: string = '';
  requiredLogistic: string = '';
  dateOfIncident: string = '';
  timeOfIncident: string = '';
  withinCountry: boolean = true;
  country: string = '';
  travelDateFrom: string = '';
  travelDateTo: string = '';
  incidentCountry: string = '';
  policeReportNumber: string = '';
  policeReportDate: string = '';
  policeReportTime: string = '';
  policeStation: string = '';
  caseId: string = '';
  /* Claim workflow information */
  assignee: string = '';
  assigneeEmail: string = '';
  assigneeName: string = '';
  status: string = '';
  claimSubStatus: string = '';
  statusCode: string = '';
  subStatusCode: string = '';
  claimSubStatusLocale: string = '';
  claimSubStatusLocaleForSms: string = '';
  insertDate: string = '';
  insertBy: string = '';
  updateDate: string = '';
  lastDeclineUpdateOn: string = '';
  updateBy: string = '';
  lastCancelUpdateOn: string = '';
  lastCancelDeclineSubStatus: string = '';
  createDate: string = '';
  createTime: string = '';
  closedDate: string = '';
  closedTime: string = '';
  finalClosedDate: string = '';
  finalClosedTime: string = '';
  freezeStartDate: string = '';
  freezeStartTime: string = '';
  freezeEndDate: string = '';
  freezeEndTime: string = '';
  freezeStartDate2: string = '';
  freezeStartTime2: string = '';
  freezeEndDate2: string = '';
  freezeEndTime2: string = '';
  /* pickup address */
  requiresPickup: boolean = true;
  requiresLoan: boolean = false;
  requiresDelivery: boolean = true;
  collectAddress1: string = '';
  collectAddress2: string = '';
  collectCity: string = '';
  collectPostcode: string = '';
  collectBarangay: string = '';
  collectState: string = '';
  collectWard: string = '';
  collectCountry: string = '';
  collectStatus: string = '';
  collectTracking: string = '';
  collectDate: string = '';
  collectTime: string = '';
  collectCourier: string = '';
  collectPriorityFee: string = '';
  logisticsPickupNotes: string = '';
  collectCourierCode: string = '';
  isAutoSelectedLsp: boolean = false;

  // Pickup & Delivery automation
  logisticsDetails: LogisticsDetails;
  
  // Collect - Actual
  actualCollectDate: string = '';
  actualCollectTime: string = '';
  // Collect - Arrival
  arrivalCollectDate: string = '';
  arrivalCollectTime: string = '';
  pickupReturnToSourceDate: string = '';
  pickupReturnToSourceTime: string = '';
  pickUpHistory: PickUpAndDeliveryHistory[];

  // Dropoff
  scheduledDropOffDate: string = '';
  scheduledDropOffTime: string = '';
  // Dropoff - Actual
  actualDropOffDate: string = '';
  actualDropOffTime: string = '';

  // Pick Up from Repairer Date
  pickUpFromRepairerDate: string = '';
  pickUpFromRepairerTime: string = '';

  /* dispatch address */
  dispatchCenterName: string = '';
  dispatchCenterCode: string = '';
  repairPartnerId: string = '';
  repairPartnerName: string = '';
  arcEmail: string = '';
  dispatchCenterPrimaryContactNumber: string = '';
  dispatchCenterAlternateContactNumber: string = '';
  isAutoSelectedArc: boolean = false;
  replacementArcEmail: string = '';
  dispatchAddress1: string = '';
  dispatchAddress2: string = '';
  dispatchCity: string = '';
  dispatchPostcode: string = '';
  dispatchState: string = '';
  dispatchCountry: string = '';
  dispatchLatitude: string = '';
  dispatchLongitude: string = '';

  /* Supplier address */
  supplierCenterCode: string = '';
  supplierCenterName: string = '';
  supplierEmail: string = '';
  supplierCenterPrimaryContactNumber: string = '';
  supplierCenterAlternateContactNumber: string = '';
  supplierAddress1: string = '';
  supplierAddress2: string = '';
  supplierPostcode: string = '';
  supplierCity: string = '';
  supplierState: string = '';
  supplierCountry: string = '';
  supplierLatitude: string = '';
  supplierLongitude: string = '';

  /* Delivery address */
  deliveryAddress1: string = '';
  deliveryAddress2: string = '';
  deliveryCity: string = '';
  deliveryPostcode: string = '';
  deliveryState: string = '';
  deliveryWard: string = '';
  deliveryCountry: string = '';
  logisticsDeliveryNotes: string = '';
  deliveryStatus: string = '';
  deliveryTracking: string = '';
  deliveryDate: string = '';
  deliveryTime: string = '';
  deliveryCourier: string = '';
  deliveryPriorityFee: string = '';
  deliveryCourierCode: string = '';
  deliveryContactPersonName: string = '';
  deliveryContactPersonPhone: string = '';
  // Delivery - Actual
  actualDeliveryDate: string = '';
  actualDeliveryTime: string = '';
  // Delivery - Customr
  customerDeliveryDate: string = '';
  customerDeliveryTime: string = '';
  deliveryReturnToSourceDate: string = '';
  deliveryReturnToSourceTime: string = '';
  deliveryHistory: PickUpAndDeliveryHistory[];

  // Pickup
  scheduledPickUpDate: string = '';
  scheduledPickUpTime: string = '';
  // Pickup - Actual
  actualPickUpDate: string = '';
  actualPickUpTime: string = '';

  copyPolicyAddressToCollect: boolean;
  copyPolicyAddressToDelivery: boolean;
  /* payment detail */
  requiredPayment: boolean;
  transactionDocumentPath: string = '';
  receivedAmount: number = 0;
  paymentMethod: string = '';
  paymentReferenceNo: string = '';
  paymentStatus: string = '';
  isExcessFeeRequired: boolean;
  excessFee: string = '';
  excessFeePaidDate: string = '';
  excessFeeReceiveDate: string = '';
  excessFeeReceiveTime: string = '';
  topUpFee: string = '';
  topUpFeePaidDate: string = '';
  topUpFeeReceiveDate: string = '';
  topUpFeeReceiveTime: string = '';
  topUpFeeReceivedAmount: string = '';
  topUpFeePaymentMethod: string = '';
  topUpFeePaymentReferenceNo: string = '';
  topUpFeePaymentStatus: string = '';
  excessFeesCollectionStatus: string = '';
  excessFeesCollectionMethod: string = '';
  excessFeesCollectionDateTimeInString: string = '';
  excessFeesCancellationDateTimeInString: string = '';
  excessFeesRefundedDateTimeInString: string = '';

  /* Device assessment */
  assessBy: string = '';
  assessOn: string = '';
  assessTime: string = '';
  offeringSwapOn: string = '';
  offeringSwitchOn: string = '';
  assessReferenceNo: string = '';
  customerAgreeRejectSwap: string = '';
  diagnosticOutcome: string = '';
  // if diagnosticOutcome is true, below columns are required
  berApprovedDate: string = '';
  berApprovedTime: string = '';
  replacementPhoneOrderDate: string = '';
  replacementPhoneOrderTime: string = '';
  replacementCollectDate: string = '';
  replacementCollectTime: string = '';
  reasonForReplace: string = '';
  replaceDeviceSerialNo: string = '';
  replaceImei: string = '';
  replaceMake: string = '';
  replaceModel: string = '';
  replaceColor: string = '';
  replaceStorage: string = '';
  replacementStatus: string = '';
  cashSettledOrder: boolean;

  //loan requires
  loanRequiresImei: string = '';
  loanRequiresMake: string = '';
  loanRequiresModel: string = '';
  loanRequiresColor: string = '';
  loanRequiresStorage: string = '';

  replacementCost: number = 0;
  diagnosticCost: number = 0;
  swapTax: number = 0;
  switchTax: number = 0;
  swapCost: string = '';
  switchCost: string = '';
  totalSwapCost: number = 0;
  totalSwitchCost: number = 0;
  replacementTax: number = 0;
  totalReplacementCost: number = 0;

  // if diagnosticOutcome is false, below columns are required
  partUsed: string = '';
  partNames: string[];
  partNumbers: string[];
  partCosts : string[];
  tseMargins: string[];
  ascMargins: string[];
  partUsage1: string = '';
  partUsage2: string = '';
  partUsage3: string = '';
  otherParts: string = '';
  repairTargetCompleteDate: string = '';
  repairTargetCompleteTime: string = '';
  quotationReleaseDate: string = '';
  quotationReleaseTime: string = '';
  quotationApproveDate: string = '';
  quotationApproveTime: string = '';
  repairCompleteDate: string = '';
  repairCompleteTime: string = '';
  attemptDeliveryDate: string = '';
  attemptDeliveryTime: string = '';
  quotationPartCosts: number = 0;
  quotationLabourCosts: number = 0;
  quotationFreightCosts: number = 0;
  quotationRepairedTax: number = 0;
  quotationAirtimeAbuseCosts: number = 0;
  quotationRepairedTotalCost: number = 0;
  finalPartCosts: number = 0;
  finalLabourCosts: number = 0;
  finalFreightCost: number = 0;
  finalRepairedTax: number = 0;
  finalAirtimeAbuseCosts: number = 0;
  finalRepairedTotalCost: number = 0;
  claimDecision: boolean = false;
  declineReason: string = '';

  /* work order */
  workOrderId: string = '';
  workOrderStatus: string = '';
  repairSeverityCalculatedPercentage: string = '';
  workOrderRejectionReason: string = '';
  workOrderSparePartsReplacementReason: string = '';
  workOrderTechnicalReportFileName: string = '';
  workOrderTechnicalReportFileUrl: string = '';
  workOrderDamagedDeviceFileName: string = '';
  workOrderDamagedDeviceFileUrl: string = '';
  workOrderInvoiceFileName: string = '';
  workOrderInvoiceFileUrl: string = '';
  updateWorkOrderError: string = '';

  /* documents path */
  completeDocumentReceivedDate: string = '';
  completeDocumentReceivedTime: string = '';
  proofDocumentPath: string = '';
  supportDocumentPath: string = '';
  otherDocumentPath: string = '';
  partnerId: string = '';
  repairId: string = '';
  repairStatus: string = '';
  ascCode: string = '';
  isSamsungClaimPower: boolean;
  isSamsungClaimJourney: boolean;
  partnerProductId: string = '';
  partnerContractId: string = '';
  fromUI: boolean = true;

  // Flowable
  flowableProcessInstanceID: string;

  // Machine Learning
  mlClassification: string;
  mlRiskPercentage: string;

  claimLogId: string;
  statusChange: boolean;
  subStatusChange: boolean;
  integrationStatus: String;
  notificationRead: string[];
  draftId: Number;
  logisticsCost: string;
  valueAddedServices: string[];

  sku: string = '';
  isSelfClaimantUpdated: boolean;

  claimDocuments = [];

  // transitent fields
  createTimestamp?: any;
  onsiteClaimRequest: boolean = false;
  gracePeriodStartDate: string;
  gracePeriodEndDate: string;
  numberOfClaim: number;

  calculatedRepairValue: string = '';
  calculatedTheftValue: string = '';
  calculatedBerValue: string = '';
  calculatedSwitchSwapNewValue: string = '';
  calculatedCrackedScreenValue: string = '';
  calculatedEWBatteryValue: string = '';

  diagnosticResult: string = 'NA';
  diagnosticImageUrl: string[] = [];

  allowedClaimPeriodNotification?: string;
  numberOfClaimAllowed: number;
  numberOfReplacementAllowed: number;
  tenureInMonths: number;
  allowTravelPeriodInDays: number;
  claimTenurePerCycle: number;
  allowClaimNotificationPeriodInDays: number;
  allowLockPeriodInDays: number;
  excessFeeAutoCalculation: boolean;
  hasExcessFeeAdldRepair: boolean;
  hasExcessFeeAdldBer: boolean;
  hasExcessFeeTheft: boolean;
  hasExcessFeeAdldCrackedScreen: boolean;

  excessFeeTheft: string;
  excessFeeCrackedScreen: string;
  excessFeeADLDRepair: string;
  excessFeeADLDBER: string;
  excessFeeEWBER: string;
  excessFeeEWRepair: string;
  excessFeeFlag: string;

  allowTopUp: boolean;
  isWorldwideCover: boolean;
  isFlowableProductRuleActive: boolean = false;
  checkedClaimEligibility: boolean;
  isBatteryReplacementCoveredInPlan: boolean = false;

  //email logs
  emailLogs: ClaimEmailLogs[];
  smsLogs: ClaimSmsLogs[];

  // Customer portal info
  turnOnOff: boolean = null;
  isDiagnosed: boolean = null;
  touchScreen: boolean = null;
  isBent: boolean = null;
  frontCamera: boolean = null;
  backCamera: boolean = null;
  audio: boolean = null;
  mic: boolean = null;
  buttons: boolean = null;
  connection: boolean = null;
  charging: boolean = null;
  imeiBlockingRequested: boolean = null;
  deviceFrontColor: string = '';
  deviceBackColor: string = '';
  imeiBlockingRequestDate: string = '';
  imeiBlockingRequestTime: string = '';
  other: string = '';
  lockPinSelect: string = '';
  lockPin: string = '';
  subscriberNameFromSP: string = '';
  claimantAlternateEmail: string = '';
  requestSubmittedByFromSP: string = '';
  companyName: string = '';
  incidentReported: boolean = null;
  incidentLocation: string = '';
  deviceUsedBy: string = '';
  isClaimAccidentOrTheft: boolean = null;
  claimHandled: string = '';
  createdClaimFlag: string;
  cancelledClaimFlag: string;
  closedClaimFlag: string;
  updateDeviceFlag: string;
  disableNotification: boolean = false;

  //VAS Fields
  diagnosticDesc: string;
  customerAgreeRejectTradeIn:string;
  tradeInQuotation:number = 0;
  isVasPolicy: boolean;

  serviceType: string;
  serviceTypeCode: string;

  // elecboy fields
  assessmentResult: string = "";
  replacementModel: string = "";
  replacementModelDesc: string= "";
  replacementModelCost: number = 0;
  replaceReplacementCost: number = 0;
  replacementCode: string = "";
  voucherCost: number = 0;
  voucherCode: string = "";
  requestedAt: string="";
  deactivationDateTime: string = "";
  deactivationReason: string = "";

  coverageADLDBalanceRRP: string = "";
  coverageEWBalanceRRP: string = "";
  oldCoverageADLDBalanceRRP: string = "";
  oldCoverageEWBalanceRRP: string = "";
  newCoverageADLDBalanceRRP: string = "";
  newCoverageEWBalanceRRP: string = "";

  //DSTR-6244
  technicalReportApproved: boolean = null;
  technicalReportRejectionReason: string = "";
  technicalReportUpdateDateTime: string = "";

  //DSTR-6246
  replacementPartner: string = "";

  //DSTR-7453
  topUpAllowed: string = "";
  worldwideCover: string = "";
  worldwideCoverPeriod: number = 0;
  claimNotificationPeriod: number = 0;
  regulatory: string = "";
  activateAutomation: string = "";
  
  //DSTR-6419
  claimCohortHistoryList: ClaimCohortHistoryList[];

  //DSTR-7777
  berQuotationDate: string = "";
  berQuotationTime: string = "";

  //DSTR-8089
  replacementSku: string = "";

  static newClaimDocument(): ClaimDocument {
    return new ClaimDocument();
  }
}

export enum ClaimType { 'ADLD', 'Extended Warranty' }

export enum ClaimStatus {
  st1 = 'Reported',
  st2 = 'Verified : pending excess fees',
  st3 = 'Pending approval from U/W',
  st4 = 'Pending technical report',
  st5 = 'Pending Excess fee payment',
  st6 = 'Pending document',
  st7 = 'Pending top up',
  st8 = 'Customer undecided – claim cancelled',
  st9 = 'Customer undecided',
  st10 = 'No response – claim cancelled',
  st11 = 'Outside policy guideline',
  st12 = 'No drop off – claim decline',
  st13 = 'Callback',
  st14 = 'No respond – claim cancelled',
  st15 = 'Approved – pending parts / replacement phone',
  st16 = 'Approved – repair',
  st17 = 'Pick up arrangement and timeline confirmation (arrangement)',
  st18 = 'Pick up in transit',
  st19 = 'Unit under technical evaluation',
  st20 = 'Repair ongoing',
  st21 = 'Approved – pending replacement phone',
  st22 = 'Replacement – pending customer decision',
  st23 = 'Unit repaired : for delivery',
  st24 = 'Unit repaired – for customer pickup',
  st25 = 'Phone collected- close',
  st26 = 'Unit replaced – claim completed',
  st27 = 'Unit repaired – claim completed',
  st28 = 'Unit repaired- delivered to customer'
}


export interface Statues {
  id: string;
  value: string;
}

export interface Assignees {
  id: string;
  value: string;
}

export interface LogisticStatus {
  id: string;
  value: string;
}

export interface LogisticCourier {
  id: string;
  value: string;
}

export interface DriverDetails {
  id: string;
  name: string;
  contactNumber: string;
  vehiclePlateNumber: string;
  photo: string;
}

export interface LogisticsDetails {
  pickupDetails: PickUpDetails;
  deliveryDetails: DeliveryDetails;
  isOrderCreated: boolean;
  orderCreationErrorMessage: string;
  isClaimStatusSubStatusLocked: boolean;
  partnerErrors: string[];
}

export interface PickUpDetails {
  trackingUrl: string;
  driverDetails: DriverDetails;
}

export interface DeliveryDetails {
  trackingUrl: string;
  driverDetails: DriverDetails;
}

export interface PickUpAndDeliveryHistory {
  addressInfo: string;
  scheduleInfo: string;
  courierInfo: string;
}

export interface ClaimEmailLogs {
  date: string;
  dateUTC: Date;
  recipientEmail: Array<string>;
  subject: string;
  keyName: string;
}

export interface ClaimSmsLogs {
  date: string;
  dateTime: Date;
  recipientMobileNo: string;
  status: string;
  content: string;
}

export interface ClaimCohortHistoryList{
  claimStatus: string;
  claimSubStatus: string;
  claimStatusCode: string;
  claimSubStatusCode: string;
  dateOfChange: string;
}
