import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpParams, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppConstants } from '../app.constants';
import { PolicyEnum } from '../model/policy.enum';
import { AppService } from '../app.service';
import Utils from '../utility/utils';
import { PolicyConstants } from "../policy/policy.constants";

@Injectable({
  providedIn: 'root'
})
export class PolicyService {

  header: any;

  constructor(private http: HttpClient, private appService: AppService) {
    //IFS-559 - Removed the local storage x-api-key
    this.header = '';
  }

  remapEoConfigurationForPolicies(user, policyStartDateFrom, policyStartDateTo) {
    return <any>this.http.post(environment.policyUrl + 'remapEoConfigurationForPolicies?user=' +
      user + '&fromDate=' + policyStartDateFrom + '&toDate=' + policyStartDateTo, null, this.header);
  }

  uploadPolicies(formData) {
    return <any>this.http.post(environment.policyUrl + 'policies/batch/upload', formData,
      this.header);
  }

  applyProductRuleOnPolicy(formData) {
    return <any>this.http.post(environment.policyUrl + 'productRules/policy', formData,
      this.header);
  }

  findPolicyBatchSummary(formData) {
    return <any>this.http.get(environment.policyUrl + 'policies/batch/summary?batchId=' + formData.get('batchId'),
      this.header);
  }

  findPolicyBatchErrorLogs(formData) {
    return <any>this.http.get(environment.policyUrl + 'policies/error?batchId=' + formData.get('batchId'),
      this.header);
  }

  findPoliciesBatchExecutionHistory(batchExecutionHistorySearchObject) {
    return this.http.get(environment.policyUrl + 'policies/batches', {
      params: batchExecutionHistorySearchObject,
      reportProgress: true, observe: 'events'
    }
    ).pipe(
      map(event => this.getEventMessage(event)),
      catchError(this.handleError)
    );
  }

  createPolicy(policyModel) {
    return <any>this.http.post(environment.policyUrl + 'policy', policyModel, this.header);
  }

  findPolicies(policySearch) {
    let enpointUrl = environment.policyUrl;
    /*if (policySearch.partnerId == PolicyEnum.PARTNER_DIGI_MALAYSIA) {
      enpointUrl = enpointUrl + 'fetchDeviceImageInfo'
    } else { */
    enpointUrl = enpointUrl + 'policies'
    //}
    return this.http.get(enpointUrl, {
      params: policySearch,
      reportProgress: true, observe: 'events'
    }
    ).pipe(
      map(event => this.getEventMessage(event)),
      catchError(this.handleError)
    );
  }

  findPolicy(policyNo: String) {
    return <any>this.http.get(environment.policyUrl + 'policies?policyNumber=' + policyNo + '&showPaymentDetail=false', {
    });
  }

  findParentPolicy(policyNo: String) {
    return <any>this.http.get(environment.policyUrl + 'masterPolicies?policyNumber=' + policyNo + '&showPaymentDetail=false', {
    });
  }

  filterClientDetails(res) {
    if (res != undefined) {
      if (!Utils.isRegional()) {
        return this.filterMultipleCountriesAccess(res);
      } else {
        return res;
      }
    }
  }

  filterMultipleCountriesAccess(res) {
    let countriesCode = Utils.getPostalAddress();
    return res.filter(element => countriesCode.includes(element.countryCode));
  }

  filterNonSamsungClientDetails(res) {
    if (res != undefined) {
      //return this.filterClientDetails(res).filter(client => !client.name.includes('Samsung'));
      return this.filterClientDetails(res).filter(client => !client.name.includes('Samsung'));
    }
  }

  filterClientDetailsForSamsung(res) {
    return this.filterClientDetails(res).filter(client => client.name.includes('Samsung'));
  }

  filterClientDetailsForSamsungD2C(res) {
    return this.filterClientDetails(res).filter(client => PolicyConstants.isSamsungEUD2C.includes(client.partnerId));
  }

  private getEventMessage(event: HttpEvent<any>) {

    switch (event.type) {

      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);

      case HttpEventType.Response:
        return this.apiResponse(event);
    }
  }

  private fileUploadProgress(event) {
    const percentDone = Math.round(100 * event.loaded / event.total);
    return { status: 'Progress', message: percentDone };
  }

  private apiResponse(event) {
    return event.body;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status},` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  decideIsCancellationActive(clientNames: any) {
    let hcvnClient = clientNames.filter(element => element.clientCode == 'HC')
    return (Utils.isMultipleCountries() || (hcvnClient != undefined && hcvnClient.length > 0));
  }

  validateIMEI(partnerId, imeiNumber): Observable<any> {
    return <any>this.http.get(environment.policyUrl + 'validateIMEI/' + partnerId + '?imeiNumber=' + imeiNumber,
      this.header);
  }

  updatePolicy(policyDocument, policyId): Observable<any> {
    return <any>this.http.put(environment.policyUrl + 'policies/' + policyId, policyDocument,
      this.header);
  }

  updateParentPolicy(parentPolicyDocument, parentPolicyId): Observable<any> {
    return <any>this.http.put(environment.policyUrl + 'masterPolicies/' + parentPolicyId, parentPolicyDocument,
      this.header);
  }

  validateAgreementNumber(partnerId, agreementNumber): Observable<any> {
    return <any>this.http.get(environment.policyUrl + 'validateAgreementNumber/' + partnerId + '?agreementNumber=' + agreementNumber,
      this.header);
  }

  validateOnBoardingField(fieldMap): Observable<any> {
    return <any>this.http.post(environment.policyUrl + 'validateOnboarding', fieldMap, this.header);
  }

  resendEmail(policyNumbers: Array<string>): Observable<any> {
    return <any>this.http.patch(`${environment.policyUrl}resendEmail`, { policyNumbers }, this.header)
  }

  resendSMS(policyNumbers: Array<string>): Observable<any> {
    return <any>this.http.patch(`${environment.policyUrl}resendSMS`, { policyNumbers }, this.header)
  }

  regenerateECert(policyNumbers: Array<string>): Observable<any> {
    return <any>this.http.patch(`${environment.policyUrl}regenerateECert`, { policyNumbers }, this.header)
  }

  getSubscription(policyNumber: string, countryCode: string) {
    return <any>this.http.get(environment.policyUrl + 'policy/subscription/details/' + policyNumber + '/' + countryCode, this.header);
  }

  getIsPartnerSMSConfigured(partnerId: string) {
    return <any>this.http.get(environment.notificationUrl + '/find-by-partnerId/' + partnerId, this.header);
  }

  getButtonsVisibility(policyNumber: string): Observable<any> {
    return <any>this.http.get(`${environment.policyUrl}policyButtonsVisibility/${policyNumber}?isCrmzUI=true`,
      this.header);
  }

  getRefundDetail(policyNumber: string, requestedDate?: string, overrideRefundType?: string, overrideRefundRule?: boolean): Observable<any> {
    let endpointUrl = environment.policyUrl;
    endpointUrl = endpointUrl + 'policyRefundDetail/' + policyNumber;
    if (overrideRefundRule) {
      endpointUrl = endpointUrl + '?cancellationRequestedDate=' + requestedDate + '&overrideRefundType=' + overrideRefundType + '&overrideRefundRule=' + overrideRefundRule;
    }
    return <any>this.http.get(endpointUrl, this.header);
  }

  triggerRefund(policyNumber: string): Observable<any> {
    return <any>this.http.post(`${environment.policyUrl}policy/refund/${policyNumber}`, policyNumber,
      this.header);
  }

  getPremiumPaymentStatus(policyNumber: string, partnerId: string, orderId: string) {
    return <any>this.http.get(environment.policyUrl + 'payment?serviceContractId=' + policyNumber + '&partnerId=' + partnerId + '&orderId=' + orderId, this.header);
  }

  isBoltSubscriptionPartner(storeCode: string, partnerId: string) {
    return <any>this.http.get(environment.policyUrl + 'isBoltSubscriptionPartner?storeCode=' + storeCode + '&partnerId=' + partnerId, this.header);
  }

  getUpcomingPaymentInfo(policyNumber: string) {
    return <any>this.http.get(environment.policyUrl + 'getUpcomingPaymentInfo/' + policyNumber, this.header);
  }

  makerRequestsToChecker(makerCheckerSearch) {
    let endpointUrl = environment.makerCheckerUrl;
    endpointUrl = endpointUrl + 'checker/requests'
    return <any>this.http.get(endpointUrl, {
      params: makerCheckerSearch
    })
  }

  getCheckersForClaim(partnerId) {
    let endpointUrl = environment.makerCheckerUrl;
    endpointUrl = `${endpointUrl}makerChecker/config/${partnerId}/claim`;
    return <any>this.http.get(endpointUrl, {
    })
  }

  findMasterPolicies(policySearch) {
    let endpointUrl = environment.policyUrl;
    endpointUrl = endpointUrl + 'masterPolicies'
    return this.http.get(endpointUrl, {
      params: policySearch,
      reportProgress: true, observe: 'events'
    }
    ).pipe(
      map(event => this.getEventMessage(event)),
      catchError(this.handleError)
    );
  }

  uploadPoliciesTPPayment(formData) {
    return <any>this.http.post(environment.policyUrl + 'policies/tppayment/upload', formData,
      this.header);
  }

  findPolicyTPPaymentBatchSummary(formData) {
    return <any>this.http.get(environment.policyUrl + 'policies/tppayment/batch/summary?batchId=' + formData.get('batchId'),
      this.header);
  }

  findPoliciesTPPaymentUploadHistory(tpPaymentUploadHistorySearchObject) {
    return this.http.get(environment.policyUrl + 'policies/tppayment/batches', {
      params: tpPaymentUploadHistorySearchObject,
      reportProgress: true, observe: 'events'
    }
    ).pipe(
      map(event => this.getEventMessage(event)),
      catchError(this.handleError)
    );
  }

  findPolicyTPPaymentUpdateErrorLogs(formData) {
    return <any>this.http.get(environment.policyUrl + 'policies/tppayment/error?batchId=' + formData.get('batchId'),
      this.header);
  }

  uploadLicenseDetails(formData) {
    return <any>this.http.post(environment.policyUrl + 'policies/license-details/upload', formData,
      this.header);
  }

  uploadPolicyDocument(formData) {
    return <any>this.http.post(environment.policyUrl + 'policies/document/upload', formData,
      this.header);
  }

  flushNotificationConfigCaches() {
    return <any>this.http.get(`${environment.policyUrl}flushNotificationConfigCaches`, this.header);
  }

  flushEcertConfigCaches() {
    return <any>this.http.get(`${environment.policyUrl}flushEcertConfigCaches`, this.header);
  }


  previewMail(request) {
    return <any>this.http.post(environment.policyUrl + 'previewMail', request, this.header);
  }

  updateDeviceRRP(policyNumbers: Array<string>): Observable<any> {
    return <any>this.http.patch(`${environment.policyUrl}updateDeviceRRP`, { policyNumbers }, this.header);
  }

  patchDeviceRRP() {
    return <any>this.http.patch(environment.policyUrl + 'patchDeviceRRP', {}, this.header);
  }

  validateMobilePrefixWithNumberFormat(formData): Observable<any> {
    return <any>this.http.post(environment.policyUrl + 'validateMobilePrefixWithNumberFormat', formData, this.header);
  }

  findExceptionLogsByPolicyNumberAndStatusAndPlatform(policyNumber, status, platform) {
    return <any>this.http.get(environment.policyUrl + 'exceptionLogs/findByPolicyNumberAndStatusAndPlatform?policyNumber=' + policyNumber + "&status=" + status + "&platform=" + platform, this.header);
  }

  findOrders(orderSearch) {
    let enpointUrl = environment.policyUrl;
    enpointUrl = enpointUrl + 'orders';
    return this.http.get(enpointUrl, {
      params: orderSearch,
      reportProgress: true, observe: 'events'
    }
    ).pipe(
      map(event => this.getEventMessage(event)),
      catchError(this.handleError)
    );
  }

  softDeletePolicy(policyNumber, partnerId, cancelReason, email): Observable<any> {
    return <any>this.http.put(environment.policyUrl + 'soft-delete?cancelReason=' + cancelReason + '&email=' + email + '&partnerId=' + partnerId + '&policyNumber=' + policyNumber, null, this.header);
  }
}
