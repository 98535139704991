import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AuthGuard} from './auth.guard';
import {AuditService} from './audit.service';
import {ProductService} from './product.service';
import {CustomerService} from './customer.service';
import {PolicyService} from './policy.service';
import {ClaimService} from './claim.service';
import { LikeForSimilarService } from './like-for-similar.service';
import {NotificationService} from './notification.service'
import {CommonService} from './common.service';
import { BlanccoService } from './blancco.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    AuthGuard,
    AuditService,
    ProductService,
    CustomerService,
    PolicyService,
    ClaimService,
    LikeForSimilarService,
    NotificationService,
    CommonService,
    BlanccoService
  ]
})
export class CommonServicesModule { }
