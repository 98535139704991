import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationService{

  isEmptyValueValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if ((control.value == undefined || control.value == '')) {
      return { 'emptyCheckFailed': true };
    }
    return null;
  }
  isNonNumericValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value != '' && isNaN(control.value)) {
      return { 'nonNumberCheckFailed': true };
    }
    return null;
  }
  isNumericValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value != '' && !isNaN(control.value)) {
      return { 'numberCheckFailed': true };
    }
    return null;
  }

  isNonAlphabeticValidator(control: AbstractControl): { [key: string]: boolean } | null {
    let specialCharPattern = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (control.value != '' && (specialCharPattern.test(control.value) || !isNaN(control.value))) {
      return { 'nonAlphabeticCheckFailed': true };
    }
    return null;
  }
  emailValidator(control: AbstractControl): { [key: string]: boolean } | null {
    let emailRegexPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (control.value != '' && !emailRegexPattern.test(control.value)) {
      return { 'emailValidationFailed': true };
    }
    return null;
  }
}
