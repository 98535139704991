import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppRoutingConstants } from '../../app-routing.constants';
import { AppConstants } from '../../app.constants';

@Component({
  selector: 'broswer-disclaimer',
  templateUrl: './browserdisclaimer.component.html',
  styleUrls: ['./browserdisclaimer.component.css']
})

export class BrowserdisclaimerComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private router: Router,) {
  }

  routeToPasswordChange() {
    localStorage.setItem("RESET_PASSWORD_PROCEED", AppConstants.TRUE);
    this.router.navigate([AppRoutingConstants.CHANGE_PASSWORD], { queryParams: { isCalledFromForcedPasswordReset: true } });
  }

}
