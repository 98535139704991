<section class="menu-filter">
  <form #myForm="ngForm" (ngSubmit)="search(myForm)">
    <div class="filter">
      <mat-form-field appearance="outline">
        <mat-label>FILTER</mat-label>
        <mat-select [(value)]="policySearch.partnerId">
          <mat-option value="">Select filter name</mat-option>
          <div *ngFor="let clientDetail of clientDetails">
            <mat-option value={{clientDetail.partnerId}}>{{clientDetail.name}}
            </mat-option>
          </div>
        </mat-select>
      </mat-form-field>
    </div>
    <section class="menu-filter">
      <mat-form-field appearance="fill">
        <mat-label>Select Coverage</mat-label>
        <mat-select [(value)]="policySearch.coverage" multiple>
          <div *ngFor="let coverage of statusTypesMap.get(coverageTypeCode)">
            <mat-option value={{coverage}}>{{coverage}}</mat-option>
          </div>
          <mat-option *ngIf="isPartnerMemoXpress()" value="ADLD_THEFT">ADLD/THEFT</mat-option>
        </mat-select>
      </mat-form-field>
    </section>
    <section class="menu-filter">
      <mat-form-field appearance="fill">
        <mat-label>Status</mat-label>
        <mat-select [(value)]="policySearch.policyStatus">
          <mat-option value="">Select Status</mat-option>
          <div *ngFor="let policyStatus of statusTypesMap.get(policyStatus)">
            <mat-option value={{policyStatus}}>{{policyStatus}}</mat-option>
          </div>
        </mat-select>
      </mat-form-field>
    </section>
    <mat-form-field class="pick-date">
      <mat-label>Date of Creation</mat-label>
      <input (dateChange)="selectCreationDate($event)" [(value)]="policySearch.dateCreated"
        [matDatepicker]="dateCreated" [max]="maxDate" matInput />
      <mat-datepicker-toggle [for]="dateCreated" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #dateCreated></mat-datepicker>
    </mat-form-field>
    <section class="menu-filter">
      <mat-form-field appearance="outline" class="sortby">
        <mat-label>Contact Number</mat-label>
        <input id="policy-filter-contactNo" matInput (input)="policySearch.custMobileNumber=$event.target.value"
          [value]="policySearch.custMobileNumber" maxlength="15">
      </mat-form-field>
      <mat-form-field appearance="outline" class="sortby">
        <mat-label>Customer Id</mat-label>
        <input matInput (input)="policySearch.customerId=$event.target.value" [value]="policySearch.customerId">
      </mat-form-field>
      <mat-form-field appearance="outline" class="sortby">
        <mat-label>Broker Contract Number</mat-label>
        <input matInput (input)="policySearch.brokerContractNum=$event.target.value"
          [value]="policySearch.brokerContractNum">
      </mat-form-field>
      <mat-form-field appearance="outline" class="sortby">
        <mat-label>Transaction Number</mat-label>
        <input matInput (input)="policySearch.clientTransactionNumber=$event.target.value"
          [value]="policySearch.clientTransactionNumber">
      </mat-form-field>
      <mat-form-field appearance="outline" class="sortby">
        <mat-label>Broker Order Id</mat-label>
        <input matInput (input)="policySearch.brokerOrderId=$event.target.value"
          [value]="policySearch.brokerOrderId">
      </mat-form-field>
      <mat-form-field appearance="outline" class="sortby">
        <mat-label>Partner Contract Id</mat-label>
        <input matInput (input)="policySearch.partnerContractId=$event.target.value"
          [value]="policySearch.partnerContractId">
      </mat-form-field>
        <mat-form-field appearance="outline" class="sortby">
        <mat-label>Customer First Name</mat-label>
        <input matInput (input)="policySearch.customerFirstName=$event.target.value"
               [value]="policySearch.customerFirstName">
      </mat-form-field>

      <mat-form-field appearance="outline" class="sortby">
        <mat-label>Customer Last Name</mat-label>
        <input matInput (input)="policySearch.customerLastName=$event.target.value"
               [value]="policySearch.customerLastName">
      </mat-form-field>

      <mat-form-field appearance="outline" class="sortby" >
        <mat-label>Customer Email Address</mat-label>
          <input matInput (input)="policySearch.customerEmail=$event.target.value"
               [value]="policySearch.customerEmail" [formControl]="customerEmailCtrl">
        <mat-error style="font-size: 11px">Email must include symbol '@'</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="sortby" >
        <mat-label>Product Code</mat-label>
        <input matInput (input)="policySearch.productCode=$event.target.value" 
              [value]="policySearch.productCode">
      </mat-form-field>
      <span class="errorMessage" *ngIf="isPartnerMandatoryForProductCode">Please select the client name first</span>

      <mat-form-field appearance="outline" class="sortby" >
        <mat-label>Product Name</mat-label>
        <input matInput (input)="policySearch.productName=$event.target.value"
               [value]="policySearch.productName" >
      </mat-form-field>
      <span class="errorMessage" *ngIf="isPartnerMandatoryForProductName">Please select the client name first</span>
    </section>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
      <button mat-button type="reset"
        (click)="(policySearch.partnerId='')||(policySearch.policyStatus='')||(policySearch.coverage='')||(policySearch.dateCreated='')|| (policySearch.policyIMEINumber='')||(policySearch.custMobileNumber='')||(policySearch.customerId='')||(policySearch.policyNumber='')||(policySearch.clientTransactionNumber='')||(policySearch.customerFirstName='')||(policySearch.customerLastName='')||(policySearch.customerEmail='')||(policySearch.productCode='')||(policySearch.productName='')"
        style="background-color:#2EB5C7;">Reset</button>
      <button id="policy-filter-apply" mat-button type="submit" style="background-color:#2EB5C7;">Apply</button>
    </mat-dialog-actions>
  </form>
</section>
