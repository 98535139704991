import { Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppConstants } from '../app.constants';
import {AppService} from '../app.service';

@Directive({
  selector: '[ItemApplicableForAsia]'
})
export class ShowForAsiaDirective implements OnInit {
  condition: boolean;
  host: String;
  constructor(
    private el: ElementRef, 
    private appService: AppService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ){
    
  }
  @Input() set ItemApplicableForAsia(condition: boolean) {
    this.condition = condition;
  }

  ngOnInit() {
    this.host = this.appService.host();
    if (!this.host.includes('eu') && this.condition) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
