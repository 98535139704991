import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppRoutingConstants {

  public static APP_LOGIN: string = 'login';
  public static APP_LOGIN_DEFAULT: string = '';
  public static HOME: string = 'home';
  public static CHANGE_PASSWORD: string = 'changePassword';
  public static FORGOT_PASSWORD_RESET: string = 'forgotPassword';

  public static AUDIT = '';
  public static AUDIT_CHANGES = 'changes';
  public static AUDIT_REPORT: string = 'report';

  public static PRODUCTS = '';
  public static PRODUCT_DETAILS = "search/details";
  public static PRODUCT_SEARCH = "search";
  public static PRODUCT_PRODUCT_CODE = ':productCode';
  public static PRODUCT_CONFIGURATION = "configuration";
  public static PRODUCT_CLIENT_CREATION = 'configuration/client/create';
  public static PRODUCT_CLIENT_DEVICE_CREATION = 'configuration/client/device';
  public static PRODUCT_CREATION = 'configuration/create';
  public static POLICY_UPLOAD: string = 'upload';
  public static POLICY_BATCH_SUMMARY: string = 'batch/summary';
  public static POLICY_CREATE: string = 'policy';
  public static POLICY_BATCH_HISTORY: string = 'batch/history';
  public static POLICY_SEARCH: string = 'list';
  public static POLICY_DETAIL: string = 'list/detail';
  public static POLICY_DETAIL_PARENT: string = 'list/detail/parent';

  
  public static CLAIM_UPLOAD: string = 'upload';
  public static CLAIM_BATCH_HISTORY: string = 'batch/history';
  public static CLAIM_SEARCH: string = 'search';
  public static CLAIM_SEARCH_BY_PARAMETERS: string = 'search/:searchBy';
  public static CLAIM_DETAIL: string = 'search/detail';
  public static CLAIM_PENDING = 'pending';
  public static CLAIM_PENDING_DETAIL = 'pending/detail';
  public static SUPPORTING_DOCUMENT_EXTRACTION: string = 'supporting/document/extraction';
  public static SUPPORTING_DOCUMENT_DOWNLOAD_LINK: string = 'supporting/document/download/link';

  public static VAS_CLAIM_DETAIL: string = 'search/detail/vas';
  public static VAS_CLAIM_PENDING_DETAIL = 'pending/detail/vas';

  public static PARTNER_CREATE = 'partner';
  public static PARTNER_LIST = 'partner/list';
  public static PARTNER_UPLOAD = 'partner/upload';
  public static PARTNER_DETAIL = 'partner/:partnerId';

  public static CUSTOMER_SEARCH: string = 'search';
  public static CUSTOMER_DETAIL: string = 'detail';
  public static CUSTOMER_EMAIL: string = 'email';

  public static SIMILAR_LIKE: string = '';
  public static SIMILAR_LIKE_DETAIL: string = 'detail';
  public static SKU_MANAGEMENT = "configuration/client/device/search";
  public static SKU_UPLOAD = "configuration/client/device/upload";
  public static EO_CONFIGURATION = "configuration/eo/search";
  public static EO_CONFIGURATION_UPLOAD = "configuration/eo/upload";
  public static EO_CONFIGURATION_UPLOAD_SUMMARY = "configuration/eo/upload/summary";
  public static PLAN_CREATION = 'plan/create';
  public static EDIT_PLAN = 'plan/edit';
  public static BULK_EDIT_PLAN = 'plan/bulk/edit';
  public static USER_SEARCH ='search';
  public static USER_CREATE ='search/user/create';
  public static PLAN_UPLOAD = 'plan/upload';
  public static PLAN_UPLOAD_SUMMARY = 'plan/upload/summary';
  public static PLAN_UPLOAD_HISTORY = 'plan/upload/history';

  public static DOCUMENT_SEARCH: string = 'document/search';

  public static APPROVAL_REQUEST = 'request';
  public static EDIT_USER = 'search/user/edit';
  public static CHECKER_MANAGEMENT_LIST = 'checker/list/search';
  public static CHECKER_MANAGEMENT_CREATE = 'checker/create';
  public static CHECKER_MANAGEMENT_EDIT = 'checker/edit';
  public static EMAIL_TEMPLATE = 'emailTemplate';
  public static EMAIL_TEMPLATE_DETAIL = 'emailTemplate/details';
  public static EMAIL_TEMPLATE_CREATE = 'emailTemplate/create';
  public static SMS_TEMPLATE = 'smsTemplate';
  public static SMS_TEMPLATE_DETAIL = 'smsTemplate/details';
  public static SMS_TEMPLATE_CREATE = 'smsTemplate/create';
  public static UPLOAD_IMAGE = 'uploadImage';
  public static IMAGE_LIST = 'image/list';
  public static EMAIL_TEMPLATE_PREVIEW = 'emailTemplate/details/preview';
  public static NOTIFICATION_CONFIGURATION = "notificationConfiguration";
  public static EMAIL_CONFIGURATION = "emailConfiguration";
  public static EMAIL_CONFIGURATION_CREATE = "emailConfiguration/create";

  public static MAKER_CHECKER_DEFAULT = '';
  public static MAKER_CHECKER = 'maker-checker';
  public static CHECKER_REQUEST: string = 'checker-request';
  public static CHECKER_ACTION_DIALOG = 'actionDialog';
  public static ECERT_CONFIG = 'eCert/configuration';
  public static ECERT_CONFIG_DETAIL = 'eCert/configuration/detail';
  public static ECERT_CONFIG_CREATE = 'eCert/configuration/create';
  public static BUCKET_CONFIG = 'eCert/bucket';
  public static BUCKET_CONFIG_DETAIL= 'eCert/bucket/detail';
  public static BUCKET_CONFIG_CREATE = 'eCert/bucket/create';
  public static BLANCCO_PRICING = 'blancco/pricing'
  public static TRENDING_MODEL_CONFIG: string = 'trending/model/configuration';
  public static TRENDING_MODEL_CONFIG_DETAIL: string = 'trending/model/configuration/detail';

  public static POLICY_TP_PAYMENT_UPLOAD: string = 'tp-payment/upload';
  public static POLICY_TP_PAYMENT_BATCH_SUMMARY: string = 'tp-payment/batch/summary';
  public static POLICY_TP_PAYMENT_UPLOAD_HISTORY: string = 'tp-payment/upload/history';

  public static TECPROTEC_DEVICES_UPLOAD = "configuration/tecprotec-devices/upload";
  public static TECPROTEC_DEVICES_UPLOAD_SUMMARY = "configuration/tecprotec-devices/upload/summary";
}
