<div *ngIf="data.errorType == 'forcedPasswordResetAlert'">
  <h1 mat-dialog-title style="text-align: center;">Password Reset Alert !!!</h1>

  <div mat-dialog-content>
    <div fxLayout="row" fxLayoutGap="10px" *ngIf="!data.isFirstLogin">
      The password has expired !!! .It has been 90 days since you last updated your password.
    </div>
    <div fxLayout="row" fxLayoutGap="10px" *ngIf="data.isFirstLogin">
      Since this is your first login, you need to reset your password. 
    </div>
    <div fxLayout="row" fxLayoutGap="10px" style="margin-top:5%">
      <button  color="green" mat-dialog-close mat-raised-button style="margin-left:0px !important" (click)="routeToPasswordChange()">Reset Password</button>&nbsp;
      <button color="warn" mat-dialog-close mat-raised-button style="margin-left:0px !important">Cancel</button>
    </div>
  </div>
</div>
<div *ngIf="data.errorType != 'forcedPasswordResetAlert'">
  <h1 *ngIf="data.errorType == 'browserMismatch'; else updateBrowser" mat-dialog-title style="text-align: center;">Switch Browser</h1>
  <ng-template #updateBrowser>
    <h1 mat-dialog-title style="text-align: center;">Update Browser</h1>
  </ng-template>

  <div mat-dialog-content>
  <span *ngIf="data.errorType == 'browserMismatch'; else versionError">
    This application works best when viewed on Chrome and Firefox browser
  </span>

    <ng-template #versionError>
      <div *ngIf="data.errorType == 'versionChrome'; then chromeUpdate else firefoxUpdate"></div>
      <ng-template #chromeUpdate>
        To use CRMZ to its full potential, we recommend using Chrome version 80.0 and above.
      </ng-template>
      <ng-template #firefoxUpdate>
        To use CRMZ to its full potential, we recommend using FF version 72.0 and above.
      </ng-template>
    </ng-template>
  </div>
  <div  mat-dialog-actions class="action-btn">
    <button style="width: 25%;" mat-raised-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>

  </div>
</div>
