export enum TypeTable {

  productTenurecode = 'TY_PRD_TENR',
  productCoverage = 'TY_PRD_COVG',
  productPackageType = 'TY_PRD_PKG_TYPE',
  subscriptionType = 'TY_PRD_SUBCRPT_TYPE',
  subscriptionCategory = 'TY_PRD_SUBCRPT_CTG',
  redemptionType = 'TY_PRD_REDM_TYPE',
  productStatus = 'TY_PRD_STAT',
  policyStatus = 'TY_POL_STAT',
  partnerChannel = 'TY_PATR_CHANL',
  financePartner = 'TY_FIN_PATR',
  productPartner = 'TY_PRDT_PATR',
  role = 'TY_ROLE',
  devicemakeName = 'TY_PRD_MK_NAME',
  TypeGroupCode = 'TY_GRP_VAS',
  claimFrequency = 'TY_PRC_CLM_FRQCY',
  claimseverity = 'TY_PRC_CLM_SVRTY',
  excessFee = 'TY_PRC_EXS_FEE',
  claimRisk = 'TY_PRC_CLM_RISK',
  cost = 'TY_PRC_COST',
  price = 'TY_PRC_PRICE',
  productCoverageWithDesc = 'TY_PRD_COVG_WITH_DESC',
  deviceType = "TY_DEVICE_TYPES",
  skuDeviceType = "TY_SKU_DEVICE_TYPES",
  aeonSmallAppliance = "TY_AEON_SMALL_APPLIANCE",
  aeonLargeAppliance = "TY_AEON_LARGE_APPLIANCE",
  aeonPaymentMethod = "TY_AEON_PAYMENT_METHOD",
  insurerType = "TY_INSURER",
  ldapGroup='TY_LDAP_GRP_VALUE',
  ldapRole='TY_LDAP_ROLE_VALUE',
  ldapUserStatus = 'TY_LDP_USR_STS',
  pricingType = 'TY_PRD_PRC_TYPE',
  deviceTenureUnit = 'TY_PRD_DEVICE_TENR_UNIT',
  documentType = "TY_PRD_DCM_TYPE",
  notificationTemplatePlaceholder = "TY_TPL_PLACEHOLDERS",
  notificationTemplateMobileCountryCode = "TY_MOBILE_COUNTRY_CODE",
  notificationInsertTemplate ='TY_TPL_INSERT_TEMPLATES',
  coverageDates ='TY_PRC_COV_DATE',
  promotionType ='TY_PRD_PROMO_TYPE',
  coverageDateParent ='TY_PRC_COV_PARENT_DATE',
  discountType ='TY_PRD_DIS_TYPE',
  productIdTenure ='TY_PRD_PRODUCTID_TENR',
  eoDocStatusText = "TY_EO_DOC_STATUS_TEXT",
  licenseType = "TY_PRD_LICENSE_TYPE",

}
