<mat-toolbar class="mat-toolbar mat-toolbar-multiple-row">
  <mat-toolbar-row>
    <div class="header-title">
      <img src="../../../assets/images/LG.png" /></div>

    <span class="spacer"></span>
    <!-- We are set the notification bar for the Mobile type  -->
    <div class="menu-header">
      <!-- <span  *ngIf="!showPartnersTab">
      <button class="notification" (click)="openNotifications()" *ngIf="showNotificationBadge()">
        <mat-icon style="top:5!important" [matBadge]="badgeCounter" matBadgeColor="warn">
          notification_important</mat-icon>
      </button>
    </span> -->
      <button class="account">

        <mat-icon>account_circle</mat-icon>
        <div style="top: 70px;font-size: 15px!important;"> {{userName}} </div>

      </button>
    </div>
    <span>
      <mat-menu #sideMenu="matMenu" direction="down" yPosition="below" [overlapTrigger]="false">
        <span>
          <button mat-menu-item (click)="openChangePasswordModal()">
            <mat-icon>fingerprint</mat-icon>
            Change Password
          </button>
          <button mat-menu-item (click)="logout()">
            <mat-icon>logout</mat-icon>
            Logout
          </button>

        </span>
      </mat-menu>
    </span>
    <button mat-icon-button [matMenuTriggerFor]="sideMenu" style="right: 20px;" #sidebarTrigger="matMenuTrigger">
      <mat-icon>menu</mat-icon>
    </button>

  </mat-toolbar-row>
</mat-toolbar>
