import { Injectable } from '@angular/core';

const CLAIM_AGENT = '501';
const FINANCE = '502';
const CLAIM_MANAGER = '503';
const BUSINESS_USER = '504';
const ADMIN = '505';
const KEY_ACCOUNT_MANAGER = '506';
const BILLING_OFFICER = '507';

@Injectable({
  providedIn: 'root'
})
export class Groups {
  policyGroups = [ADMIN,BUSINESS_USER,CLAIM_AGENT, CLAIM_MANAGER, FINANCE, KEY_ACCOUNT_MANAGER, BILLING_OFFICER];
  claimGroups = [ADMIN, BUSINESS_USER, CLAIM_AGENT, CLAIM_MANAGER];
  productGroups = [ADMIN, CLAIM_AGENT, CLAIM_MANAGER, FINANCE, KEY_ACCOUNT_MANAGER, BILLING_OFFICER];
  userManagementGroups = [ADMIN, CLAIM_MANAGER];
  administrators = [ADMIN, CLAIM_MANAGER];
}
