
import { AppConstants } from '../app.constants';
import {PolicyDocument} from "../model/policy.document";
import { PolicyConstants } from '../policy/policy.constants';
import moment from "moment";

export default class Utils {
  static convertDateToUTC(dateToBeConverted: Date = null): string {
    let targetDate = dateToBeConverted ? dateToBeConverted : new Date();
    try {
      return targetDate.getUTCDate() + AppConstants.DEFAULT_DATE_SEPARATOR +
        (targetDate.getUTCMonth() + 1) + AppConstants.DEFAULT_DATE_SEPARATOR +
        targetDate.getUTCFullYear();
    } catch (err) {
      return null;
    }
  }

  static convertStringToDate(targetDate: string) {
    if ((typeof targetDate === 'string') && (targetDate.indexOf('/') > -1)) {
      const str = targetDate.split('/');
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof targetDate === 'number' ? targetDate : Date.parse(targetDate);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  static compareDates(dateOne: string, dateTwo: string): boolean | null {
    try {
      if (dateOne && dateTwo) {
        let firstDate = this.convertStringToDate(dateOne);
        let secondDate = this.convertStringToDate(dateTwo);
        return firstDate.getTime() < secondDate.getTime();
      }
    } catch (err) {
      return null;
    }
  }

  static addYearsToDate(targetDate: Date, yearsToAdd: number = 0): string | null {
    try {
      if (targetDate) {
        return targetDate.getUTCDate() + AppConstants.DEFAULT_DATE_SEPARATOR +
          (targetDate.getUTCMonth() + 1) + AppConstants.DEFAULT_DATE_SEPARATOR +
          (targetDate.getUTCFullYear() + yearsToAdd);
      }
    } catch (err) {
      return null;
    }
  }
  static ifDateExistsBetween(firstDate: string, secondDate: string, targetDate: string): boolean | null {
    try {
      return this.compareDates(firstDate, targetDate) && this.compareDates(targetDate, secondDate);
    } catch (err) {
      return null;
    }
  }
  static convertTZ(date, tzString) {
    return (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString });
  }
  static getTZ(country) {
    let tz = null;
    if (country == "ID") tz = "Asia/Jakarta";
    else if (country == "BE") tz = "Europe/Brussels";
    else if (country == "VN") tz = "Asia/Ho_Chi_Minh";
    else if (country == "CH") tz = "Europe/Zurich";
    else if (country == "SE") tz = "Europe/Stockholm";
    else if (country == "ES") tz = "Africa/Ceuta";
    else if (country == "ZA") tz = "Africa/Johannesburg";
    else if (country == "PT") tz = "Europe/Lisbon";
    else if (country == "NZ") tz = "Pacific/Auckland";
    else if (country == "NL") tz = "Europe/Amsterdam";
    else if (country == "MX") tz = "America/Mexico_City";
    else if (country == "HK") tz = "Asia/Hong_Kong";
    else if (country == "TH") tz = "Asia/Bangkok";
    else if (country == "SG") tz = "Asia/Singapore";
    else if (country == "MY") tz = "Asia/Kuala_Lumpur";
    else if (country == "PH") tz = "Asia/Manila";
    else if (country == "TW") tz = "Asia/Taipei";
    else if (country == "AT") tz = "Europe/Vienna";
    else if (country == "MO") tz = "Asia/Macau";
    else if (country == "NO") tz = "Europe/Oslo";
    else if (country == "DK") tz = "Europe/Copenhagen";
    else if (country == "FI") tz = "Europe/Helsinki";
    else if (country == "IE") tz = "Europe/Dublin";
    else tz = "UTC";
    return tz;
  }

  static mapPolicyModel(oldPolicyModel: PolicyDocument, newPolicyModel: PolicyDocument) {
    if(!newPolicyModel.paymentDetail){
      newPolicyModel.paymentDetail = oldPolicyModel.paymentDetail;
    }
    if(oldPolicyModel.renewedTimes !== newPolicyModel.renewedTimes){
      newPolicyModel.renewedTimes = oldPolicyModel.renewedTimes;
      newPolicyModel.lastRenewalDate = oldPolicyModel.lastRenewalDate;
      newPolicyModel.nextRenewalDate = oldPolicyModel.nextRenewalDate;
    }
    return newPolicyModel;
  }

  static sortByProperty(property: string,sortOrder:number): any {
    return function (a:any, b:any) {
      let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }

  static convertMMDDYYYYtoDDMMYYYY(date, separator) {
    let [month, day, year] = date.split(separator);
    if (month < 10 && month.length == 1) { month = '0' + month; }
    if (day < 10 && day.length == 1) { day = '0' + day; }
    let newDate = day + separator + month + separator + year;
    return newDate;
  }

  static convertDDMMYYYYtoYYYYMMDD(date, firstSeparator, secondSeparator, pattern) {
    const str = date.split(firstSeparator);
    const [day, month, year] = str[0].split(secondSeparator);
    let newDate = year + pattern + month + pattern + day + ' ' + str[1];
    return newDate;
  }

  static isShowPatchDeviceRRP(policyModel: PolicyDocument){
    if(null != policyModel && this.isDeviceRRPEmpty(policyModel.deviceRRP) && PolicyConstants.isSKUUploadPartners(policyModel.partnerId)){
      return true;
    }
    return false;
  }

  static isDeviceRRPEmpty(deviceRRP: String){
    if(deviceRRP === '0.00' || deviceRRP === '0.0' || deviceRRP === '0' || deviceRRP === ''){
      return true;
    }
    return false;
  }

  static convertMonthsToYear(monthCount):any{
    if(!isNaN(monthCount) && Number.isInteger(monthCount)) {
      let month = parseInt(monthCount);
      return (Math.floor(month/12));
    }
    return '';
  }

  static getPostalAddress(){
    return localStorage.getItem(AppConstants.COUNTRY_CODE).replace(/\s/g, "").toUpperCase();
  }

  static isRegional(){
    const countryCode = this.getPostalAddress();
    if(countryCode.toString().toLowerCase() === AppConstants.ALL_COUNTRIES_ACCESS){
      return true;
    } 
    return false;
  }

  static isMultipleCountries(){
    const countryCode = this.getPostalAddress().split(',');
    if(countryCode.toString().toLowerCase() === AppConstants.ALL_COUNTRIES_ACCESS){
      return true;
    } else if(countryCode.length>1){
      return true;
    }
    else {
      return false;
    }
    
  }

   /* WARNING: arrays must not contain {objects} or behavior may be undefined */
  static arraysEqual(a1,a2) {
    return JSON.stringify(a1)==JSON.stringify(a2);
  }

  static getCurrentDateTime(): string {
    const localDateTime = moment(); 
    return localDateTime.format(AppConstants.DATE_FORMAT_YYYY_MM_DD_T_HH_MM_SS_SSS);
  }
}
