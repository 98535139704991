import { Directive, ElementRef, HostListener } from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[removeLeadingZeros]'
})
export class RemoveLeadingZerosDirective {

  constructor(private elem: ElementRef, private control: NgControl) {

  }

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const initalValue = this.elem.nativeElement.value;
    this.elem.nativeElement.value = initalValue.replace(/\b0+/g, '');
    this.control.control.setValue(this.elem.nativeElement.value);
    if (initalValue !== this.elem.nativeElement.value) {
      event.stopPropagation();
    }
  }

}
