import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppConstants} from '../app.constants';

@Injectable({
    providedIn: 'root'
})
export class LikeForSimilarService {
 

    getAllProductData() {
        return <any>this.http.get(environment.recommendationServiceUrl + 'getAllProductData', {});
    }

    searchInventory(grn) {
        let options = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return <any>this.http.post(environment.recommendationServiceUrl + 'findInventoryItem?inventoryItemId' + grn, {}, options);
    }

    getLikeForSimilarDeviceList(filter: {
        facilityName: string; unitPrice: number; capacity: string;
        brandName: string;
        model: string;
        ram: string;
        reservedBy: string;
    }) {
        let options = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return <any>this.http.post(environment.recommendationServiceUrl + 'findLikeforSimilarInventoryItems', JSON.stringify(filter), options);
    }

    getLFSLFLSearchResult(filter: {
        facilityName: string; unitPrice: number; capacity: string;
        brandName: string;
        model: string;
        ram: string;
        searchFor:string
    }) {
        let options = {headers: new HttpHeaders().set('Content-Type', 'application/json')};

        return <any>this.http.post(environment.recommendationServiceUrl + 'findLFSLFKInventoryItems', JSON.stringify(filter), options);
    }

  reserveInventory(reserveInputs: { grn: string; claimRefNo: string; byUser: string; customerName: string; customerAddress: string; customerContactNo: string; faultyUnitIMEINo: string; faultyUnitBrandName: string; faultyUnitModelName: string;}) {
    let options = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return <any>this.http.post(environment.recommendationServiceUrl + 'reserveItem', JSON.stringify(reserveInputs), options);
  }

  cancelReservation(cancelInputs: {
    grn: string;
    byUser: string;
    cancelReason: string;
}) {
    let options = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return <any>this.http.post(environment.recommendationServiceUrl + 'cancelReservedItem', JSON.stringify(cancelInputs), options);
  }

  confirmSwapInventory(confirmInputsForSwap: { grn: string; claimRefNo: string; byUser: string; customerName: string; customerAddress: string; customerContactNo: string; faultyUnitIMEINo: string; faultyUnitBrandName: string; faultyUnitModelName: string; }) {
    let options = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return <any>this.http.post(environment.recommendationServiceUrl + 'confirmSwap', JSON.stringify(confirmInputsForSwap), options);
  }
  cancelSwapInventory(cancelSwapInput: { grn: string; byUser: string; cancelReason: string; }) {
    let options = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return <any>this.http.post(environment.recommendationServiceUrl + 'cancelSwap', JSON.stringify(cancelSwapInput), options);
  }

    getListOfRam() {
        return <any>this.http.get(environment.recommendationServiceUrl + 'getRams', {});
    }

    constructor(private http: HttpClient) {
    }

    getListOCapacity() {
        return <any>this.http.get(environment.recommendationServiceUrl + 'getCapacities', {});
    }

    getListOfColour() {
        return <any>this.http.get(environment.recommendationServiceUrl + 'getColors', {});
    }

    getListOfCamera() {
        return <any>this.http.get(environment.recommendationServiceUrl + 'getCameraSpecs', {});
    }

    getListOfAccessories() {
        return <any>this.http.get(environment.recommendationServiceUrl + 'getAccessories', {});
    }

    getListOfModel() {
        return <any>this.http.get(environment.recommendationServiceUrl + 'getModels', {});
    }

    getListOfBrand() {
        return <any>this.http.get(environment.recommendationServiceUrl + 'getBrandNames', {});
    }

  getWarehouseNamesCountryWise(countryCode) {
        return <any>this.http.get(environment.recommendationServiceUrl + 'getWarehouseNamesCountryWise?countryCode='+countryCode, {});
    }
}
