import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { AppConstants } from 'src/app/app.constants';
import { AppService } from 'src/app/app.service';
import { ClaimService } from 'src/app/common-services/claim.service';
import { ProductService } from 'src/app/common-services/product.service';
import { ValidationService } from 'src/app/common-services/validation-service';
import { ClaimDocument } from 'src/app/model/ClaimDocument';
import Utils from 'src/app/utility/utils';

@Component({
  selector: 'app-onsite-claim',
  templateUrl: './onsite-claim.component.html',
  styleUrls: ['./onsite-claim.component.scss']
})
export class OnsiteClaimComponent implements OnInit {
  searchTextChanged = new Subject<any>();
  subscription: any;
  onsiteClaimForm: UntypedFormGroup
  claimModel = ClaimDocument.newClaimDocument();
  searchList: Array<any>;
  countries : any;
  countryDesc : any;
  warningContent : any;
  shouldProceedForOnsiteRequest : boolean = false;
  constructor(private formBuilder: UntypedFormBuilder, private productService: ProductService,
    @Inject(MAT_DIALOG_DATA) public dataInput: any , private claimService : ClaimService ,
    private spinner: NgxSpinnerService,
    private service : AppService , private dialogRef : MatDialogRef<OnsiteClaimComponent> ,
    private validationService : ValidationService) { }

  ngOnInit() {
    this.onsiteClaimForm = this.formBuilder.group(
      {
        logisticsCost: new UntypedFormControl('', {validators: [
          this.validationService.isEmptyValueValidator.bind(this),
          this.validationService.isNonNumericValidator.bind(this)
        ], updateOn: "blur"}),
        collectAddress1: new UntypedFormControl('' , {validators: [
          this.validationService.isEmptyValueValidator.bind(this)
        ], updateOn: "blur"}),
        collectAddress2: new UntypedFormControl(''),
        collectPostcode: new UntypedFormControl(''),
        collectState: new UntypedFormControl(''),
        collectCity: new UntypedFormControl(''),
        collectCountry: new UntypedFormControl(''),
      }
    )
    this.subscription = this.searchTextChanged.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      mergeMap(search => this.productService.getAutcompleteAdress(search.searchKey, search.country)
      )
    ).subscribe((res: any) => {
      this.searchList = res;
    });
    let policyModel = this.dataInput.policyModel;
    let countryCode = policyModel.countryCode;
    const productModelObj = typeof policyModel.productsModel === 'string' ? JSON.parse(policyModel.productsModel) : policyModel.productsModel;
    if (countryCode === null || countryCode === undefined) {
      countryCode = productModelObj.client.countryCode;
    }
    this.claimModel.collectCountry = countryCode;
    this.countries = this.dataInput.countries;
    this.countryDesc = this.dataInput.countryDesc;
    const controlsLogistic = this.getLogisticsDetailsControls();
    controlsLogistic.collectCountry.setValue(countryCode);
    this.setWarningContent();
  }

  autoCompleteSearch(event, country) {
    const searchCriteria = {
      searchKey: event.target.value,
      country: country
    };
    this.searchTextChanged.next(searchCriteria);
  }

  getLogisticsDetailsControls() {
    return this.onsiteClaimForm.controls;
  }

  setAddress(placeId: string, country: string) {
    this.productService.getAddressDetails(placeId, country).subscribe((res) => {
      const controlsLogistic = this.getLogisticsDetailsControls();
      if (res.addressLine1 == null) {
        controlsLogistic.collectAddress1.setValue(res.name);
      } else {
        controlsLogistic.collectAddress1.setValue(res.name + ',' + res.addressLine1);
      }
      controlsLogistic.collectAddress2.setValue(res.addressLine2);
      controlsLogistic.collectPostcode.setValue(res.postalCode);
      controlsLogistic.collectCity.setValue(res.city);
      controlsLogistic.collectState.setValue(res.state);
    });

  }

  onSubmit(){
    if(this.onsiteClaimForm.valid){
      this.spinner.show();
      this.populateClaimModelRequest();
      let claimData = new FormData();
      claimData.append('claimRequest' , JSON.stringify(this.claimModel));
      this.claimService.createClaim(claimData).subscribe(
        async res => {
          if (res['message'][0] === 'Claim Created') {
            const newClaim: ClaimDocument = res.content;
            this.service.showInfo('Claim created, Claim ID: ' + newClaim.claimNo, 'Success');
            this.dialogRef.close({newClaim : newClaim});
          } else {
            this.claimService.validateErrorCode(res, 'IN CREATING CLAIM');
            this.dialogRef.close();
          }
          this.spinner.hide();
        }
      );
    }else{
      this.service.showInfo('Invalid Onsite Claim Request', 'Failure');
    }
  }

  populateClaimModelRequest() : void{
    const controlsLogistic = this.getLogisticsDetailsControls();
    let policyModel = this.dataInput.policyModel;
    this.claimModel.logisticsCost = controlsLogistic.logisticsCost.value;
    this.claimModel.collectAddress1 = controlsLogistic.collectAddress1.value;
    this.claimModel.collectAddress2 = controlsLogistic.collectAddress2.value;
    this.claimModel.collectPostcode = controlsLogistic.collectPostcode.value;
    this.claimModel.collectCity = controlsLogistic.collectCity.value;
    this.claimModel.collectState = controlsLogistic.collectState.value;
    this.claimModel.partnerId = policyModel.partnerId;
    this.claimModel.policyNo = policyModel.policyNumber;
    this.claimModel.country = policyModel.countryCode;
    this.claimModel.partnerContractId = policyModel.partnerContractId;
    this.claimModel.imei = policyModel.policyIMEINumber;
    this.claimModel.deviceSerialNo = policyModel.deviceSerialNo;
    this.claimModel.onsiteClaimRequest = true;
    this.claimModel.status = AppConstants.STATUS_COMPLETE;
    this.claimModel.statusCode = AppConstants.CLAIM_STATUS_CODE_COMPLETED;
    this.claimModel.claimSubStatus = AppConstants.CLAIM_SUB_STATUS_CLOSED;
    this.claimModel.subStatusCode = AppConstants.CLAIM_SUB_STATUS_CODE_CLOSED;
    this.claimModel.policyStatus = policyModel.policyStatus;
    this.claimModel.customerName = policyModel.customerName;
    this.claimModel.coverage = policyModel.coverage;
    this.claimModel.make = policyModel.deviceMake;
    this.claimModel.productName = policyModel.productName;
    this.claimModel.model = policyModel.deviceModel;
    this.claimModel.productDesc = policyModel.clientProductDescription;
    this.claimModel.colour = policyModel.deviceColor;
    this.claimModel.rrp = policyModel.deviceRRP;
    this.claimModel.balanceRRP = policyModel.balanceRRP;
    this.claimModel.oldBalanceRRP = policyModel.balanceRRP;
    this.claimModel.memoryStorage = parseInt(policyModel.deviceMemory, 10);
    this.claimModel.purchaseDate = policyModel.policyPurchaseDate;
    this.claimModel.customerName = policyModel.customerName;
    this.claimModel.customerLastName = policyModel.customerLastName;
    this.claimModel.customerContact = policyModel.custMobileNumber;
    this.claimModel.customerEmail = policyModel.customerEmail;
    this.claimModel.claimantEmail = policyModel.customerEmail;
    this.claimModel.claimantContact = policyModel.custMobileNumber;
  }

  setWarningContent(){
    let policyModel = this.dataInput.policyModel;
    let applicationDate = Utils.convertDateToUTC(new Date());
    let policyPurchaseDate = Utils.convertStringToDate(policyModel.policyPurchaseDate);
    let firstActiveYearBound = Utils.addYearsToDate(policyPurchaseDate , 1);
    let secondActiveYearDate = Utils.addYearsToDate(policyPurchaseDate , 2);
    let firstActiveYearValidationFailed = policyModel.onsiteClaimRecord &&
                                        Utils.compareDates(applicationDate , firstActiveYearBound) &&
                                        policyModel.onsiteClaimRecord[AppConstants.FIRST_ACTIVE_YEAR] >= AppConstants.FIRST_ACTIVE_YEAR_BOUND ;


    let secondActiveYearValidationFailed = policyModel.onsiteClaimRecord &&
                                          Utils.ifDateExistsBetween(firstActiveYearBound  ,
                                            secondActiveYearDate , applicationDate) &&
                                          policyModel.onsiteClaimRecord[AppConstants.SECOND_ACTIVE_YEAR] >= AppConstants.SECOND_ACTIVE_YEAR_BOUND ;

    if(firstActiveYearValidationFailed || secondActiveYearValidationFailed){
      this.warningContent = AppConstants.ONSITE_CLAIM_COUNT_BOUND_ERROR;
    }else if(policyModel.balanceRRP <= 0){
      this.warningContent = AppConstants.ONSITE_CLAIM_BALANCE_RRP_ERROR;
    }else{
      this.shouldProceedForOnsiteClaimRequest();
    }
  }

  shouldProceedForOnsiteClaimRequest(){
    this.shouldProceedForOnsiteRequest = true;
  }

}
