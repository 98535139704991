import { HttpClient, HttpErrorResponse, HttpHeaders, HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { AppConstants } from '../app.constants';
import { ResponseObject } from '../model/response.claim.object';
import { ClaimDocument } from '../model/ClaimDocument';
import { catchError, map } from 'rxjs/operators';
import { ClaimConstants } from '../claim/claim.constants';
import { AppService } from '../app.service';
import { Assignee } from '../model/assignee';
import { Client } from '../model/client';
import { ProductService } from './product.service';
import Utils from '../utility/utils';

@Injectable({
  providedIn: 'root'
})
export class ClaimService {
  header: any;
  constructor(private http: HttpClient,
    private service: AppService,
    private productService: ProductService) {
    this.header = this.apiKeyHeaders();
  }

  createClaim(claimModel): Observable<any> {
    return <any>this.http.post(environment.claimUrl + 'v1', claimModel, this.header);
  }

  createVasClaim(claimModel): Observable<any> {
    return <any>this.http.post(environment.claimUrl + 'vasClaim/v1',claimModel,this.header);
  }

  updateClaim(claimModel): Observable<any> {
    return <any>this.http.post(environment.claimUrl + 'v1/update', claimModel, this.header);
  }

  updateVasClaim(claimModel): Observable<any>{
    return <any>this.http.post(environment.claimUrl + 'vasClaim/v1/update', claimModel,this.header);
  }

  updateLogisticOrder(updateLogisticOrder): Observable<any> {
    return <any>this.http.put(environment.claimUrl + 'updateLogisticOrder', updateLogisticOrder, this.header);
  }

  cancelLogisticOrder(claimNo: String, orderNo: String): Observable<any> {
    return <any>this.http.put(environment.claimUrl + `cancelLogisticsOrder/${claimNo}/${orderNo}`, {}, this.header);
  }

  generatePaymentLink(claimNo: String): Observable<any> {
    return <any>this.http.post(environment.claimUrl + `generatePaymentLink/${claimNo}`, {}, this.header);
  }

  resetRepairPopupFlag(claimNo: String): Observable<any> {
    return <any>this.http.put(environment.claimUrl + `resetRepairFlag/${claimNo}`, {}, this.header);
  }

  getClaimLogisticsDetails(claimNo: String): Observable<any> {
    return <any>this.http.get(environment.claimUrl + `getClaimLogisticsDetails/${claimNo}`, this.header);
  }

  patchClaim(claimPatchModel): Observable<any> {
    return <any>this.http.patch(environment.claimUrl + 'patchClaim', claimPatchModel, this.header);
  }

  findClaim(policyId: String) {
    return <any>this.http.get(environment.claimUrl + 'byPolicyNo/v1?policyNo=' + policyId, this.header);
  }

  getClaimByPolicyNo(policyNo: String) {
    const url = environment.claimUrl + 'byActiveClaim/v1?policyNo=' + policyNo;
    return <any>this.http.get(url, this.header);
  }

  getClaimByClaimNo(claimNo: String){
    const url = environment.claimUrl + '/byClaimNo/v1?claimNo=' + claimNo;
    return <any>this.http.get(url,this.header);
  }

  checkClaimEligibility(policyNo: String) : Observable<any> {
    return this.http.get<ResponseObject>(environment.claimUrl + 'claimEligibility/' + policyNo, this.header);
  }

  checkClaimEligibility2(policyNo: String) : Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.checkClaimEligibility(policyNo).subscribe(res => {
        if (res.status === 'OK') {
          const claimErrorMsg = res.content.claimErrorMsg;
          resolve(claimErrorMsg);
        } else {
          reject();
        }
      }, () => reject());
    });
  }

  fetchDraftClaimByPolicyNo(policyNo: string): Observable<any> {
    return <any>this.http.get(environment.claimUrl + 'fetchDraft/' + policyNo, this.header);
  }
  draftClaim(claimModel): Observable<any> {
    return <any>this.http.post(environment.claimUrl + '/draft', claimModel, this.header);
  }
  deActiveDraft(formData: FormData): Observable<any> {
    return <any>this.http.post(environment.claimUrl + '/deActivateDraft', formData, this.header);
  }
  removeDraftDocument(formData) {
    return <any>this.http.post(environment.claimUrl + 'draft/document/delete', formData, this.header);
  }

  draftVasClaim(claimModel): Observable<any>{
    return <any>this.http.post(environment.claimUrl+ '/draftVas', claimModel, this.header);
  }

  findClaims(claimSearch): Observable<any> {
    return this.http.get(environment.claimUrl + 'claimSearchAll', {
      headers: new HttpHeaders({
        'X-Requested-With': localStorage.getItem(AppConstants.JWT_TOKEN)
      }),
      params: claimSearch
    }
    );
  }

  findClaimAuditTrial(claimNo: string): Observable<any> {
    return this.http.get(environment.claimUrl + 'claimAuditLog?claimNo=' + claimNo, this.header);
  }

  showClaims(): Observable<any> {
    const url = environment.claimUrl + 'all/v1';
    return this.http.get<ClaimDocument>(url, this.header);
  }

  showClaimDetail(claimId: String, partnerId: String): Observable<any> {
    const url = environment.claimUrl + 'byClaimId/v1/' + claimId + '?' + 'partnerId=' + partnerId;
    return this.http.get<ResponseObject>(url, this.header);
  }

  showClaimDetailByClaimNumber(claimNo: String, claimLogId: string): Observable<any> {
    const userId = localStorage.getItem(AppConstants.LOGGED_IN_USER);
    const url = environment.claimUrl + 'byClaimType/v1?claimNo=' + claimNo + '&userId=' + userId + '&claimLogId=' + claimLogId;
    return this.http.get<ResponseObject>(url, this.header);
  }

  findActiveClaim(policyNo: String): Observable<any> {
    const url = environment.claimUrl + 'byActiveClaim/v1?policyNo=' + policyNo;
    return this.http.get(url, this.header);
  }

  findClaimStatus(): Observable<any> {
    return this.http.get(environment.claimUrl + 'claimStatusList', this.header);
  }

  findVasClaimStatus(): Observable<any>{
    return this.http.get(environment.claimUrl + 'vasClaimStatusList', this.header);
  }

  getAssignees(): Observable<any> {
    return this.http.get(environment.claimUrl + 'assignee', this.header);
  }

  getAssignees2(): Promise<Assignee[]> {
    return new Promise<Assignee[]>((resolve, reject) => {
      this.getAssignees().subscribe(res => {
        if (res.status === 'OK') {
          const assigneesList = res.content as Assignee[];
          resolve(assigneesList);
        } else {
          reject();
        }
      }, () => reject());
    });
  }

  uploadDocument(formData): Observable<any> {
    return this.http.post(environment.claimUrl + 'upload', formData, this.header);
  }

  removeDocument(formData) {
    return <any>this.http.post(environment.claimUrl + 'document/delete', formData, this.header);
  }

  removeReopenDocument(claimId, formData): Observable<any> {
    return this.http.post(environment.claimUrl + 'claimDocument/delete/' + claimId, formData, this.header);
  }


  downloadDocument(path, filename) {
    const url = environment.claimUrl + 'downloadFile/' + filename;
    return this.http.get<ClaimDocument>(url, this.header).pipe(
      map(event => {
        return event;
      }),
      catchError(this.handleError)
    );
  }

  uploadImage(formData) {
    return <any>this.http.post(environment.claimUrl + 'upload', formData, this.header);
  }

  deleteImage(formData) {
    return this.http.post<any>(environment.claimUrl + 'deleteImage', formData, this.header)
      .pipe(
        catchError(this.handleError)
      );
  }

  apiKeyHeaders(): any {
    return {
      headers: new HttpHeaders({
        'X-Requested-With': localStorage.getItem(AppConstants.JWT_TOKEN)
      })
    };
  }

  private handleError(error: HttpErrorResponse) {
    return throwError('Something bad happened; please try again later.');
  }

  validateErrorCode(res: any, message: String) {
    if (res.status === 'ERROR' && res.content) {
      if (res.code === '200.100') {
        this.service.showInfo(ClaimConstants.ERROR_MSG, message);
        return;
      }
      else {
        this.service.showInfo(res.message[0], res.error);
        return;
      }
    }
    else if (res.status === 'ERROR' && res.message){
      this.service.showInfo(res.message, "ERROR!!");
    }
  }

  getCouriers(country): Observable<any> {
    let courierQuery = { "country": country }
    return <any>this.http.get(environment.claimUrl + "couriers", {
      headers: new HttpHeaders({
        'X-Requested-With': localStorage.getItem(AppConstants.JWT_TOKEN)
      })
      , params: courierQuery
    });
  }

  getAssigneeForPartnerId(partnerId: string[]): Promise<Assignee[]> {
    return new Promise<Assignee[]>((resolve, reject) => {
      this.productService.findClientPartnerDetails(null).subscribe(res => {
        const clients = res.clientList as Client[];
        const clientArr: Client[] = [];
        clients.forEach(value => {
          partnerId.forEach(pId => {
            if (pId.trim() === value.partnerId.trim()) {
              clientArr.push(value);
            }
          });
        });
        if (clientArr.length > 0) {
          this.getAssigneeForPartner(clientArr).then(assignees => resolve(assignees)).catch(() => reject());
        } else {
          reject();
        }
      }, () => reject());
    });
  }

  getAssigneeForPartner(partners: Array<Client>): Promise<Assignee[]> {
    return new Promise<Assignee[]>((resolve, reject) => {
      this.getAssignees().subscribe(res => {
        if (res.status === 'OK') {
          const assigneesList = res.content as Assignee[];
          const assigneesByCountry: Assignee[] = [];

          assigneesList.forEach(assignee => {
            partners.forEach(partner => {
              if (assignee.countryCode && assignee.countryCode.trim() === partner.countryCode.trim()) {
                assigneesByCountry.push(assignee);
              }
            });
          });

          resolve(assigneesByCountry);
        } else {
          reject();
        }
      }, () => {
        reject();
      });
    });
  }

  getSwapRejectReasons(): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      this.http.get(environment.claimUrl + "fetchKeyConfigValues", {
        headers: new HttpHeaders({
          'X-Requested-With': localStorage.getItem(AppConstants.JWT_TOKEN)
        })
      }).subscribe((response: any) => {
        if (response.status === "OK") {
          const content = response.content as {
            key: string,
            values: string
          }[];
          const rejectReasons = content.find(value => value.key === 'swapRejectionReasons');
          if (rejectReasons) {
            resolve(rejectReasons.values.split(';'))
          }
        }

        reject();
      }, () => reject());
    });
  }

  applyProductRulesOnClaim(claimModel): Observable<any> {
    return <any>this.http.post(environment.claimUrl + 'productRules/', claimModel, this.header);
  }

  getClaimEligibility(policyNo): Observable<any> {
    return <any>this.http.get(`${environment.claimUrl}claimEligibility/${policyNo}`, {
      headers: new HttpHeaders({
        'X-Requested-With': localStorage.getItem(AppConstants.JWT_TOKEN)
      })
    })
  }

  getClaimEligibilityByParentPolicy(parentPolicy, childPolicy): Observable<any> {
    return <any>this.http.get(`${environment.claimUrl}claimEligibilityByParentPolicy/${parentPolicy}?policyNumbers=${childPolicy}`, this.header)
  }

  flushNotificationConfigCaches() {
    return <any>this.http.get(`${environment.claimUrl}flushNotificationConfigCaches`, this.header);
  }

  previewMail(request) {
    return <any>this.http.post(environment.claimUrl + 'previewMail', request, this.header);
  }

  flushCaches() {
    return <any>this.http.get(`${environment.claimUrl}flushCache`, this.header);
  }

  findClaimBatchExecutionHistory(batchExecutionHistorySearchObject) {
    return <any>this.http.get(`${environment.claimUrl}claimBulkUpdateBatches/`, {
      headers: new HttpHeaders({
        'X-Requested-With': localStorage.getItem(AppConstants.JWT_TOKEN)
      }),
      params: batchExecutionHistorySearchObject
    })
  }

  filterClientDetails(res) {
    if (res != undefined) {
      if (!Utils.isRegional()) {
        return this.filterMultipleCountriesAccess(res);
      } else {
        return res;
      }
    }
  }

  filterMultipleCountriesAccess(res){
    let countriesCode = Utils.getPostalAddress();
    return res.filter(element => countriesCode.includes(element.countryCode));
  }

  uploadClaims(formData, partnerId) {
    return <any>this.http.post(environment.claimUrl + 'claimBulkUpdate?partnerId='+ partnerId, formData,
      this.header);
  }

  getAllProvince() : Observable<any> {
    return this.http.get<ResponseObject>(`${environment.claimUrl}/vn/address/provinces`, this.header);
  }

  getAllDistricts(selectedProvinceCode: number) : Observable<any> {
    return this.http.get<ResponseObject>(`${environment.claimUrl}/vn/address/province/${selectedProvinceCode}/districts`, this.header);
  }

  getAllWards(selectedProvinceCode: number, selectedDistrictCode: number) : Observable<any> {
    return this.http.get<ResponseObject>(`${environment.claimUrl}/vn/address/province/${selectedProvinceCode}/district/${selectedDistrictCode}/wards`, this.header);
  }

  getAllPhProvince() : Observable<any> {
    return this.http.get<ResponseObject>(`${environment.claimUrl}/ph/address/provinces`, this.header);
  }

  getAllCityByProvince(selectedProvince: string) : Observable<any> {
    return this.http.get<ResponseObject>(`${environment.claimUrl}/ph/address/cities/${selectedProvince}`, this.header);
  }

  getAllBarangaysByProvinceAndCity(selectedProvince: string, selectedCity: string) : Observable<any> {
    return this.http.get<ResponseObject>(`${environment.claimUrl}/ph/address/barangays/${selectedProvince}/${selectedCity}`, this.header);
  }

  getZipcodeForSelectedAddr(selectedProvince: string, selectedCity: string, selectedBarangay: string) : Observable<any> {
    return this.http.get<ResponseObject>(`${environment.claimUrl}/ph/address/zipcode/${selectedProvince}/${selectedCity}/${selectedBarangay}`, this.header);
  }

  findClaimSupportingDocumentlink(batchExecutionHistorySearchObject) {
    return <any>this.http.get(`${environment.claimUrl}all/claimDoc/v1/`, {
      headers: new HttpHeaders({
        'X-Requested-With': localStorage.getItem(AppConstants.JWT_TOKEN)
      }),
      params: batchExecutionHistorySearchObject
    })
  }

  uploadSupportingDocumentExtraction(formData, partnerId) {
    return <any>this.http.post(environment.claimUrl + 'upload/claimDoc?partnerId='+ partnerId, formData,
      this.header);
  }
}
