import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppService } from './app.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppConstants } from './app.constants';

@Injectable({
  providedIn: 'root'
})
export class MdmService {

  header: any;

  constructor(private http: HttpClient, private appService: AppService) {
    this.header = AppService.apiKeyHeaders();
  }

  findDeviceDetailsByImei(imeiNumber: string): Observable<any> {
    let countryCode: string = localStorage.getItem(AppConstants.COUNTRY_CODE);
    return this.http.get(environment.mdmUrl + '/deviceinfo/' + imeiNumber + "?countryCode=" + countryCode,
      this.header);
  }

  saveImei(imeiNumber: string , countryCd : string): Observable<any> {
    let imeiParams = new HttpParams();
    imeiParams = imeiParams.set('imeiNumber', imeiNumber);
    imeiParams = imeiParams.set('countryCd', countryCd);
    return <any>this.http.post(environment.mdmUrl + '/saveImei', imeiParams, this.header);
  }

  findStores(partnerProductCode: string): Observable<any> {
    return <any>this.http.get(environment.mdmUrl + '/store?productPartnerCode=' + partnerProductCode, AppService.apiKeyHeaders());
  }

}
