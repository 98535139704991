export class PolicySearchCriteria {
  policyNumber: String = '';
  customerName: String = '';
  customerFirstName: String = '';
  customerLastName: String = '';
  policyIMEINumber: String = '';
  policyStatus: String = '';
  custMobileNumber: String = '';
  customerId: String = '';
  coverage: String = '';
  customerEmail: String = '';
  clientTransactionNumber: String = '';
  policyPurchaseDate: String = '';
  partnerContractId: String = '';
  partnerProductId: String = '';
  partnerId: String = '';
  currentPage: Number = 0;
  dateCreated: String = '';
  countryCode: string[] = [];
  claimNo: String = '';
  pageIndex: number = 0;
  pageRecordCount: number = 10;
  deviceSerialNo: String = '';
  valueAddedServices:[];
  legacyValueAddedServices: [];
  brokerContractNum: String = '';
  brokerOrderId : String ='';
  exactMatch: boolean = true;
  showPaymentDetail: boolean = true;
  showCustomerDetail: boolean = false;
  partnerParentPolicyId: string = '';
  isParentPolicy: boolean = false;
  lastRenewedPolicyNumber: string = '';
  productCode: String = '';
  productName: String = '';
  static newPolicySearchCriteria(): PolicySearchCriteria {
    return new PolicySearchCriteria();
  }
}
