import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { PolicyService } from '../../common-services/policy.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductService } from 'src/app/common-services/product.service';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PolicyDocument } from 'src/app/model/policy.document';
import { Subject, Observable } from 'rxjs';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { ClaimConstants } from 'src/app/claim/claim.constants';
import { ProductDetail } from 'src/app/model/product.detail';
import { DigiPackages } from 'src/app/model/DigiPackages';
import { DigiProducts } from 'src/app/model/DigiProducts';
import { DigiSubProducts } from 'src/app/model/DigiSubProducts';
import { DigiShieldDevices } from 'src/app/model/DigiShieldDevices';
import { LoginService } from 'src/app/components/user/login/login.service';
import { AppConstants } from 'src/app/app.constants';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MdmService } from 'src/app/mdm.service';
import { PartnerOnboardingService } from 'src/app/partner/partner-onboarding.service';
import { PartnerOnBoardingConstants } from 'src/app/partner/partner-onboarding.constants';
import { ResponseClientDto } from 'src/app/model/response.client';
import { TypeTable } from 'src/app/model/typeTable';
import Utils from 'src/app/utility/utils';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-partner-onboarding',
  templateUrl: './partner-onboarding.component.html',
  styleUrls: ['./partner-onboarding.component.css']
})
export class PartnerOnboardingComponent implements OnInit {

  constructor(public service: AppService,
    private spinner: NgxSpinnerService,
    private productService: ProductService,
    public dialog: MatDialog, private cdr: ChangeDetectorRef,
    private loginService: LoginService, private deviceService: DeviceDetectorService,
    public partnerOnboardingService: PartnerOnboardingService,
    private mdmService: MdmService, private policyService: PolicyService,
    public datepipe: DatePipe) { 
      setTimeout(() => {
        this.loginService.showAppLandingPage.next(AppConstants.FALSE);
        this.loginService.showDigiFailedOnboarding.next(AppConstants.FALSE);
        this.loginService.showDigiOnboardingApp.next(AppConstants.TRUE);
      })
    }

  partnerOnboardingForm: UntypedFormGroup;
  partnerPolicy: PolicyDocument = PolicyDocument.newPolicyDocument();
  searchTextChanged = new Subject<any>();
  searchList: Array<any>;
  subscription: any;
  currentActiveTab: number = 0;
  // latest snapshot
  public frontWebcamImage: WebcamImage = null;
  frontImageUrl: string = null;
  backImageUrl: string = null;
  countries: any[];
  panelErrorMessage: string;
  customerInfoValidationFailed: boolean = false;
  deviceInfoValidationFailed: boolean= false;
  staffInfoValidationFailed: boolean= false;
  productValidationFailed: boolean= false;
  frontImageValidationFailed: boolean= false;
  backImageValidationFailed: boolean= false;
  staffAcknowledgementValidationFailed: boolean = false;
  customerAcknowledgementValidationFailed: boolean = false;
  staffAcknowledgement: boolean = false;
  customerAcknowledgement: boolean = false;
  productDetailList: Array<ProductDetail>;
  digiPackagesList: Array<DigiPackages>;
  digiProductList: Array<DigiProducts>;
  digiSubProductList : Array<DigiSubProducts>;
  digiShieldDevicesList : Array<DigiShieldDevices>;
  pageHeadingArray: Array<string>;
  partnerStoresName: any[];
  partnerStores : any [];
  imageCapturePanel: boolean = false;
  productDescriptionBullets: string[];
  partnerColorCode: any;
  showOnboardingFailurePage: boolean = false;
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  defaultCountry: string;
  imagePanelWidth: number;
  imagePanelHeight: number;
  deviceRrpErrStatus : boolean = true;
  deviceRrpIdentifier : string;
  policyProductDeviceRRP : Map<String , String> = new Map();
  selectedDigiDevice: DigiShieldDevices;
  selectedPlanRrp: any;
  policyCreationStatus : string;

  ngOnInit(): void {
    this.getListOfCoutries();
    this.partnerOnboardingForm = new UntypedFormGroup({
      customerDetailPanel: new UntypedFormGroup({
        customerName: new UntypedFormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.CUSTOMER_NAME),
          updateOn: "blur"
        }),
        customerLastName: new UntypedFormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.CUSTOMER_NAME),
          updateOn:"blur"
        }),
        agreementNumber :  new UntypedFormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.AGREEMENT_ID),
          updateOn: "blur"
        }),
        idType: new UntypedFormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.ID_TYPE),
          updateOn: "blur"
        }),
        customerId: new UntypedFormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.CUSTOMER_ID),
          updateOn: "blur"
        }),
        customerEmail: new UntypedFormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.CUSTOMER_EMAIL),
          updateOn: "blur"
        }),
        custMobileNumber: new UntypedFormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.CUSTOMER_MOBILE_NUMBER),
          updateOn: "blur"
        }),
        custAddress: new UntypedFormControl(''),
        postalCode: new UntypedFormControl(''),
        customerCity: new UntypedFormControl(''),
        customerState: new UntypedFormControl(''),
        customerCountryCode: new UntypedFormControl('')
      }),
      deviceDetailPanel: new UntypedFormGroup({
        policyIMEINumber: new UntypedFormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.POLICY_IMEI_NUMBER),
          updateOn: "blur"
        }),
        deviceSerialNo: new UntypedFormControl(''),
        deviceMake: new UntypedFormControl(''),
        deviceModel: new UntypedFormControl(''),
        deviceCapacity: new UntypedFormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.DEVICE_CAPACITY),
          updateOn: "blur"
        }),
        deviceColor: new UntypedFormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.DEVICE_COLOR),
          updateOn: "blur"
        }),
      }),
      productDetailPanel: new UntypedFormGroup({
        productName: new UntypedFormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.PRODUCT_NAME),
          updateOn: "blur"
        }),
        productsModel: new UntypedFormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.PRODUCT_MODEL),
          updateOn: "blur"
        }),
        selectedDigiDevice: new UntypedFormControl(''),
      }),
      staffDetailPanel: new UntypedFormGroup({
        staffName: new UntypedFormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.STAFF_NAME),
          updateOn: "blur"
        }),
        storeName: new UntypedFormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.STORE_NAME),
          updateOn: "blur"
        }),
      }),
      confirmationPanel: new UntypedFormGroup({
        customerAcknowledgement: new UntypedFormControl('', Validators.required),
        staffAcknowledgement: new UntypedFormControl('', Validators.required),
      }),
    })
    this.setPageDefaults();
    this.setAddressHandlers();
    this.getListOfCoutries();
    this.fetchClientDetails();
    this.partnerOnboardingService.setSharedSpace(this.partnerOnboardingForm , this.partnerPolicy);
  }

  setPageDefaults() {
    //Setting StoresName
    this.mdmService.findStores(this.partnerOnboardingService.fetchPartnerProductCodes()).subscribe((res) => {
      if (res && res.status == 'OK') {
        this.partnerStores = res.obj;
        this.partnerStoresName = []
        this.partnerStores.forEach((store) => {
          store.storeName = store.storeName.trim();
          store.storeCode = store.storeCode.trim();
          let storeFullName = store.storeName + "  " + store.storeCode;
          this.partnerStoresName.push(storeFullName.trim());
        });
      }
    });
    this.setImageFrameDimensions();
  }

  setImageFrameDimensions() {
    if (this.deviceService.isMobile()) {
      if (this.deviceService.os === "Android") {
        this.imagePanelWidth = 300;
        this.imagePanelHeight = 400;
      } else if (this.deviceService.os === "iOS") {
        this.imagePanelWidth = 275;
        this.imagePanelHeight = 375;
      }
    } else if (this.deviceService.isTablet()) {
      if (this.deviceService.os === "Android") {
        this.imagePanelWidth = 300;
        this.imagePanelHeight = 300;
      } else if (this.deviceService.os === "iOS") {
        this.imagePanelWidth = 275;
        this.imagePanelHeight = 375;
      }
    } else {
      this.imagePanelWidth = 500;
      this.imagePanelHeight = 375;
    }
  }

  fetchClientDetails() {
    this.productService.findClientPartnerDetails(null).subscribe(
      res => {
        let clientDetails = this.policyService.filterClientDetails((<ResponseClientDto>res).clientList);
        if (clientDetails != undefined) {
          let digiClient = clientDetails.filter((x) => x.clientCode === "DIMY")
          if (digiClient != undefined) {
            this.partnerColorCode = digiClient[0].colorCode;
          }
        }
      }
    );
  }

  setAddressHandlers() {
    this.subscription = this.searchTextChanged.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      mergeMap(search => this.productService.getAutcompleteAdress(search.searchKey, search.country)
      )
    ).subscribe((res: any) => {
      this.searchList = res
    });
    if (this.partnerPolicy.custAddress == null || this.partnerPolicy.custAddress == undefined) {
      this.partnerPolicy.custAddress = {}
    }
    this.partnerPolicy.custAddress.customerCountry = "Malaysia";
    this.defaultCountry = this.partnerPolicy.custAddress.customerCountry;
    this.partnerOnboardingForm.get('customerDetailPanel').get('customerCountryCode').setValue("MY");
  }

  getListOfCoutries() {
    this.productService.getCountryList().subscribe(res => {
      this.countries = res;
    });
  }

  autoCompleteSearch(event) {
    let countryCode = this.partnerOnboardingForm.get('customerDetailPanel').get('customerCountryCode').value;
    if (countryCode == '') {
      this.service.showInfo("Please select the country before search", "");
      return;
    }
    let searchCriteria = {
      searchKey: event.target.value,
      country: countryCode
    }
    this.searchTextChanged.next(searchCriteria);
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public recaptureImage(isBackImage: boolean = false) {
    if (!isBackImage) {
      this.frontImageUrl = null;
    } else {
      this.backImageUrl = null;
    }
  }

  public handleInitError(error: WebcamInitError): void {
    // this.errors.push(error);
  }

  public handleImage(webcamImage: WebcamImage): void {
    if (this.imageCapturePanel && this.frontImageUrl == null) {
      this.frontImageUrl = webcamImage.imageAsDataUrl;
      this.partnerPolicy.deviceFrontImage = webcamImage.imageAsBase64;
    } else if (!this.imageCapturePanel && this.backImageUrl == null) {
      this.backImageUrl = webcamImage.imageAsDataUrl;
      this.partnerPolicy.deviceBackImage = webcamImage.imageAsBase64;
    }
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  setAddress(placeId: string, country: string) {
    this.partnerOnboardingForm.get('customerDetailPanel').get('custAddress').value;

    this.productService.getAddressDetails(placeId, country).subscribe((res) => {

      if (res.addressLine1 != null) {
        this.partnerOnboardingForm.get('customerDetailPanel').get('custAddress').setValue(res.name + " , " + res.addressLine1);
      } else {
        this.partnerOnboardingForm.get('customerDetailPanel').get('custAddress').setValue(res.name);
      }
      this.partnerOnboardingForm.get('customerDetailPanel').get('postalCode').setValue(res.postalCode);
      this.partnerOnboardingForm.get('customerDetailPanel').get('customerState').setValue(res.state);
      this.partnerOnboardingForm.get('customerDetailPanel').get('customerCity').setValue(res.city);
      this.partnerPolicy.custAddress.address1 = res.name;
      this.partnerPolicy.custAddress.postalCode = res.postalCode;
      this.partnerPolicy.custAddress.state = res.state;
      this.partnerPolicy.custAddress.postalCode = res.postalCode;
      this.partnerPolicy.custAddress.city = res.city;
      this.partnerPolicy.custAddress.countryCode = "MY";
    });
  }

  validateRules(): boolean {
    let resultFromRuleEngine: boolean = true;
    if(this.partnerOnboardingService.isPanelToBeShown(
      PartnerOnBoardingConstants.PARTNER_SALES_REGISTRATION_PANEL , this.currentActiveTab)){
      if (!this.partnerOnboardingForm['controls'].customerDetailPanel.valid) {
        this.panelErrorMessage = ClaimConstants.MANDATORY_FILL;
        this.customerInfoValidationFailed = true;
        resultFromRuleEngine = false;
      }
      if (!this.validatePolicyIMEI() || !this.partnerOnboardingForm['controls'].deviceDetailPanel.valid) {
        this.panelErrorMessage = ClaimConstants.MANDATORY_FILL;
        this.deviceInfoValidationFailed = true;
        resultFromRuleEngine = false;
      }
      if (!this.partnerOnboardingForm['controls'].staffDetailPanel.valid) {
        this.panelErrorMessage = ClaimConstants.MANDATORY_FILL;
        this.staffInfoValidationFailed = true;
        resultFromRuleEngine = false;
      }
    }
    if((this.partnerOnboardingService.isPanelToBeShown(
      PartnerOnBoardingConstants.PARTNER_DEVICE_CAPTURE_PANEL , this.currentActiveTab))){
        if (this.frontImageUrl == null) {
          this.panelErrorMessage = ClaimConstants.MANDATORY_FILL;
          this.frontImageValidationFailed = true;
          resultFromRuleEngine = false;
        }
        if (this.backImageUrl == null) {
          this.panelErrorMessage = ClaimConstants.MANDATORY_FILL;
          this.backImageValidationFailed = true;
          resultFromRuleEngine = false;
        }
    }
    return resultFromRuleEngine;
  }

  private setProductDetailsForDevice() {
    this.spinner.show();
    this.productService.findProductByPartnerid(this.service.getCurrentLoggedInPartner()).subscribe((response) => {
      if (response && response != undefined) {
        this.spinner.hide();
        this.productDetailList = response;
      }
    })
  }

  setDigiPackages() {
    if (this.service.getCurrentLoggedInPartner() == PartnerOnBoardingConstants.PARTNER_ID_DIGI_SHIELD){
      this.digiShieldDevicesList = null;
      this.selectedDigiDevice = null;
      this.digiSubProductList = null;
      this.digiProductList = null;
      this.spinner.show();
      this.productService.getAllActiveDigiPackages().subscribe((response) => {
        if (response && response != undefined) {
          this.spinner.hide();
          this.digiPackagesList = response;
        }
      })
    }  
  }

  setDigiProductsBasedOnSuppliedPackage(event) {
    let packageId = event.value;
    if(packageId) {
      this.digiSubProductList = null;
      this.productDetailList = null;
      this.digiShieldDevicesList = null;
      this.selectedDigiDevice = null;
      this.spinner.show();
      this.productService.getDigiProductsBasedOnSuppliedPackage(packageId).subscribe((response) => {
        if (response && response != undefined) {
          this.spinner.hide();
          this.digiProductList = response;
        }
      })  
    }
      
  }

  setDigiSubProductsBasedOnSuppliedDigiProductId(event) {
    let productId = event.value;
    if(productId) {
      this.productDetailList = null;
      this.digiShieldDevicesList = null;
      this.selectedDigiDevice = null;
      this.spinner.show();
      this.productService.getAllActiveDigiSubProductsBasedOnSuppliedDigiProductId(productId).subscribe((response) => {
        if (response && response != undefined) {
          this.spinner.hide();
          this.digiSubProductList = response;
        }
      })  
    }
      
  }

  setCrmzProductwithPlansBasedOnProductCode(event) {
    let crmzProductCode = event.value.productCodes;
    if(crmzProductCode) {
      this.digiShieldDevicesList = null;
      this.selectedDigiDevice = null;
      this.spinner.show();
      this.productService.getAllActiveCrmzProductwithPlansBasedOnProductCode(crmzProductCode).subscribe((response) => {
        if (response && response != undefined) {
          this.spinner.hide();
          this.productDetailList = response;
          
        }
      })
      this.partnerPolicy.partnerProductId = event.value.partnerProductId;
      this.partnerPolicy.internalProductId = event.value.amsInternalProductId;
    }
  }

  mapRequestDataToPolicy(){
    let partnerId = localStorage.getItem("partnerId");
    switch(partnerId){
      case PartnerOnBoardingConstants.PARTNER_ID_AEON:
        this.partnerPolicy.productName = this.partnerPolicy.productsModel.productName;
        this.partnerPolicy.deviceRRP = this.partnerPolicy.productsModel.deviceRRP;
        this.partnerPolicy.productsModel = null;
        break;
      case PartnerOnBoardingConstants.PARTNER_ID_DIGI:
        this.partnerPolicy.salesRepId = localStorage.getItem("loggedInUser");
        this.partnerPolicy.partnerProductId = this.partnerPolicy.productsModel.partnerProductId;
        this.partnerPolicy.internalProductId = this.partnerPolicy.productsModel.amsInternalProductId;
        this.partnerPolicy.partnerProductId = PartnerOnBoardingConstants.DIGI_BYOD_PARTNER_PRODUCT_ID;
        this.partnerPolicy.sku = this.partnerPolicy.deviceMake+' '+this.partnerPolicy.deviceModel;
        this.partnerPolicy.productName = this.partnerPolicy.productsModel.productName;
        //this.partnerPolicy.productsModel = JSON.stringify(this.partnerPolicy.productsModel);
        delete this.partnerPolicy.productsModel;
        delete this.partnerPolicy.cancellationNote;
        break;
      case PartnerOnBoardingConstants.PARTNER_ID_DIGI_SHIELD:
        this.partnerPolicy.salesRepId = localStorage.getItem("loggedInUser");
        this.partnerPolicy.coverage = this.productDetailList[0].productCoverage.join("/");
        this.partnerPolicy.productName = this.productDetailList[0].productName;
        this.partnerPolicy.sku = this.partnerPolicy.deviceMake+' '+this.partnerPolicy.deviceModel;
        this.partnerPolicy.productCode = this.partnerPolicy.productsModel.productCode;
        delete this.partnerPolicy.productsModel;
        delete this.partnerPolicy.cancellationNote; 
        break;
    }
    this.partnerPolicy.partnerId = partnerId;
    this.partnerPolicy.custMobileNumber = "60" + this.partnerPolicy.custMobileNumber;
    this.partnerPolicy.createdBy = this.service.getCurrentUser();
    this.partnerPolicy.countryCode="MY";
    this.partnerPolicy.policyStartDate = Utils.convertDateToUTC() ;
    this.partnerPolicy.policyPurchaseDate = this.partnerPolicy.policyStartDate;
    this.partnerPolicy.deviceSoldDate = this.partnerPolicy.policyStartDate;
  }
  
  onboardPartnerPolicy() {
    if (this.validateConfirmation()) {
      this.spinner.show();
      this.currentActiveTab++;
      this.mapRequestDataToPolicy();
      this.policyService.createPolicy(this.partnerPolicy).subscribe((response) => {
        if(response && response != undefined) {
            this.spinner.hide();
            this.performPostOnboardingOperations();
            this.resetPageDefaults();
            if(response && response.status == "ERROR" && response.obj && response.obj["validationFailuresLogs"] != null){
              let partnerId = this.service.getCurrentLoggedInPartner();
              this.policyCreationStatus = response.status;
              switch(partnerId){
                case PartnerOnBoardingConstants.PARTNER_ID_DIGI_SHIELD:
                  setTimeout(() => {
                    this.loginService.showAppLandingPage.next(AppConstants.FALSE);
                    this.loginService.showDigiFailedOnboarding.next(AppConstants.TRUE);
                    this.loginService.showDigiOnboardingApp.next(AppConstants.FALSE);
                  },500)
                break;
              }
            }
        }
      })

    }
  }
  
  getTenureInYear(tenure:string){
    var value = Number(tenure)
    if(!isNaN(value)){
      return (value/12)
    }else{
      return null
    }
  }

  getCoverageStartDate(){
    return (this.datepipe.transform(new Date(),'E, MMM dd, yyyy'))
  }

  resetCountry(event) {
    let selectedCountryCode = event.value;
    let selectedCountry = this.countries.filter((x) => x.twoCharCode == selectedCountryCode);
    this.refreshCountryOfAddress(selectedCountry[0].name);
    this.defaultCountry = this.partnerPolicy.custAddress.customerCountry;
  }

  enableWebCam(isBackImageCapturePanel) {
    this.imageCapturePanel = isBackImageCapturePanel;
  }
  confirmContract(contract: any) {
    this.partnerPolicy.productsModel = contract;
    let currentPartner = this.service.getCurrentLoggedInPartner();
    if(currentPartner === PartnerOnBoardingConstants.PARTNER_ID_AEON){
      let contractCode = contract.productCode;
      let deviceRRP = parseInt((<HTMLInputElement>document.getElementById(contractCode)).value);
      this.partnerPolicy.productsModel.deviceRRP = deviceRRP;
      if(this.deviceRrpErrStatus){
        this.service.showInfo("Please enter device RRP for continuing", AppConstants.PRODUCT_SELECTION_LABEL);
      }else{
        this.validateDuplicatePolicyCheck(this.partnerPolicy.policyIMEINumber , contract.productName , contractCode ,deviceRRP, currentPartner);
      }
    }else if(currentPartner === PartnerOnBoardingConstants.PARTNER_ID_DIGI_SHIELD) {
      let contractCode = contract.productCode;
      let deviceRRP = this.partnerPolicy.deviceRRP;
      this.validateDuplicatePolicyCheck(this.partnerPolicy.policyIMEINumber , contract.productName , contractCode ,deviceRRP, currentPartner);
    }else{
      this.currentActiveTab++;
    }
  }

  resetPageDefaults() {
    this.frontImageUrl = null;
    this.backImageUrl = null;
    this.partnerPolicy = PolicyDocument.newPolicyDocument();
    this.partnerPolicy.custAddress = new Object();
    this.partnerOnboardingForm.reset();
    this.setPageDefaults();
    this.setAddressHandlers();
    this.getListOfCoutries();
  }
  confirmAcknowledgement(inputString) {
    switch (inputString) {
      case 'CUSTOMER':
        this.customerAcknowledgement = !this.customerAcknowledgement;
        break;
      case 'STAFF':
        this.staffAcknowledgement = !this.staffAcknowledgement;
        break;
    }
  }

  validateConfirmation() {
    let resultFromConfirmationValidationEngine = true;
    if (!this.customerAcknowledgement) {
      this.panelErrorMessage = ClaimConstants.MANDATORY_FILL;
      this.customerAcknowledgementValidationFailed = true;
      resultFromConfirmationValidationEngine = false;
    } else {
      this.customerAcknowledgementValidationFailed = false;
    }
    if (!this.staffAcknowledgement) {
      this.panelErrorMessage = ClaimConstants.MANDATORY_FILL;
      this.staffAcknowledgementValidationFailed = true;
      resultFromConfirmationValidationEngine = false;
    } else {
      this.staffAcknowledgementValidationFailed = false;
    }
    return resultFromConfirmationValidationEngine;
  }


  refreshCountryOfAddress(country: any = this.defaultCountry) {
    if (this.partnerPolicy.custAddress == null || this.partnerPolicy.custAddress == undefined) {
      this.partnerPolicy.custAddress = new Object();
    }
    this.partnerPolicy.custAddress.customerCountry = country;
  }

  saveImeiInDB(imeiNumber: string , countryCd : string) {
    this.mdmService.saveImei(imeiNumber , countryCd).subscribe((response) => {
    })
  }

  validatePolicyIMEI() {
    let resultFromValidateIMEIEngine = true
    let deviceControls = this.partnerOnboardingForm['controls'].deviceDetailPanel['controls'];
    let imeiNumber = deviceControls.policyIMEINumber.value;
    let makeNumber = deviceControls.deviceMake;
    let modelNumber = deviceControls.deviceModel;
    if ((imeiNumber == null || imeiNumber == '')) {
      this.partnerOnboardingForm['controls'].deviceDetailPanel['controls'].policyIMEINumber.setErrors({ 'bothIMEIandSerialNumberEmpty': true });
      resultFromValidateIMEIEngine = false;
    }
    if (this.service.getCurrentLoggedInPartner() != PartnerOnBoardingConstants.PARTNER_ID_DIGI_SHIELD){
      if ((makeNumber.value == null || makeNumber.value == '')) {
        makeNumber.setErrors({ 'makeCheckFailed': true });
        resultFromValidateIMEIEngine = false;
      }
      if ((modelNumber.value == null || modelNumber.value == '')) {
        modelNumber.setErrors({ 'modelCheckFailed': true });
        resultFromValidateIMEIEngine = false;
      }
    }
    
    return resultFromValidateIMEIEngine;
  }

  changeTab(action: string) {
    let methodHandlerName
    switch (action) {
      case 'CHANGE_TAB_INC':
        methodHandlerName = PartnerOnBoardingConstants.PARTNER_ONBORADING_CHANGE_TAB_INC_METHOD_LABEL;
        break;
      case 'CHANGE_TAB_DEC':
        methodHandlerName = PartnerOnBoardingConstants.PARTNER_ONBORADING_CHANGE_TAB_DEC_METHOD_LABEL;
        break;
      case 'CHANGE_TAB_SUBMIT':
        methodHandlerName = PartnerOnBoardingConstants.PARTNER_ONBORADING_CHANGE_TAB_SUBMIT_METHOD_LABEL;
        break;
      case 'CHANGE_TAB_LANDING':
        methodHandlerName = PartnerOnBoardingConstants.PARTNER_ONBORADING_CHANGE_TAB_LANDING_METHOD_LABEL;
        break;
    }
    if (methodHandlerName != '') {
      if(this[methodHandlerName]){
        this[methodHandlerName]();
      }
    }
  }

  changeTabInc() {
    let validatorCallChain = this.partnerOnboardingService.fetchActionhandlers(this.currentActiveTab);
    let resultFromValidatorCallChain : boolean = true;
    if(validatorCallChain){
      validatorCallChain.forEach(handler => {
        if(this[handler]){
          resultFromValidatorCallChain = resultFromValidatorCallChain && this[handler]();
        }
      });
    }
    if(resultFromValidatorCallChain || resultFromValidatorCallChain == undefined){
      this.currentActiveTab++ ;
    }
  }

  changeTabDec() {
    this.currentActiveTab--;
  }

  changeTabLanding(){
    this.loginService.showAppLandingPage.next(AppConstants.TRUE);
    this.loginService.showDigiOnboardingApp.next(AppConstants.FALSE);
    this.loginService.showDigiFailedOnboarding.next(AppConstants.FALSE);
    this.currentActiveTab = 0;
  }

  performPostOnboardingOperations(){
    let partnerId = this.service.getCurrentLoggedInPartner();
    switch(partnerId){
      case PartnerOnBoardingConstants.PARTNER_ID_DIGI:
        if (Number(this.partnerPolicy.deviceRRP) != 0) {
          this.saveImeiInDB(this.partnerPolicy.policyIMEINumber , this.partnerPolicy.countryCode);
        }
      break;
    }
  }

  generateDeviceRrpIdentifier(productCode , productName){
      return productCode + "_" + productName;
  }

  setDeviceRRP(productCd : string , errPanelId : string){
    let deviceRrpString = (<HTMLInputElement>document.getElementById(productCd)).value;
    let deviceRRP = parseInt((<HTMLInputElement>document.getElementById(productCd)).value);
    let errPanel = document.getElementById(errPanelId);
    let errBorderPanel = document.getElementById(productCd+"_"+errPanelId);
    let targetPricepanel = document.getElementById(productCd+"_000");
    if(deviceRrpString.length == 0){
      errPanel.innerHTML = "Device RRP cannot be blank";
      this.deviceRrpErrStatus = true;
      errBorderPanel.style.borderColor = "red";
      targetPricepanel.innerHTML = "";
    }else if(isNaN(deviceRRP)){
      errPanel.innerHTML = "Numerical values only";
      this.deviceRrpErrStatus = true;
      errBorderPanel.style.borderColor = "red";
      targetPricepanel.innerHTML = "";
    }else{
      let devicePrice = Number(deviceRRP);
      if(devicePrice <= 0){
        errPanel.innerHTML = "Should be greater than 0.";
        this.deviceRrpErrStatus = true;
        errBorderPanel.style.borderColor = "red";
        targetPricepanel.innerHTML = "";
      }else{
        let selectedProduct , selectedPlan;
        this.productDetailList.forEach((x) => {
          if(x.productCode === productCd){
            selectedProduct = x;
          }
        });
        selectedProduct.plans.forEach(plan => {
          if(deviceRRP <= plan.devicePriceEndingRange && deviceRRP >= plan.devicePriceStartingRange){
            selectedPlan = plan;
          }
        });
        let plans = selectedProduct.plans;
        plans.sort((a,b) => a.bandNo - b.bandNo);
        let maxBandPrice = plans[plans.length - 1].devicePriceEndingRange;
        if(deviceRRP > maxBandPrice){
          errPanel.innerHTML = "Should be less than "+maxBandPrice;
          this.deviceRrpErrStatus = true;
          errBorderPanel.style.borderColor = "red";
          targetPricepanel.innerHTML = "";
        }else{
          this.deviceRrpErrStatus = false;
          errPanel.innerHTML = null;
          errBorderPanel.style.borderColor = "";
          targetPricepanel.innerHTML = "   RM  "+selectedPlan.retailPriceValueWithTax;
        }
      }
    }
  }

  validateDuplicatePolicyCheck(imei : string , productName : string , contractCode , deviceRRP, currentPartner: string){
    let onboardingValidationRequest = {} , onboardingFields = {};
    onboardingFields[AppConstants.POLICY_IMEI_NUMBER] = imei;
    onboardingFields[AppConstants.ONBOARDING_FIELD_PRODUCT_NAME] = productName;
    onboardingValidationRequest[AppConstants.REQUEST_ONBOARDING_FIELDS] = onboardingFields;
    onboardingValidationRequest[AppConstants.REQUEST_COMMANDS] = ["OBV_002"];
    onboardingValidationRequest[AppConstants.PARTNER_ID] = currentPartner;
    this.spinner.show();
    this.policyService.validateOnBoardingField(onboardingValidationRequest).subscribe((response) => {
      this.spinner.hide();
      if (response && response.status === "OK" && response.obj) {
        let validationErrors = response.obj["validationFailures"];
        if(validationErrors){
          let OBV_002 = validationErrors["OBV_002"];
          
          if(OBV_002[0].validationFailureCode == "200.0400"){
            this.service.showInfo(validationErrors["policyIMEINumber"][0]["validationFailureReason"], AppConstants.PRODUCT_SELECTION_LABEL);
          } else {
            this.service.showInfo(OBV_002[0].validationFailureReason, AppConstants.PRODUCT_SELECTION_LABEL);
          }
          let partnerId = this.service.getCurrentLoggedInPartner();
          switch(partnerId){
            case PartnerOnBoardingConstants.PARTNER_ID_DIGI_SHIELD:
              this.loginService.showAppLandingPage.next(AppConstants.FALSE);
              this.loginService.showDigiOnboardingApp.next(AppConstants.FALSE);
              this.loginService.showDigiFailedOnboarding.next(AppConstants.TRUE);
            break;
          }
        }else{
          this.partnerPolicy.deviceRRP = deviceRRP.toString();
          this.policyProductDeviceRRP.set(contractCode , this.partnerPolicy.deviceRRP);
          this.currentActiveTab++;
        }
      }
    });
  }

  setDeviceType(event){
    this.partnerPolicy.deviceType = event.value.name;
  }

  setStoreInfo(event){
    let storeAttr = event.value.split(" ");
    let storeCode = storeAttr[storeAttr.length - 1];
    for(let i= 0 ; i< this.partnerStores.length ; i++){
        if(this.partnerStores[i].storeCode == storeCode){
          this.partnerPolicy.storeCode = this.partnerStores[i].storeCode ? this.partnerStores[i].storeCode : "";
          this.partnerPolicy.storeCity = this.partnerStores[i].city ? this.partnerStores[i].city.trim() : "" ;
        }
    }
  }

  getDigiDeviceList(productCoverage, productObj){
    //stop execution on BACK Button Press
    if (this.digiShieldDevicesList == null) {
      this.spinner.show();
      this.productService.getAllActiveDigiShieldDevices(productCoverage, PartnerOnBoardingConstants.PARTNER_ID_DIGI_SHIELD).subscribe((response) => {
        if (response && response != undefined) {
          this.spinner.hide();
          this.digiShieldDevicesList = response;
        }
      })
    } else {
      this.digiDisplayProductPrice(this.selectedDigiDevice,productObj);
    }
  }

  digiDisplayProductPrice(selectedDevice,productObj){
    this.spinner.show();
    let deviceRRP = parseInt(selectedDevice.deviceRetailPrice);
    this.partnerPolicy.deviceRRP = deviceRRP.toString();
    this.partnerPolicy.deviceMake = selectedDevice.makeName;
    this.partnerPolicy.deviceModel = selectedDevice.modelName;
    this.partnerPolicy.sku = selectedDevice.makeName + " "+selectedDevice.modelName;
    
    let productCode = productObj.productCode;
    let selectedProduct , selectedPlan;
    
    this.productDetailList.forEach((x) => {
      if(x.productCode === productCode){
        selectedProduct = x;
      }
    });

    selectedProduct.plans.forEach(plan => {
      if(deviceRRP <= plan.devicePriceEndingRange && deviceRRP >= plan.devicePriceStartingRange){
        selectedPlan = plan;
      }
    });
    
    setTimeout (() => {
      this.selectedPlanRrp = parseFloat(selectedPlan.retailPriceValueWithoutTax).toFixed(2);
      let targetPricepanel = document.getElementById(productObj.productCode+"_000");
      targetPricepanel.innerHTML = "   RM  "+this.selectedPlanRrp;
      this.spinner.hide();
   }, 1500);
  }
}
