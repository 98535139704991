import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppService} from '../app.service';
import {environment} from '../../environments/environment';
import {AppConstants} from '../app.constants';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class IdentityService {

    header: any;
    constructor(private http: HttpClient, private appService: AppService) {   
        this.header = AppService.apiKeyHeaders();
    }

    generateAndSendNewPassword(email, username) {
        return <any>this.http.post(environment.identityUrl + 'generateAndSendPassword', {'email': email, 'username': username});
    }

    createUser(userData) {
        return <any>this.http.post(environment.identityUrl + 'create', userData);
    }

    updatePassword(new_password) {
        var username = localStorage.getItem(AppConstants.LOGGED_IN_USER);
        return <any>this.http.post(environment.identityUrl,
        {'username': username, 'password': new_password, 'actionCommand': 'IA_002'},this.header);
    }

    validateUserData(email, username) {
        return <any>this.http.post(environment.identityUrl + 'validateUserAttribute', {'username': username, 'email': email});
    }

    validateSuppliedAttribute(password) {
        let username = this.appService.getCurrentUser();
        return <any>this.http.post(environment.identityUrl + 'validateSuppliedAttributes', {'username': username, 'password': password});
    }

    getUserList(userSearchCriteria): Observable<any>{
        return<any> this.http.get(environment.identityUrl+'search',{headers: new HttpHeaders({
            'x-api-key': localStorage.getItem(AppConstants.JWT_TOKEN)
          }),
          params:userSearchCriteria});
    }

    getCheckerList(userSearchCriteria): Observable<any>{
        return<any> this.http.get(environment.identityUrl+'checker/search',{headers: new HttpHeaders({
            'x-api-key': localStorage.getItem(AppConstants.JWT_TOKEN)
          }),
          params:userSearchCriteria});
    }

    async getUserStatus(username): Promise<any> {
        return await this.http.get(environment.identityUrl+`fetchUserStatus?username=${username}`).toPromise();
    }

    fetchPendingTask(assignee : string, authKey : string) : Observable<any>{
        return<any> this.http.get(environment.identityUrl+'approval-dashboard/pending-task?assignee='+assignee+"", {headers: new HttpHeaders({
            'flowable-auth-key': authKey
          })});
    }

    updateUser(payload) : Observable<any>{
        return <any>this.http.post(environment.identityUrl,payload, this.header);
    }

    fetchHistoricTask(assignee : string, authKey : string) : Observable<any>{
        return<any> this.http.get(environment.identityUrl+'approval-dashboard/historic-task?assignee='+assignee+"", {headers: new HttpHeaders({
            'flowable-auth-key': authKey
          })});
    }

    fetchCountryApproval(username : string, authKey : string) : Observable<any>{
        return<any> this.http.get(environment.identityUrl+'countryApproval'+'?authKey='+authKey+'&username='+username);
    }

    fetchConfiguredCheckers(makerCheckerSearch) {
        let endpointUrl = environment.identityUrl;
        endpointUrl = endpointUrl + 'checker/search?'
        return<any> this.http.get(endpointUrl, {
            headers: new HttpHeaders({
            'x-api-key': localStorage.getItem(AppConstants.JWT_TOKEN),
            'email': localStorage.getItem(AppConstants.MAIL)
            }),
            params: makerCheckerSearch
        })
    }

    checkIsQa(email) {
        return <any>this.http.post(environment.identityUrl + 'isQa?Email='+email,{});
    }
}
