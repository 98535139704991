import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';
import { AppConstants } from '../../../app.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutingConstants } from '../../../app-routing.constants';
import { IdentityService } from '../../../user-management/identity.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ChangePasswordComponent>, private service: AppService,
    private formBuilder: UntypedFormBuilder, private route: ActivatedRoute,
    private identityService: IdentityService, private router: Router) {
  }
  oldPasswordHide: boolean = true;
  newPasswordHide: boolean = true;
  retypeNewPasswordHide: boolean = true;
  passwordStrength: string;

  updatePasswordForm: UntypedFormGroup;
  isCalledFromForcedPasswordReset: boolean = false;
  isCalledFromForgotPasswordResetMail = false;
  isCalledFromResetPasswordEmail: boolean = false;

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.isCalledFromForcedPasswordReset = params[AppConstants.IS_CALLED_FROM_FORCED_PASSWORD_RESET] === AppConstants.TRUE;
      this.isCalledFromResetPasswordEmail = params['requestUser'];
      this.isCalledFromForgotPasswordResetMail = params[AppConstants.IS_CALLED_FROM_FORGOT_PASSWORD_RESET] === AppConstants.TRUE;
      if (this.isCalledFromResetPasswordEmail) {
        localStorage.setItem(AppConstants.HIDE_HEADERS_TAB, AppConstants.TRUE);
      }
    });
    this.updatePasswordForm = this.formBuilder.group({
      oldPassword: new UntypedFormControl('', {
        validators: this.oldPasswordValidator(),
        updateOn: "submit"
      }),
      newPassword: new UntypedFormControl('', {
        validators: [Validators.required, Validators.minLength(8), Validators.maxLength(20),
        Validators.pattern(AppConstants.PASSWORD_REGEX), this.oldNewPasswordValidator.bind(this)],
        updateOn: "change"
      }),
      retypeNewPassword: new UntypedFormControl('', {
        validators: [Validators.required, this.compareNewPasswordValidator.bind(this)],
        updateOn: "change"
      })
    });
  }

  oldPasswordValidator() {
    return !this.isCalledFromForgotPasswordResetMail ? Validators.required : Validators.nullValidator;
  }

  onStrengthChanged(event) {
    if (event < 40) {
      this.passwordStrength = 'Weak';
    }
    else if (event <= 80) this.passwordStrength = 'Medium'
    else this.passwordStrength = 'Strong'
    return '';
  }

  fetchNewPasswordError(): string {
    let control = this.updatePasswordForm.get('newPassword');
    if (control.hasError('required')) {
      return "New password cannot be empty";
    }
    if (control.hasError('minlength')) {
      return "New password minimum length is 8";
    }
    if (control.hasError('maxlength')) {
      return "New password maximum length is 20";
    }
    if (control.hasError('pattern')) {
      return "Password should have between 8-20 characters and must include atleast 1 uppercase, " +
        "1 numerical value and 1 special character"
    }
    if (control.hasError('samePasswordWithOld')) {
      return "New password same with Old Password"
    }
    return null;
  }

  fetchRetypeNewPasswordError(): string {
    let control = this.updatePasswordForm.get('retypeNewPassword');
    if (control.hasError('required')) {
      return "Retype new password cannot be empty";
    }
    if (control.hasError('notSamePassword')) {
      return "Password does not match";
    }
    return null;
  }

  refreshValidity() {
    Object.keys(this.updatePasswordForm.controls).forEach(field => {
      let control = this.updatePasswordForm.get(field);
      control.updateValueAndValidity();
    })
  }

  async onSubmit() {
    this.refreshValidity();
    if (this.updatePasswordForm.valid) {
      let validOldPassword:boolean = await this.validateOldPassword();
      if(!validOldPassword){
        this.service.showInfo(AppConstants.OLD_PASSWORD_NOT_MATCH,AppConstants.PASSWORD_RESET_TITLE_LABEL);
        return;
      }
      let control = this.updatePasswordForm.get('newPassword');
      this.identityService.updatePassword(control.value).subscribe(res => {
        let response: any = res;
        if (response.status === 'Success') {
          if (localStorage.getItem(AppConstants.HIDE_HEADERS_TAB) == AppConstants.TRUE) {
            this.service.showInfo(AppConstants.FORCED_PASSWORD_RESET_SUCCESS_MESSAGE, AppConstants.PASSWORD_RESET_TITLE_LABEL);
            this.service.logout(3000);
          } else {
            this.service.logout();
          }
        } else {
          this.service.showInfo(response.message, AppConstants.PASSWORD_RESET_TITLE_LABEL);
        }
        this.dialogRef.close();
      })
    }
  }

  async validateOldPassword():Promise<boolean>{
    let control = this.updatePasswordForm.get('oldPassword');
    return new Promise((resolve,reject)=>{
      //Password reset has no OldPassword
      if(this.isCalledFromForgotPasswordResetMail){
        resolve(true);
      }
      let response:any;
      this.identityService.validateSuppliedAttribute(control.value).subscribe(res=>{
        response = res;
        if(response && response.status === 'SUCCESS'){
          resolve(true);
        }else{
          resolve(false);
        }
      },()=>{
        resolve(false);
      },()=>{
        this.service.onComplete(response);
      })
    })
  }

  logout() {
    if (this.isCalledFromResetPasswordEmail) {
      localStorage.setItem(AppConstants.HIDE_HEADERS_TAB, AppConstants.FALSE);
      this.router.navigate([AppRoutingConstants.HOME])
    } else {
      this.service.logout();
    }
  }

  shouldRenderExternalCallConfig() {
    return this.isCalledFromForcedPasswordReset || this.isCalledFromForgotPasswordResetMail
  }

  oldNewPasswordValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (this.updatePasswordForm) {
      let oldControl = this.updatePasswordForm.get('oldPassword');
      if (!this.isCalledFromForgotPasswordResetMail) {
        if (control.value && oldControl.value) {
          if (control.value === oldControl.value) {
            return { 'samePasswordWithOld': true };
          }
        }
      }
      return null;
    } else {
      return null;
    }
  }

  compareNewPasswordValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (this.updatePasswordForm) {
      let newControl = this.updatePasswordForm.get('newPassword');
      if (newControl.value && control.value) {
        if (newControl.value != control.value) {
          return { 'notSamePassword': true };
        } else {
          return null;
        }
      }
      return null;
    } else {
      return null;
    }

  }
}
