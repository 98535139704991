export class KeycloakInit {
}
import { KeycloakService } from "keycloak-angular";
import { of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { fromPromise } from 'rxjs/internal-compatibility';

export function initializeKeycloak(
  keycloak: KeycloakService,
  ) {
    return () =>

            keycloak.init({
              config: {
                url: 'https://keycloak.cw.app.staging.device.bolttech.asia' + '/auth',
                realm: 'crmz-fe',
                clientId: 'frontend',
              },
              initOptions: {
    
                pkceMethod: 'S256', 
                // must match to the configured value in keycloak
                redirectUri: 'http://dev-eks-public-test-253275944.ap-southeast-1.elb.amazonaws.com/*',   
                // this will solved the error 
                checkLoginIframe: false
              }
            });
}

