<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" color="#FFFFFF" fullScreen="false" size="large" type="ball-fussion">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
<div appBlockCopyPaste>
<div class="container" fxLayoutGap="0" fxLayoutGap.xs="0">
  <div class="logo-container" *ngIf="this.partnerId === PolicyEnum.PARTNER_AEON_RET_MALAYSIA; then aeonLogo else defaultLogo">
  </div>
  <ng-template #aeonLogo><img src="../../../assets/images/aeon_safeguard_logo.png" style="width: 100%;"></ng-template>
  <ng-template #defaultLogo><img src="../../../assets/images/deviceProtection_Logo_2.png" style="width: 100%;"></ng-template>
  <div fxLayout="column" class="div-box m-20">
    <ng-container *ngIf="hasOnboardingConfig">
      <div>
        <h1 class="heading-container">
          Step {{currentActiveTab+1}} of {{totalOnboardingSteps}}:
        </h1>
        <h1 class="heading-container">
          {{partnerOnboardingService.fetchHeaders(currentActiveTab)}}
        </h1>
      </div>
      <div>
        <mat-card>
          <mat-card-content>
            <ng-container *ngIf="partnerOnboardingService.isPanelToBeShown('PARTNER_DEVICE_PRICE_PANEL', currentActiveTab)">
              <div class="block">
                <div>
                  <span><b>Enter the device RRP in Ringgit (RM) to see available plans</b></span>
                  
                </div>
                <form [formGroup]="deviceRRPForm">
                  <div formGroupName="pricePanel">
                    <mat-form-field>
                      <mat-label class="label-policy">Device Retail Price</mat-label>
                      <input (input)="partnerPolicy.deviceRRP=$event.target.value" placeholder="0-10000"
                      (keyup.enter)="submitEnter('PARTNER_DEVICE_PRICE_PANEL')"
                        formControlName="deviceRRP" required matInput type="text" />
                      <mat-error *ngIf="(deviceRRPForm.get('pricePanel').get('deviceRRP').hasError('emptyCheckFailed'))">
                        Device Retail Price cannot be empty
                      </mat-error>
                      <mat-error
                        *ngIf="deviceRRPForm.get('pricePanel').get('deviceRRP').hasError('nonNumberCheckFailed')">
                        Device Retail Price must be numeric
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="deviceRRPValidationFailed &&
                      !deviceRRPForm.get('pricePanel').touched">
                      <b>{{deviceRRPErrorMessage}}</b>
                    </mat-error>
                  </div>
                </form>
              </div>
            </ng-container>
            <ng-container *ngIf="partnerOnboardingService.isPanelToBeShown('PARTNER_SKU_CODE_PANEL', currentActiveTab)">
              <div class="block">
                <div>
                  <span><b>Enter the SKU code</b></span>
                </div>
                <form [formGroup]="skuCodeForm">
                  <div formGroupName="skuCodePanel">
                    <mat-form-field>
                      <mat-label class="label-policy">SKU Code</mat-label>
                      <input (input)="partnerPolicy.partnerProductId=$event.target.value" placeholder="Enter code"
                      (keyup.enter)="submitEnter('PARTNER_SKU_CODE_PANEL')"
                        formControlName="partnerProductId" required matInput type="text" (paste)="$event.stopPropagation()" (copy)="$event.stopPropagation()" />
                      <mat-error *ngIf="(skuCodeForm.get('skuCodePanel').get('partnerProductId').hasError('emptyCheckFailed'))">
                        SKU code cannot be empty
                      </mat-error>
                      <mat-error *ngIf="(skuCodeForm.get('skuCodePanel').get('partnerProductId').hasError('validationFailed'))">
                      {{skuCodeErrorMessage}}
                    </mat-error>
                    </mat-form-field>
                  </div>
                </form>
              </div>
            </ng-container>
            <ng-container *ngIf="partnerOnboardingService.isPanelToBeShown('PARTNER_COVERAGE_SELECTION_PANEL',currentActiveTab)">
              <div class="block">
                <div>
                  <span><b>Select a protection type</b></span>
                </div>
                <div>
                  <ng-container *ngIf="hasADLDProduct">
                    <button mat-raised-button (click)="getCoverageOptions(adldProduct)">
                      <table cols="2" style="width:100%;">
                        <tr>
                          <td style="width:30%; text-align:center"><img class="productIcon" src="../../../assets/images/ADLD_Product_Icon.png"></td>
                          <td style="width:70%;">
                            <table style="width:100%">
                              <tr><td><b>Accidental and Liquid Damage</b></td></tr>
                              <tr><td>
                                <ul>
                                  <li>Screen Replacement</li>
                                  <li>Water Damage</li>
                                </ul>  
                              </td></tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </button>
                  </ng-container>
                </div>
                <div>
                  <ng-container *ngIf="hasTHEFTProduct">
                    <button mat-raised-button class="product-btn" (click)="getCoverageOptions(theftProduct)">
                      <table cols="2" style="width:100%;">
                        <tr>
                          <td style="width:30%; text-align:center"><img class="productIcon" src="../../../assets/images/THEFT_Product_Icon.png"></td>
                          <td style="width:70%;">
                            <table style="width:100%">
                              <tr><td><b>Theft Cover</b></td></tr>
                              <tr><td>
                                <ul>
                                  <li>Screen Replacement</li>
                                  <li>Water Damage</li>
                                  <li>Theft of Device</li>
                                </ul>  
                              </td></tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </button>
                  </ng-container>
                </div>
                <div>
                  <ng-container *ngIf="hasCSProduct">
                    <button mat-raised-button class="product-btn" (click)="getCoverageOptions(csProduct)">
                      <table cols="2" style="width:100%;">
                        <tr>
                          <td style="width:30%; text-align:center"><img class="productIcon" src="../../../assets/images/CS_Product_Icon.png"></td>
                          <td style="width:70%;">
                            <table style="width:100%">
                              <tr><td><b>Cracked Screen</b></td></tr>
                              <tr><td>
                                <ul>
                                  <li>Screen Replacement</li>
                                </ul>  
                              </td></tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </button>
                  </ng-container>
                </div>
                <div>
                  <ng-container *ngIf="hasEWProduct">
                    <button mat-raised-button class="product-btn" (click)="getCoverageOptions(ewProduct)">
                      <table cols="2" style="width:100%;">
                        <tr>
                          <td style="width:30%; text-align:center"><img class="productIcon" src="../../../assets/images/CS_Product_Icon.png"></td>
                          <td style="width:70%;">
                            <table style="width:100%">
                              <tr><td><b>Extended Warranty</b></td></tr>
                              <tr><td>
                                <ul>
                                  <li>Manufacturing defects</li>
                                </ul>  
                              </td></tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </button>
                  </ng-container>
                </div>
                <div>
                  <ng-container *ngIf="hasAdldEwProduct">
                    <button mat-raised-button class="product-btn" (click)="getCoverageOptions(adldEwProduct)">
                      <table cols="2" style="width:100%;">
                        <tr>
                          <td style="width:30%; text-align:center"><img class="productIcon" src="../../../assets/images/ADLD_Product_Icon.png"></td>
                          <td style="width:70%;">
                            <table style="width:100%">
                              <tr><td><b>Accidental and Liquid Damage with Extended Warranty</b></td></tr>
                              <tr><td>
                                <ul>
                                  <li>Screen Replacement</li>
                                  <li>Water Damage</li>
                                  <li>Manufacturing defects</li>
                                </ul>  
                              </td></tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </button>
                  </ng-container>
                </div>
                <div>
                  <ng-container *ngIf="hasCsEwProduct">
                    <button mat-raised-button class="product-btn" (click)="getCoverageOptions(csEwProduct)">
                      <table cols="2" style="width:100%;">
                        <tr>
                          <td style="width:30%; text-align:center"><img class="productIcon" src="../../../assets/images/CS_Product_Icon.png"></td>
                          <td style="width:70%;">
                            <table style="width:100%">
                              <tr><td><b>Cracked Screen with Extended Warranty</b></td></tr>
                              <tr><td>
                                <ul>
                                  <li>Screen Replacement</li>
                                  <li>Manufacturing defects</li>
                                </ul>  
                              </td></tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </button>
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="partnerOnboardingService.isPanelToBeShown('PARTNER_PRODUCT_SELECTION_PANEL',currentActiveTab)">
              <div class="block">
                <div>
                  <span><b>Plans are available for these devices</b></span>
                </div>
                <div class="productName">
                  <ng-container *ngFor="let product of insuranceProducts">
                    <button mat-raised-button (click)="getProductOptions(product)" style="padding:0px;border: 1px solid cyan;white-space:normal">
                      <span><b>{{product.clientProductDescription}}</b></span>
                    </button>
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="partnerOnboardingService.isPanelToBeShown('PARTNER_BUNDLE_SELECTION_PANEL',currentActiveTab)">
              <ng-container *ngIf="rrpOverrange">
                <div class="block" style="text-align:center">
                  <span><b>Sorry, there is no insurance available for the Device RRP Range.
                  </b></span>
                </div>
              </ng-container>
              <ng-container *ngIf="!rrpOverrange">
                <div class="block">
                  <button mat-raised-button (click)="onboardBundleProduct()" style="padding:0px;border: 1px solid cyan">
                    <table cols="2" style="width: 100%;">
                      <tr>
                        <td style="width: 75%; text-align:left" class="p-20">
                          <span><b>{{bundledProduct.packageName}}</b></span>
                        </td>
                        <td style="width: 25%; text-align:left" class="p-20">
                        <span><b>RM{{bundledPlanRRP | number : '1.2-2'}}</b></span>
                        </td>
                      </tr>
                    </table>
                    <table style="width: 100%; text-align:left;background-color:#F3F3F6 ;">
                      <tr>
                        <td class="p-20">
                          <span><b>{{bundledProduct.packageDescription}}</b></span>
                        </td>
                      </tr>
                      <table cols="2" style="width:100%;" *ngFor="let packageDetail of bundledProduct.packageDetails">
                        <tr>
                          <ng-container *ngIf="packageDetail.packagePartner == 'Tune'">
                            <td style="width:5%" class="p-20"><img src="../../../assets/images/Tune_logo.png" class="logo"></td>
                          </ng-container>
                          <ng-container *ngIf="packageDetail.packagePartner == 'Bolttech'">
                            <td style="width:5%" class="p-20"><img src="../../../assets/images/Bolttech_B_Logo.png" class="logo"></td>
                          </ng-container>
                          <td style="width:95%" class="p-20">
                            <ul style="font-size:14px;line-height:normal">
                              <li *ngFor="let details of packageDetail.packageDetails">{{details}}</li>
                            </ul>
                          </td>
                        </tr>
                      </table>
                    </table>
                  </button>
                  <ng-container *ngIf="!displayOtherProducts">
                    <div class="div-box text-left m-20">
                      <span>Click here to see </span><span style="color:cyan;cursor: pointer;" (click)="showOtherProducts(true)"><u>other plans</u></span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="displayOtherProducts">
                    <ng-container *ngIf="insuranceProduct !='' && insuranceProduct != null">
                      <button mat-raised-button class="product-btn div-box m-20" (click)="onboardInsuranceProduct()" style="padding:0px;border: 1px solid cyan">
                        <table cols="2" style="width: 100%;">
                          <tr>
                            <td style="width: 75%; text-align:left" class="p-20">
                              <span><b>{{insuranceProduct.packageName}}</b></span>
                            </td>
                            <td style="width: 25%; text-align:left" class="p-20">
                            <span><b>RM{{insurancePlanRRP | number : '1.2-2'}}</b></span>
                            </td>
                          </tr>
                        </table>
                        <table style="width: 100%; text-align:left;background-color:#F3F3F6 ;">
                          <tr>
                            <td class="p-20">
                              <span><b>{{insuranceProduct.packageDescription}}</b></span>
                            </td>
                          </tr>
                          <table cols="2" style="width:100%;" *ngFor="let packageDetail of insuranceProduct.packageDetails">
                            <tr>
                              <ng-container *ngIf="packageDetail.packagePartner == 'Tune'">
                                <td style="width:5%" class="p-20"><img src="../../../assets/images/Tune_logo.png" class="logo"></td>
                              </ng-container>
                              <ng-container *ngIf="packageDetail.packagePartner == 'Bolttech'">
                                <td style="width:5%" class="p-20"><img src="../../../assets/images/Bolttech_B_Logo.png" class="logo"></td>
                              </ng-container>
                              <td style="width:95%" class="p-20">
                                <ul style="font-size:14px;line-height:normal">
                                  <li *ngFor="let details of packageDetail.packageDetails">{{details}}</li>
                                </ul>
                              </td>
                            </tr>
                          </table>
                        </table>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="vasProduct !='' && vasProduct != null">
                      <button mat-raised-button class="product-btn div-box m-20" (click)="onboardVASProduct()" style="padding:0px;border: 1px solid cyan">
                        <table cols="2" style="width: 100%;">
                          <tr>
                            <td style="width: 75%; text-align:left" class="p-20">
                              <span><b>{{vasProduct.packageName}}</b></span>
                            </td>
                            <td style="width: 25%; text-align:left" class="p-20">
                            <span><b>RM{{vasStandalonePlanRRP | number : '1.2-2'}}</b></span>
                            </td>
                          </tr>
                        </table>
                        <table style="width: 100%; text-align:left;background-color:#F3F3F6 ;">
                          <tr>
                            <td class="p-20">
                              <span><b>{{vasProduct.packageDescription}}</b></span>
                            </td>
                          </tr>
                          <table cols="2" style="width:100%;" *ngFor="let packageDetail of vasProduct.packageDetails">
                            <tr>
                              <ng-container *ngIf="packageDetail.packagePartner == 'Tune'">
                                <td style="width:5%" class="p-20"><img src="../../../assets/images/Tune_logo.png" class="logo"></td>
                              </ng-container>
                              <ng-container *ngIf="packageDetail.packagePartner == 'Bolttech'">
                                <td style="width:5%" class="p-20"><img src="../../../assets/images/Bolttech_B_Logo.png" class="logo"></td>
                              </ng-container>
                              <td style="width:95%" class="p-20">
                                <ul style="font-size:14px;line-height:normal">
                                  <li *ngFor="let details of packageDetail.packageDetails">{{details}}</li>
                                </ul>
                              </td>
                            </tr>
                          </table>
                        </table>
                      </button>
                    </ng-container>
                    <div class="div-box text-left m-20">
                      <span>Click here to see </span><span style="color:cyan;cursor: pointer;" (click)="showOtherProducts(false)"><u>less plans</u></span>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="partnerOnboardingService.isPanelToBeShown('PARTNER_PLAN_DETAIL_PANEL',currentActiveTab)">
              <div class="block div-box m-20">
                <table cols="2" style="width: 100%;margin-left:20px;margin-right: 20px ">
                  <tr>
                    <td class="c1">
                      <table cols="2">
                        <tr>
                          <td style="width:25%">
                            <img src="../../../assets/images/deviceProtection_icon.png" style="width:35px" />
                          </td>
                          <td style="width:75%">
                            <span class="fontHeading"><b>{{onboardPackage.packageName}}</b></span>
                          </td>
                        </tr>
                      </table>
                    <td class="c2">
                      <span style="color:cyan;font-size:20px;"><b>RM{{reviewTotalRRP | number : '1.2-2'}}</b></span>
                    </td>
                  </tr>
                </table>
                <ng-container *ngFor="let packagePlan of onboardPackage.packagePlans">
                  <mat-divider style="color: #E8E7EE;"></mat-divider>
                  <table cols="2" style="width: 100%;margin-left:20px;margin-right: 20px" class="m-20">
                    <tr>
                      <td class="c1">
                        <span class="fontHeading"><b>{{packagePlan.packagePlanName}}</b></span>
                      </td>
                      <td class="c2">
                        <span class="fontHeading"><b>RM{{parseDescriptionsValue(packagePlan.packagePlanRRP) | number : '1.2-2'}}</b></span>
                      </td>
                    </tr>
                    <tr>
                      <td class="c1">
                        <table cols="2" style="width:100%">
                          <tr>
                            <ng-container *ngIf="packagePlan.packagePlanPartner == 'Tune'">
                              <td style="width:10%">
                                <img src="../../../assets/images/Tune_logo.png" class="logo">
                              </td>
                            </ng-container>
                            <ng-container *ngIf="packagePlan.packagePlanPartner == 'Bolttech'">
                              <td style="width:10%">
                                <img src="../../../assets/images/BOLTTECH_POSITIVE_CYAN_RGB.png" class="logo">
                              </td>
                            </ng-container>
                            <td style="width:90%">
                              <span
                                style="color:#9D9D9D;font-size:10px;text-align:left">{{packagePlan.packagePartnerDescription}}</span>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr *ngFor="let description of packagePlan.packagePlanDescription">
                      <td class="c1-description">
                        <span class="reviewDescription"><b>{{description.planDescription}}</b></span>
                      </td>
                      <td class="c2-description">
                        <span class="reviewDescription"><b>{{parseDescriptionsValue(description.planCoverage)}}</b></span>
                      </td>
                    </tr>
                  </table>
                </ng-container>
                <table cols="2" style="width: 100%;margin-left:20px;margin-right: 20px" class="m-20">
                  <ng-container
                    *ngIf="onboardPackage.packageBrochureUrl !='' && onboardPackage.packageBrochureUrl != null">
                    <tr>
                      <td style="width:3%">
                        <img src="../../../assets/images/Vector.png" style="max-height:20px">
                      </td>
                      <td style="width:97%">
                        <span style="color:cyan"><a href="{{onboardPackage.packageBrochureUrl}}">Download
                            brochure</a></span>
                      </td>
                    </tr>
                    <tr>
                  </ng-container>
                  <ng-container>
                    <tr>
                      <td style="width:1%;padding-left:0;padding-right:0;text-align:center">
                        <img src="../../../assets/images/Vector.png" style="max-height:20px">
                      </td>
                      <td style="width:50%">
                        <span style="color:cyan;font-size:16px;text-align:left">
                          <a [href]="(onboardPackage.packagePDSUrl && onboardPackage.packagePDSUrl !== '')?onboardPackage.packagePDSUrl
                                                  :'https://download.device.bolttech.my/PDS_Bolttech+Mobile+Device+Protection.pdf'"
                            target=”_blank”>
                            Download product disclosure sheet
                          </a>
                        </span>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="onboardPackage.packagePolicyUrl !='' && onboardPackage.packagePolicyUrl != null">
                    <tr>
                      <td style="width:3%">
                        <img src="../../../assets/images/Vector.png" style="max-height:20px">
                      </td>
                      <td style="width:97%">
                        <span style="color:cyan"><a href="{{onboardPackage.packagePolicyUrl}}">Download policy
                            provisions</a></span>
                      </td>
                    </tr>
                    <tr>
                  </ng-container>
                </table>
              </div>
            </ng-container>
            <ng-container *ngIf="partnerOnboardingService.isPanelToBeShown('PARTNER_SALES_REGISTRATION_PANEL',currentActiveTab)">
              <div class="block">
                <form [formGroup]="partnerOnboardingForm">
                  <mat-accordion>
                    <div>
                      <mat-expansion-panel formGroupName="customerDetailPanel">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <b>Customer Details</b>
                            <mat-error *ngIf="customerInfoValidationFailed" class="mat-error-panel">
                              <b>{{panelErrorMessage}}</b>
                            </mat-error>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div style="overflow-y:auto !important;">
                          <table class="alignTable">
                            <tr>
                              <td>
                                <mat-form-field>
                                  <mat-label class="label-policy">Customer's First Name</mat-label>
                                  <input (input)="partnerPolicy.customerName=$event.target.value"
                                    formControlName="customerName" placeholder="Customer's Name" matInput required type="text"
                                    maxlength="100" [errorStateMatcher]="matcher">
                                  <mat-error
                                    *ngIf="partnerOnboardingForm.get('customerDetailPanel').get('customerName').hasError('emptyCheckFailed')">
                                    First Name cannot be empty
                                  </mat-error>
                                  <mat-error
                                    *ngIf="partnerOnboardingForm.get('customerDetailPanel').get('customerName').hasError('numberCheckFailed')">
                                    First Name must not be numberic only
                                  </mat-error>
                                </mat-form-field>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <mat-form-field>
                                  <mat-label class="label-policy">Customer's Last Name</mat-label>
                                  <input (input)="partnerPolicy.customerLastName=$event.target.value"
                                    formControlName="customerLastName" placeholder="Customer's Last Name" matInput required type="text"
                                    maxlength="100" [errorStateMatcher]="matcher">
                                  <mat-error
                                    *ngIf="partnerOnboardingForm.get('customerDetailPanel').get('customerLastName').hasError('emptyCheckFailed')">
                                    Last Name cannot be empty
                                  </mat-error>
                                  <mat-error
                                    *ngIf="partnerOnboardingForm.get('customerDetailPanel').get('customerLastName').hasError('numberCheckFailed')">
                                    Last Name must not be numberic only
                                  </mat-error>
                                </mat-form-field>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <mat-form-field class="example-full-width">
                                  <mat-label class="label-policy">Mobile</mat-label>
                                  <span matPrefix>60 &nbsp;</span>
                                  <input type="text" matInput placeholder="xx-xxxx-xxxx"
                                    (input)="partnerPolicy.custMobileNumber=$event.target.value"
                                    formControlName="custMobileNumber" required maxlength="10" minlength="9" [errorStateMatcher]="matcher">
                                  <mat-error
                                    *ngIf="partnerOnboardingForm.get('customerDetailPanel').get('custMobileNumber').hasError('emptyCheckFailed')">
                                    Mobile no. cannot be empty
                                  </mat-error>
                                  <mat-error
                                    *ngIf="partnerOnboardingForm.get('customerDetailPanel').get('custMobileNumber').hasError('nonNumberCheckFailed')">
                                    Mobile no. must be numberic only
                                  </mat-error>
                                  <mat-error
                                    *ngIf="partnerOnboardingForm.get('customerDetailPanel').get('custMobileNumber').hasError('minlength') &&
                                      !partnerOnboardingForm.get('customerDetailPanel').get('custMobileNumber').hasError('nonNumberCheckFailed')">
                                    Mobile no. should contain minimum 9 digits
                                  </mat-error>
                                  <mat-error
                                    *ngIf="partnerOnboardingForm.get('customerDetailPanel').get('custMobileNumber').hasError('prefixError') &&
                                      !partnerOnboardingForm.get('customerDetailPanel').get('custMobileNumber').hasError('minlength')">
                                    Mobile no. must start from "1" after countryCode (60)
                                  </mat-error>
                                </mat-form-field>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <mat-form-field>
                                  <mat-label class="label-policy">Email</mat-label>
                                  <input (input)="partnerPolicy.customerEmail=$event.target.value"
                                    placeholder="Customer Email" formControlName="customerEmail" required matInput type="text" [errorStateMatcher]="matcher">
                                  <mat-error
                                    *ngIf="partnerOnboardingForm.get('customerDetailPanel').get('customerEmail').hasError('emailValidationFailed')">
                                    Please provide valid value for email
                                  </mat-error>
                                  <mat-error
                                    *ngIf="partnerOnboardingForm.get('customerDetailPanel').get('customerEmail').hasError('emptyCheckFailed')">
                                    Email cannot be empty
                                  </mat-error>
                                </mat-form-field>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>ID Type</b>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <mat-button-toggle-group [(value)]="partnerPolicy.idType" formControlName="idType">
                                  <mat-button-toggle value="IC">IC</mat-button-toggle>
                                  <mat-button-toggle value="Passport">Passport</mat-button-toggle>
                                </mat-button-toggle-group>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <mat-form-field>
                                  <mat-label class="label-policy">ID No.</mat-label>
                                  <input (input)="partnerPolicy.custIdNumber=$event.target.value"
                                    placeholder="Customer Id Number" formControlName="customerId" matInput type="text">
                                </mat-form-field>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <mat-form-field>
                                  <mat-label class="label-policy">Address</mat-label>
                                  <input (input)="partnerPolicy.custAddress.address1=$event.target.value"
                                    placeholder="Customer Address" formControlName="custAddress" matInput type="text"
                                    maxlength="250">
                                </mat-form-field>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <mat-form-field>
                                  <mat-label class="label-policy">Postal Code</mat-label>
                                  <input (input)="partnerPolicy.custAddress.postalCode=$event.target.value"
                                    placeholder="Customer Postal Code" formControlName="postalCode" matInput type="text"
                                    maxlength="20">
                                </mat-form-field>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <mat-form-field>
                                  <mat-label class="label-policy">City</mat-label>
                                  <input (input)="partnerPolicy.custAddress.customerCity=$event.target.value"
                                    placeholder="Customer City" formControlName="customerCity" matInput type="text"
                                    maxlength="50">
                                </mat-form-field>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <mat-form-field>
                                  <mat-label class="label-policy">State</mat-label>
                                  <input placeholder="Customer State"
                                    (input)="partnerPolicy.custAddress.customerState=$event.target.value"
                                    formControlName="customerState" matInput type="text" maxlength="50">
                                </mat-form-field>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <mat-form-field>
                                  <mat-select (selectionChange)="resetCountry($event)" formControlName="customerCountryCode"
                                    placeholder="Country">
                                    <mat-option *ngFor="let country of countries" [value]="country.twoCharCode">
                                      {{country.name}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </mat-expansion-panel>
                    </div>

                    <div>
                      <mat-expansion-panel formGroupName="deviceDetailPanel">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <b>Device Details</b>
                            <mat-error *ngIf="deviceInfoValidationFailed || !fileName" class="mat-error-panel">
                              <b>{{panelErrorMessage}}</b>
                            </mat-error>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div style="overflow-y:auto !important;">
                          <table class="alignTable">
                            <tr>
                              <td *ngIf="this.partnerId === PolicyEnum.PARTNER_AEON_RET_MALAYSIA; then isMYAeonPartnerDeviceRRP else notMYAeonPartnerDeviceRRP" ></td>
                              <ng-template #isMYAeonPartnerDeviceRRP>
                                <mat-form-field >
                                  <mat-label class="label-policy">Retail Price</mat-label>
                                  <input (input)="partnerPolicy.deviceRRP=$event.target.value" [placeholder]="minDeviceRRP + ' - ' + maxDeviceRRP"
                                    formControlName="deviceRRP" required matInput type="text" [errorStateMatcher]="matcher"/>
                                  <mat-error *ngIf="(partnerOnboardingForm.get('deviceDetailPanel').get('deviceRRP').hasError('emptyCheckFailed'))">
                                    Retail Price cannot be empty
                                  </mat-error>
                                  <mat-error *ngIf="partnerOnboardingForm.get('deviceDetailPanel').get('deviceRRP').hasError('nonNumberCheckFailed')">
                                    Retail Price must be numeric only
                                  </mat-error>
                                  <mat-error *ngIf="partnerOnboardingForm.get('deviceDetailPanel').get('deviceRRP').hasError('rrpOverrange')">
                                    Retail Price does not fall in the price band range
                                  </mat-error>
                               </mat-form-field>
                              </ng-template>
                              <ng-template #notMYAeonPartnerDeviceRRP>
                                <mat-form-field >
                                  <mat-label class="label-policy">Device Retail Price</mat-label>
                                  <span matPrefix>RM &nbsp;</span>
                                  <input [value]="partnerPolicy.deviceRRP" matInput type="text" [readonly]="true" />
                                </mat-form-field>
                              </ng-template>
                            </tr>
                            <tr>
                              <td>
                                <mat-form-field>
                                  <mat-label class="label-policy">Device Type</mat-label>
                                  <mat-select required formControlName="deviceType" [(value)]="selected_deviceType"
                                    (selectionChange)="setDeviceType($event)" [errorStateMatcher]="matcher">
                                    <mat-option *ngFor="let deviceType of deviceTypeList" [value]="deviceType">
                                      {{deviceType.name}}
                                    </mat-option>
                                  </mat-select>
                                  <mat-error
                                    *ngIf="partnerOnboardingForm.get('deviceDetailPanel').get('deviceType').hasError('required')">
                                    Device Type cannot be empty
                                  </mat-error>
                                </mat-form-field>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <mat-form-field>
                                  <mat-label class="label-policy">Device Brand</mat-label>
                                  <input (input)="partnerPolicy.deviceMake=$event.target.value" placeholder="Samsung"
                                    formControlName="deviceMake" matInput required type="text" [errorStateMatcher]="matcher"/>
                                  <mat-error
                                    *ngIf="partnerOnboardingForm.get('deviceDetailPanel').get('deviceMake').hasError('emptyCheckFailed')">
                                    Device Brand cannot be empty
                                  </mat-error>
                                </mat-form-field>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <mat-form-field>
                                  <mat-label class="label-policy">Device Model</mat-label>
                                  <input (input)="partnerPolicy.deviceModel=$event.target.value" placeholder="S21"
                                    formControlName="deviceModel" matInput required type="text" [errorStateMatcher]="matcher"/>
                                  <mat-error
                                    *ngIf="partnerOnboardingForm.get('deviceDetailPanel').get('deviceModel').hasError('emptyCheckFailed')">
                                    Device Model cannot be empty
                                  </mat-error>
                                </mat-form-field>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <mat-form-field>
                                  <mat-label class="label-policy">Device Capacity (GB)</mat-label>
                                  <input (input)="partnerPolicy.deviceCapacity=$event.target.value" placeholder="128"
                                    formControlName="deviceCapacity" matInput type="text" [errorStateMatcher]="matcher"
                                    [required]="this.partnerId !== PolicyEnum.PARTNER_AEON_RET_MALAYSIA"/>
                                  <mat-error
                                    *ngIf="partnerOnboardingForm.get('deviceDetailPanel').get('deviceCapacity').hasError('emptyCheckFailed')">
                                    Device capacity cannot be empty
                                  </mat-error>
                                  <mat-error
                                    *ngIf="partnerOnboardingForm.get('deviceDetailPanel').get('deviceCapacity').hasError('nonNumberCheckFailed')">
                                    Only numeric value can be entered e.g. 32, 64, 128
                                  </mat-error>
                                </mat-form-field>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <mat-form-field>
                                  <mat-label class="label-policy">Device Colour</mat-label>
                                  <input (input)="partnerPolicy.deviceColor=$event.target.value" placeholder="Black"
                                    formControlName="deviceColor" matInput type="text" [errorStateMatcher]="matcher"
                                    [required]="this.partnerId !== PolicyEnum.PARTNER_AEON_RET_MALAYSIA"/>
                                  <mat-error
                                    *ngIf="partnerOnboardingForm.get('deviceDetailPanel').get('deviceColor').hasError('nonAlphabeticCheckFailed')">
                                    No special character or number allowed, Only alphabets
                                  </mat-error>
                                  <mat-error
                                    *ngIf="partnerOnboardingForm.get('deviceDetailPanel').get('deviceColor').hasError('emptyCheckFailed')">
                                    Device colour cannot be empty
                                  </mat-error>
                                </mat-form-field>
                              </td>
                            </tr>
                            <tr *ngIf="this.partnerId === PolicyEnum.PARTNER_AEON_RET_MALAYSIA">
                              <td>
                                <mat-form-field>
                                  <mat-label class="label-policy">Invoice Number</mat-label>
                                  <input (keydown)="onKeydown($event)" (input)="partnerPolicy.invoiceNo=$event.target.value"
                                    formControlName="invoiceNo" matInput required type="number" [errorStateMatcher]="matcher">
                                  <mat-error
                                    *ngIf="partnerOnboardingForm.get('deviceDetailPanel').get('invoiceNo').hasError('emptyCheckFailed')">
                                    Invoice number cannot be empty
                                  </mat-error>
                                  <mat-error
                                    *ngIf="partnerOnboardingForm.get('deviceDetailPanel').get('invoiceNo').hasError('invoiceValidationFailed')">
                                    Invoice number should be exactly 18 digits
                                  </mat-error>
                                </mat-form-field>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <mat-form-field>
                                  <mat-label class="label-policy">IMEI Number</mat-label>
                                  <input (input)="partnerPolicy.policyIMEINumber=$event.target.value"
                                    placeholder="Service Contract IMEI No." formControlName="policyIMEINumber" matInput
                                    type="text" maxlength="15" [errorStateMatcher]="matcher">
                                  <mat-error
                                    *ngIf="partnerOnboardingForm.get('deviceDetailPanel').get('policyIMEINumber').hasError('validationErrors')">
                                    {{partnerOnboardingService.controlErrorMsg}}
                                  </mat-error>
                                  <mat-error *ngIf="partnerOnboardingForm.get('deviceDetailPanel').get('policyIMEINumber').hasError('atLeastOneFailed')">
                                    Either IMEI or Serial Number is required.
                                  </mat-error>
                                </mat-form-field>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <mat-form-field>
                                  <mat-label class="label-policy">Serial Number</mat-label>
                                  <input (input)="partnerPolicy.deviceSerialNo=$event.target.value"
                                    placeholder="Serial Number" formControlName="deviceSerialNo" matInput type="text"
                                    [errorStateMatcher]="matcher">
                                    <mat-error *ngIf="partnerOnboardingForm.get('deviceDetailPanel').get('deviceSerialNo').hasError('atLeastOneFailed')">
                                      Either IMEI or Serial Number is required.
                                    </mat-error>
                                </mat-form-field>   
                              </td>
                            </tr>
                            <tr *ngIf="this.partnerId === PolicyEnum.PARTNER_AEON_RET_MALAYSIA">
                              <td>
                                <mat-form-field>
                                  <mat-label class="label-policy">Payment Method</mat-label>
                                  <mat-select required formControlName="paymentType" [(value)]="selected_paymentMethod"
                                    (selectionChange)="setPaymentType($event)" [errorStateMatcher]="matcher">
                                    <mat-option *ngFor="let paymentMethod of paymentMethodList" [value]="paymentMethod">
                                      {{paymentMethod}}
                                    </mat-option>
                                  </mat-select>
                                  <mat-error
                                    *ngIf="partnerOnboardingForm.get('deviceDetailPanel').get('paymentType').hasError('required')">
                                    Payment Method cannot be left blank
                                  </mat-error>
                                </mat-form-field>
                              </td>
                            </tr>

                            <tr *ngIf="this.partnerId === PolicyEnum.PARTNER_AEON_RET_MALAYSIA">
                              <td>
                                <div class="mv-2">
                                  <label class="file-policy">Any device purchase invoices here to upload? </label>
                                  <p class="fileInstr">only .jpg, .png, .pdf files at 10mb or less</p>
                                  <input hidden type="file" class="file-input"
                                  #fileInput required [value]="file"
                                  [accept]="requiredFileType"
                                  (change)="onFileSelected($event)">
                                  <div class="fileInput">
                                    <p>Device purchase invoice</p>
                                    <button type="button" (click)="fileInput.click()">{{addUploadBtnText}}</button>
                                  </div>
                                  
                                  <div [ngClass]="fileUploadError ? 'fileUploads error' : 'fileUploads'" *ngIf="fileName">
                                    <div class="fileName">
                                      <p class="">{{fileName}}</p>
                                      <button (click)="removeFile()">
                                        <mat-icon>close</mat-icon>
                                      </button>
                                    </div>
                                    <p *ngIf="fileUploadError" class="fileUploadError">{{fileUploadError}}</p>
                                  </div>
                                  <p *ngIf="!fileName" class="fileUploadError">Please upload device purchase invoice of following file type (*.jpeg, *.png, *pdf)</p>
                                </div>   
                              </td>
                            </tr>
                            
                          </table>
                        </div>
                      </mat-expansion-panel>
                    </div>
                    <mat-expansion-panel formGroupName="staffDetailPanel">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <b>Staff Details</b>
                          <mat-error *ngIf="staffInfoValidationFailed" class="mat-error-panel">
                            <b>{{panelErrorMessage}}</b>
                          </mat-error>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div style="overflow-y:auto !important;">
                        <table class="alignTable">
                          <tr>
                            <td>
                              <mat-form-field>
                                <mat-label class="label-policy">Staff Name</mat-label>
                                <input (input)="partnerPolicy.salesRepName=$event.target.value" placeholder="Staff Name"
                                  formControlName="staffName" matInput type="text" required maxlength="255" [errorStateMatcher]="matcher">
                                <mat-error
                                  *ngIf="partnerOnboardingForm.get('staffDetailPanel').get('staffName').hasError('required')">
                                  Staff Name cannot be left blank
                                </mat-error>
                              </mat-form-field>
                            </td>
                          </tr>
                          <tr *ngIf="this.partnerId === PolicyEnum.PARTNER_AEON_RET_MALAYSIA">
                            <td>
                              <mat-form-field>
                                <mat-label class="label-policy">Staff ID</mat-label>
                                <input (input)="partnerPolicy.salesRepId=$event.target.value" placeholder="Staff ID"
                                  formControlName="salesRepId" matInput type="text" required maxlength="200" [errorStateMatcher]="matcher">
                                <mat-error
                                  *ngIf="partnerOnboardingForm.get('staffDetailPanel').get('salesRepId').hasError('required')">
                                  Staff ID cannot be left blank
                                </mat-error>
                              </mat-form-field>
                            </td>
                          </tr>
                          <tr>
                            <td *ngIf="this.partnerId === PolicyEnum.PARTNER_AEON_RET_MALAYSIA; then isMYAeonPartnerStorName else notMYAeonPartnerStorName" ></td>
                            <ng-template #isMYAeonPartnerStorName>
                                <mat-form-field>
                                  <mat-label class="label-policy">Store Name</mat-label>
                                  <mat-select required formControlName="storeName" [(value)]="selected_storeName"
                                    (selectionChange)="setStoreName($event)" [errorStateMatcher]="matcher">
                                    <mat-option *ngFor="let storeName of storeNameList" [value]="storeName">
                                      {{storeName}}
                                    </mat-option>
                                  </mat-select>
                                  <mat-error
                                    *ngIf="partnerOnboardingForm.get('staffDetailPanel').get('storeName').hasError('required')">
                                    Store Name cannot be left blank
                                  </mat-error>
                              </mat-form-field>
                            </ng-template>
                            <ng-template #notMYAeonPartnerStorName>
                              <mat-form-field>
                                <mat-label class="label-policy">Store Name</mat-label>
                                <input (input)="partnerPolicy.storeName=$event.target.value" placeholder="Store Name"
                                  formControlName="storeName" matInput type="text" required maxlength="255" [errorStateMatcher]="matcher">
                                <mat-error
                                  *ngIf="partnerOnboardingForm.get('staffDetailPanel').get('storeName').hasError('required')">
                                  Store Name cannot be left blank
                                </mat-error>
                              </mat-form-field>
                            </ng-template>
                          </tr>
                        </table>
                      </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel formGroupName="declarationPanel">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <b>Declaration and Authorization</b>
                          <mat-error *ngIf="declarationValidationFailed" class="mat-error-panel">
                            <b>Please confirm</b>
                          </mat-error>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                        <span><b>In purchasing and registering myself for the insurance cover and/or 
                          Device Support Package, I hereby:
                        </b></span>
                        <table class="alignTable">
                          <tr>
                            <td>
                              <div style="margin-top:10px;margin-bottom:10px;">
                                <mat-checkbox color="primary" formControlName="declarationForm1"
                                  (click)="confirmAcknowledgement('DECLARATION_1')" required="true" [errorStateMatcher]="matcher">
                                  <div style="margin-left:10px">
                                    <span style="white-space:normal!important;">
                                    agree to provide all information and personal data to Bolttech Device Protection 
                                    Malaysia Sdn. Bhd. (“Company”) and/or Tune Insurance Malaysia Berhad (“Tune”) in 
                                    accordance with the Personal Data Protection Act 2010;</span>
                                  </div>
                                </mat-checkbox>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div style="margin-top:10px;margin-bottom:10px;">
                                <mat-checkbox color="primary" formControlName="declarationForm2"
                                (click)="confirmAcknowledgement('DECLARATION_2')" required="true" [errorStateMatcher]="matcher">
                                  <div style="margin-left:10px">
                                    <span style="white-space:normal!important;">
                                      declare and agree that all information provided by me, including all statements, 
                                      questionnaires and answers made in relation to the insurance cover and/or Device 
                                      Support Package are full, complete, true, accurate and correct, and I understand 
                                      and agree that the Company and/or Tune, believing them to be such, will rely and 
                                      act on them, otherwise the insurance cover and/or Device Support Package may be 
                                      void at the sole discretion of Tune and/or the Company; 
                                    </span>
                                  </div>
                                </mat-checkbox>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div style="margin-top:10px;margin-bottom:10px;">
                                <mat-checkbox color="primary" formControlName="declarationForm3"
                                (click)="confirmAcknowledgement('DECLARATION_3')" required="true" [errorStateMatcher]="matcher">
                                  <div style="margin-left:10px;">
                                    <span style="white-space:normal!important; margin-top:10px;margin-bottom:10px">
                                      consent and authorize the Company, Tune and/or their affiliates to process my personal 
                                      data for any direct or indirect marketing purposes.
                                    </span>
                                  </div>
                                </mat-checkbox>
                              </div>
                            </td>
                          </tr>
                        </table>
                    </mat-expansion-panel>
                    <mat-expansion-panel formGroupName="consentPanel">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <b>Consent Statement</b>
                          <mat-error *ngIf="consentValidationFailed" class="mat-error-panel">
                            <b>Please confirm</b>
                          </mat-error>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                        <span><b>By this registration, I hereby certify that I have read, agree and accepted: 
                        </b></span>
                        <table class="alignTable">
                          <tr>
                            <td>
                              <div style="margin-top:10px;margin-bottom:10px;">
                                <mat-checkbox color="primary" formControlName="consentForm1"
                                  (click)="confirmAcknowledgement('CONSENT_1')" required="true" [errorStateMatcher]="matcher">
                                  <div style="margin-left:10px">
                                    <span style="white-space:normal!important;">
                                      the Product Disclosure Sheet of the insurance cover underwritten by Tune;</span>
                                  </div>
                                </mat-checkbox>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div style="margin-top:10px;margin-bottom:10px;">
                                <mat-checkbox color="primary" formControlName="consentForm2"
                                (click)="confirmAcknowledgement('CONSENT_2')" required="true" [errorStateMatcher]="matcher">
                                  <div style="margin-left:10px">
                                    <span style="white-space:normal!important;">
                                      the Terms of Service of Device Support Package provided by the Company; and
                                    </span>
                                  </div>
                                </mat-checkbox>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div style="margin-top:10px;margin-bottom:10px;">
                                <mat-checkbox color="primary" formControlName="consentForm3"
                                (click)="confirmAcknowledgement('CONSENT_3')" required="true" [errorStateMatcher]="matcher">
                                  <div style="margin-left:10px;">
                                    <span style="white-space:normal!important; margin-top:10px;margin-bottom:10px">
                                      the Company's Privacy Notice.
                                    </span>
                                  </div>
                                </mat-checkbox>
                              </div>
                            </td>
                          </tr>
                        </table>
                    </mat-expansion-panel>
                  </mat-accordion>
                </form>
              </div>
            </ng-container>
            <ng-container *ngIf="partnerOnboardingService.isPanelToBeShown('PARTNER_SALES_REVIEW_PANEL', currentActiveTab)">
              <div class="block">
                <div class="m-20">
                  <span><b>Please check these details are correct before submitting your order.</b></span>
                </div>
                <div class="m-20">
                  <table cols="2" style="width: 100%">
                    <tr>
                      <td class="c1-review">
                        <table cols="2" style="width:100%">
                          <tr>
                            <td style="width:10%"><img src="../../../assets/images/deviceProtection_icon.png"></td>
                            <td style="width:90%"><span class="fontHeading"><b>Device Protection Package</b></span></td>
                          </tr>
                        </table>
                      </td>
                      <td class="c2-review">
                        <span style="font-size:24px;color:cyan"><b>{{parseDescriptionsValue('ANNUAL_FINAL_RRP')}}</b></span>
                      </td>
                    </tr>
                  </table>
                  <ng-container *ngFor="let plan of onboardPackage.reviewPlanDetails">
                    <mat-divider style="color: #E8E7EE;"></mat-divider>
                    <table cols="2" style="width: 100%">
                      <tr>
                        <td class="c1-review">
                          <span><b>{{plan.reviewPlanName}}</b></span>
                        </td>
                      </tr>
                      <tr *ngFor="let description of plan.reviewPlanDescriptions">
                        <td class="c1-review"><span>{{description.description}}</span></td>
                        <td class="c2-review"><span><b>{{parseDescriptionsValue(description.value)}}</b></span></td>
                      </tr>
                    </table>
                  </ng-container>
                  <mat-divider style="color: #E8E7EE;"></mat-divider>
                  <table cols="2" style="width: 100%;">
                    <tr>
                      <td style="width:50%" class="c1-review"><span style="font-size:18px;text-align:left"><b>Final
                            Price</b></span></td>
                      <td style="width:50%" class="c2-review"><span
                          style="font-size:24px;text-align:right;color:cyan"><b>{{parseDescriptionsValue('FINAL_RRP')}}</b></span></td>
                    </tr>
                    <ng-container *ngIf="checkNotEmpty(onboardPackage.packagePrivacyUrl) ||
                      checkNotEmpty(onboardPackage.TnCUrl)">
                      <tr>
                        <td style="width:50%"><span style="font-size:16px;text-align:left">
                            By continuing, you have agreed to our
                            <span style="color:cyan" *ngIf="checkNotEmpty(onboardPackage.packagePrivacyUrl)"><a
                                href="{{onboardPackage.packagePrivacyUrl}}" target=”_blank”>Privacy Policy</a></span>
                            <span
                              *ngIf="checkNotEmpty(onboardPackage.packagePrivacyUrl) && checkNotEmpty(onboardPackage.packageTnCUrl)">and</span>
                            <span style="color:cyan" *ngIf="checkNotEmpty(onboardPackage.packageTnCUrl)"><a
                                href="{{onboardPackage.packageTnCUrl}}" target=”_blank”>T&C</a></span>
                          </span></td>
                      </tr>
                    </ng-container>
                    <tr *ngIf="fileUploadError || !fileName" class="fileUploadError">
                      <td>{{fileUploadError}}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="partnerOnboardingService.isPanelToBeShown('PARTNER_SUCCESS_PANEL' , currentActiveTab)">
              <div class="block">
                <mat-card>
                  <mat-card-content>
                    <img src="../../../assets/images/correct.png" class="check-img" />
                  </mat-card-content>
                  <mat-card-content>
                    <b class="success-text">
                      <p>Your device protection plan has been successfully submitted.</p>
                      <p>Customer will be receiving notification once the registration and the 1st charging is successful.</p>
                    </b>
                  </mat-card-content>
                </mat-card>
              </div>  
            </ng-container>
            <ng-container *ngIf="partnerOnboardingService.isPanelToBeShown('PARTNER_FAILED_PANEL', currentActiveTab)">
              <div class="block">
                <mat-card>
                  <mat-card-content>
                    <img src="../../../assets/images/failed.png" class="check-img"/>
                  </mat-card-content>
                  <mat-card-content>
                    <b class="success-text">
                      <p>The request for device protection has been failed.</p>
                      <p>&nbsp;</p>
                      <div class="text-red">
                        <p>Please check the registration details and retry again.</p>
                      </div>
                    </b>
                  </mat-card-content>
                </mat-card>
              </div>
            </ng-container>
            <div fxLayout="row" fxLayoutAlign="space-evenly center">
              <ng-container *ngIf="this.partnerOnboardingService.fetchActionLabel('NEXT' , currentActiveTab)">
                <button mat-raised-button style="width: 35%;text-align:center" [ngStyle]="{'color': this.partnerOnboardingService.partnerOnboardingConfigModel.fontColor ,
                            'background': this.partnerOnboardingService.partnerOnboardingConfigModel.themeColor}"
                  (click)="changeTab(this.partnerOnboardingService.fetchActionCommand('NEXT' , currentActiveTab));">
                  {{this.partnerOnboardingService.fetchActionLabel('NEXT' , currentActiveTab)}}
                </button>
              </ng-container>
              <ng-container *ngIf="this.partnerOnboardingService.fetchActionLabel('BACK' , currentActiveTab)">
                <button mat-raised-button style="width: 35%;text-align:center" [ngStyle]="{'color': this.partnerOnboardingService.partnerOnboardingConfigModel.fontColor ,
                  'background': this.partnerOnboardingService.partnerOnboardingConfigModel.themeColor}"
                  (click)="changeTab(this.partnerOnboardingService.fetchActionCommand('BACK' , currentActiveTab));">
                  {{partnerOnboardingService.fetchActionLabel('BACK' , currentActiveTab)}}
                </button>
              </ng-container>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </ng-container>
  </div>
  <!--row closed-->
</div>
<!--container closed-->
<div class="div-box text-left m-20">
  <img src="../../../assets/images/bolttech_logo.png" style="margin-top:15px; height: 50px;">
  <p class="footer"><span>Bolttech Device Protection Malaysia Sdn. Bhd.</span></p>
</div>
<mat-divider style="color: #E8E7EE;"></mat-divider>
<div class="div-box text-left footer">
  <p><span><b>Insurance Partner</b></span></p>
  <p><span>Tune Insurance Malaysia Berhad</span></p>
</div>
<mat-divider style="color: #E8E7EE;"></mat-divider>
<div class="div-box text-left footer">
  <table col="2" style="width:100%">
    <tr style="margin-top:0px;">
      <td style="width:50%">
        <p><span><b>Customer support:</b></span></p>
      </td>
      <td style="width:50%">
        <p><span><b>Security & Compliance</b></span></p>
      </td>
    </tr>
    <tr>
      <td style="width:50%">
        <p><span>+603 - 9212 4314</span></p>
      </td>
      <td style="width:50%">
        <p><span><img src="../../../assets/images/geotrust_logo.png" class="logo"></span></p>
      </td>
    </tr>
  </table>
</div>
<mat-divider style="color: #E8E7EE;"></mat-divider>
<div class="div-box text-left footer">
  <p><span>bolttech © 2023 All Rights Reserved.</span></p>
</div>
</div>