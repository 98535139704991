import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { PolicyEnum } from './model/policy.enum';

@Injectable({
  providedIn: 'root'
})
export class FlowableConstants {

  // Endpoint configurations
  public static FLOWABLE_PROCESS_API_RUNTIME_ENDPOINT = environment.flowableTaskUrl + 'process-api/runtime/';
  public static FLOWABLE_PROCESS_API_REPOSITORY_ENDPOINT = environment.flowableTaskUrl + 'process-api/repository/';
  public static FLOWABLE_TASK_ENDPOINT = FlowableConstants.FLOWABLE_PROCESS_API_RUNTIME_ENDPOINT + 'task';
  public static FLOWABLE_TASKS_ENDPOINT = FlowableConstants.FLOWABLE_PROCESS_API_RUNTIME_ENDPOINT + 'tasks';
  public static FLOWABLE_PROCESS_INSTANCE_ENDPOINT = FlowableConstants.FLOWABLE_PROCESS_API_RUNTIME_ENDPOINT + 'process-instances';
  public static FLOWABLE_PROCESS_DEFINITION_ENDPOINT = FlowableConstants.FLOWABLE_PROCESS_API_REPOSITORY_ENDPOINT + 'process-definitions';


  // Actions
  public static FLOWABLE_ACTION_CLAIM_TASK = 'claim';
  public static FLOWABLE_ACTION_COMPLETE_TASK = 'complete';
  public static FLOWABLE_BASIC_AUTH_KEY: string = 'flowableBasicAuth';

  public static NON_FLOWABLE_PARTNERS = [
    PolicyEnum.PARTNER_SAMSUNG_PHILLIPINES_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_PHILLIPINES_D2C,
    PolicyEnum.PARTNER_SAMSUNG_INDONESIA_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_INDONESIA_CF,
    PolicyEnum.PARTNER_SAMSUNG_PHILLIPINES_CF,
    PolicyEnum.PARTNER_SAMSUNG_VIETNAM_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_TSE,
    PolicyEnum.PARTNER_SAMSUNG_TAIWAN_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_MALAYSIA_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_HONGKONG_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_BELGIUM,
    PolicyEnum.PARTNER_SAMSUNG_NETHERLANDS,
    PolicyEnum.PARTNER_SAMSUNG_SWEDEN,
    PolicyEnum.PARTNER_SAMSUNG_NORWAY,
    PolicyEnum.PARTNER_SAMSUNG_DENMARK,
    PolicyEnum.PARTNER_SAMSUNG_FINLAND,
    PolicyEnum.PARTNER_SAMSUNG_MACAU,
    PolicyEnum.PARTNER_SAMSUNG_B2B_BELGIUM,
    PolicyEnum.PARTNER_SAMSUNG_B2B_NETHERLANDS,
    PolicyEnum.PARTNER_SAMSUNG_B2B_SWEDEN,
    PolicyEnum.PARTNER_SAMSUNG_B2B_NORWAY,
    PolicyEnum.PARTNER_SAMSUNG_B2B_DENMARK,
    PolicyEnum.PARTNER_SAMSUNG_B2B_FINLAND,
    PolicyEnum.PARTNER_SAMSUNG_TUNE_MALAYSIA,
    PolicyEnum.PARTNER_THREE_SWEDEN,
    PolicyEnum.PARTNER_SAMSUNG_INDONESIA_CE
  ];

  public static flowableTaskEndpoint(taskID: string) {
    // e.g. <flowable-task-endpoint>/<taskid>/form
    return [this.FLOWABLE_TASKS_ENDPOINT, taskID].join('/');
  }

  public static flowableTaskFormEndpoint(taskID: string) {
    // e.g. <flowable-task-endpoint>/<taskid>/form
    return [this.FLOWABLE_TASKS_ENDPOINT, taskID, 'form'].join('/');
  }

  /**
   * This function returns whether the partnerId provided is enabled for
   * flowable automation. If enabled then create / update requests are
   * made through the flowable rest api.
   *
   * @param partnerId of the claim / policy
   * @returns boolean whether the partnerId is enabled for flowable autmation
   */
  public static shouldUseFlowable(partnerId: string): boolean {
    return (FlowableConstants.NON_FLOWABLE_PARTNERS.findIndex(value => value === partnerId) < 0);
  }
}
