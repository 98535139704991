<div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="10px" fxLayoutGap.xs="0">
  <div class="rightHalf" fxFlex="70%">
    <div class="row logo-container">
      <span>
        <div class="logo-container">
          <ng-container [ngSwitch]="service.getCurrentLoggedInPartner()">
            <ng-container *ngSwitchCase="'MYDIGIMTELNA01'">
              <img src="../../../assets/images/digiLogo.png" class="img-container" />
            </ng-container>
            <ng-container *ngSwitchCase="'MYAEONCRETCF01'">
              <img class="center" src="../../../assets/images/aeonLogo.png" style="margin-top:20px;height: 100px;">
            </ng-container>
            <ng-container *ngSwitchCase="'MYDIGIMTELNA02'">
              <img src="../../../assets/images/digiLogo.png" class="img-container"/>
            </ng-container>
          </ng-container>
        </div>
        <h1 class="heading-container">
          Failed
        </h1>
      </span>
    </div>
    <div class="row">
      <mat-card>
        <mat-card-content>
          <img src="../../../assets/images/failed.png" class="check-img" />
        </mat-card-content>
        <mat-card-content>
          <b class="failed-text">
            <ng-container [ngSwitch]="service.getCurrentLoggedInPartner()">
              <ng-container *ngSwitchCase="'MYDIGIMTELNA02'">
                <p>The request for device protection has been failed. Duplication of IMEI detected.</p>
                <p>Please check the registration details. Customer will not be charged.</p>
                <p>&nbsp;</p>
                <div class="text-red">
                  <p>We are unable to process your request due to duplication of IMEI. </p>
                  <p>Please check the registration details and retry again.</p>
                  <p>Note: Customer will not be charged for this request.</p>
                </div>
              </ng-container>
            </ng-container>
          </b>
        </mat-card-content>
          <button mat-raised-button class="back-btn" [ngStyle]="{'color': 'yellow', 'background': 'black'}"
            (click)="changeTabLanding();">
            Back to home
          </button>
      </mat-card>
    </div>
    
  </div>
</div>
<mat-card>
  <mat-card-content>
    <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="center">
      <img class="center" src="../../../assets/images/bolttech_logo.png" style="margin-top:80px;height: 50px;">
    </div>
</mat-card-content>