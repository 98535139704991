import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { PolicyService } from '../../common-services/policy.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductService } from 'src/app/common-services/product.service';
import { MatDialog } from '@angular/material/dialog';
import { PolicyDocument } from 'src/app/model/policy.document';
import { Subject, Observable } from 'rxjs';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { ClaimConstants } from 'src/app/claim/claim.constants';
import { ProductDetail } from 'src/app/model/product.detail';
import { LoginService } from 'src/app/components/user/login/login.service';
import { AppConstants } from 'src/app/app.constants';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MdmService } from 'src/app/mdm.service';
import { PartnerOnboardingService } from 'src/app/partner/partner-onboarding.service';
import { PartnerOnBoardingConstants } from 'src/app/partner/partner-onboarding.constants';
import { ResponseClientDto } from 'src/app/model/response.client';
import { TypeTable } from 'src/app/model/typeTable';
import Utils from 'src/app/utility/utils';
import { DatePipe } from '@angular/common';
import { Product } from 'src/app/product/product';
import { ProductConstants } from 'src/app/product/product.constants';
import { MyErrorStateMatcher } from 'src/app/utility/errorStateMatcher';
import { PolicyEnum } from 'src/app/model/policy.enum';
import { PolicyConstants } from 'src/app/policy/policy.constants';


@Component({
  selector: 'app-partner-my-onboarding',
  templateUrl: './partner-my-onboarding.component.html',
  styleUrls: ['./partner-my-onboarding.component.css']
})
export class PartnerMYOnboardingComponent implements OnInit {
  constructor(public service: AppService,
    private spinner: NgxSpinnerService,
    private productService: ProductService,
    public dialog: MatDialog,
    private loginService: LoginService,
    public partnerOnboardingService: PartnerOnboardingService, 
    private policyService: PolicyService,
    public datePipe: DatePipe) { 
      setTimeout(() => {
        this.loginService.showAppLandingPage.next(AppConstants.FALSE);
        this.loginService.showDigiFailedOnboarding.next(AppConstants.FALSE);
        this.loginService.showDigiOnboardingApp.next(AppConstants.TRUE);
      })
    }
  readonly PolicyEnum = PolicyEnum;
  partnerOnboardingForm: FormGroup;
  deviceRRPForm: FormGroup;
  skuCodeForm: FormGroup;
  partnerPolicy: PolicyDocument = PolicyDocument.newPolicyDocument();
  searchTextChanged = new Subject<any>();
  searchList: Array<any>;
  subscription: any;
  currentActiveTab: number = 0;
  // latest snapshot
  countries: any[];
  panelErrorMessage: string;
  customerInfoValidationFailed: boolean = false;
  deviceRRPValidationFailed: boolean = false;
  deviceInfoValidationFailed: boolean= false;
  staffInfoValidationFailed: boolean= false;
  productDetailList: Array<ProductDetail>;
  pageHeadingArray: Array<string>;
  partnerStoresName: any[];
  partnerStores : any [];
  imageCapturePanel: boolean = false;
  productDescriptionBullets: string[];
  partnerColorCode: any;
  showOnboardingFailurePage: boolean = false;
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  defaultCountry: string;
  partnerId: string;
  hasADLDProduct: boolean = false;
  hasTHEFTProduct: boolean = false;
  hasCSProduct: boolean = false;
  hasEWProduct: boolean = false;
  hasAdldEwProduct: boolean = false;
  hasCsEwProduct: boolean = false;
  deviceRrpErrStatus : boolean = true;
  deviceRrpIdentifier : string;
  policyProductDeviceRRP : Map<String , String> = new Map();
  policyCreationStatus : string;
  storeNameList: any[];
  deviceTypeList: any[];
  allDeviceTypeList: any[];
  smallAppDeviceTypeList: any[];
  largeAppDeviceTypeList: any[];
  paymentMethodList: any[];
  onboardPackage: any;
  vasProduct: any;
  bundledProduct: any;
  insuranceProduct: any;
  insuranceProducts:Product [] = [];
  adldProduct: Product [] = [];
  theftProduct: Product [] = [];
  csProduct: Product [] = [];
  ewProduct: Product [] = [];
  adldEwProduct: Product [] = [];
  csEwProduct: Product [] = [];

  requiredFileType = "image/png, image/jpg, image/jpeg, application/pdf";
  acceptedFileFormat = ["jpg", "jpeg", "png", "pdf", "PNG", "PDF", "JPG", "JPEG"];
  file:File = null;
  fileName: string;
  licenseFileUpload: any;
  fileUploadError: string;
  addUploadBtnText: string = 'Add';
  isFileAdded: boolean=false;

  showProductOptions: boolean=false;
  hasOnboardingConfig: boolean=false;
  onboardBundled: boolean=false;
  onboardInsurance: boolean=false;
  onboardVAS: boolean=false;
  displayOtherProducts: boolean=false;

  totalOnboardingSteps: number;

  bundledPlanRRP: number;
  insurancePlanRRP: number;
  vasStandalonePlanRRP: number;
  vasBundledPlanRRP: number;

  insuranceAnnualRRP: number;
  vasStandaloneAnnualRRP: number;
  vasBundledAnnualRRP : number;
  
  finalInsuranceAnnualRRP: number;
  finalVasAnnualRRP: number;
  finalTotalAnnualRRP: number;

  minDeviceRRP: number;
  maxDeviceRRP: number;

  reviewTotalRRP: number;
  reviewInsuranceRRP: number;
  reviewVasRRP: number;
  sstValue:number;

  rrpOverrange:boolean=false;
  deviceRRPErrorMessage: string;
  skuCodeErrorMessage: string;
  
  imeiOrSerialRequiredFailed:boolean=false;

  matcher = new MyErrorStateMatcher();
  applyButtonPressed: boolean=false;

  declaration1Acknowledgement:boolean=false;
  declaration2Acknowledgement:boolean=false;
  declaration3Acknowledgement:boolean=false;
  declarationValidationFailed:boolean=false;

  consent1Acknowledgement:boolean=false;
  consent2Acknowledgement:boolean=false;
  consent3Acknowledgement:boolean=false;
  consentValidationFailed:boolean=false;
  maxInvoiceCharLen:number;

  

  ngOnInit(): void {
    this.spinner.show();
    this.partnerId = localStorage.getItem(AppConstants.PARTNER_ID);
    this.getOnboardingConfigModel();
    this.getAllInsuranceProducts(this.partnerId);
    this.getStorNameList();
    this.getListFromTypeTable();
    this.initFirstForm(this.partnerId);
    this.setAddressHandlers();
    this.getListOfCoutries();
    this.maxInvoiceCharLen = PolicyConstants.MAX_INVOICE_CHAR_LEN;
    this.partnerOnboardingService.setSharedSpace(this.partnerOnboardingForm , this.partnerPolicy);
    this.spinner.hide();
  }

  setAddressHandlers() {
    this.subscription = this.searchTextChanged.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      mergeMap(search => this.productService.getAutcompleteAdress(search.searchKey, search.country)
      )
    ).subscribe((res: any) => {
      this.searchList = res
    });
    if (this.partnerPolicy.custAddress == null || this.partnerPolicy.custAddress == undefined) {
      this.partnerPolicy.custAddress = {}
    }
    this.partnerPolicy.custAddress.customerCountry = "Malaysia";
    this.defaultCountry = this.partnerPolicy.custAddress.customerCountry;
  }

  getListOfCoutries() {
    this.productService.getCountryList().subscribe(res => {
      this.countries = res;
    });
  }

  initFirstForm(partnerId){
    switch(partnerId) {
    case PolicyEnum.PARTNER_AEON_RET_MALAYSIA:
      this.skuCodeForm = new FormGroup({
        skuCodePanel: new FormGroup({
          partnerProductId: new FormControl('',{
            validators:this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.SKU_CODE),
            updateOn: "change"
          })
        })
      })
    default:
      this.deviceRRPForm = new FormGroup({
        pricePanel: new FormGroup({
          deviceRRP: new FormControl('',{
            validators:this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.DEVICE_RRP),
            updateOn: "change"
          })
        })
      });
      break;
    }
  }

  submitEnter(input:any){
    switch(input){
      case 'PARTNER_DEVICE_PRICE_PANEL':
        this.changeTab(this.partnerOnboardingService.fetchActionCommand('NEXT' , this.currentActiveTab));
        break;
      case 'PARTNER_SKU_CODE_PANEL':
        this.changeTab(this.partnerOnboardingService.fetchActionCommand('NEXT' , this.currentActiveTab));
        break;
    }
  }

  async validateSkuCode() {
    let skuCode = this.skuCodeForm['controls'].skuCodePanel['controls'].partnerProductId.value;
    if(skuCode && skuCode.length > 0) {
      this.spinner.show();
      this.productService.getAeonProductsBySkuCode(skuCode).subscribe((res) => {
        if (res && res.obj != null) {
          let products = res.obj;
          let hasBundled = false;
          let hasInsurance = false;
          let hasVas = false;
          for(let prod of products) {
            if(prod.packageType == ProductConstants.PACKAGE_TYPE_BUNDLED){
              this.bundledProduct = prod;
              hasBundled = true;
            }else if (prod.packageType == ProductConstants.PACKAGE_TYPE_INSURANCE){
              this.insuranceProduct = prod;
              hasInsurance = true;
            }else if (prod.packageType == ProductConstants.PACKAGE_TYPE_VAS){
              this.insuranceProduct = prod;
              hasVas = true;
            }
          }
          this.calculateOnboardRRP();
          if(hasBundled) {
            this.setOnboardInfo(ProductConstants.PACKAGE_TYPE_BUNDLED);
          } else if (hasInsurance) {
            this.setOnboardInfo(ProductConstants.PACKAGE_TYPE_INSURANCE);
          } else if(hasVas) {
            this.setOnboardInfo(ProductConstants.PACKAGE_TYPE_VAS);
          }
          if(this.onboardPackage) {
            this.setMinMaxDeviceRRPforOnboardProduct();
            this.setDeviceTypeByOnboardProduct();
          }
          this.currentActiveTab++;
        } else {
          let respErrorMsg = "Failed to retrieve for SKU Code";
          if(res && res.error) {
            respErrorMsg = res.error;
          }
          let skuCodeField = this.skuCodeForm['controls'].skuCodePanel['controls'].partnerProductId;
          skuCodeField.setErrors({'validationFailed':true});
          this.skuCodeErrorMessage = respErrorMsg;
          //this.service.showInfo(respErrorMsg, "Validation Error");
          this.panelErrorMessage = ClaimConstants.ERROR_MSG;
        }
        this.spinner.hide();
      });
    }
  }

  setOnboardInfo(packageType) {
    this.onboardInsurance = false;
    this.onboardVAS = false;
    this.onboardBundled = false;
    if(packageType == ProductConstants.PACKAGE_TYPE_BUNDLED){
      this.onboardBundled = true;
      this.onboardPackage = this.bundledProduct;

      this.reviewTotalRRP = this.bundledPlanRRP;
      this.reviewInsuranceRRP = this.insurancePlanRRP;
      this.reviewVasRRP = this.vasBundledPlanRRP;

      this.finalVasAnnualRRP = this.vasBundledPlanRRP;
      this.finalInsuranceAnnualRRP = this.insurancePlanRRP;
      this.finalTotalAnnualRRP = this.vasBundledPlanRRP + this.insurancePlanRRP;
    } else if (packageType == ProductConstants.PACKAGE_TYPE_INSURANCE){
      this.onboardInsurance = true;
      this.onboardPackage = this.insuranceProduct;
      
      this.reviewTotalRRP = this.insurancePlanRRP;
      this.reviewInsuranceRRP = this.insurancePlanRRP;

      this.finalInsuranceAnnualRRP = this.insurancePlanRRP;
      this.finalTotalAnnualRRP = this.insurancePlanRRP;
    } else if (packageType == ProductConstants.PACKAGE_TYPE_VAS) {
      this.onboardVAS = true;
      this.onboardPackage = this.vasProduct;

      this.reviewTotalRRP = this.vasStandalonePlanRRP;
      this.reviewVasRRP = this.vasStandalonePlanRRP;

      this.finalVasAnnualRRP = this.vasStandalonePlanRRP;
      this.finalTotalAnnualRRP = this.vasStandalonePlanRRP;
    }
  }

  setMinMaxDeviceRRPforOnboardProduct() {
    let finalMinPrice = 0;
    let finalMaxPrice = 0;
    for(let plan of this.onboardPackage.plans) {
      let minPrice = parseFloat(plan.devicePriceStartingRange);
      let maxPrice = parseFloat(plan.devicePriceEndingRange);
      if(finalMinPrice == 0 || finalMinPrice > minPrice) {
        finalMinPrice = minPrice;
      }
      if(finalMaxPrice == 0 || finalMaxPrice < maxPrice) {
        finalMaxPrice = maxPrice;
      }
    }
    this.minDeviceRRP = finalMinPrice;
    this.maxDeviceRRP = finalMaxPrice;
  }

  setDeviceTypeByOnboardProduct() {
    let arrDeviceTypes = [];
    if(this.onboardPackage.deviceType) {
      for(let deviceType of this.onboardPackage.deviceType) {
        switch(deviceType.deviceCode) {
          case PartnerOnBoardingConstants.AEON_SMALL_APPLIANCE_CODE:
            if (this.smallAppDeviceTypeList) {
              arrDeviceTypes.push(...this.smallAppDeviceTypeList);
            }
            break;
          case PartnerOnBoardingConstants.AEON_LARGE_APPLIANCE_CODE:
            if (this.largeAppDeviceTypeList) {
              arrDeviceTypes.push(...this.largeAppDeviceTypeList);
            }
            break;
          default:
            let result = this.allDeviceTypeList.find(item => item.deviceCode === deviceType.deviceCode);
            if(result) {
              arrDeviceTypes.push(result);
            }
            break;
        }
      }
    }
    this.deviceTypeList = arrDeviceTypes;
  }

  initOnboardingForm(){
    this.partnerOnboardingForm = new FormGroup({
      customerDetailPanel: new FormGroup({
        customerName: new FormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.CUSTOMER_NAME),
          updateOn: "blur"
        }),
        customerLastName: new FormControl('',{
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.CUSTOMER_NAME),
          updateOn: "blur"
        }),
        custMobileNumber: new FormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.CUSTOMER_MOBILE_NUMBER),
          updateOn: "blur"
        }),
        customerEmail: new FormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.CUSTOMER_EMAIL),
          updateOn: "blur"
        }),
        idType: new FormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.ID_TYPE),
          updateOn: "blur"
        }),
        customerId: new FormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.CUSTOMER_ID),
          updateOn: "blur"
        }),
        custAddress: new FormControl(''),
        postalCode: new FormControl(''),
        customerCity: new FormControl(''),
        customerState: new FormControl(''),
        customerCountryCode: new FormControl('')
      }),
      deviceDetailPanel: new FormGroup({
        deviceType: new FormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.DEVICE_TYPE),
          updateOn: "blur"
        }),
        deviceMake: new FormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.DEVICE_MAKE),
          updateOn: "blur"
        }),
        deviceModel: new FormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.DEVICE_MODEL),
          updateOn: "blur"
        }),
        deviceCapacity: new FormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.DEVICE_CAPACITY),
          updateOn: "blur"
        }),
        deviceColor: new FormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.DEVICE_COLOR),
          updateOn: "blur"
        }),
        policyIMEINumber: new FormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.POLICY_IMEI_NUMBER),
          updateOn: "blur"
        }),
        deviceSerialNo: new FormControl('')
      }, {
        validators: this.atLeastOneValidator(['policyIMEINumber', 'deviceSerialNo']).bind(this),
        updateOn: "blur"
      }),
      staffDetailPanel: new FormGroup({
        staffName: new FormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.STAFF_NAME),
          updateOn: "blur"
        }),
        storeName: new FormControl('', {
          validators:
            this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.STORE_NAME),
          updateOn: "blur"
        }),
      }),
      declarationPanel: new FormGroup({
        declarationForm1: new FormControl('', Validators.required),
        declarationForm2: new FormControl('', Validators.required),
        declarationForm3: new FormControl('', Validators.required)
      }),
      consentPanel: new FormGroup({
        consentForm1: new FormControl('', Validators.required),
        consentForm2: new FormControl('', Validators.required),
        consentForm3: new FormControl('', Validators.required)
      })
    });

    if (this.partnerId === PolicyEnum.PARTNER_AEON_RET_MALAYSIA) {
      (this.partnerOnboardingForm.get('deviceDetailPanel') as FormGroup).addControl('deviceRRP', new FormControl('', {
        validators: [this.validateDeviceRRPRange(),...this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.DEVICE_RRP)],
        updateOn: "blur",
      }));
      (this.partnerOnboardingForm.get('deviceDetailPanel') as FormGroup).addControl('invoiceNo', new FormControl('', {
        validators: this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.INVOICE_NUMBER),
        updateOn: "blur"
      }));
      (this.partnerOnboardingForm.get('deviceDetailPanel') as FormGroup).addControl('paymentType', new FormControl('', {
        validators: this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.PAYMENT_METHOD),
        updateOn: "blur"
      }));
      (this.partnerOnboardingForm.get('staffDetailPanel') as FormGroup).addControl('salesRepId', new FormControl('', {
        validators: this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.STAFF_ID),
        updateOn: "blur"
      }));
      (this.partnerOnboardingForm.get('staffDetailPanel') as FormGroup).addControl('storeName', new FormControl('', {
        validators: this.partnerOnboardingService.setFieldValidatorsForPartner(PartnerOnBoardingConstants.STORE_NAME),
        updateOn: "blur"
      }));
    }
    
    this.partnerOnboardingForm.get('customerDetailPanel').get('customerCountryCode').setValue("MY");
    
    //Reset if customer use Back function
    this.resetValidationErrors();
    this.resetAcknowledgement();
  }

  atLeastOneValidator = (keys: string[]) => {
    return (group: FormGroup) => {
      let { controls } = group;
      let checkFields = keys.map(key => { return controls[key] });

      let errors = checkFields.some(field => field && !!field.value)
        ? null
        : { 'atLeastOneFailed': true };

      checkFields.forEach(field => {
        field.setErrors(errors);
      });
    };
  };

  getStorNameList() {
    if(this.partnerId !== PolicyEnum.PARTNER_AEON_RET_MALAYSIA) {
      return;
    }
    this.productService.getStoreLocations(this.partnerId).subscribe(res =>{
      if (res && res.obj != null) {
        this.storeNameList = res.obj;
      }
    });
  }

  onKeydown(event) {
    const charLen = event.target.value?.length;
    const acceptedKeys = [8, 9, 37, 38, 39, 40, 46]
    if (charLen < this.maxInvoiceCharLen && event.keyCode >= 48 && event.keyCode <= 57) {
      return true
    } else if(acceptedKeys.indexOf(event.keyCode) >= 0) {
      return true;
    }
    return false;
  }

  getListFromTypeTable(){
    if(this.partnerId === PolicyEnum.PARTNER_AEON_RET_MALAYSIA) {
      let typeCodes = [TypeTable.deviceType, TypeTable.aeonSmallAppliance, TypeTable.aeonLargeAppliance, TypeTable.aeonPaymentMethod];
      this.productService.getTypeValuesByCodes(typeCodes).subscribe(res =>{
        if (res && res.obj != null) {
          let arrDeviceTypes = [];
          for(let typeObj of res.obj) {
            switch (typeObj.typeCode) {
              case TypeTable.deviceType:
                this.allDeviceTypeList = typeObj.typeValues;
                break;
              case TypeTable.aeonSmallAppliance:
                this.smallAppDeviceTypeList = typeObj.typeValues;
                break;
              case TypeTable.aeonLargeAppliance:
                this.largeAppDeviceTypeList = typeObj.typeValues;
                break;
              case TypeTable.aeonPaymentMethod:
                this.paymentMethodList = typeObj.typeValues;
                break;
              default:
                break;
            }
          }
        }
      });
    } else {
      this.productService.getTypeValues(TypeTable.deviceType).subscribe(res =>{
        if (res && res.obj != null){
          this.allDeviceTypeList = res.obj.typeValues;
        }
      });
    }
  }

  autoCompleteSearch(event) {
    let countryCode = this.partnerOnboardingForm.get('customerDetailPanel').get('customerCountryCode').value;
    if (countryCode == '') {
      this.service.showInfo("Please select the country before search", "");
      return;
    }
    let searchCriteria = {
      searchKey: event.target.value,
      country: countryCode
    }
    this.searchTextChanged.next(searchCriteria);
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public handleInitError(error: WebcamInitError): void {
    // this.errors.push(error);
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  setAddress(placeId: string, country: string) {
    this.partnerOnboardingForm.get('customerDetailPanel').get('custAddress').value;

    this.productService.getAddressDetails(placeId, country).subscribe((res) => {

      if (res.addressLine1 != null) {
        this.partnerOnboardingForm.get('customerDetailPanel').get('custAddress').setValue(res.name + " , " + res.addressLine1);
      } else {
        this.partnerOnboardingForm.get('customerDetailPanel').get('custAddress').setValue(res.name);
      }
      this.partnerOnboardingForm.get('customerDetailPanel').get('postalCode').setValue(res.postalCode);
      this.partnerOnboardingForm.get('customerDetailPanel').get('customerState').setValue(res.state);
      this.partnerOnboardingForm.get('customerDetailPanel').get('customerCity').setValue(res.city);
      this.partnerPolicy.custAddress.address1 = res.name;
      this.partnerPolicy.custAddress.postalCode = res.postalCode;
      this.partnerPolicy.custAddress.state = res.state;
      this.partnerPolicy.custAddress.postalCode = res.postalCode;
      this.partnerPolicy.custAddress.city = res.city;
      this.partnerPolicy.custAddress.countryCode = "MY";
    });
  }

  validateRules(): boolean {
    let resultFromRuleEngine: boolean = true;
    this.customerInfoValidationFailed = false;
    this.deviceInfoValidationFailed = false;
    this.staffInfoValidationFailed = false;

    if (!this.partnerOnboardingForm['controls'].customerDetailPanel.valid) {
      this.panelErrorMessage = ClaimConstants.MANDATORY_FILL;
      this.customerInfoValidationFailed = true;
      resultFromRuleEngine = false;
    }
    if (!this.partnerOnboardingForm['controls'].deviceDetailPanel.valid) {
      this.panelErrorMessage = ClaimConstants.MANDATORY_FILL;
      this.deviceInfoValidationFailed = true;
      resultFromRuleEngine = false;
    }
    if (!this.partnerOnboardingForm['controls'].staffDetailPanel.valid) {
      this.panelErrorMessage = ClaimConstants.MANDATORY_FILL;
      this.staffInfoValidationFailed = true;
      resultFromRuleEngine = false;
    }
    if(!this.validateConfirmation()){
      resultFromRuleEngine = false;
    }
    return resultFromRuleEngine;
  }

  setCrmzProductwithPlansBasedOnProductCode(event) {
    let crmzProductCode = event.value.productCodes;
    if(crmzProductCode) {
      this.spinner.show();
      this.productService.getAllActiveCrmzProductwithPlansBasedOnProductCode(crmzProductCode).subscribe((response) => {
        if (response && response != undefined) {
          this.spinner.hide();
          this.productDetailList = response;
          
        }
      })
      this.partnerPolicy.partnerProductId = event.value.partnerProductId;
      this.partnerPolicy.internalProductId = event.value.amsInternalProductId;
    }
  }

  mapRequestDataToPolicy(){
    let partnerId = localStorage.getItem("partnerId");
    this.partnerPolicy.partnerId = partnerId;
    this.partnerPolicy.custMobileNumber = "60" + this.partnerPolicy.custMobileNumber;
    this.partnerPolicy.createdBy = this.service.getCurrentUser();
    this.partnerPolicy.countryCode="MY";
    if(this.insuranceProduct.productCoverage)
    var coverage = this.insuranceProduct.productCoverage;
    var todayDate = Utils.convertDateToUTC();
    var startDate : Date = this.getCoverageStartDate();
    this.partnerPolicy.policyStartDate = Utils.convertDateToUTC(startDate);

    this.partnerPolicy.policyPurchaseDate = todayDate;
    this.partnerPolicy.deviceSoldDate = todayDate;

    this.partnerPolicy.declarationAgreement = !this.declarationValidationFailed;
    this.partnerPolicy.consentAgreement = !this.consentValidationFailed;

    delete this.partnerPolicy.cancellationNote;
  }

  parseDescriptionsValue(value:any){
    let parsedValue;
    switch(value){
      case 'POLICY_START_DATE':
        let startDate = this.getCoverageStartDate();
        parsedValue = this.datePipe.transform(startDate,'E, MMM dd, yyyy');
        break;
      case 'VAS_RRP':
        parsedValue = this.reviewVasRRP;
        break;
      case 'INSURANCE_RRP':
        parsedValue = this.reviewInsuranceRRP;
        break;
      case 'SALES_SERVICE_TAX':
        parsedValue = "RM" + this.sstValue.toFixed(2).toString();
        break;
      case 'INSURANCE_POLICY_TENURE':
        parsedValue = Utils.convertMonthsToYear(this.insuranceProduct.tenureInMonths) +" Year";

        break;
      case 'VAS_POLICY_TENURE':
        parsedValue = Utils.convertMonthsToYear(this.vasProduct.tenureInMonths)+" Year";
        break;
      case 'BUNDLE_POLICY_TENURE':
        parsedValue = Utils.convertMonthsToYear(this.bundledProduct.tenureInMonths)+" Year";
        break;
      case 'ANNUAL_VAS_RRP':
        parsedValue = "MYR " + this.finalVasAnnualRRP.toFixed(2).toString();
        break;
      case 'ANNUAL_INSURANCE_RRP':
        parsedValue = "MYR " + this.finalInsuranceAnnualRRP.toFixed(2).toString();
        break;
      case 'ANNUAL_FINAL_RRP':
        parsedValue = "MYR " + this.finalTotalAnnualRRP.toFixed(2).toString();
        break;
      case 'FINAL_RRP':
        parsedValue = "MYR " + this.finalTotalAnnualRRP.toFixed(2).toString();
        break;
      default:
        parsedValue = value;
    }
    return parsedValue;
  }
  
  async onboardPartnerPolicy() {
    this.spinner.show();
    let duplicateCheck = await this.validateIMEISerialNoDuplicate();
    if(duplicateCheck){
      this.mapRequestDataToPolicy();
      let policyInvoiceS3Path = ''
      // If there is a file to be uploaded
      if (this.partnerId === PolicyEnum.PARTNER_AEON_RET_MALAYSIA) {
        if (this.file) {
          try {
            const resp = await this.onSubmit()
            policyInvoiceS3Path = resp ? resp.obj?.s3Url : '';
            this.removeFile();
          } catch(e) {
            console.error("Error while upload sales doc: ",e)
            this.fileUploadError = AppConstants.UPLOAD_FILE_ERROR;
            this.spinner.hide();
            return;
          }
        } else {
          this.fileUploadError = AppConstants.UPLOAD_FILE_ERROR;
          this.spinner.hide();
          return;
        }
      }
      
      if (policyInvoiceS3Path) {
        this.partnerPolicy.salesDocument = policyInvoiceS3Path;
      }
      if(this.onboardBundled){
        //Onboard insuranceProduct First
        this.partnerPolicy.productName = this.insuranceProduct.name;
        this.partnerPolicy.productCode = this.insuranceProduct.productCode;
        this.partnerPolicy.packageType = ProductConstants.PACKAGE_TYPE_BUNDLED;
        this.policyService.createPolicy(this.partnerPolicy).subscribe((response) => {
          if(response && response != undefined) {
            if(response && response.status == "ERROR" && response.obj && response.obj["validationFailuresLogs"] != null){
              this.resetPageDefaults();
              this.policyCreationStatus = response.status;
              this.currentActiveTab = PartnerOnBoardingConstants.PARTNER_ONBOARDING_FAILED_TAB;
              this.spinner.hide();
            }else{
              //Onboard vasProduct
              this.partnerPolicy.productName = this.bundledProduct.name;
              this.partnerPolicy.productCode = this.bundledProduct.productCode;
              this.partnerPolicy.isVasPolicy = true;
              //Set the parentPolicyNumber of VAS referring to Insurance Policy
              this.partnerPolicy.parentPolicyNumber = response.obj["policyNumber"];
              
              this.policyService.createPolicy(this.partnerPolicy).subscribe((response) => {
                if(response && response != undefined) {
                  this.resetPageDefaults();
                  if(response && response.status == "ERROR" && response.obj && response.obj["validationFailuresLogs"] != null){
                    this.policyCreationStatus = response.status;
                    this.currentActiveTab = PartnerOnBoardingConstants.PARTNER_ONBOARDING_FAILED_TAB;
                  }else{
                    this.currentActiveTab++;
                  }
                  this.spinner.hide();
                }
              })
            }
          }
        })
      }else{
        this.partnerPolicy.productName = this.onboardVAS ? this.vasProduct.name : this.insuranceProduct.name;
        this.partnerPolicy.productCode = this.onboardVAS ? this.vasProduct.productCode : this.insuranceProduct.productCode;
        this.partnerPolicy.packageType = this.onboardVAS ? ProductConstants.PACKAGE_TYPE_VAS : ProductConstants.PACKAGE_TYPE_INSURANCE;
        this.policyService.createPolicy(this.partnerPolicy).subscribe((response) => {
          if(response && response != undefined) {
              this.resetPageDefaults();
              if(response && response.status == "ERROR" && response.obj && response.obj["validationFailuresLogs"] != null){
                this.policyCreationStatus = response.status;
                this.currentActiveTab = PartnerOnBoardingConstants.PARTNER_ONBOARDING_FAILED_TAB;
              }else{
                this.currentActiveTab++;
              }
              this.spinner.hide();
          }
        })
      }
    }else{
      this.spinner.hide();
    }
  }
  
  getTenureInYear(tenure:string){
    var value = Number(tenure)
    if(!isNaN(value)){
      return (value/12)
    }else{
      return null
    }
  }

  getCoverageStartDate() : Date{
    var todayDate = new Date();
    
    let productCoverage = this.insuranceProduct.productCoverage;
    //If Manufacturer Warranty(WA) is there and only contains EW Coverage, 
    //calculate the policyStartDate based on WA tenure
    if(productCoverage.indexOf("WA")>=0 && productCoverage.indexOf("EW") >=0 && productCoverage.length == 2){
      let manufacturerTenure :number = this.insuranceProduct.manufacturerWarrantyTenure;
      if(manufacturerTenure){
        var startDate = new Date(todayDate.setMonth(todayDate.getMonth() + manufacturerTenure));
        return startDate;
      }
    }
    if(productCoverage.length ==1 && productCoverage[0] == "EW"){
      var startDate = new Date(todayDate.setFullYear(todayDate.getFullYear() + 1));
      return startDate;
    }else{
      return todayDate;
    }
  }

  resetCountry(event) {
    let selectedCountryCode = event.value;
    let selectedCountry = this.countries.filter((x) => x.twoCharCode == selectedCountryCode);
    this.refreshCountryOfAddress(selectedCountry[0].name);
    this.defaultCountry = this.partnerPolicy.custAddress.customerCountry;
  }

  resetPageDefaults() {
    this.partnerPolicy = PolicyDocument.newPolicyDocument();
    this.partnerPolicy.custAddress = new Object();
    this.partnerOnboardingForm.reset();
    this.imeiOrSerialRequiredFailed = false;
    this.resetValidationErrors();
    this.resetAcknowledgement();
    this.initFirstForm(this.partnerId);
    this.setAddressHandlers();
    this.getListOfCoutries();
  }

  resetValidationErrors(){
    this.deviceRRPValidationFailed=false;
    this.deviceRRPErrorMessage = "";
    this.skuCodeErrorMessage = "";
    this.customerInfoValidationFailed = false;
    this.deviceInfoValidationFailed = false;
    this.staffInfoValidationFailed=false;
    this.declarationValidationFailed=false;
    this.consentValidationFailed=false;
  }

  confirmAcknowledgement(inputString) {
    switch (inputString) {
      case 'DECLARATION_1':
        this.declaration1Acknowledgement = !this.declaration1Acknowledgement;
        break;
      case 'DECLARATION_2':
        this.declaration2Acknowledgement = !this.declaration2Acknowledgement;
        break;
      case 'DECLARATION_3':
        this.declaration3Acknowledgement = !this.declaration3Acknowledgement;
        break;
      case 'CONSENT_1':
        this.consent1Acknowledgement = !this.consent1Acknowledgement;
        break;
      case 'CONSENT_2':
        this.consent2Acknowledgement = !this.consent2Acknowledgement;
        break;
      case 'CONSENT_3':
        this.consent3Acknowledgement = !this.consent3Acknowledgement;
        break;
    }
  }

  resetAcknowledgement(){
    this.declaration1Acknowledgement=false;
    this.declaration2Acknowledgement=false;
    this.declaration3Acknowledgement=false;
    this.consent1Acknowledgement=false;
    this.consent2Acknowledgement=false;
    this.consent3Acknowledgement=false;
  }

  removeFile() {
    this.fileName = "";
    this.file = null;
    this.fileUploadError = "";
    this.addUploadBtnText = AppConstants.ADD_BUTTON_TEXT;
  }

  onFileSelected(event) {
    this.file = event.target.files[0];
    this.fileName = this.file.name;
    const fileType = this.fileName.split(".")[1];
    // Is file format accepted type ?
    if (this.acceptedFileFormat.indexOf(fileType) < 0) {
      this.file = null;
      this.fileName = "";
      this.addUploadBtnText = AppConstants.ADD_BUTTON_TEXT;
      return false;
    }
    const errorReason = this.validate();
    this.addUploadBtnText = AppConstants.REUPLOAD_BUTTON_TEXT;
    const formData = new FormData();
    formData.append("thumbnail", this.file);
    this.fileUploadError = errorReason;
  }

  validate():any {
    if (this.file) {
      const fileSize = Math.round((this.file.size / 1024))
      if (fileSize > 10240) {
        return AppConstants.FILE_SIZE_ERROR;
      }
    } else {
      return AppConstants.INVALID_FILE_ERROR;
    }
    return "";
  }

  onSubmit():Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (this.file) {
        this.spinner.show();
        const formData = new FormData();
        formData.append('file', this.file);
        formData.append('partnerId', this.partnerId);
        formData.append('user', localStorage.getItem(AppConstants.LOGGED_IN_USER));
  
        return await this.policyService.uploadPolicyDocument(formData).subscribe(res => {
          this.licenseFileUpload = res;
          this.spinner.hide();
          resolve(res);
        }, err => {
          this.fileUploadError = err;
          this.spinner.hide();
          reject(err);
        }, () => {
          this.service.onComplete(this.licenseFileUpload);
        })
        
      }
    })
  }

  validateConfirmation():boolean{
    let resultFromConfirmationValidationEngine = true;
    if(!(this.declaration1Acknowledgement && this.declaration2Acknowledgement &&
      this.declaration3Acknowledgement)){
        this.declarationValidationFailed = true;
        resultFromConfirmationValidationEngine = false;
    }else{
      this.declarationValidationFailed = false;
    }

    if (!(this.consent1Acknowledgement && this.consent2Acknowledgement && 
      this.consent3Acknowledgement)) {
      this.consentValidationFailed = true;
      resultFromConfirmationValidationEngine = false;
    } else {
      this.consentValidationFailed = false;
    }

    return resultFromConfirmationValidationEngine;
  }


  refreshCountryOfAddress(country: any = this.defaultCountry) {
    if (this.partnerPolicy.custAddress == null || this.partnerPolicy.custAddress == undefined) {
      this.partnerPolicy.custAddress = new Object();
    }
    this.partnerPolicy.custAddress.customerCountry = country;
  }

  validateDeviceRRPRange = () => {
    return (deviceRRP: FormControl) => {
      if (deviceRRP.value && deviceRRP.value.length > 0) {
        if (deviceRRP.value < this.minDeviceRRP ||
          deviceRRP.value > this.maxDeviceRRP) {
          return { 'rrpOverrange': true };
        } else {
          return null;
        }
      }
    }
  }

  async changeTab(action: string) {
    let methodHandlerName
    switch (action) {
      case 'CHANGE_TAB_INC':
        methodHandlerName = PartnerOnBoardingConstants.PARTNER_ONBORADING_CHANGE_TAB_INC_METHOD_LABEL;
        break;
      case 'CHANGE_TAB_DEC':
        methodHandlerName = PartnerOnBoardingConstants.PARTNER_ONBORADING_CHANGE_TAB_DEC_METHOD_LABEL;
        break;
      case 'CHANGE_TAB_SUBMIT':
        methodHandlerName = PartnerOnBoardingConstants.PARTNER_ONBORADING_CHANGE_TAB_SUBMIT_METHOD_LABEL;
        break;
      case 'CHANGE_TAB_VALIDATE_SKU':
        methodHandlerName = PartnerOnBoardingConstants.PARTNER_ONBOARDING_CHANGE_TAB_VALIDATE_SKU_METHOD_LABEL;
        break;
      case 'CHANGE_TAB_FIRST_PAGE':
        methodHandlerName = PartnerOnBoardingConstants.PARTNER_ONBOARDING_CHANGE_TAB_FIRST_TAB;
        break;
    }
    if (methodHandlerName != '') {
      if(this[methodHandlerName]){
        await this[methodHandlerName]();
      }
    }
  }

  changeTabInc() {
    let validatorCallChain = this.partnerOnboardingService.fetchActionhandlers(this.currentActiveTab);
    let resultFromValidatorCallChain : boolean = true;
    if(validatorCallChain){
      validatorCallChain.forEach(handler => {
        if(this[handler]){
          resultFromValidatorCallChain = resultFromValidatorCallChain && this[handler]();
        }
      });
    }
    if(resultFromValidatorCallChain || resultFromValidatorCallChain == undefined){
      this.currentActiveTab++ ;
    }
  }

  changeTabDec() {
    this.currentActiveTab--;
  }

  changeFirstTab(){
    this.currentActiveTab = 0;
  }

  async validateIMEISerialNoDuplicate():Promise<Boolean>{
    return new Promise((resolve)=>{
      let onboardingValidationRequest = {} , onboardingFields = {};
      onboardingValidationRequest[AppConstants.REQUEST_COMMANDS] = [];
      
      if(this.partnerPolicy.policyIMEINumber != null && this.partnerPolicy.policyIMEINumber != ''){
        onboardingFields[AppConstants.POLICY_IMEI_NUMBER] = this.partnerPolicy.policyIMEINumber;
        onboardingValidationRequest[AppConstants.REQUEST_COMMANDS].push("OBV_002");
      }
      if (this.partnerPolicy.deviceSerialNo != null && this.partnerPolicy.deviceSerialNo != ''){
        onboardingFields[AppConstants.DEVICE_SERIAL_NUMBER] = this.partnerPolicy.deviceSerialNo;
        onboardingValidationRequest[AppConstants.REQUEST_COMMANDS].push("OBV_010");
      }

      if(this.onboardBundled){
        //Validate Insurance Product First
        onboardingFields[AppConstants.ONBOARDING_FIELD_PRODUCT_NAME] = this.insuranceProduct.name;
        onboardingValidationRequest[AppConstants.REQUEST_ONBOARDING_FIELDS] = onboardingFields;
        this.policyService.validateOnBoardingField(onboardingValidationRequest).subscribe((response)=> {
          if (response && response.status === "OK" && response.obj) {
            let validationErrors = response.obj["validationFailures"];
            if(validationErrors == null){
              //Validate VAS Product
              onboardingFields[AppConstants.ONBOARDING_FIELD_PRODUCT_NAME] =this.bundledProduct.name;
              onboardingValidationRequest[AppConstants.REQUEST_ONBOARDING_FIELDS] = onboardingFields;
              this.policyService.validateOnBoardingField(onboardingValidationRequest).subscribe((res)=> {
                if (res && res.status === "OK" && res.obj){
                  let validationErr = res.obj["validationFailures"];
                  if(validationErr){
                    if(validationErr["OBV_002"]){
                      let OBV = validationErr["OBV_002"];
                      if(OBV[0].validationFailureCode == "200.0400"){
                          this.service.showInfo(validationErr["policyIMEINumber"][0]["validationFailureReason"],"IMEI Validation Error");
                      } else {
                        this.service.showInfo(OBV[0].validationFailureReason, "Validation Error");
                      }
                    }else if(validationErr["OBV_010"]){
                      let OBV = validationErr["OBV_010"];
                      if(OBV[0].validationFailureCode == "200.0400"){
                          this.service.showInfo(validationErr["deviceSerialNo"][0]["validationFailureReason"],"Device Serial No Validation Error");
                      } else {
                        this.service.showInfo(OBV[0].validationFailureReason, "Validation Error");
                      }
                    }
                    resolve(false);
                  }else{
                    resolve(true);
                  }
                }
              });
            }else{
              if(validationErrors["OBV_002"]){
                let OBV = validationErrors["OBV_002"];
                if(OBV[0].validationFailureCode == "200.0400"){
                    this.service.showInfo(validationErrors["policyIMEINumber"][0]["validationFailureReason"],"IMEI Validation Error");
                } else {
                  this.service.showInfo(OBV[0].validationFailureReason, "Validation Error");
                }
              }else if(validationErrors["OBV_010"]){
                let OBV = validationErrors["OBV_010"];
                if(OBV[0].validationFailureCode == "200.0400"){
                    this.service.showInfo(validationErrors["deviceSerialNo"][0]["validationFailureReason"],"Device Serial No Validation Error");
                } else {
                  this.service.showInfo(OBV[0].validationFailureReason, "Validation Error");
                }
              }
              resolve(false);
            }
          }
        })
      }else{
        onboardingFields[AppConstants.ONBOARDING_FIELD_PRODUCT_NAME] = this.onboardInsurance?this.insuranceProduct.name:this.vasProduct.name;
        onboardingValidationRequest[AppConstants.REQUEST_ONBOARDING_FIELDS] = onboardingFields;
        this.policyService.validateOnBoardingField(onboardingValidationRequest).subscribe((response) => {
          if (response && response.status === "OK" && response.obj) {
            let validationErrors = response.obj["validationFailures"];
            if(validationErrors){
              if(validationErrors["OBV_002"]){
                let OBV = validationErrors["OBV_002"];
                if(OBV[0].validationFailureCode == "200.0400"){
                    this.service.showInfo(validationErrors["policyIMEINumber"][0]["validationFailureReason"],"IMEI Validation Error");
                } else {
                  this.service.showInfo(OBV[0].validationFailureReason, "Validation Error");
                }
              }else if(validationErrors["OBV_010"]){
                let OBV = validationErrors["OBV_010"];
                if(OBV[0].validationFailureCode == "200.0400"){
                    this.service.showInfo(validationErrors["deviceSerialNo"][0]["validationFailureReason"],"Device Serial No Validation Error");
                } else {
                  this.service.showInfo(OBV[0].validationFailureReason, "Validation Error");
                }
              }
              resolve(false);
            }else{
              resolve(true);
            }
          }
        })
      }
    })
  }

  setDeviceType(event){
    this.partnerPolicy.deviceType = event.value.name;
  }

  setStoreName(event){
    this.partnerPolicy.storeName = event.value;
  }

  setPaymentType(event){
    this.partnerPolicy.paymentType = event.value;
  }

  setVASProduct(product:ProductDetail){
    this.vasProduct = product;
  }
  setInsuranceProduct(product:ProductDetail){
    this.insuranceProduct=product;
  }

  checkNotEmpty(obj:any){
    return (obj != '' && obj != null);
  }

  showInsuranceProducts():boolean{
    let deviceRRPValid = true;
    if (!this.deviceRRPForm['controls'].pricePanel.valid) {
      if(this.deviceRRPForm['controls'].pricePanel['controls'].deviceRRP.value == ""){
        this.deviceRRPErrorMessage = "Device Retail Price cannot be empty"
      }else{
        this.deviceRRPErrorMessage = "Device Retail Price must be numeric"
      }
      this.panelErrorMessage = ClaimConstants.MANDATORY_FILL;
      this.deviceRRPValidationFailed = true;
      deviceRRPValid = false;
    }
    return deviceRRPValid;
  }

  getOnboardingConfigModel() {
    this.totalOnboardingSteps = 0;
    this.productService.findPartnerOnboardingConfig(this.partnerId).subscribe((response) => {
      if (response && response.obj != null) {
        let configModel = response.obj;
        this.partnerOnboardingService.setOnboardingConfig(configModel);
        this.hasOnboardingConfig = true;
        if (configModel.configComponents && configModel.configComponents.length > 0) {
          if (configModel.configComponents.some(component => (component.value && component.value === "PARTNER_FAILED_PANEL"))) {
            // Exclude failed page
            this.totalOnboardingSteps = configModel.configComponents.length - 1;
          } else {
            this.totalOnboardingSteps = configModel.configComponents.length;
          }
        }
      }
    });
  }
  
  onboardBundleProduct(){
    this.spinner.show();
    this.onboardInsurance = false;
    this.onboardVAS = false;
    this.onboardBundled = true;
    this.onboardPackage = this.bundledProduct;

    this.reviewTotalRRP = this.bundledPlanRRP;
    this.reviewInsuranceRRP = this.insurancePlanRRP;
    this.reviewVasRRP = this.vasBundledPlanRRP;

    this.finalVasAnnualRRP = this.vasBundledPlanRRP;
    this.finalInsuranceAnnualRRP = this.insurancePlanRRP;
    this.finalTotalAnnualRRP = this.vasBundledPlanRRP + this.insurancePlanRRP;
    this.setDeviceTypeByOnboardProduct();
    this.currentActiveTab++;
    this.spinner.hide();
  }
  onboardInsuranceProduct(){
    this.spinner.show();
    this.onboardBundled = false;
    this.onboardVAS = false;
    this.onboardInsurance = true;
    this.onboardPackage = this.insuranceProduct;

    this.reviewTotalRRP = this.insurancePlanRRP;
    this.reviewInsuranceRRP = this.insurancePlanRRP;
    this.finalInsuranceAnnualRRP = this.insurancePlanRRP;
    this.finalTotalAnnualRRP = this.insurancePlanRRP;
    this.setDeviceTypeByOnboardProduct();
    this.currentActiveTab++;
    this.spinner.hide();
  }
  onboardVASProduct(){
    this.spinner.show();
    this.onboardInsurance = false;
    this.onboardBundled = false;
    this.onboardVAS = true;
    this.onboardPackage = this.vasProduct;

    this.reviewTotalRRP = this.vasStandalonePlanRRP;
    this.reviewVasRRP = this.vasStandalonePlanRRP;
    this.finalVasAnnualRRP = this.vasStandalonePlanRRP;
    this.finalTotalAnnualRRP = this.vasStandalonePlanRRP;
    this.setDeviceTypeByOnboardProduct();
    this.currentActiveTab++;
    this.spinner.hide();
  }

  getRRPFromPlans(plans:any[],deviceRRP:string):any{
    let devicePrice = parseFloat(deviceRRP);
    let rrpArray : Map<string , string[]> = new Map;
    let maxPrice,minPrice :number = 0;
    let RRP:number = 0;

    for(let plan of plans){
      minPrice = parseFloat(plan.devicePriceStartingRange);
      maxPrice = parseFloat(plan.devicePriceEndingRange);
      let arr = rrpArray.get(plan.coverageCode);
      if (this.partnerId === PolicyEnum.PARTNER_AEON_RET_MALAYSIA) {
        if (arr != null){
          arr.push(plan.price.retailPriceValueWithTax);
          rrpArray.set(plan.coverageCode,arr);
        }else{
          let newArr = [];
          newArr.push(plan.price.retailPriceValueWithTax);
          rrpArray.set(plan.coverageCode,newArr);
        }
      } else {
        if (devicePrice >= minPrice && devicePrice <= maxPrice){
          if (arr != null){
            arr.push(plan.price.retailPriceValueWithTax);
            rrpArray.set(plan.coverageCode,arr);
          }else{
            let newArr = [];
            newArr.push(plan.price.retailPriceValueWithTax);
            rrpArray.set(plan.coverageCode,newArr);
          }
        }
      }
    }

    for( let key of rrpArray.keys()){
      let sumArray = rrpArray.get(key);
      let sumRRP = 0;
      for(var i =0;i<sumArray.length;i++){
        sumRRP = sumRRP + parseFloat(sumArray[i]);
      }
      RRP = RRP + (sumRRP/sumArray.length);
    }

    return this.roundOffDecimal(RRP);
  }


  getSSTFromPlans(plans:any[],deviceRRP:string):any{
    let devicePrice = parseFloat(deviceRRP);
    let sstArray : Map<string , string[]> = new Map;
    let maxPrice,minPrice :number = 0;
    let SST:number = 0;

    for(let plan of plans){
      minPrice = parseFloat(plan.devicePriceStartingRange);
      maxPrice = parseFloat(plan.devicePriceEndingRange);
      let arr = sstArray.get(plan.coverageCode);
      if (this.partnerId === PolicyEnum.PARTNER_AEON_RET_MALAYSIA) {
        if (arr != null){
          arr.push(plan.price.sstValue);
          sstArray.set(plan.coverageCode,arr);
        }else{
          let newArr = [];
          newArr.push(plan.price.sstValue);
          sstArray.set(plan.coverageCode,newArr);
        }
      } else {
        if (devicePrice >= minPrice && devicePrice <= maxPrice){
          if (arr != null){
            arr.push(plan.price.sstValue);
            sstArray.set(plan.coverageCode,arr);
          }else{
            let newArr = [];
            newArr.push(plan.price.sstValue);
            sstArray.set(plan.coverageCode,newArr);
          }
        }
      }
    }

    for( let key of sstArray.keys()){
      let sumArray = sstArray.get(key);
      let sumRRP = 0;
      for(var i =0;i<sumArray.length;i++){
        sumRRP = sumRRP + parseFloat(sumArray[i]);
      }
      SST = SST + (sumRRP/sumArray.length);
    }

    return this.roundOffDecimal(SST);
  }

  showOtherProducts(input:boolean){
    this.displayOtherProducts = input;
  }

  roundOffDecimal(value: any) {
    return Math.round((parseFloat(value) + Number.EPSILON) * 100) / 100;
  }

  calculateOnboardRRP(){
    let deviceRRP = this.partnerPolicy.deviceRRP;
    let rrp = 0;

    //Everything in month, need to divide by its productTenure;
    if(this.insuranceProduct) {
      rrp = this.getRRPFromPlans(this.insuranceProduct.plans,deviceRRP);
      this.insurancePlanRRP = rrp;
    }

    if(this.vasProduct) {
      rrp = this.getRRPFromPlans(this.vasProduct.plans,deviceRRP);
      this.vasStandalonePlanRRP = this.roundOffDecimal(rrp);
    }

    if(this.bundledProduct) {
      rrp = this.getRRPFromPlans(this.bundledProduct.plans,deviceRRP);
      this.vasBundledPlanRRP = this.roundOffDecimal(rrp);
    }
    
    if(this.insuranceProduct) {
      rrp = this.getSSTFromPlans(this.insuranceProduct.plans,deviceRRP);
      this.sstValue = this.roundOffDecimal(rrp);
    }

    this.bundledPlanRRP = this.insurancePlanRRP + this.vasBundledPlanRRP;

    if(this.insurancePlanRRP == 0 ||
      this.vasStandalonePlanRRP == 0 ||
      this.bundledPlanRRP == 0){
        this.rrpOverrange=true;
      }else{
        this.rrpOverrange=false;
      }
  }

  getAllInsuranceProducts(clientId:string){
    if(clientId === PolicyEnum.PARTNER_AEON_RET_MALAYSIA) {
      return;
    } else {
      this.productService.getInsuranceProducts(clientId).subscribe((res) => {
        if (res && res.obj != null){
          let insuranceProducts = res.obj;
          for (var product of insuranceProducts){
            if (product.productCoverage.indexOf('ADLD')>=0){
              //Check if is ADLD/EW Coverage
              if(product.productCoverage.indexOf('EW')>=0){
                this.hasAdldEwProduct = true;
                this.adldEwProduct.push(product);
              }else{
                this.hasADLDProduct = true;
                this.adldProduct.push(product);
              }
            }else if(product.productCoverage.indexOf('THEFT')>=0){
              this.hasTHEFTProduct = true;
              this.theftProduct.push(product);
            }else if(product.productCoverage.indexOf('CS')>=0){
              //Check if is CS/EW Coverage
              if(product.productCoverage.indexOf('EW')>=0){
                this.hasCsEwProduct = true;
                this.csEwProduct.push(product);
              }else{
                this.hasCSProduct = true;
                this.csProduct.push(product);
              }
            }else if(product.productCoverage.indexOf('EW')>=0){
              this.hasEWProduct = true;
              this.ewProduct.push(product);
            }
          }
        }
      });
    }
  }

  getCoverageOptions(products:Array<Product>){
    this.spinner.show();
    if(products != null){
      this.insuranceProducts = products;
    }
    this.currentActiveTab++;
    this.spinner.hide();
  }

  getProductOptions(product:Product){
    this.spinner.show();
    this.displayOtherProducts = false;
    this.productService.getProductOptions(product.packageProductName).subscribe((res) => {
      if (res && res.obj != null){
        let products = res.obj;
        for(let prod of products){
          if(prod.packageType == ProductConstants.PACKAGE_TYPE_BUNDLED){
            this.bundledProduct = prod
          }else if (prod.packageType == ProductConstants.PACKAGE_TYPE_INSURANCE){
            this.insuranceProduct = prod
          }else if (prod.packageType == ProductConstants.PACKAGE_TYPE_VAS){
            this.vasProduct = prod
          }
        }
        this.calculateOnboardRRP();
        this.currentActiveTab++;
        this.spinner.hide();
      }
    })
  }

}
