import { RoleEnum } from './roles.enum';
import { AppConstants } from '../app.constants';
import { Injectable } from "@angular/core";

@Injectable()
export class RoleMap {
  public roleMap: any;

  constructor() {
    this.roleMap = new Map<String, Number>();
    this.roleMap.set(AppConstants.ROLE_CONFIG, RoleEnum.Config);
    this.roleMap.set(AppConstants.ROLE_UPLOAD, RoleEnum.Upload);
    this.roleMap.set(AppConstants.ROLE_CREATE, RoleEnum.Create);
    this.roleMap.set(AppConstants.ROLE_UPDATE, RoleEnum.Update);
    this.roleMap.set(AppConstants.ROLE_READ, RoleEnum.Read);
  }
}
