import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { NgForm, AbstractControl, Validators,UntypedFormGroup} from '@angular/forms';
import { PolicyService } from '../common-services/policy.service';
import { PolicyDocument } from '../model/policy.document';
import { MdmService } from '../mdm.service';
import { PartnerOnBoardingConstants } from './partner-onboarding.constants';
import { PartnerOnboardingConfig } from '../model/partner.onboarding.config';
import { ProductService } from '../common-services/product.service';
import { AppConstants } from '../app.constants';
import { PolicyConstants } from '../policy/policy.constants';


@Injectable({
  providedIn: 'root'
})
export class PartnerOnboardingService {

  partnerOnboardingForm: UntypedFormGroup;
  partnerPolicy: PolicyDocument;
  partnerOnboardingConfigModel: PartnerOnboardingConfig;
  controlErrorMsg: String;

  constructor(private service: AppService, private policyService: PolicyService,
    private mdmService: MdmService, private productService: ProductService) {
  }

  setSharedSpace(partnerOnboardingForm: UntypedFormGroup, partnerPolicyModel: PolicyDocument) {
    this.partnerOnboardingForm = partnerOnboardingForm;
    this.partnerPolicy = partnerPolicyModel;
  }

  setOnboardingConfig(productConfigModel: any) {
    this.partnerOnboardingConfigModel = productConfigModel;
  }
 
  setFieldValidatorsForPartner(fieldName) {
    const partnerId = this.service.getCurrentLoggedInPartner();
    let fieldValidators = [];
    switch (fieldName) {
      case PartnerOnBoardingConstants.CUSTOMER_NAME:
        switch (partnerId) {
          case PartnerOnBoardingConstants.PARTNER_ID_DIGI:
          case PartnerOnBoardingConstants.PARTNER_ID_DIGI_SHIELD:
            fieldValidators = [this.isEmptyValueValidator.bind(this), this.isNumericValidator.bind(this)];
            break;
          default:
            fieldValidators = [ this.isEmptyValueValidator.bind(this), this.isNumericValidator.bind(this)];
            break;
        }
        break;
      case PartnerOnBoardingConstants.ID_TYPE:
        switch (partnerId) {
          case PartnerOnBoardingConstants.PARTNER_ID_AEON:
            fieldValidators = [Validators.required];
            break;
        }
        break;
      case PartnerOnBoardingConstants.CUSTOMER_ID:
        switch (partnerId) {
          case PartnerOnBoardingConstants.PARTNER_ID_AEON:
            fieldValidators = [this.onboardingAbstractControlvalidator.bind(this)];
            break;
        }
        break;
      case PartnerOnBoardingConstants.CUSTOMER_EMAIL:
        switch (partnerId) {
          case PartnerOnBoardingConstants.PARTNER_ID_AEON:
            fieldValidators = [this.emailValidator.bind(this)];
            break;
          case PartnerOnBoardingConstants.PARTNER_ID_DIGI:
          case PartnerOnBoardingConstants.PARTNER_ID_DIGI_SHIELD:
            fieldValidators = [this.emailValidator.bind(this)];
            break;
          default:
            fieldValidators = [this.emailValidator.bind(this),this.isEmptyValueValidator.bind(this)];
            break;
        }
        break;
      case PartnerOnBoardingConstants.CUSTOMER_MOBILE_NUMBER:
        switch (partnerId) {
          case PartnerOnBoardingConstants.PARTNER_ID_DIGI:
          case PartnerOnBoardingConstants.PARTNER_ID_DIGI_SHIELD:
            fieldValidators = [this.isEmptyValueValidator.bind(this), this.isNonNumericValidator.bind(this),this.isPrefixCorrectValidator.bind(this)];
            break;
          default:
            fieldValidators = [this.isEmptyValueValidator.bind(this), this.isNonNumericValidator.bind(this), this.isPrefixCorrectValidator.bind(this)];
            break;
        }
        break;
      case PartnerOnBoardingConstants.POLICY_IMEI_NUMBER:
        switch (partnerId) {
          case PartnerOnBoardingConstants.PARTNER_ID_DIGI:
            fieldValidators = [Validators.required, this.onboardingAbstractControlvalidator.bind(this)];
            break;
          case PartnerOnBoardingConstants.PARTNER_ID_DIGI_SHIELD:
            fieldValidators = [this.isEmptyValueValidator.bind(this), this.performLuhnCheck.bind(this)];
            break;
          case PartnerOnBoardingConstants.PARTNER_ID_AEON:
            fieldValidators = [Validators.required, this.onboardingAbstractControlvalidator.bind(this)];
            break;
          default:
            fieldValidators = [this.performLuhnCheck.bind(this)]
            break;
        }
        break;
      case PartnerOnBoardingConstants.DEVICE_CAPACITY:
        switch (partnerId) {
          case PartnerOnBoardingConstants.PARTNER_ID_DIGI_SHIELD:
            break;
          case PartnerOnBoardingConstants.PARTNER_ID_DIGI:
            fieldValidators = [this.isEmptyValueValidator.bind(this), this.isNonNumericValidator.bind(this)];
            break;
          case PartnerOnBoardingConstants.PARTNER_ID_AEON_RETAIL:
            fieldValidators = [this.isNonNumericValidator.bind(this)];
            break;
          default:
            fieldValidators = [this.isEmptyValueValidator.bind(this),this.isNonNumericValidator.bind(this)]
            break;
        }
        break;
      case PartnerOnBoardingConstants.DEVICE_COLOR:
        switch (partnerId) {
          case PartnerOnBoardingConstants.PARTNER_ID_DIGI:
          case PartnerOnBoardingConstants.PARTNER_ID_DIGI_SHIELD:
            fieldValidators = [this.isEmptyValueValidator.bind(this), this.isNonAlphabeticValidator.bind(this)];
            break;
          case PartnerOnBoardingConstants.PARTNER_ID_AEON_RETAIL:
            fieldValidators = [this.isNonAlphabeticValidator.bind(this)];
            break;
          default:
            fieldValidators = [this.isEmptyValueValidator.bind(this),this.isNonAlphabeticValidator.bind(this)]
            break;
        }
        break;
      case PartnerOnBoardingConstants.PRODUCT_NAME:
        switch (partnerId) {
          case PartnerOnBoardingConstants.PARTNER_ID_AEON:
          case PartnerOnBoardingConstants.PARTNER_ID_DIGI:
          case PartnerOnBoardingConstants.PARTNER_ID_DIGI_SHIELD:
            fieldValidators = [Validators.required];
            break;
        }
        break;
      case PartnerOnBoardingConstants.PRODUCT_MODEL:
        switch (partnerId) {
          case PartnerOnBoardingConstants.PARTNER_ID_AEON:
          case PartnerOnBoardingConstants.PARTNER_ID_DIGI:
          case PartnerOnBoardingConstants.PARTNER_ID_DIGI_SHIELD:
            fieldValidators = [Validators.required];
            break;
          default:
        }
        break;
      case PartnerOnBoardingConstants.STAFF_NAME:
        switch (partnerId) {
          case PartnerOnBoardingConstants.PARTNER_ID_DIGI:
          case PartnerOnBoardingConstants.PARTNER_ID_DIGI_SHIELD:
            fieldValidators = [Validators.required];
            break;
          default:
            fieldValidators = [Validators.required];
            break;
        }
        break;
        case PartnerOnBoardingConstants.STAFF_NAME:
          switch (partnerId) {
            case PartnerOnBoardingConstants.PARTNER_ID_AEON_RETAIL:
              fieldValidators = [Validators.required];
              break;
            default:
              break;
          }
          break;
      case PartnerOnBoardingConstants.STORE_NAME:
        switch (partnerId) {
          case PartnerOnBoardingConstants.PARTNER_ID_DIGI:
          case PartnerOnBoardingConstants.PARTNER_ID_DIGI_SHIELD:
            fieldValidators = [Validators.required];
            break;
          default:
            fieldValidators = [Validators.required];
            break;
        }
        break;
      case PartnerOnBoardingConstants.CUSTOMER_ACKNOWLEDGEMENT:
        switch (partnerId) {
          case PartnerOnBoardingConstants.PARTNER_ID_AEON:
          case PartnerOnBoardingConstants.PARTNER_ID_DIGI:
          case PartnerOnBoardingConstants.PARTNER_ID_DIGI_SHIELD:
            fieldValidators = [Validators.required];
            break;
        }
        break;
      case PartnerOnBoardingConstants.STAFF_ACKNOWLEDGEMENT:
        switch (partnerId) {
          case PartnerOnBoardingConstants.PARTNER_ID_AEON:
            fieldValidators = [Validators.required];
            break;
          case PartnerOnBoardingConstants.PARTNER_ID_DIGI:
            fieldValidators = [Validators.required];
            break;
          case PartnerOnBoardingConstants.PARTNER_ID_DIGI_SHIELD:
            fieldValidators = [Validators.required];
            break;
        }
        break;
      case PartnerOnBoardingConstants.AGREEMENT_ID:
        switch (partnerId) {
          case PartnerOnBoardingConstants.PARTNER_ID_AEON:
            fieldValidators = [Validators.required, this.onboardingAbstractControlvalidator.bind(this)];
            break;
        }
        break;
      case PartnerOnBoardingConstants.DEVICE_TYPE:
        switch(partnerId){
          default:
            fieldValidators = [Validators.required]
            break;
        }
        break;
      case PartnerOnBoardingConstants.DEVICE_MAKE:
        switch(partnerId){
          default:
            fieldValidators = [this.isEmptyValueValidator.bind(this)]
            break;
        }
        break;
      case PartnerOnBoardingConstants.DEVICE_MODEL:
        switch(partnerId){
          default:
            fieldValidators = [this.isEmptyValueValidator.bind(this)]
            break;
        }
        break;
      case PartnerOnBoardingConstants.DEVICE_RRP:
        switch(partnerId){
          default:
            fieldValidators = [this.isEmptyValueValidator.bind(this),
              this.isNonNumericValidator.bind(this)];
            break;
        }
        break;
      case PartnerOnBoardingConstants.INVOICE_NUMBER:
          switch(partnerId){
            default:
              fieldValidators = [this.isEmptyValueValidator.bind(this), this.invoiceNoValidator.bind(this)]
              break;
          }
          break;
      case PartnerOnBoardingConstants.SKU_CODE:
          switch(partnerId){
            default:
              fieldValidators = [this.isEmptyValueValidator.bind(this)];
              break;
          }
          break;
      case PartnerOnBoardingConstants.PAYMENT_METHOD:
        switch(partnerId){
          case PartnerOnBoardingConstants.PARTNER_ID_AEON_RETAIL:
            fieldValidators = [Validators.required];
            break;
          default:
            break;
        }
        break;   
    }
    return fieldValidators;
  }

  isPrefixCorrectValidator(control: AbstractControl): { [key: string]:boolean} | null {
    if(control.value != '' && control.value != null){
      if(control.value.substr(0,1) != "1"){
        return { 'prefixError':true};
      }
    }
    return null;
  }
  isEmptyValueValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if ((control.value == undefined || control.value == '')) {
      return { 'emptyCheckFailed': true };
    }
    return null;
  }
  isNonNumericValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value != '' && isNaN(control.value)) {
      return { 'nonNumberCheckFailed': true };
    }
    return null;
  }
  isNumericValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value != '' && !isNaN(control.value)) {
      return { 'numberCheckFailed': true };
    }
    return null;
  }

  isNonAlphabeticValidator(control: AbstractControl): { [key: string]: boolean } | null {
    let specialCharPattern = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (control.value != '' && (specialCharPattern.test(control.value) || !isNaN(control.value))) {
      return { 'nonAlphabeticCheckFailed': true };
    }
    return null;
  }
  emailValidator(control: AbstractControl): { [key: string]: boolean } | null {
    let emailRegexPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (control.value != '' && !emailRegexPattern.test(control.value)) {
      return { 'emailValidationFailed': true };
    }
    return null;
  }

  invoiceNoValidator(control: AbstractControl): { [key: string]: boolean } | null {
    let invoiceRegexPattern = /^[0-9]{18}$/;
    if (control.value != '' && !invoiceRegexPattern.test(control.value)) {
      return { 'invoiceValidationFailed': true };
    }
    return null;
  }

  setOnboardingValidationrequest(control: AbstractControl) {
    const controlName = (Object.keys(control.parent.controls).find(key => control.parent.controls[key] === control));
    let onboardingValidationRequest = {}, onboardingFields = {};
    let partnerId = localStorage.getItem("partnerId");
    switch (controlName) {
      case "policyIMEINumber":
        onboardingFields[AppConstants.POLICY_IMEI_NUMBER] = control.value;
        if (partnerId === PartnerOnBoardingConstants.PARTNER_ID_DIGI) {
          onboardingValidationRequest[AppConstants.PARTNER_ID] = partnerId;
          onboardingValidationRequest[AppConstants.REQUEST_COMMANDS] = [AppConstants.VALIDATION_RULE_OBV_004];
          onboardingValidationRequest[AppConstants.REQUEST_OPERATOR] = AppConstants.OPERATOR_AND;
        }
        if (partnerId === PartnerOnBoardingConstants.PARTNER_ID_AEON) {
          onboardingValidationRequest[AppConstants.PARTNER_ID] = partnerId;
          onboardingFields[AppConstants.REQUEST_AGREEMENT_NUMBER] = this.partnerOnboardingForm['controls'].customerDetailPanel['controls'].agreementNumber.value;
          onboardingValidationRequest[AppConstants.REQUEST_COMMANDS] = [AppConstants.VALIDATION_RULE_OBV_006];
          onboardingValidationRequest[AppConstants.REQUEST_OPERATOR] = AppConstants.OPERATOR_AND;
        }
        if (partnerId === PartnerOnBoardingConstants.PARTNER_ID_DIGI_SHIELD) {
          onboardingFields[AppConstants.POLICY_IMEI_NUMBER] = this.partnerOnboardingForm['controls'].deviceDetailPanel['controls'].policyIMEINumber.value;
        }
        break;
      case "agreementNumber":
        if (partnerId === PartnerOnBoardingConstants.PARTNER_ID_AEON) {
          onboardingValidationRequest[AppConstants.PARTNER_ID] = partnerId;
          onboardingValidationRequest[AppConstants.REQUEST_COMMANDS] = [AppConstants.VALIDATION_RULE_OBV_003];
          onboardingValidationRequest[AppConstants.REQUEST_OPERATOR] = AppConstants.OPERATOR_AND;
        }
        onboardingFields[AppConstants.REQUEST_AGREEMENT_NUMBER] = control.value;
        break;
      case "customerId":
        if (partnerId === PartnerOnBoardingConstants.PARTNER_ID_AEON) {
          onboardingValidationRequest[AppConstants.REQUEST_COMMANDS] = [AppConstants.VALIDATION_RULE_OBV_005];
          onboardingValidationRequest[AppConstants.REQUEST_OPERATOR] = AppConstants.OPERATOR_AND;
        }
        onboardingFields[AppConstants.ONBOARDING_FIELD_CUSTOMER_ID] = control.value;
        break;
    }
    onboardingValidationRequest[AppConstants.REQUEST_ONBOARDING_FIELDS] = onboardingFields;
    return onboardingValidationRequest;
  }

  performPostValidationOperations(control: AbstractControl) {
    const controlName = (Object.keys(control.parent.controls).find(key => control.parent.controls[key] === control));
    switch (controlName) {
      case "policyIMEINumber":
        this.setDeviceDetailslUsingIMEI(control.value);
        break;
    }
  }

  performPostValidationFailureOp(control: AbstractControl) {
    const controlName = (Object.keys(control.parent.controls).find(key => control.parent.controls[key] === control));
    switch (controlName) {
      case "policyIMEINumber":
        if (!control.pristine) {
          this.resetDeviceDetails();
        }
        break;
    }
  }

  extractMsgFromError(response: any, control: AbstractControl, validationRequest: any) {
    const controlName = (Object.keys(control.parent.controls).find(key => control.parent.controls[key] === control));
    let ruleName = validationRequest['commands'] && validationRequest['commands'][0];
    let controlError = (response.obj && response.obj.validationFailures &&
                        response.obj.validationFailures[controlName]
                       && response.obj.validationFailures[controlName][0]);
    let ruleError =  ruleName && response.obj && response.obj.validationFailures &&
                      response.obj.validationFailures[ruleName];
    if (controlError || (ruleError && ruleError[0])) {
      let errStatus, errMsg;
      errStatus = controlError ? controlError.validationFailureCode : null;
      errStatus = (ruleError && ruleError[0] && !errStatus) ? ruleError[0].validationFailureCode : errStatus;
      if (errStatus === "200.0405" || errStatus === "200.0400") {
        errMsg = controlError ? controlError.validationFailureReason : null;
        errMsg = (ruleError && !errMsg) ? ruleError[0].validationFailureReason : errMsg;
        this.controlErrorMsg = errMsg;
        control.setErrors({ 'validationErrors': true });
        this.performPostValidationFailureOp(control);
      }
    } else {
      control.setErrors(null);
      this.performPostValidationOperations(control);
    }
  }

  onboardingAbstractControlvalidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value != undefined && control.value != '') {
      let validationRequest = this.setOnboardingValidationrequest(control);
      this.policyService.validateOnBoardingField(validationRequest).subscribe((response) => {
        if (response && response.status === "OK") {
          this.extractMsgFromError(response, control, validationRequest);
        }
      })
    }
    return null;
  }

  resetDeviceDetails() {
    //Resetting values if already set
    let makeControl = this.partnerOnboardingForm['controls'].deviceDetailPanel['controls'].deviceMake;
    let modelControl = this.partnerOnboardingForm['controls'].deviceDetailPanel['controls'].deviceModel;

    //Resetting values
    if (makeControl.value != null || makeControl.value != undefined) {
      makeControl.setValue(null);
      this.partnerPolicy.deviceMake = null;
    }
    if (modelControl.value != null || makeControl.value != undefined) {
      modelControl.setValue(null);
      this.partnerPolicy.deviceModel = null;
    }
    this.partnerPolicy.deviceRRP = '0';
  }
  resetDevicePanelErrors() {
    this.partnerOnboardingForm['controls'].deviceDetailPanel['controls'].policyIMEINumber.setErrors(null);
    this.partnerOnboardingForm['controls'].deviceDetailPanel['controls'].deviceSerialNo.setErrors(null);
  }

  setDeviceDetailslUsingIMEI(imeiNumber: string) {
    this.mdmService.findDeviceDetailsByImei(imeiNumber).subscribe((response) => {
      if (response && response.status == 'OK') {
        let element = response.obj[0];
        if ((element != null || element != undefined) && element.tacMake != '' && element.tacModel != '') {
          let makeControl: AbstractControl = this.partnerOnboardingForm['controls'].deviceDetailPanel['controls'].deviceMake;
          let modelControl: AbstractControl = this.partnerOnboardingForm['controls'].deviceDetailPanel['controls'].deviceModel;
          makeControl.setValue(element.tacMake.trim());
          modelControl.setValue(element.tacModel.trim());
          makeControl.markAsDirty();
          modelControl.markAsDirty();
          this.partnerPolicy.deviceMake = element.tacMake.trim();
          this.partnerPolicy.deviceModel = element.tacModel.trim();
          let currentPartner = this.service.getCurrentLoggedInPartner();
          if (currentPartner === PartnerOnBoardingConstants.PARTNER_ID_DIGI)
            this.partnerPolicy.deviceRRP = element.proposedRrp;
        } else {
          this.resetDeviceDetails();
        }
      }
    })
  }

  isPanelIncluded(panel: string) {
    let currentPanel = this.partnerOnboardingConfigModel.configComponents.filter((x) => {
      x.value === panel;
    })
    return currentPanel != null || currentPanel != undefined;
  }

  fetchHeaders(onBoardingScreenNumber) {
    return this.partnerOnboardingConfigModel.configComponents[onBoardingScreenNumber].headerLabel;
  }
  isPanelToBeShown(panelInfo: string, onBoardingScreenNumber: number) {
    let panelList = this.partnerOnboardingConfigModel.configComponents.map((x) => x.value);
    return panelList.includes(panelInfo) && (panelInfo === panelList[onBoardingScreenNumber])
  }

  fetchActionCommand(actionCommand: string, onBoardingScreenNumber: number) {
    let result
    switch (actionCommand) {
      case 'NEXT':
        result = this.partnerOnboardingConfigModel.configComponents[onBoardingScreenNumber].forwardActionCommand;
        break;
      case 'BACK':
        result = this.partnerOnboardingConfigModel.configComponents[onBoardingScreenNumber].previousActionCommand;
        break;
    }
    return result;
  }

  fetchActionLabel(actionCommand: string, onBoardingScreenNumber: number) {
    let result
    switch (actionCommand) {
      case 'NEXT':
        result = this.partnerOnboardingConfigModel.configComponents[onBoardingScreenNumber].forwardActionLabel;
        break;
      case 'BACK':
        result = this.partnerOnboardingConfigModel.configComponents[onBoardingScreenNumber].previousActionLabel;
        break;
    }
    return result;
  }

  fetchActionhandlers(onBoardingScreenNumber: number) {
    return this.partnerOnboardingConfigModel.configComponents[onBoardingScreenNumber].forwardActionHandlers;
  }

  fetchPartnerProductCodes() {
    let currentPartner = this.service.getCurrentLoggedInPartner();
    switch (currentPartner) {
      case PartnerOnBoardingConstants.PARTNER_ID_DIGI:
        return PolicyConstants.PARTNER_PRODUCT_CODE_DIGI;
      case PartnerOnBoardingConstants.PARTNER_ID_AEON:
        return PolicyConstants.PARTNER_PRODUCT_CODE_AEON;
      case PartnerOnBoardingConstants.PARTNER_ID_DIGI_SHIELD:
        return PolicyConstants.PARTNER_PRODUCT_CODE_DIGI_SHIELD;
    }
  }

  fetchDisclaimerLabel(labelType) {
    let currentPartner = this.service.getCurrentLoggedInPartner();
    let resultString;
    switch (currentPartner) {
      case PartnerOnBoardingConstants.PARTNER_ID_DIGI:
        switch (labelType) {
          case 'storeDisclaimerLabel':
            resultString = PartnerOnBoardingConstants.AMTRUST_COMPANY_LABEL;
            break;
          case 'customerDisclaimerLabel':
            resultString = PartnerOnBoardingConstants.AMTRUST_DISCLAIMER_LABEL;
            break;
        }
        break;
      case PartnerOnBoardingConstants.PARTNER_ID_AEON:
        switch (labelType) {
          case 'storeDisclaimerLabel':
            resultString = PartnerOnBoardingConstants.BOLTECH_COMPANY_LABEL;
            break;
          case 'customerDisclaimerLabel':
            resultString = PartnerOnBoardingConstants.BOLTECH_DISCLAIIMER_LABEL;
            break;
        }
        break;
    }
    return resultString;
  }

  performLuhnCheck(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value != undefined && control.value != '') {
      let validationRequest = this.setOnboardingValidationrequest(control);
      this.policyService.validateOnBoardingField(validationRequest).subscribe((response) => {
        if (response && response.status === "OK") {
          let validationFailures = response.obj.validationFailures;
          if(validationFailures && validationFailures != null) {
            this.controlErrorMsg = validationFailures["policyIMEINumber"][0]["validationFailureReason"];
            control.setErrors({ 'validationErrors': true });
          }
        }
      })
    }
    return null;
  }
}
