import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppService} from '../app.service';
import {codes} from 'country-calling-code';
import { AppConstants } from 'src/app/app.constants';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  header: any;
  checkerApprovingRights = AppConstants.CHECKER_APPROVING_RIGHTS;

  constructor(private http: HttpClient) {
    this.header = AppService.apiKeyHeaders();
  }

  getCountry(countryCode: string):any{
    return codes.find(c => c.countryCodes[0] === countryCode);
  }

  checkIfFutureDate(dt, time) {
    let currentDateTime = new Date();
    if (dt && time) {
      let timeDetails = time.split(":");
      dt.setHours(Number(timeDetails[0]));
      dt.setMinutes(Number(timeDetails[1]));

      return +dt > +currentDateTime;
    }

    return false;
  }

  checkIfPastDate(dt, time) {
    let currentDateTime = new Date();
    if (dt && time) {
      let timeDetails = time.split(":");
      dt.setHours(Number(timeDetails[0]));
      dt.setMinutes(Number(timeDetails[1]));

      return +dt < +currentDateTime;
    }

    return false;
  }

  checkerDeptNoModuleWise(moduleName:string){
    let departmentNos = [];
    this.checkerApprovingRights.filter(function(obj){  
      if(obj.module==moduleName){
        departmentNos.push(obj.departmentNumber);
      }
    })
    return departmentNos;
  }
}
