import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.css']
})
export class InputDialogComponent implements OnInit {

  title:string;
  inputContent:string;
  placeholder:string;
  subtitle:string;

  constructor(
    public dialogRef: MatDialogRef<InputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any) {
  }

  ngOnInit() {
    this.title = this.inputData.title;
    this.inputContent = this.inputData.inputContent;
    this.placeholder = this.inputData.placeholder;
    this.subtitle = this.inputData.subtitle;
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  submit(){
    this.dialogRef.close(this.inputContent);
  }
}
