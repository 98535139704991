import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppService } from '../app.service';
import { AppConstants } from '../app.constants';
import { ProductConstants } from '../product/product.constants';
import Utils from 'src/app/utility/utils';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  header: any;
  private productUrl = environment.productUrl + 'products/VN_HC_NA_ADLD';

  constructor(private http: HttpClient) {
    //IFS-559 - Remove the local storage x-api-key
    this.header = '';
  }

  getProductList(productSearchCriteria): Observable<any> {
    const url = environment.productUrl + 'products/search';
    return this.http.post(url, productSearchCriteria, this.header);
  }

  getProducts(): Observable<any> {
    return <any>this.http.get(this.productUrl, this.header);
  }

  getTypeValues(typeCode: any): Observable<any> {
    return <any>this.http.get(environment.productUrl + 'products/typeValues/' + typeCode, this.header);
  }

  getPackageProduct(clientCode: any): Observable<any> {
    return <any>this.http.get(environment.productUrl + 'products/packageProductName/' + clientCode, this.header);
  }

  getTypeValuesByGroupCode(typeGroupCode: any): Observable<any> {
    return <any>this.http.get(environment.productUrl + 'products/typeValuesByGroupCode/' + typeGroupCode, this.header)
  }

  getTypeDescriptionValues(typeValue: string[]): Observable<any> {
    const body = { typeValuesList: typeValue };
    return this.http.post(environment.productUrl + 'products/typeDescriptionValues', body, this.header)
  }

  getProduct(id: number): Observable<any> {
    const url = `{productUrl}/${id}`;
    return <any>this.http.get(url, this.header);
  }

  getAutcompleteAdress(searchKey: string, country: string): Observable<any> {
    return <any>this.http.get(environment.productUrl + 'address/autoComplete/' + country + "?searchKey=" + searchKey, this.header);
  }

  getAddressDetails(placeId: string, country: string): Observable<any> {
    return <any>this.http.get(environment.productUrl + 'address/details/' + country + '?placeId=' + placeId, this.header);
  }

  findProductByPartnerid(partnerid: string): Observable<any> {
    return <any>this.http.get(environment.productUrl + 'products/products-by-partnerid/' + partnerid, this.header);
  }

  getProductRuleByProductCode(productCode: string): Observable<any> {
    return <any>this.http.get(environment.productUrl + '/productrules/rule?productCode=' + productCode, this.header);
  }

  getCountryList(): Observable<any> {
    return <any>this.http.get(environment.productUrl + 'countries/', this.header);
  }

  getCountryListByContinent(continent: Array<String>): Observable<any> {
    return <any>this.http.get(environment.productUrl + 'countries/find-by-continent?continent=' + continent, this.header);
  }

  findApprovalCountriesByApprover(approverName: string): Observable<any> {
    return <any>this.http.get(environment.productUrl + `countries/findApprovalCountries/${approverName}`, this.header);
  }

  async findUserCreationApprovalByCountries(countries: string[]): Promise<any> {
    return await this.http.get(environment.productUrl + 'countries/findUserCreationApproval?countries=' + countries, this.header).toPromise();
  }

  getProductRules(): Observable<any> {
    return <any>this.http.get(environment.productUrl + 'productrules/', this.header);
  }

  findClientPartnerDetails(productSearch): any {
    return this.http.get(environment.productUrl + 'clients/', {
      params: productSearch
    });
  }

  async findClientDetails(productSearch): Promise<any> {
    return await this.http.get(environment.productUrl + 'clients/', {
      params: productSearch
    }).toPromise();
  }

  createClient(clientDocument): Observable<any> {
    return this.http.post(environment.productUrl + 'clients/create', clientDocument, this.header);
  }

  createProduct(product): Observable<any> {

     if(!Array.isArray(product.deviceType)){
          let deviceType = product.deviceType.split(',');
          product.deviceType = deviceType;
     }

    return this.http.post(environment.productUrl + 'products/createOrUpdate', product, this.header);
  }

  updateProductByCheckerMaker(product): Observable<any> {
    product.createdBy = localStorage.getItem(AppConstants.LOGGED_IN_USER);
    product.createdByEmail = localStorage.getItem(AppConstants.MAIL);
    product.partnerId = product.client.partnerId;
    product.requestedAt = Utils.getCurrentDateTime();
    product.countryCode = product.client.countryCode;
    if(!Array.isArray(product.deviceType)){
         let deviceType = product.deviceType.split(',');
         product.deviceType = deviceType;
    }
   return this.http.post(environment.productUrl + 'products/createOrUpdate', product, this.header);
 }

 makerRequestsToChecker(makerCheckerSearch) {
  let endpointUrl = environment.makerCheckerUrl;
  endpointUrl = endpointUrl + 'checker/requests'
  return <any>this.http.get(endpointUrl, {

    headers: new HttpHeaders({
      'x-api-key': localStorage.getItem(AppConstants.JWT_TOKEN)
    }),
    params: makerCheckerSearch
  })
}

  findProductByProductName(productName): Observable<any>{
    return this.http.get(environment.productUrl + 'products/findProductByProductName?productName=' + productName,
      this.header);
  }

  createPlan(planList): Observable<any> {
    return this.http.post(environment.productUrl + 'products/plans/add', planList, this.header);
  }

  editPlan(plan): Observable<any> {
    return this.http.post(environment.productUrl + 'products/plan/edit', plan, this.header);
  }

  bulkUpdatePlans(request): Observable<any> {
    return this.http.post(environment.productUrl + 'products/plan/bulkUpdate', request, this.header);
  }

  createClientDevices(clientDeviceDocument): Observable<any> {
    return this.http.post(environment.productUrl + 'clients/devices/create', clientDeviceDocument, this.header);
  }

  uploadClientDevices(clientDeviceUploadRequest): Observable<any> {
    return this.http.post(environment.productUrl + 'clients/devices/upload', clientDeviceUploadRequest, this.header);
  }

  findPartnerOnboardingConfig(partnerId: string): Observable<any> {
    return this.http.get(environment.productUrl + 'clients/config/' + partnerId, this.header);
  }

  getProductByFilter(filter): Observable<any> {
    const url = environment.productUrl + 'products/product-by-filtered-data';
    return this.http.post(url, filter, this.header);
  }

  uploadPlan(planUploadRequest): Observable<any> {
    return this.http.post(environment.productUrl + 'products/plans/upload', planUploadRequest, this.header);
  }

  fetchPlanUploadSummary(taskId: string): Observable<any> {
    return this.http.get(environment.productUrl + 'products/plans/upload/' + taskId, this.header);
  }

  getInsuranceProducts(clientId): Observable<any> {
    return <any>this.http.get(environment.productUrl + 'products/insuranceProducts/' + clientId,this.header);
  }

  getAeonProductsBySkuCode(skuCode): Observable<any> {
    return <any> this.http.get(environment.productUrl + 'products/aeonProducts?sku=' + skuCode,this.header);
  }

  getProductOptions(packageProductName): Observable<any>{
    return <any>this.http.get(environment.productUrl + 'products/productOptions/' + packageProductName,this.header);
  }

  getplanUploadSummary(planUploadSearchCriteria): Observable<any> {
    const url = environment.productUrl + 'products/plans/upload/search';
    return this.http.post(url, planUploadSearchCriteria, this.header);
  }

  getTypeValuesByCodes(typeCodes:string[]): Observable<any> {
    return <any>this.http.get(environment.productUrl + 'products/typeValuesByCodes/?typeCodes=' + typeCodes, this.header);
  }

  getAllActiveDigiPackages(): Observable<any> {
    return <any>this.http.get(environment.productUrl + 'products/digi/packages/', this.header);
  }

  getDigiProductsBasedOnSuppliedPackage(packageId:string): Observable<any> {
    return <any>this.http.get(environment.productUrl + 'products/digi/products/'+packageId, this.header);
  }

  getAllActiveDigiSubProductsBasedOnSuppliedDigiProductId(productId:string): Observable<any> {
    return <any>this.http.get(environment.productUrl + 'products/digi/subProducts/'+productId, this.header);
  }

  getAllActiveCrmzProductwithPlansBasedOnProductCode(crmzProductCode:string): Observable<any> {
    return <any>this.http.get(environment.productUrl + 'products/digi/digiCrmzProducts/'+crmzProductCode, this.header);
  }

  getAllActiveDigiShieldDevices(productCoverage:string [], partnerId:string): Observable<any> {
    return <any>this.http.get(environment.productUrl + 'clients/devices/'+partnerId+'?coverage='+productCoverage, this.header);
  }

  isBatteryReplacementCoveredInPlan(productId:string): Observable<any> {
    return <any>this.http.get(environment.productUrl + 'products/plans/checkBatteryReplacementCovered/'+productId, this.header);
  }

  onSyncPartnerDocument(documentSearchCriteria): Observable<any> {
    return this.http.post(environment.productUrl + 'docManagement/sync', documentSearchCriteria, this.header);
  }

  findPartnerDocumentList(documentSearchCriteria): Observable<any> {
    return this.http.post(environment.productUrl + 'docManagement/search', documentSearchCriteria, this.header);
  }

  updateDocument(documentUpdateCriteria): Observable<any> {
   return this.http.post(environment.productUrl + 'docManagement/', documentUpdateCriteria, this.header);
  }

  getProductByProductCode(productCode: string): Observable<any> {
    return <any>this.http.get(environment.productUrl + 'products/' + productCode, this.header);
  }

  getProductByClientProductName(clientProductName: string): Observable<any> {
    return <any>this.http.get(environment.productUrl + 'products/product-by-client-product-name/' + clientProductName, this.header);
  }

  getStoreLocations(partnerId: string): Observable<any> {
    return <any>this.http.get(environment.productUrl + 'products/getStoreLocations/' + partnerId, this.header);
  }

  updateInsurerId(insurerIdRequest): Observable<any>{
    return this.http.put(environment.productUrl + 'products/plan/updateInsurerId',insurerIdRequest,this.header);
  }

  searchNotificationEcert(productSearchCriteria): Observable<any>{
    const url = environment.productUrl + 'v1/notifEcert/search';
    return this.http.post(url, productSearchCriteria, this.header);
  }

  updateNotificationConfiguration(notificationConfiguration): Observable<any>{
    const url = environment.productUrl + 'v1/notifEcert/update';
    return this.http.post(url, notificationConfiguration, this.header);
  }

  async getProductPlanIdByPartnerId(partnerId: string): Promise<any> {
    return await this.http.get(environment.productUrl + 'products/productPlanIdByPartnerId/' + partnerId, this.header).toPromise();
  }

  getNotificationEcert(notificationConfiguration):Observable<any>{
    const url = environment.productUrl + 'v1/notifEcert';
    return this.http.post(url, notificationConfiguration, this.header);
  }

  findTrendingProducts(partnerId):Observable<any>{
    return <any>this.http.get(environment.productUrl + 'products/trendingProducts?partnerID='+partnerId, this.header);
  }

  updateTrendingProduct(formData): Observable<any> {
    return this.http.post(environment.productUrl + 'products/createOrUpdateTrendingProducts', formData, this.header);
  }
}
