import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { RoleMap } from '../model/roles.map';
import { AppConstants } from '../app.constants';
import { Groups } from '../model/groups';
import { RoleEnum } from '../model/roles.enum';
import {HasPermissions} from '../common-services/hasPermissions';

@Directive({
  selector: '[hideIfUnauthorized]'
})
export class HideUnauthorizedDirective implements OnInit {
  @Input() permission: String; // Required permission passed in
  @Input() module: String;
  @Input() gidNumber: String;
  @Input() postalAddress: String;

  constructor(private el: ElementRef, private hasPermissions: HasPermissions) {
  }

  ngOnInit() {
    let isPermissionGranted = this.hasPermissions.hasPermissions(this.module, this.permission, this.gidNumber, this.postalAddress);
    if (isPermissionGranted != undefined && isPermissionGranted) {
      return;
    } else if (isPermissionGranted != undefined && !isPermissionGranted) {
      this.el.nativeElement.style.display = AppConstants.NONE;
    }
  }
}
