import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppConstants } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) { }

  searchCustomer(customerData) {
    return <any>this.http.get(environment.customerServiceUrl + "/find", {
      headers: new HttpHeaders({
        'x-api-key': localStorage.getItem(AppConstants.JWT_TOKEN)
      })
      , params: customerData
    });
  }

  updateCustomer(customerData) {
    return <any>this.http.post(environment.customerServiceUrl + "/update",
      customerData, {
      headers: new HttpHeaders({
        'x-api-key': localStorage.getItem(AppConstants.JWT_TOKEN)
      })
    });
  }
}
