import { WebcamImage } from 'ngx-webcam';
import { AppConstants } from '../app.constants';
import RefundDetail from './policy.document.refundDetail';
import PaymentDetail from "./policy.document.paymentDetail";

/**
 * Policy module model data
 */
export class PolicyDocument {
  custAddress?: any;
  policyStatusCode?: string;
  coverage?: string;
  cancelReason?: string;
  cancelBackedDateOn?:Date;
  cancellationNote?:string=AppConstants.POLICY_CANCELLATION_DEFAULT_NOTE;
  activationReason?: string;
  overrideCancellationRule:boolean=false;
  overrideCancellationRuleSelection:number = 2;
  overrideRefundRule:boolean=false;
  clientProductDescription?;
  //Client Transaction Number
  clientTransactionNo?: string;
  //Policy Sold Date
  policyPurchaseDate?: string;
  //SKU of AMS product. E.g. Product name by Brand
  productName?: string;
  //Customer Name
  customerName?: string;
  //Customer Identification Number (IC/Passport)
  custIdNumber?: string;
  //Customer Identification Type
  idType?: string;
  //Mobile number with country code (no +,-,spaces)
  custMobileNumber?: string;
  //Customer Email
  customerEmail?: string;
  //Customer Address
  salesDocument?: string;

  //Customer City
  customerCity?: string;
  //Store Code
  storeCode?: string;
  //Store Name
  storeName?: string;
  //Store Address
  storeAddress?: string;
  //Store City
  storeCity?: string;
  //Sales Representative Id
  salesRepId?: string;
  // Sales Representative Name
  salesRepName?: string;
  //Device Retail Price
  deviceRRP?: string;
  //Balance RRP
  balanceRRP?: string;
  //Device Type covered Under Policy
  deviceType?: string;
  //Device Make
  deviceMake?: string;
  //Device Model
  deviceModel?: string;
  //Device Color
  deviceColor?: string;
  //Policy IMEI Number
  policyIMEINumber?: string;
  //Product Type For Which Policy Is Purchased
  nameGoodsType?: string;
  //Policy Start Date
  policyStartDate?: string;
  //Policy End Date
  policyEndDate?: string;
  //Policy Cancellation Date
  cancellationDate?:string;
  //Device Policy Sum Assured value
  deviceValueSumAssured?: string;
  //Policy Validation Failure Logs
  validationFailuresLogs?: any;
  // Who updated?
  updatedBy?: string;
  loggerEmailId?: string;
  productsModel?: any;
  policyStatus?: string;
  policyNumber?: string;
  brokerContractNum?: string;
  policyTenure?: string;
  customerLastName?: string;
  countryCode?: string;
  deviceSoldDate?: string;
  partnerId?: string;
  clientKey?: string;
  partnerProductId?: string;
  isSamsungClaimPower?: boolean;
  partnerContractId?: string;
  creationDate?: string;
  createdBy?: string;
  orderId?: string;
  deviceMemory?: string;
  custPostalCode?: string;
  customerState?: string;
  deviceSerialNo?: string;
  storeState?: string;
  storePromotion?: string;
  deviceCapacity?: string;
  invoiceNo?: string;
  ecertUrl?: string;
  postalCode?: string;
  deviceFrontImage?: string;
  deviceBackImage?: string;
  retailerName?: string;
  consumerFinanceName?: string;
  customerId?: string;
  custAlternativeMobileNumber?: string;
  partnerProductCode?: string;
  agreementNumber?: string;
  valueAddedServices?: string[];
  legacyValueAddedServices?: string[];
  onsiteClaimRecord?: string;
  sku?: string = '';
  gracePeriodStartDate?: string;
  gracePeriodEndDate?: string;
  gracePeriodExtensionReason?:string;
  deviceDiagnosticResponse?: any;
  productCode?:string;
  internalProductId?: string;
  isDummyCustomer?:boolean;
  brokerOrderId?:string;

  lastResentEmailDateTime?: string;
  lastRegenEcertDateTime?: string;

  lastResentSmsDateTime?:string;
  lastPatchedDeviceRRPDateTime?: String;

  onHoldStartDate?: string;
	onHoldEndDate?: string;
  refundDetail?: RefundDetail;
  paymentType?: string;
  paymentDetail?: PaymentDetail;
  cancellationRequestedDate?: string;
  //email logs
  emailLogs: PolicyEmailLogs[];
  smsLogs: PolicySmsLogs[];
  partnerPolicies?:any;

  //Vas Field
  isVasPolicy?: any;
  packageType?: any;

  //Consent
  declarationAgreement?:boolean;
  consentAgreement?:boolean;

  renewedTimes?:number = 0;
  lastRenewalDate?:string;
  nextRenewalDate?:string;
  mobilePhonePrefix?:string;
  migrationToPartner?: any;
  parentPolicyNumber?:string;
  coverages: [];
  swapOtherModelYN?:boolean;
  cancellationDateByAPI?:string;
  inactivationDate?:string;
  updateTime?:string = "";
  reasonForChange: string = '';
  voucherCode?:string;
  deviceCondition?:string;
  partnerSku?:string;
  crmzUI?:boolean;
  isPBA?:boolean;

  additionalInfo?: AdditionalInfo;

  coverageADLDBalanceRRP: string = '';
  coverageEWBalanceRRP: string = '';

  tpPaymentRef: string = '';
  tseEoDetails: object = {};

  triggerGracePeriodExtensionLogicForSamsungAsia: boolean = false;
  paymentId: string = '';
  paymentDate: string = '';
  paymentAmount: number = 0;

  static newPolicyDocument(): PolicyDocument {
    const policyDocumentObj: PolicyDocument = new PolicyDocument();
    return policyDocumentObj;
  }
}

export interface PolicyEmailLogs {
   date: string;
   dateTime?: Date;
   recipientEmail: Array<string>;
   ccEmail: Array<string>;
   bccEmail: Array<string>;
   mailStatus: string;
   ecertUrl: string;
   smtpServer: string;
   emailMethod: string;
   subject: string;
   keyName: string;
   isLatestWelcomeEmail: boolean;
   isSESFailed: boolean;
}

export interface PolicySmsLogs {
   date: string;
   dateTime: Date;
   recipientMobileNo: string;
   status: string;
   content: string;
}

export interface EcertLogs {
  insurerPolicyNumber?: string;
  ecertLink?: string;
  ecertLink2?:string;
  coverage?: string;
  insurerPartner?: string;
  policyStartDate?: Date;
  policyEndDate?: Date;
  policyEffectiveDate?:Date;
  policyExpiredDate?:Date;
  insurerPolicyStatus?:string;
  cancellationFailReason?:string;
  cancellationSuccessDate?:string;
}

export interface AdditionalInfo {
  premiumAmt: string,
  commissionAmt: string,
  deductibleAmt: string,
  premiumRefundAmt: string,
  commissionRefundAmt: string,
  endorsements: Array<Endorsement>
}

export interface Endorsement {
  endorsementNo: string,
  endorsementDate: string,
  customerName: string,
  customerMobileNumber: string,
  customerEmail: string,
  customerAddress: string,
  customerCity: string,
  deviceRRP: string,
  deviceMake: string,
  deviceModel: string,
  deviceColor: string,
  imeiNumber: string,
  serialNo: string
}
