import packageInfo from '../../package.json';
import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { LoginService } from './components/user/login/login.service';
import { Router, NavigationStart } from '@angular/router';
import { AppConstants } from './app.constants';
import { BnNgIdleService } from 'bn-ng-idle';
import { AppService } from './app.service';
import { ToastrService } from 'ngx-toastr';
import { ResponseObject } from './model/response.claim.object.js';
import { AppRoutingConstants } from './app-routing.constants';
import { WebSocketAPI } from './websockets/webSocketAPI';
import { WebSocketTopics } from './websockets/websocket.topics.constants';
import { PartnerOnBoardingConstants } from './partner/partner-onboarding.constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})
export class AppComponent implements AfterContentChecked, OnInit {


  appVersion: string;
  title = AppConstants.TITLE;
  showTabs: boolean = false;
  showPolicyTab: boolean = false;
  showClaimTab: boolean = false;
  showProductTab: boolean = false;
  showUserManagementTab: boolean = false;
  showPartnersTab: boolean = false;
  showAppLandingPage: boolean = true;
  showDigiOnboardingApp: boolean = false;
  showDigiFailedOnboarding: boolean = false;
  showMYOnboardingApp: boolean = false;
  userName: string;
  resetPasswordRequired: boolean = false;
  responseList: any = {
    headers: Object,
    body: Object,
    statusCodeValue: '',
    statusCode: ''
  }
  response: ResponseObject;
  show: boolean;
  text: string;



  constructor(private router: Router, private toastr: ToastrService, private loginService: LoginService,
    private bnIdle: BnNgIdleService, private appService: AppService, private webSocketAPI: WebSocketAPI) {
    this.show = false;
    this.text = "close";
    this.appVersion = packageInfo.version;
    this.bnIdle.startWatching(3600).subscribe((res) => {
      if (res) {
        this.toastr.info('Session Expired', '', {
          closeButton: true,
          positionClass: 'toast-top-center',
          timeOut: 600000
        });
        this.logout();
      }
    });
    this.loginService.showTabs.subscribe(res => {
      this.showTabs = res === AppConstants.TRUE;
      this.userName = localStorage.getItem(AppConstants.LOGGED_IN_USER);
    });
    this.loginService.showPolicyTab.subscribe(res1 => {
      this.showPolicyTab = res1 === AppConstants.TRUE;
    });
    this.loginService.showClaimTab.subscribe(res2 => {
      this.showClaimTab = res2 === AppConstants.TRUE;
    });
    this.loginService.showProductTab.subscribe(res2 => {
      this.showProductTab = res2 === AppConstants.TRUE;
    });
    this.loginService.showUserManagementTab.subscribe(res2 => {
      this.showUserManagementTab = res2 === AppConstants.TRUE;
    });
    this.loginService.showPartnersTab.subscribe(res4 => {
      this.showPartnersTab = res4 === AppConstants.TRUE;
    });
    this.loginService.showAppLandingPage.subscribe(res5 => {
      this.showAppLandingPage = res5 === AppConstants.TRUE;
    });
    this.loginService.showDigiFailedOnboarding.subscribe(res6 => {
      this.showDigiFailedOnboarding = res6 === AppConstants.TRUE;
    });
    this.loginService.showDigiOnboardingApp.subscribe(res7 => {
      this.showDigiOnboardingApp = res7 === AppConstants.TRUE;
    });
    this.loginService.showMYOnboardingApp.subscribe(res8 =>{
      this.showMYOnboardingApp = res8 === AppConstants.TRUE;
    })
    this.routeToForgotPasswordScreen();
    this.routeToCheckerMakerScreen();
  
  }


  ngAfterContentChecked() {
    if (localStorage.getItem(AppConstants.LOGGED_IN_USER)) {
      this.userName = localStorage.getItem(AppConstants.LOGGED_IN_USER);
    }
    if (localStorage.getItem(AppConstants.ENABLE_LOGIN)) {
      this.showTabs = localStorage.getItem(AppConstants.ENABLE_LOGIN) == AppConstants.TRUE;
    }
    if (localStorage.getItem(AppConstants.ENABLE_POLICY_TAB)) {
      this.showPolicyTab = localStorage.getItem(AppConstants.ENABLE_POLICY_TAB) == AppConstants.TRUE;
    }
    if (localStorage.getItem(AppConstants.ENABLE_CLAIM_TAB)) {
      this.showClaimTab = localStorage.getItem(AppConstants.ENABLE_CLAIM_TAB) == AppConstants.TRUE;
    }
    if (localStorage.getItem(AppConstants.ENABLE_PRODUCTS_TAB)) {
      this.showProductTab = localStorage.getItem(AppConstants.ENABLE_PRODUCTS_TAB) == AppConstants.TRUE;
    }
    if (localStorage.getItem(AppConstants.ENABLE_USER_MANAGEMENT_TAB)) {
      this.showUserManagementTab = localStorage.getItem(AppConstants.ENABLE_USER_MANAGEMENT_TAB) == AppConstants.TRUE;
    }
    if (localStorage.getItem(AppConstants.ENABLE_PARTNERS_TAB)) {
      this.showPartnersTab = localStorage.getItem(AppConstants.ENABLE_PARTNERS_TAB) == AppConstants.TRUE;
    }
    if(localStorage.getItem(AppConstants.PARTNER_ID)){
      var partnerId = localStorage.getItem(AppConstants.PARTNER_ID);
      if(PartnerOnBoardingConstants.PARTNER_ONBOARDING_MY_SERVICE_PORTAL.findIndex(value => value === partnerId) >= 0){
        this.loginService.showMYOnboardingApp.next(AppConstants.TRUE);
        this.loginService.showAppLandingPage.next(AppConstants.FALSE);
        this.loginService.showDigiFailedOnboarding.next(AppConstants.FALSE);
        this.loginService.showDigiOnboardingApp.next(AppConstants.FALSE);
      }
    }

  }

  logout() {
    this.appService.logout()
  }

  /**
  * Reset outletName of each tab DOM
  * content to null
  */
  resetRoute() {
    this.appService.clearLockSubscriptions();
  }

  toggleMenu() {
    this.text = this.text == "close" ? "open" : "close";
    this.show = this.show == false ? true : false;
  }

  isResetPasswordScreen() {
    let headersToBeHidden = localStorage.getItem(AppConstants.HIDE_HEADERS_TAB)
    return (headersToBeHidden == undefined || headersToBeHidden == null) || (headersToBeHidden === AppConstants.TRUE);
  }

  routeToForgotPasswordScreen() {
    let url = window.location.href;
    if (url.includes(AppRoutingConstants.FORGOT_PASSWORD_RESET) && url.includes(AppConstants.STAMP) && url.includes(AppConstants.USER_ATTR) && url.includes(AppConstants.userApiKey)) {
      let queryString = url.split('&');
      let queryMap = new Object();
      queryString.splice
      queryString.forEach((x) => {
        let paramParts = x.split("=");
        if (paramParts.length > 0) {
          queryMap[paramParts[0]] = paramParts[1];
        }
      })
      let currentuser = queryMap['requestUser'];
      let secretKey = queryMap['userAttr'];
      let hashedUrl = queryMap['stamp'];
      let apiKey = queryMap['apiKey']
      this.appService.validateMd5Hash(currentuser, secretKey, hashedUrl).subscribe(
        (res) => {
          if (res && res.status === AppConstants.SUCCESS_STATUS_IN_RESPONSE) {
            this.showTabs = true;
            localStorage.setItem(AppConstants.LOGGED_IN_USER, currentuser);
            localStorage.setItem(AppConstants.JWT_TOKEN, AppConstants.BLANK_STRING);
            this.router.navigate([AppRoutingConstants.CHANGE_PASSWORD], { queryParams: { 'isCalledFromForgotPasswordReset': true } });
          }
        }
      );
    }
  }

  routeToCheckerMakerScreen() {
    let url = window.location.href;
    if (url.includes(AppRoutingConstants.MAKER_CHECKER) && url.includes(AppConstants.P)) {
      this.showTabs = true;
    }
  }

  ngOnInit() {
    this.logoutFromAllTabs();
    this.continueAccess();
    //this.loadNotification();
  }

  continueAccess() {
    let hash = window.location.hash;
    const hour = 1;
    let loggedInAt = localStorage.getItem(AppConstants.ACCESSED_AT);
    const currentTime = this.appService.getCurrentTime();
    const diff = hour * 60 * 60 * 1000;
    if (loggedInAt && (currentTime - Number(loggedInAt) > diff)) {
      this.logout();
    } else {
      if (hash != "" && hash != "#/") {
        localStorage.setItem(AppConstants.ACCESSED_AT, '' + currentTime);
      }
    }
  }

  logoutFromAllTabs() {
    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        if (localStorage.length == 0) {
          window.location.replace(window.location.origin);
        }
      }
    }, false);
  }

  loadNotification() {
    let hash = window.location.hash;
    if (hash != "" && hash != "#/") {
      this.webSocketAPI.listOfNotification.subscribe(list => {
        this.responseList = list;
        if (this.responseList) {
          if (this.responseList.body == undefined) {
            this.webSocketAPI.connect(WebSocketTopics.topicOfClaimLogs, this.userName);
          }
        }
      });
    }
  }
}
