import { PolicyEnum } from '../model/policy.enum'

export class ProductConstants {
  public static STATUS_ACTIVE = 'ACTIVE';
  public static STATUS_INACTIVE = 'INACTIVE';
  public static RESPONSE_FAILED = "ERROR";
  public static RESPONSE_SUCCESS = "OK";

  public static CLIENT_NAME_IS_MANDATORY = 'Client Name is Mandatory Field.';
  public static DOCUMENT_TYPE_IS_MANDATORY = 'Document Type is Mandatory Field.';

  public static PACKAGE_TYPE_BUNDLED = 'BUNDLED';
  public static PACKAGE_TYPE_VAS = 'VAS';
  public static PACKAGE_TYPE_INSURANCE = 'INSURANCE';
  
  public static VAS_COVERAGE = ['TI','TS','RS','RL'];

}
