import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivityRequest } from './activity/models/activity.request';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  header: any;

  constructor(private http: HttpClient) {
    //IFS-559 - Removed the local storage x-api-key
    this.header = '';
  }

  saveActivityRequest(activityRequest: any): Observable<any> {
    return <any>this.http.post(environment.activityUrl, activityRequest, this.header);
  }

  getActivityConstants(activitType: string): Observable<any> {
    return <any>this.http.get(environment.activityUrl + 'constants?activitYType=' + activitType, this.header);
  }

  getActivityLog(relatedId: string, activityType: string): Observable<any> {
    return <any>this.http.get(environment.activityUrl + relatedId + '?activityType=' + activityType, this.header);
  }

  updateActivityRequest(activityRequest: ActivityRequest, activityId: string) {
    return <any>this.http.put(environment.activityUrl + activityId, activityRequest, this.header);

  }

  getConstantsRequest(activitYType: string): Observable<any> {
    return <any>this.http.get(environment.activityUrl + 'constants?activityType=' + activitYType, this.header);
  }

}
