export enum PolicyEnum {
  PARTNER_SAMSUNG_PHILLIPINES_RETAIL = 'PHSAMSUOEMNA01',
  PARTNER_SAMSUNG_PHILLIPINES_D2C = 'PHSAMSUOEMDG01',
  PARTNER_SAMSUNG_INDONESIA_RETAIL = 'IDSAMSUOEMNA01',
  PARTNER_SAMSUNG_INDONESIA_CF = 'IDSAMSUOEMCF01',
  PARTNER_SAMSUNG_INDONESIA_CE = 'IDSAMSUOEMCE01',
  PARTNER_SAMSUNG_PHILLIPINES_CF = 'PHSAMSUOEMCF01',
  PARTNER_SAMSUNG_VIETNAM_RETAIL = 'VNSAMSUOEMNA01',
  PARTNER_SAMSUNG_TSE = 'THSAMSUOEMNA01',
  PARTNER_MEMOEXPRESS_PHILIPPINES = 'PHMEMOXRETNA01',
  PARTNER_SAMSUNG_VIETNAM_RETAIL_GRAB = 'VNSAMSUOEMNA02',
  PARTNER_SAMSUNG_THAILAND_RETAIL = 'THSAMSUOEMNA01',
  PARTNER_SAMSUNG_TAIWAN_RETAIL = "TWSAMSUOEMNA01",
  HOME_CREDIT_INDONESIA = 'IDHOMCRCFINA01',
  INDOSAT = 'IDHOMCRCFINA01',//TODO change partnerId when available
  PARTNER_AUSTRIA_DREI = 'ATDREIATELNA01',
  PARTNER_AUSTRIA_MTEL = 'ATMTELPTELNA01',
  EDIT_LOCK_MESSAGE = 'Editing of policy',
  PARTNER_SAMSUNG_MALAYSIA_RETAIL="MYSAMSUOEMNA01",
  PARTNER_SAMSUNG_HONGKONG_RETAIL="HKSAMSUOEMNA01",
  PARTNER_SAMSUNG_BELGIUM="BESAMSUOEMNA01",
  PARTNER_SAMSUNG_NETHERLANDS="NLSAMSUOEMNA01",
  PARTNER_SAMSUNG_SWEDEN="SESAMSUOEMNA01",
  PARTNER_MOBISURE_MALAYSIA="MYMOBISINSNA01",
  PARTNER_SAMSUNG_NORWAY="NOSAMSUOEMNA01",
  PARTNER_SAMSUNG_DENMARK="DKSAMSUOEMNA01",
  PARTNER_SAMSUNG_FINLAND="FISAMSUOEMNA01",
  PARTNER_SHOPEE_THAILAND = "THSHOPEONLDG01",
  PARTNER_SAMSUNG_MACAU="MOSAMSURETNA01",
  PARTNER_SAMSUNG_MALAYSIA_TUNE = "MYSAMSUOEMNA02",
  PARTNER_SAMSUNG_SINGAPORE = "SGSAMSURETNA01",
  PARTNER_DIGI_MALAYSIA = 'MYDIGIMTELNA01',
  PARTNER_DIGI_SHIELD_MALAYSIA = 'MYDIGIMTELNA02',
  PARTNER_TMHGP_MALAYSIA = "MYTMHGPTELNA01",
  PARTNER_ASCENDSP_MALAYSIA = "MYASCENRETNA01",
  PARTNER_AEON_MALAYSIA ="MYAEONCRETCF01",
  PARTNER_TMUNIFI_MALAYSIA = "MYTMUNITELNA01",
  PARTNER_ULTIMATE_MALAYSIA = "MYULTIMRETNA01",
  PARTNER_AEON_RET_MALAYSIA = "MYAEONRRETNA01",
  PARTNER_ALLIT_MALAYSIA = "MYALLITRETNA01",
  PARTNER_TMNEWA_TAIWAN = "TWTMNEWFINNA01",
  PARTNER_MSIG_TAIWAN = "TWMINGTINSFI01",
  PARTNER_ASHITA_MALAYSIA = "MYASHITRETNA01",
  PARTNER_TG_FONE_THAILAND = "THTGFONRETNA01",
  PARTNER_TG_FONE_DIGI_THAILAND = "THTGFONRETDG01",
  PARTNER_JMART_THAILAND = "THJMARTRETNA01",
  PARTNER_LAZADA_THAILAND = "THLAZADONLNA01",
  PARTNER_FRANK_LINE_THAILAND = "THFRANKINSDG01",
  PARTNER_COM_SEVEN_THAILAND = "THCOMSERETNA01",
  PARTNER_SAMSUNG_B2B_BELGIUM="BESAMSUOEMNA02",
  PARTNER_SAMSUNG_B2B_NETHERLANDS="NLSAMSUOEMNA02",
  PARTNER_SAMSUNG_B2B_SWEDEN="SESAMSUOEMNA02",
  PARTNER_SAMSUNG_B2B_NORWAY="NOSAMSUOEMNA02",
  PARTNER_SAMSUNG_B2B_DENMARK="DKSAMSUOEMNA02",
  PARTNER_SAMSUNG_B2B_FINLAND = "FISAMSUOEMNA02",
  PARTNER_HK_FALCONFAITH = "HKFALCORETNA01",
  PARTNER_SAMSUNG_TUNE_MALAYSIA="MYSAMSUOEMNA02",
  PARTNER_ELECBOY="HKELECBRETNA01",
  PARTNER_EIR="IEEIRDPTELNA01",
  PARTNER_THREE_SWEDEN="SETRESWTELNA01",
  PARTNER_SINGTEL="SGSINGTTELNA01",
  PARTNER_FPT_VIETNAM = "VNFPTCOTELNA01",
  PARTNER_HOME_CREDIT_VIETNAM = "VNHOMCRCFINA01"
}
