import { Component, OnInit, AfterContentChecked  } from '@angular/core';
import { AppConstants } from '../../app.constants';
import { ResponseObject } from '../../model/response.claim.object';
import { LoginService } from '../user/login/login.service';
import { AppService } from '../../app.service';
import { WebSocketAPI } from '../../websockets/webSocketAPI';
import { MatDialog } from '@angular/material/dialog';
import { ClaimDocument } from '../../model/ClaimDocument';
import { ChangePasswordComponent } from '../user/change-password/change-password.component';
import { NotificationComponent } from '../notification/notification.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterContentChecked {
  title = AppConstants.TITLE;
  showPartnersTab: boolean = false;
  userName: string;
  resetPasswordRequired : boolean = false;
  responseList: any = {
    headers: Object,
    body: Object,
    statusCodeValue: '',
    statusCode: ''
  }
  response: ResponseObject={
    status: '',
    message: [],
    content: [],
    transactionId: '',
    timeStamp: '',
    code: ''
  }
  badgeCounter: Number;

  constructor(private loginService: LoginService,
     private appService: AppService,
    private webSocketAPI: WebSocketAPI, private dialog: MatDialog) {
    this.loginService.showTabs.subscribe(res => {
      this.userName = localStorage.getItem(AppConstants.LOGGED_IN_USER);
    });
    this.loginService.showPartnersTab.subscribe(res4 => {
      this.showPartnersTab = res4 === AppConstants.TRUE ? true : false;
    });
    this.webSocketAPI.listOfNotification.subscribe(list => {
      this.responseList = list;
      if (this.responseList) {
        this.response = this.responseList.body;
      }
      if (this.response && this.response.content) {
        let i: number = 0;
        for (let entry of this.response.content) {
          if (!((<ClaimDocument>entry).notificationRead && (<ClaimDocument>entry).notificationRead.length > 0
            && (<ClaimDocument>entry).notificationRead.includes(localStorage.getItem(AppConstants.LOGGED_IN_USER).toLowerCase()))) {
            i++;
          }
        }
        this.badgeCounter = i;
      }
    });
  }
  openChangePasswordModal() {
    this.dialog.open(ChangePasswordComponent);
  }

  ngAfterContentChecked() {
    if (localStorage.getItem(AppConstants.LOGGED_IN_USER)) {
      this.userName = localStorage.getItem(AppConstants.LOGGED_IN_USER);
    }
  }

  logout() {
    this.appService.logout()
  }

  openNotifications() {
    this.dialog.open(NotificationComponent, {
      data: this.response.content
    });
    this.badgeCounter = null;
  }

  showNotificationBadge(){
    return !localStorage.getItem(AppConstants.PARTNER_ID);
  }

}
