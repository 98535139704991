import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpParams, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppConstants } from '../app.constants';
import { PolicyEnum } from '../model/policy.enum';
import { AppService } from '../app.service';
import { EmailConfigs } from '../model/Notification/EmailConfigsModel';
import { PaginateSearch } from '../model/paginatedSearchModel';
import { EmailCollectionRequest, EmailCollectionSearch } from '../model/Notification/EmailCollection';

@Injectable({
  providedIn: 'root'
})
export class NotificationService{

  header: any;

  constructor(private http: HttpClient, private appService: AppService) {
    this.header = '';
  }

  searchEmailConfigs(paginateSearch:PaginateSearch):Observable<any>{
    return this.http.post(`${environment.notificationUrl}emailConfigs/search`,paginateSearch,this.header);
  }
  
  updateEmailConfigActive(emailConfig:EmailConfigs):Observable<any>{
    return this.http.post(`${environment.notificationUrl}emailConfigs/update/active`,emailConfig,this.header);
  }

  updateEmailConfigUseSMTP(emailConfig:EmailConfigs):Observable<any>{
    return this.http.post(`${environment.notificationUrl}emailConfigs/update/useSMTP`,emailConfig,this.header);
  }

  createOrInsertEmailConfig(emailConfig:EmailConfigs):Observable<any>{
    return this.http.post(`${environment.notificationUrl}emailConfigs/createOrUpdate`,emailConfig,this.header);
  }

  findEmailLogs(id: string, isClaim: boolean) {
    return <any>this.http.get(`${environment.notificationUrl}findEmailLogsById/${id}?isClaim=${isClaim}`,
      this.header);
  }

  findSmsLogs(id: string, isClaim: boolean) {
    return <any>this.http.get(`${environment.notificationUrl}findSmsLogsById/${id}?isClaim=${isClaim}`,
      this.header);
  }

  findNotificationTemplateList(emailTemplateSearchCriteria: any) {
    return <any>this.http.post(`${environment.notificationUrl}notificationTemplateConfig/search`, emailTemplateSearchCriteria, this.header);
  }

  updateNotificationTemplateConfig(emailTemplateConfig: any) {
    return <any>this.http.post(`${environment.notificationUrl}notificationTemplateConfig/update`, emailTemplateConfig, this.header);
  }

  createNotificationTemplateConfig(emailTemplateConfig: any) {
    return <any>this.http.post(`${environment.notificationUrl}notificationTemplateConfig/create`, emailTemplateConfig, this.header);
  }

  uploadImage(imageUploadRequest): Observable<any> {
    return this.http.post(environment.notificationUrl + 'notificationTemplateResource/upload', imageUploadRequest, this.header);
  }

  findNotificationTemplateResourceList(notificationTemplateSearchCriteria: any) {
    return <any>this.http.get(`${environment.notificationUrl}notificationTemplateResource/search`, {
      params: notificationTemplateSearchCriteria
    });
  }

  deployNotificationTemplateConfig(url: string, emailTemplateConfig: any) {
    return <any>this.http.post(`${url}notificationTemplateConfig/deploy`, emailTemplateConfig, this.header);
  }

  getNotificationTemplateConfigBackup(url:string, templateKey: string, templateProfile: string) {
    return <any>this.http.get(`${url}notificationTemplateConfig/backup?templateKey=${templateKey}&templateProfile=${templateProfile}`, this.header);
  }

  findEmailCollectionList(searchParam: EmailCollectionSearch): Observable<any> {
    const url = environment.notificationUrl + 'emailCollection/search';
    return this.http.post(url, searchParam, this.header);
  }

  updateEmailCollection(updateParam: EmailCollectionRequest): Observable<any> {
    const url = environment.notificationUrl + 'emailCollection/validity';
    return this.http.post(url, updateParam, this.header);
  }

}
