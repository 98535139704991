<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" color="#FFFFFF" size="large" type="ball-fussion">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
<ng-container [ngSwitch]="service.getCurrentLoggedInPartner()">
  <ng-container *ngSwitchCase="'MYDIGIMTELNA01'">
    <div>
      <img class="center" src="../../../assets/images/digiLogo.png" style="width:150px ; height:212px;">
    </div>
    <div class="m-60">
      <mat-card class="card-center">
        <ng-container *ngIf="true">
          <div style="text-align:center">
            <span><b> We are upgrading our sales portal.</b></span>
            <br><br>
            <span><b>With effect from 7th October 2022, all Digi Shield products offer through the sales portal will be 
              discontinued.</b></span>
            <br>
            <span><b>Our new sales portal will be launching soon. Stay tuned!</b></span>
          </div>
        </ng-container>
        <ng-container *ngIf="false">
          <h3 class="text-center">DIGI SHIELD (BYOD)</h3>
          <mat-divider class="divider-color"></mat-divider>
          <div class="m-60">
            <button class="btn-block" (click)="showAppLandingPage()" mat-raised-button>Digi Shield for All Digi Postpaid RM39</button>
          </div>
        </ng-container>
      </mat-card>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'MYDIGIMTELNA02'">
    <div>
      <img class="center" src="../../../assets/images/digiLogo.png" style="width:150px ; height:212px;">
    </div>
    <div class="m-60">
      <mat-card class="card-center">
        <ng-container *ngIf="true">
          <div style="text-align:center">
            <span><b> We are upgrading our sales portal.</b></span>
            <br><br>
            <span><b>With effect from 7th October 2022, all Digi Shield products offer through the sales portal will be 
              discontinued.</b></span>
            <br>
            <span><b>Our new sales portal will be launching soon. Stay tuned!</b></span>
          </div>
        </ng-container>
        <ng-container *ngIf="false">
          <h3 class="text-center">DIGI SHIELD (NEW DEVICES)</h3>
          <mat-divider class="divider-color"></mat-divider>
          <div class="m-60">
            <button class="btn-block" (click)="showAppLandingPage()" mat-raised-button >New Device - Digi Shield – Basic Cracked Screen, Digi Shield ADLD & Digi Shield Plus</button>
          </div>
        </ng-container>
      </mat-card>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'MYDIGIMTELNA02,MYDIGIMTELNA01'">
    <div>
      <img class="center" src="../../../assets/images/digiLogo.png" style="width:150px ; height:212px;">
    </div>
    <div class="m-60">
      <mat-card class="card-center">
        <ng-container *ngIf="true">
          <div style="text-align:center">
            <span><b> We are upgrading our sales portal.</b></span>
            <br><br>
            <span><b>With effect from 7th October 2022, all Digi Shield products offer through the sales portal will be 
              discontinued.</b></span>
            <br>
            <span><b>Our new sales portal will be launching soon. Stay tuned!</b></span>
          </div>
        </ng-container>
        <ng-container *ngIf="false">
          <h3 class="text-center">DIGI SHIELD (NEW DEVICES)</h3>
          <mat-divider class="divider-color"></mat-divider>
          <div class="m-60">
            <button class="btn-block" (click)="showDigiSheildNewDeviceOrByodApp('MYDIGIMTELNA02')" mat-raised-button >New Device - Digi Shield – Basic Cracked Screen, Digi Shield ADLD & Digi Shield Plus</button>
          </div>
      
          <h3 class="text-center">DIGI SHIELD (BYOD)</h3>
          <mat-divider class="divider-color"></mat-divider>
          <div class="m-60">
            <button class="btn-block" (click)="showDigiSheildNewDeviceOrByodApp('MYDIGIMTELNA01')" mat-raised-button>Digi Shield for All Digi Postpaid RM39</button>
          </div>
        </ng-container>
      </mat-card>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'MYAEONCRETCF01'">
    <div>
      <img class="center" src="../../../assets/images/aeonLogo.png" style="margin-top:20px;height: 100px;">
    </div>
    <div class="m-60">
      <mat-card class="card-center">
        <ng-container *ngIf="true">
          <div style="text-align:center">
            <span><b> Discontinuation of AEON Protect.</b></span>
            <br><br>
            <span><b>With effect from 1st December 2022, all AEON Protect product offer through the sales portal will be 
              discontinued.</b></span>
            <br>
            <span><b>Thank you!</b></span>
          </div>
        </ng-container>
        <ng-container *ngIf="false">
          <h3 class="text-center">AEON DEVICE PROTECTION</h3>
          <mat-divider class="divider-color"></mat-divider>
          <div class="m-60">
            <button class="btn-block" (click)="showAppLandingPage()" mat-raised-button>
              <h1 style="color:#4c55a1">AEON Protect</h1>
            </button>
          </div>
        </ng-container>
      </mat-card>
    </div>
  </ng-container>
  <div>
    <img class="center" src="../../../assets/images/bolttech_logo.png" style="margin-top:80px;height: 50px;">
  </div>
</ng-container>
