<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" color="#FFFFFF" fullScreen="false" size="large" type="ball-fussion">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
<h1 mat-dialog-title>Onsite Claim Request</h1>
<form (ngSubmit)="onSubmit()" [formGroup]="onsiteClaimForm" *ngIf="shouldProceedForOnsiteRequest">
<div mat-dialog-content>
  <div>
    <table>
      <tr>
        <td class="td-align logisticsLocation">
          <mat-card class="card-container">
              <table>
                <tr>
                  <td>
                    <mat-form-field class="tp-full-width">
                      <input (input)="claimModel.logisticsCost=$event.target.value" formControlName="logisticsCost"
                        matInput placeholder="Logistic Cost">
                        <mat-error *ngIf="onsiteClaimForm.get('logisticsCost').hasError('emptyCheckFailed')">Logistic cost required</mat-error>
                        <mat-error *ngIf="onsiteClaimForm.get('logisticsCost').hasError('nonNumberCheckFailed')">Numerical values allowed</mat-error>
                      </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <mat-form-field class="tp-full-width" appearance="outline">
                      <input (keyup)="autoCompleteSearch($event,claimModel.collectCountry)" matInput
                        placeholder="Search Address" [matAutocomplete]="auto">
                      <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option *ngFor=" let item of searchList" matTooltip="{{item.description}}"
                        matTooltipClass="autocomplete-tooltip"
                        (onSelectionChange)="setAddress(item.placeId,item.country)">
                        {{item.description}}
                      </mat-option>
                    </mat-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td>
                    <mat-form-field style="width: 150%;">
                      <input [(value)]="claimModel.collectAddress1" formControlName="collectAddress1" matInput
                        placeholder="Address1">
                        <mat-error *ngIf="onsiteClaimForm.get('collectAddress1').hasError('emptyCheckFailed')">Address one required</mat-error>
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <mat-form-field style="width: 150%;">
                      <input [(value)]="claimModel.collectAddress2" formControlName="collectAddress2" matInput
                        placeholder="Address2">
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <mat-form-field style="width: 70%;">
                      <input [(value)]="claimModel.collectPostcode" formControlName="collectPostcode" matInput
                        placeholder="Postal Code">

                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field style="width: 70%;">
                      <input [(value)]="claimModel.collectState" formControlName="collectState" matInput
                        placeholder="State">
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <mat-form-field style="width: 70%;">
                      <input [(value)]="claimModel.collectCity" formControlName="collectCity" matInput placeholder="City">
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field style="width: 70%;">
                      <mat-select (selectionChange)="claimModel.collectCountry = $event.value"
                        formControlName="collectCountry" placeholder="Country">
                        <mat-option *ngFor="let country of countries" [(value)]="country.twoCharCode">
                          {{country.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                </tr>
              </table>
          </mat-card>
        </td>
      </tr>
    </table>
  </div>
</div>
<div mat-dialog-actions>
  <button type="submit" mat-raised-button>Save</button>
<button mat-raised-button mat-dialog-close>Cancel</button>
</div>
</form>
<div *ngIf = "!shouldProceedForOnsiteRequest">
  <div mat-dialog-content>
    {{warningContent}}
  </div>
  <div mat-dialog-actions>
    <button type="submit" (click)="shouldProceedForOnsiteClaimRequest()" mat-raised-button>Proceed</button>
    <button mat-raised-button mat-dialog-close>Cancel</button>
  </div>
</div>
