// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  development: true,
  repairUrl: '/repairapi/',
  notificationUrl: '/notificationapi/',
  // notificationUrl: 'http://localhost:8085/',
  policyUrl: '/policyapi/',
  // policyUrl: 'http://localhost:8082/',
  claimUrl: '/api/claim/',
  // claimUrl: 'http://localhost:8086/api/claim/',
  websocketUrl: 'https://crmz-uat-websocket.device.bolttech.asia:8086/api/claim/',
  // websocketUrl: 'http://localhost:8080/api/claim/',
  identityUrl: '/identity/',
  // identityUrl: 'http://localhost:8081/',
  customerServiceUrl: '/customerapi/',
  // customerServiceUrl: 'http://localhost:8083/customer',
  productUrl: '/productapi/',
  //productUrl: 'http://localhost:8083/productapi/',
  s3ObjUrl: 'https://download.optimus.uat.device.bolttech.asia/claim/',
  s3Url: 'https://download.optimus.uat.device.bolttech.asia/',
  activityUrl: '/activityapi/',
  //'http://localhost:8080/activity/',
  lockUrl: '/lockapi/',
  //'http://localhost:8080/lock/',
  mdmUrl: 'https://optimus.uat.device.bolttech.asia:8700/api/v1',
  //'http://localhost:8080/api/v1',
  makerCheckerUrl: '/makecheckerapi/',
  //'http://localhost:8088/',
  // flowableTaskUrl: 'http://localhost:8080/http://localhost:9999/flowable-task/', // Local
  // flowableTaskUrl: 'https://cors-anywhere.herokuapp.com/http://flowabledev.amtrust.asia:9999/flowable-task/process-api/runtime/', // Dev
  // flowableTaskUrl: 'http://localhost:8080/https://optimus.uat.device.bolttech.asia/flowable-task/', //Local instance CORS
  flowableTaskUrl: 'https://cors-anywhere.herokuapp.com/https://optimus.uat.device.bolttech.asia/flowable-task/', // UAT
  flowableAppDefinitionKey: 'process',
  flowableAppVasDefinitionKey: 'BolttechVASClaim',
  flowableAppAIDefinitionKey: 'aiAutomationProcess',
  flowableUserAccessAppDefinitionKey: 'userAccessProcess',
  flowableMultipleUserAccessDefinitionKey: 'multipleUserAccess',
  flowableProductRulesDefiniitonKey: 'productRules',
  blanccoUrl: 'https://api.buyback-uat.device.bolttech.asia:8086',
  //'http://localhost:8086',

  recommendationServiceUrl: 'https://recommendation.csdocker.staging.device.bolttech.asia/api/recommendation/',
  partnerServiceUrl: 'https://partner.csdocker.staging.device.bolttech.asia/v1/partners',
  docGenUrl: 'https://docgen.uat.device.bolttech.asia/',

  //Configure target environment settings to deploy E-cert configuration to RELEASE and PRODUCTION environment
  targetEnvironmentForEcertConfig: [
    {
      targetEnvironmentName: 'RELEASE',
      targetEnvironmentUrl: 'https://crmz-stg.device.bolttech.asia',
      targetEnvironmentDocGenUrl: 'https://crmz-stg.device.bolttech.asia:8877/',
    },
    {
      targetEnvironmentName: 'PRODUCTION',
      targetEnvironmentUrl: 'https://crmz.device.bolttech.asia',
      targetEnvironmentDocGenUrl: 'https://portal-api.tecprotec.co:8083/',
    }
  ],

  //Configure target environment settings to deploy notification configuration to RELEASE and PRODUCTION environment
  targetEnvironmentForNotificationConfig:[
    {
      targetEnvironmentName: 'RELEASE',
      targetEnvironmentUrl: 'https://crmz-stg.device.bolttech.asia',
      targetEnvironmentNotificationUrl: 'https://crmz-stg.device.bolttech.asia:8085/',
    },
    {
      targetEnvironmentName: 'PRODUCTION',
      targetEnvironmentUrl: 'https://crmz.device.bolttech.asia',
      targetEnvironmentNotificationUrl: 'https://crmz.device.bolttech.asia:8085/',
    }
  ],

  pspdfkitLicenseKey: "",
  enablePDFEditor: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
