import { Injectable, OnInit } from '@angular/core';
import { RoleMap } from '../model/roles.map';
import { Groups } from '../model/groups';
import { AppConstants } from '../app.constants';
import { RoleEnum } from '../model/roles.enum';

@Injectable({
  providedIn: 'root'
})
export class HasPermissions implements OnInit {

  constructor(private access: RoleMap, private groups: Groups) {
  }

  ngOnInit() {
  }

  public hasPermissions(module: String, permission: String, gidNumber: String, postalAddress: String) {
    let isPermissionGranted: boolean = false;
    let roles: string[];
    if (localStorage.getItem(AppConstants.ROLE) != undefined && localStorage.getItem(AppConstants.ROLE) != null) {
      roles = localStorage.getItem(AppConstants.ROLE).split(',');
    }
    if (module === AppConstants.POLICY_MODULE
      && this.groups.policyGroups.includes(localStorage.getItem(AppConstants.GROUP))) {
      isPermissionGranted = this.checkAccess(roles[0], permission, RoleEnum.Upload, gidNumber, postalAddress);
    } else if (module === AppConstants.CLAIM_MODULE) {
      if (this.groups.claimGroups.includes(localStorage.getItem(AppConstants.GROUP))) {
        if (this.access.roleMap.get(permission) <= Number(roles[1]) || this.access.roleMap.get(permission) === RoleEnum.Upload) {
          isPermissionGranted = true;
        }
      }
    } else if (module === AppConstants.PRODUCT_MODULE && this.groups.productGroups.includes(localStorage.getItem(AppConstants.GROUP))) {
      isPermissionGranted = this.checkAccess(roles[2], permission, RoleEnum.Config, gidNumber, postalAddress);
    }else if (module === AppConstants.USER_MANAGEMENT_MODULE && this.groups.userManagementGroups.includes(localStorage.getItem(AppConstants.GROUP)) && roles.length>3) {
      isPermissionGranted = this.checkAccess(roles[3], permission, RoleEnum.Upload, gidNumber, postalAddress);
    }
    return isPermissionGranted;
  }

  checkAccess(roles: string, permission: any, roleValue: any, gidNumber: any, postalAddress: any) {
    if (Number(roles) >= roleValue) {
      if (this.access.roleMap.get(permission) === roleValue) {
        return true;
      } else if (this.access.roleMap.get(permission) <= (Number(roles) - roleValue)) {
        return true;
      }
    } else if (this.access.roleMap.get(permission) <= Number(roles)) {
      let loggedInUserGidNumber = localStorage.getItem(AppConstants.GROUP);
      let loggedInUserCountryCode = localStorage.getItem(AppConstants.COUNTRY_CODE); 
      if (loggedInUserGidNumber != null && loggedInUserCountryCode != null && gidNumber != null && postalAddress != null) {
        if (gidNumber.includes(loggedInUserGidNumber)
        && postalAddress.includes(loggedInUserCountryCode)) {
          return true;
        } 
        return false;
      }
      return true;
    } else {
      return false;
    }
  }
}
