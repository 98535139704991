import { Location } from '@angular/common';
import { Injectable,EventEmitter,Output } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, Subscribable, SubscribableOrPromise, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ResponseObject } from './model/response.object';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from './app.constants';
import { LockEnum } from './model/lock.enum';
import { emit } from 'process';
import { KeycloakService } from "keycloak-angular";

@Injectable({
  providedIn: 'root'
})

export class AppService {
  @Output("afterReset") afterReset: EventEmitter<any> = new EventEmitter();
  header: any;
  constructor(private http: HttpClient, 
              private toastr: ToastrService,
              private location: Location,
              private keycloakService: KeycloakService) {
    this.header = AppService.apiKeyHeaders();
  }

  getTypeValues(typeCode: any, componentRef) {
    this.getTypeValuesApi(typeCode).subscribe(
      res => {
        componentRef.statusTypesMap.set(typeCode, (<ResponseObject>res).obj.typeValues);
      }
    );
  }

  getTypeValuesApi(typeCode: any): Observable<any> {
    return <any>this.http.get(environment.productUrl + 'products/typeValues/' + typeCode, AppService.apiKeyHeaders());
  }

  getRepairDetails(repairId, partnerId): Observable<any> {
    const url = environment.repairUrl + 'repair/v1/cases/' + repairId + '?partnerId=' + partnerId;
    return this.http.get(url, AppService.apiKeyHeaders()).pipe(
      map(event => {
        return event;
      }),
      catchError(this.handleError)
    );
  }

  showInfo(message, title, boldTitle = true, enableHtml = false) {
    this.toastr.info(message, title, {
      closeButton: true,
      enableHtml: enableHtml,
      titleClass: boldTitle?'toast-title':'toast-title-no-bold',
      positionClass: 'toast-top-center'
    });
  }
   
  clearDuplicateToaster(title:string, message:string, resetOnDuplicate:boolean, countDuplicates:boolean) {
    this.toastr.findDuplicate(title, message, resetOnDuplicate, countDuplicates);
  }

  clearToaster() {
    this.toastr.clear();
  }
  
  subscribeToLockNotifications(primaryIdentifier: string, subscriberId: string): EventSource {
    return new EventSource(`${environment.lockUrl}${primaryIdentifier}?subscriberId=${subscriberId}`);
  }

  acquireLock(lockRequestModel): Observable<any> {
    return <any>this.http.post(environment.lockUrl, lockRequestModel);
  }

  releaseLock(primaryIdentifier: string, subscriberId: string, lockId: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('primaryIdentifier', primaryIdentifier);
    params = params.append('subscriberId', subscriberId);
    params = params.append('lockId', lockId);
    return <any>this.http.post(`${environment.lockUrl}release?primaryIdentifier=${primaryIdentifier}&subscriberId=${subscriberId}&lockId=${lockId}`, null);
  }

  uuidv4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  removeSubscriberBeforeWindowUnload(primaryIdentifier: string, subscriberId: string, lockId: string) {
    navigator.sendBeacon(`${environment.lockUrl}/release?primaryIdentifier=${primaryIdentifier}&subscriberId=${subscriberId}&lockId=${lockId}`);
  }

  paginateSearch(event, itemsPerPage, searchObj, callback: Function, componentRef, isItemsChangeEvent = false): void {
    if (!isItemsChangeEvent) {
      searchObj.pageIndex = event - 1;
      searchObj.pageRecordCount = itemsPerPage;
      if(searchObj.isParentPolicy){
        componentRef.parentCurrentPage = event;
      }else {
        componentRef.currentPage = event;
      }
    } else {
      searchObj.pageIndex = componentRef.currentPage - 1;
      searchObj.pageRecordCount = event.value;
    }
    callback(searchObj, false);
  }

  resetControls(searchObject, componentRef) {
    searchObject.pageIndex = 0;
    componentRef.currentPage = 1;
    searchObject.pageRecordCount = 10;
  }

  getCurrentUser() {
    return localStorage.getItem(AppConstants.LOGGED_IN_USER);
  }

  getCurrentUserEmailId(){
    return localStorage.getItem(AppConstants.LOGGED_IN_USER_MAIL);
  }

  logout(waitTime = 0) {
    this.clearLockSubscriptions();
    this.delay(waitTime).then(() => {
      let logout = this.http.post(environment.identityUrl + 'logout/permissions', {'token': localStorage.getItem(AppConstants.JWT_TOKEN)});
      logout.subscribe().add(() => {
        this.keycloakService.logout();
        localStorage.clear();
        window.location.replace(window.location.origin);
      });
    })
  }

  clearLockSubscriptions() {
    let primaryIdentifier = localStorage.getItem('primaryIdentifier');
    let subscriberId = localStorage.getItem('subscriberId');
    let lockId = localStorage.getItem(LockEnum.CLAIM_LOCK_ID) || localStorage.getItem(LockEnum.POLICY_LOCK_ID) || localStorage.getItem(LockEnum.REPAIR_LOCK_ID);
    if (primaryIdentifier && subscriberId) {
      this.releaseLock(primaryIdentifier, subscriberId, lockId).subscribe((res: any) => {

      }), (err: any) => {
      };
    }
  }

  static apiKeyHeaders(): any {
    return {
      headers: new HttpHeaders({
        'x-api-key': localStorage.getItem(AppConstants.JWT_TOKEN)
      })
    };
  }

  onComplete(response: any) {
    if (response != undefined && response.status === 'ERROR') {
      if (response.code === AppConstants.USER_ACCESS_DENIED_CODE) {
        this.showInfo(AppConstants.SESSION_EXPIRED, '');
        setTimeout(() => {
          this.logout();
        }, 60000);
      }
    }
  }

  private getEventMessage(event: HttpEvent<any>) {

    switch (event.type) {

      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);

      case HttpEventType.Response:
        return this.apiResponse(event);
    }
  }

  private fileUploadProgress(event) {
    const percentDone = Math.round(100 * event.loaded / event.total);
    return { status: 'Progress', message: percentDone };
  }

  private apiResponse(event) {
    return event.body;
  }

  private handleError(error: HttpErrorResponse) {
    return throwError('Something bad happened; please try again later.');
  }

  searchCustomer(customerData) {
    return <any>this.http.get(environment.customerServiceUrl + "/find", { params: customerData });
  }

  updateCustomer(customerData) {
    customerData['updatedBy'] = sessionStorage.getItem(AppConstants.LOGGED_IN_USER);
    return <any>this.http.post(environment.customerServiceUrl + "/update",
      customerData);

  }


  async delay(waitTime: number = 0) {
    await new Promise(r => setTimeout(r, waitTime));
  }

  validateMd5Hash(currentuser, secretKey, hashedUrl) {
    return <any>this.http.post(`${environment.identityUrl}validateHash?currentuser=${currentuser}&secretKey=${secretKey}&hashedUrl=${hashedUrl}`, null);
  }

  getCurrentTime() {
    return new Date().getTime();
  }

  getCurrentLoggedInPartner():string{
    return localStorage.getItem(AppConstants.PARTNER_ID);
  }

  extractOnePartnerIdAndSetInLocalStorage(partnerId: string):string{
    localStorage.setItem(AppConstants.MULTIPLE_PARTNER_ID,localStorage.getItem(AppConstants.PARTNER_ID));
    let partnerIdArray = partnerId.split(',');
    if(partnerIdArray.includes(partnerId)){
      localStorage.setItem(AppConstants.PARTNER_ID, partnerId);
    }
    else {
      console.log("partnerId not found");
    }
    return localStorage.getItem(AppConstants.PARTNER_ID);;
  }

  getCurrentLoggedInPartnerids():string{
    return localStorage.getItem(AppConstants.MULTIPLE_PARTNER_ID);
  }

  rsaEncrypt(payload: string): Observable<any>{
    return <any>this.http.post(environment.identityUrl+'rsa/encrypt?payload='+payload,null,{
        headers: new HttpHeaders({
            'Accept':'text/plain'}),
        responseType: 'text'});
  }

  rsaDecrypt(payload: string): Observable<any>{
    return <any>this.http.post(environment.identityUrl+'rsa/decrypt?payload='+payload,null,{
        headers: new HttpHeaders({
            'Accept':'text/plain'}),
        responseType: 'text'});
  }

  applicableContinent(): Array<String> {
    let host = this.host();
    console.log("host",host);
    let continentToSearch = ['Europe','Asia','America', 'Africa', 'Australia'];
    if (host.includes(".eu")) {
      continentToSearch = ['Europe'];
    } else if (host.includes(".asia")) {
      continentToSearch = ['Asia','America', 'Africa', 'Australia'];
    } 
    return continentToSearch;
  }

  flowableGetProductRules(data: any): Observable<any>{
    return <any>this.http.post(environment.identityUrl + 'approval-dashboard/product-rules-variable', data, AppService.apiKeyHeaders());
  }

  host(): String{
    return location.host;
  }

  resetButtonClickedEvent(message: string){
    this.afterReset.emit(message);
  }
}


