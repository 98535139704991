<div style="margin-top: 7%;">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center center">
    <mat-card>
      <div class="logo"></div>
      <div class="login-content">
        <mat-card-header class="card-title">
          <mat-card-title id="change-password-title">Enter New Password!</mat-card-title>
        </mat-card-header>
        <form [formGroup]="updatePasswordForm" (ngSubmit)="onSubmit()">
          <mat-card-content>
            <div fxLayout="row" fxLayoutGap="30px" *ngIf="!isCalledFromForgotPasswordResetMail">
              <div fxFlex>
                <mat-form-field color="green" class="change-password-field">
                  <mat-label>Old Password</mat-label>
                  <input [type]="oldPasswordHide ? 'password' : 'text'" matInput formControlName="oldPassword"
                    placeholder="Old Password">
                  <mat-icon (click)="oldPasswordHide = !oldPasswordHide" class="show-password"
                    matSuffix>{{oldPasswordHide ?
                    'visibility_off' : 'visibility'}}
                  </mat-icon>
                  <mat-error *ngIf="updatePasswordForm.get('oldPassword').hasError('required')">
                    Old password cannot be empty
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div fxLayout="row" fxLayoutGap="30px">
              <div fxFlex>
                <mat-form-field
                  [ngClass]="(updatePasswordForm.get('newPassword').touched && updatePasswordForm.get('newPassword').invalid)?'change-password-field-error':'change-password-field'">
                  <mat-label>New Password</mat-label>
                  <input [type]="newPasswordHide ? 'password' : 'text'" matInput #newPassword
                    formControlName="newPassword" placeholder="New Password">

                  <mat-icon (click)="newPasswordHide = !newPasswordHide" class="show-password"
                    matSuffix>{{newPasswordHide ?
                    'visibility_off' : 'visibility'}}
                  </mat-icon>
                  <mat-error *ngIf="updatePasswordForm.get('newPassword').invalid">
                    {{fetchNewPasswordError()}}
                  </mat-error>
                </mat-form-field>
                <!-- THE PASSWORD STRENGTH COMPONENT -->
                <mat-label *ngIf="updatePasswordForm.get('newPassword').touched"
                  class="password-strength change-password-field">Password Strength: {{passwordStrength}}</mat-label>
                <mat-password-strength *ngIf="updatePasswordForm.get('newPassword').touched" #passwordComponent
                  (onStrengthChanged)="onStrengthChanged($event)" [password]="newPassword.value"
                  class="change-password-field">
                </mat-password-strength>
              </div>
            </div>
            <div fxLayout="row" fxLayoutGap="30px">
              <div fxFlex>
                <mat-form-field
                  [ngClass]="(updatePasswordForm.get('retypeNewPassword').touched && updatePasswordForm.get('retypeNewPassword').invalid)?'change-password-field-error':'change-password-field'">
                  <mat-label>Retype New Password</mat-label>
                  <input [type]="retypeNewPasswordHide ? 'password' : 'text'" matInput #retypePassword
                    formControlName="retypeNewPassword" placeholder="Retype New Password">
                  <mat-icon (click)="retypeNewPasswordHide = !retypeNewPasswordHide" class="show-password"
                    matSuffix>{{retypeNewPasswordHide ?
                    'visibility_off' : 'visibility'}}
                  </mat-icon>
                  <mat-error *ngIf="updatePasswordForm.get('retypeNewPassword').invalid">
                    {{fetchRetypeNewPasswordError()}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <div fxLayout="row" fxLayoutAlign="space-evently center">
              <button fxFlex color="accent" mat-raised-button type="submit">Submit</button>
              <button fxFlex *ngIf="shouldRenderExternalCallConfig()" (click)="logout()" color="warn" mat-raised-button
                type="button">Dismiss</button>
              <button fxFlex *ngIf="!shouldRenderExternalCallConfig()" mat-dialog-close color="warn" mat-raised-button
                type="button">Dismiss</button>
            </div>
          </mat-card-actions>
        </form>
        <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" color="#FFFFFF" size="large" type="ball-fussion">
          <p style="color: white"> Loading... </p>
        </ngx-spinner>
      </div>
    </mat-card>
  </div>
</div>