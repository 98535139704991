import { Injectable } from "@angular/core";

import { ClaimDocument, LogisticCourier, LogisticStatus } from '../model/ClaimDocument';
import { PolicyDocument } from '../model/policy.document';
import { PolicyEnum } from '../model/policy.enum'
import { CreateClaimComponent } from './create-claim/create-claim.component';

@Injectable()
export class ClaimConstants {
  public static POLICY_NUMBER = 'policyNo';
  public static CLAIM_NUMBER = 'claimNo';
  public static IMEI = 'imei';
  public static STATUS = 'status';
  public static SUB_STATUS = 'claimSubStatus';
  public static STATUS_CODE = 'statusCode';
  public static STATUS_CODES = 'statusCodes';
  public static SUB_STATUS_CODES = 'subStatusCodes';
  public static TAT_STATUS = 'tatStatus';
  public static CLIENT = 'client';
  public static CUSTOMER_NAME = 'customerName';
  public static CUSTOMER_CONTACT = 'customerContact';
  public static CUSTOMER_ID = 'customerId';
  public static CUSTOMER_EMAIL = 'customerEmail';
  public static COUNTRY = 'country';
  public static PARTNER_ID = 'partnerId';
  public static DATE_CREATED = 'dateCreated';
  public static EXIST = 'Exist';
  public static UPDATED_CLAIM = 'Updated Claim';
  public static APPROVED = 'Approved';
  public static NEW_CLAIM_REQUEST = 'CS_001';
  public static PENDING_CUSTOMER = 'CS_002';
  public static PENDING_INTERNAL_PROCESS = 'CS_003';
  public static COMPLETED = 'CS_007';
  public static CLOSED = 'CSS_039';
  public static NEW_CLAIM_REQUEST_SUBSTATUS_SAMSUNG = 'CSS_001';
  public static NEW_CLAIM_REQUEST_SUBSTATUS_FLOWABLE = 'CSS_002';
  public static PENDING_EXCESS_FEES_AND_DEDUCTIBLES = 'CSS_003';
  public static PENDING_PICKUP = 'CS_004';
  public static PENDING_DELIVERY = 'CS_006';
  public static PENDING_TECHNICAL_REPORT = 'CSS_013';
  public static PENDING_QUOTATION = 'CSS_014';
  public static PICKUP_ARRANGEMENTS = 'CSS_021';
  public static DELIVERY_ARRANGEMENTS = 'CSS_036';
  public static DECLINED = 'CS_011';
  public static CANCELLED = 'CS_008';
  public static ATTEMPT_PICKUP_1 = 'CSS_016';
  public static ATTEMPT_DELIVERY_1 = 'CSS_029';
  public static APPROVED_BY_CS = 'CSS_001';
  public static PROCESSING_DEVICE = 'CS_005';
  public static PENDING_REPLACEMENT_DEVICE = 'CSS_027';
  public static POLICY_ONHOLD = 'OnHold';
  public static POLICY_INACTIVE = 'Inactive';
  public static POLICY_CANCELLED = 'Cancelled';
  public static POLICY_WITHDRAWN = 'Withdrawn';
  public static POLICY_FUTURE = 'Future';
  public static POLICY_VOID = 'Void';
  public static POLICY_GRACE_PERIOD = 'Grace Period';
  public static POLICY_FULFILLED = 'FulFilled';
  static ERROR_MSG = 'Something went wrong, Please try again. We are looking into it';
  static MANDATORY_FILL = 'Please fill all the mandatory fields.';
  static TRANSACTION_SLIP_REQUIRED = 'Transaction Slip is required for the following Excess Fees Collection Method: Bank-In, ASC';
  static EMAIL_INCORRECT = 'Please fill correct email format.';
  static IMEI_AND_REPLACEIMEI_EQUAL = 'Replacement IMEI should not be equal to previous IMEI.';
  static IMEI_NUMERIC_ONLY = "Replacement IMEI should only contain numeric (0-9) characters.";
  static SERIALNO_AND_REPLACESERIALNO_EQUAL = 'Replacement Device Serial No should not be equal to previous Device Serial No.';
  static TOPUP_NOT_ALLOWED = 'Top-Up is not allowed on this productCode ';
  static POLICE_REPORT_DATE_VALIDATION = 'Police report date should be within policy period.';
  static NO_SEARCH_CRITERIA_SPECIFIED = 'No search criteria specified';
  static EXCESS_FEE_VALIDATION_MSG = 'Please fill the excess fee field';
  static AMOUNT_RECEIVED_VALIDATION_MSG = 'Please fill the Amount received field.';
  static _VALIDATION_MSG_FINAL_TOTAL_COST = 'Total Cost is a mandatory field, please fill.';
  static _VALIDATION_MSG_FINAL_TOTAL_REPLACEMENT_COST = 'Total Replacement Cost is a mandatory field, please fill.';
  static REFRESH_INFO_MSG = 'Please refresh info.';
  public static LIST_OF_ASSIFNEES = 'listOfAssignees';
  public static SEARCHED_ASSIGNEES = 'assigneesSearched';
  public static CLIENT_NAME_IS_MANDATORY = 'Client Name Is Mandatory Field';
  public static EDIT_LOCK_ACQUIRED = 'Somebody has started editing the claim detail';
  public static EDIT_LOCK_RELEASED = 'You can continue editing the claim detail';
  public static EDIT_LOCK_MESSAGE = 'Editing of claim';
  public static FILE_UPLOAD_HEADING = 'File Upload';
  public static FILE_UPLOAD_LIMIT_EXCEEDED = 'Can not upload more than 10 documents.';
  public static INVALID_FILE_EXTENSION = 'Only files with the following extension are supported: (.jpg,.jpeg,.png,.xls,.csv,.pdf, .doc,.docx,.xlsx,.msg).';
  public static SUPPORTED_EXTENSIONS_DISPLAY_MESSAGE = 'Only files with the following extension are supported: (.jpg,.jpeg,.png,.xls,.csv,.pdf, .doc,.docx,.xlsx, .eml, .msg, .ics).';
  public static INVALID_FILE_SIZE = 'size is too large, The maximum file size allowed is 2GB.';
  public static DUPLICATE_FILE = 'A file with same name already exist and uploading this file will override the already existing file.';
  public static TRANSACTION_SLIP_UPLOAD_LIMIT_EXCEEDED = 'Can not upload more than 10 Transaction slips.';
  public static B2B_POLICY = "_B2B";
  public static EMPTY = "Empty";
  public static UNABLE_TO_FETCH_CLAIM_BY_POLICY = "Error while fetching claim by service contract number";
  public static INVALID_DOC_TYPE_SELECTED_FOR_UPLOAD = 'Please select a valid Document type';
  public static COLLECT_B2B_CUSTOMER_DETAIL = 'Collect Customer Information (Name, email address, and Contact number)';
  public static CLAIM_AUTO_SAVED = "Claim details auto saved successfully";
  public static PARTNER_PRODUCT_CODE_LABEL = "partnerProductCode";
  public static LOGISTIC_OUT_FOR_DELIVERY = "OutForDelivery";
  public static VAS_MBG_CODE: string = "TY_MBK_GR";
  public static POLICY_NOT_SUPPORT_THIS_DAMAGE_TYPE = "POLICY NOT SUPPORT THIS DAMAGE TYPE";
  public static YYYY_MM_DD:string = 'YYYY-MM-DD';
  public static TECHNICAL_SUPPORT: string = 'Technical Support';
  public static REPAIR_SERVICES: string = 'Elective Repair';
  public static TRADE_IN: string = 'Device Trade In';
  public static LOGISTIC_SERVICES: string = 'Logistic Services';
  public static ZERO: string = "0";
  public static GSPN_INTEGRATION_FAILED_STATUS: string = 'NO';
  public static GSPN_INTEGRATION_NA_STATUS: string = 'NA';
  public static GSPN_INTEGRATION_SUCCESS_STATUS: string = 'YES';
  public static GSPN_INTEGRATION_BYPASS_STATUS: string = 'BYPASS';
  public static DEVICE_TYPE_EARBUDS: string = "EARBUDS";
  public static HC_MBW_CODE: string = 'TY_HCVN_MBW';
  public static FPT_EX_CODE: string = 'TY_EX';
  public static SELF: string = 'self';
  public static UPLOAD_ACTION_UPDATE_CLAIM = "UPDATE_CLAIM";
  public static REGEX_NUMBERS_ONLY: RegExp = /^[0-9]+$/;

  public static reopeningDocuments: any = [
    { "title": "Top up Receipt/Invoice", "type": "TOP_UP_RECEIPT" },
    { "title": "Email Request by Customer", "type": "EMAIL_REQUEST_BY_CUSTOMER" },
    { "title": "Evidence of presence or absence", "type": "EVIDENCE_OF_PRESENCE_OR_ABSENCE" },
    { "title": "Customer Request letter for reopening", "type": "CUSTOMER_REQUEST_LETTER_FOR_REOPENING" },
    { "title": "Approval Letter from CS", "type": "APPROVAL_LETTER_FROM_CS" },
    { "title": "Service Fee Invoice", "type": "SERVICE_FEE_INVOICE" },
    { "title": "Out of station proof", "type": "OUT_OF_STATION_PROOF" }
  ];

  public static DECLINED_STATUS_CODE: string = "CS_011";

  public static CANCELLED_STATUS_CODE: string = "CS_008";
  public static REOPENED_STATUS_CODE: string = "CS_013";

  public static REOPEN_REQUEST_STATUS_CODE: string = "CS_012";
  public static INCOMPLETE_DOCUMENTS_SUBSTATUS_CODE: string = "CSS_057";
  public static ONSITE_VISIT: string = "CSS_087";

  public static SAMSUNG_BRAND = "SAMSUNG";
  public static SAMSUNG_EU_ADDRESS_TYPE = "Authorized Service Center";
  public static SEBN_COUNTRIES = ['BE', 'NL'];
  public static SENA_COUNTRIES = ['SE', 'NO', 'DK', 'FI'];

  public static statusesToAllowReopen = [
    ClaimConstants.DECLINED_STATUS_CODE,
    ClaimConstants.CANCELLED_STATUS_CODE,
    ClaimConstants.REOPEN_REQUEST_STATUS_CODE
  ];

  public static subStatusCodesToAllowReopen = [
    "CSS_060", "CSS_044", "CSS_045", "CSS_046", "CSS_047",
    "CSS_048", "CSS_058", "CSS_073", "CSS_075", "CSS_076",
    "CSS_077", "CSS_055", "CSS_056", "CSS_057",
    "CSS_064", "CSS_065", "CSS_053", "CSS_071"
  ];

  public static partnerIdsAllowedToReopen = [
    PolicyEnum.PARTNER_SAMSUNG_PHILLIPINES_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_INDONESIA_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_INDONESIA_CF,
    PolicyEnum.PARTNER_SAMSUNG_PHILLIPINES_CF,
    PolicyEnum.PARTNER_SAMSUNG_VIETNAM_RETAIL
  ];

  public static partnerIdsAllowedLoan = [
    PolicyEnum.INDOSAT,
    PolicyEnum.HOME_CREDIT_INDONESIA
  ];

  public static isAustria = [
    PolicyEnum.PARTNER_AUSTRIA_DREI,
    PolicyEnum.PARTNER_AUSTRIA_MTEL
  ];

  public static isAusDrei = [
    PolicyEnum.PARTNER_AUSTRIA_DREI
  ];

  public static isEir = [
    PolicyEnum.PARTNER_EIR
  ];

  public static isHomeCreditIndonesia = [
    PolicyEnum.HOME_CREDIT_INDONESIA
  ];

  public static isSamsungHK = [
    PolicyEnum.PARTNER_SAMSUNG_HONGKONG_RETAIL
  ];

  public static isSamsungMO = [
    PolicyEnum.PARTNER_SAMSUNG_MACAU
  ];

  public static isSamsungSEPCO = [
    PolicyEnum.PARTNER_SAMSUNG_PHILLIPINES_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_PHILLIPINES_D2C,
    PolicyEnum.PARTNER_SAMSUNG_PHILLIPINES_CF
  ];

  public static isSamsungSEIN = [
    PolicyEnum.PARTNER_SAMSUNG_INDONESIA_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_INDONESIA_CF
  ];

  public static isSamsungSEBN = [
    PolicyEnum.PARTNER_SAMSUNG_BELGIUM,
    PolicyEnum.PARTNER_SAMSUNG_NETHERLANDS
  ];

  public static isSamsungVN = [
    PolicyEnum.PARTNER_SAMSUNG_VIETNAM_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_VIETNAM_RETAIL_GRAB
  ];

  public static isSamsungTH = [
    PolicyEnum.PARTNER_SAMSUNG_THAILAND_RETAIL
  ];

  public static isSamsungTW = [
    PolicyEnum.PARTNER_SAMSUNG_TAIWAN_RETAIL
  ];

  public static isSamsungMY = [
    PolicyEnum.PARTNER_SAMSUNG_MALAYSIA_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_MALAYSIA_TUNE
  ]

  public static isElecboyHK = [
    PolicyEnum.PARTNER_ELECBOY
  ];

  public static isBdpHK = [
    PolicyEnum.PARTNER_HK_FALCONFAITH
  ];

  public static isSamsungEU = [
    PolicyEnum.PARTNER_SAMSUNG_BELGIUM,
    PolicyEnum.PARTNER_SAMSUNG_NETHERLANDS,
    PolicyEnum.PARTNER_SAMSUNG_SWEDEN,
    PolicyEnum.PARTNER_SAMSUNG_NORWAY,
    PolicyEnum.PARTNER_SAMSUNG_DENMARK,
    PolicyEnum.PARTNER_SAMSUNG_FINLAND,
  ];

  public static isSamsungEUB2B = [
    PolicyEnum.PARTNER_SAMSUNG_B2B_BELGIUM,
    PolicyEnum.PARTNER_SAMSUNG_B2B_NETHERLANDS,
    PolicyEnum.PARTNER_SAMSUNG_B2B_SWEDEN,
    PolicyEnum.PARTNER_SAMSUNG_B2B_NORWAY,
    PolicyEnum.PARTNER_SAMSUNG_B2B_DENMARK,
    PolicyEnum.PARTNER_SAMSUNG_B2B_FINLAND,
  ];

  public static isSamsung = [
    PolicyEnum.PARTNER_SAMSUNG_PHILLIPINES_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_INDONESIA_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_INDONESIA_CF,
    PolicyEnum.PARTNER_SAMSUNG_PHILLIPINES_CF,
    PolicyEnum.PARTNER_SAMSUNG_VIETNAM_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_HONGKONG_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_MALAYSIA_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_THAILAND_RETAIL,
    PolicyEnum.PARTNER_SAMSUNG_TAIWAN_RETAIL,

  ];

  public static isLocaleRequiredPartners = [
    PolicyEnum.PARTNER_SAMSUNG_BELGIUM
  ];

  public static isThreeSweden = [
    PolicyEnum.PARTNER_THREE_SWEDEN
  ];

  public static isSingtel = [
    PolicyEnum.PARTNER_SINGTEL
  ];

  public static subStatusNotRequiringApprovalForReopen = ["CSS_055", "CSS_056", "CSS_071"]

  public static CLAIM_DOCUMENT_DELETION_SUCCESS_RESPONSE_CODE: string = "3009.001";

  public static PARENT_CONTRACT_ID_RESPONSE_FIELD_NAME: string = "parentContractId";

  public static getVasMbgTAT(country: string) {
    if ('TH' == country) {
      return VAS_MBG_TAT_TH;
    } else if ('VN' == country) {
      return VAS_MBG_TAT_VN;
    } else if ('MY' == country) {
      return VAS_MBG_TAT_MY;
    } else if ('PH' == country) {
      return VAS_MBG_TAT_PH;
    } else if ('ID' == country) {
      return VAS_MBG_TAT_ID;
    }
  }

  public static isSEPCO(partnerId : string) : boolean{
    return (ClaimConstants.isSamsungSEPCO.findIndex(value => value === partnerId) >= 0);
  }

  public static isSEIN(partnerId : string) : boolean{
    return (ClaimConstants.isSamsungSEIN.findIndex(value => value === partnerId) >= 0);
  }

  public static isSEBN(partnerId : string) : boolean{
    return (ClaimConstants.isSamsungSEBN.findIndex(value => value === partnerId) >= 0);
  }

  public static isSEVN(partnerId : string) : boolean{
    return (ClaimConstants.isSamsungVN.findIndex(value => value === partnerId) >= 0);
  }

  public static isTSE(partnerId : string) : boolean{
    return (ClaimConstants.isSamsungTH.findIndex(value => value === partnerId) >= 0);
  }

  public static isSME(partnerId : string) : boolean{
    return (ClaimConstants.isSamsungMY.findIndex(value => value === partnerId) >= 0);
  }

  public static isElecboy(partnerId: string): boolean {
    return (ClaimConstants.isElecboyHK.findIndex(value => value === partnerId) >= 0);
  }

  public static isMYPartners(countryCode: string) : boolean{
    return (countryCode == 'MY');
  }

  public static isPHPartners(countryCode: string) :boolean{
    return (countryCode == 'PH');
  }

}

export const LogisticStatuses: LogisticStatus[] = [
  { id: 'Unknown', value: 'Unknown' },
  { id: 'Booked', value: 'Booked' },
  { id: 'InTransit', value: 'InTransit' },
  { id: 'Driver Assigned', value: 'Driver Assigned' },
  { id: 'OutForDelivery', value: 'OutForDelivery' },
  { id: 'Delivered', value: 'Delivered' },
  { id: 'Undelivered', value: 'Undelivered' },
  { id: 'Failed delivery - NCI', value: 'Failed delivery - NCI' },
  { id: 'RTO', value: 'RTO' },
  { id: 'HeldUp', value: 'HeldUp' },
  { id: 'MisRoute', value: 'MisRoute' },
  { id: 'Partial Delivered', value: 'Partial Delivered' },
  { id: 'Closed', value: 'Closed' },
  { id: 'PickedUp', value: 'PickedUp' },
  { id: 'Arrived', value: 'Arrived' },
  { id: 'Cancelled', value: 'Cancelled' },
  { id: 'Expired', value: 'Expired' },
  { id: 'Rejected', value: 'Rejected' },
  { id: 'OutForPickUP', value: 'OutForPickUP' },
  { id: 'PickUpFailure', value: 'PickUpFailure' },
  { id: 'RTOPickedUP', value: 'RTOPickedUP' },
  { id: 'RTOInTransit', value: 'RTOInTransit' },
  { id: 'RTOHandover', value: 'RTOHandover' },
  { id: 'RTODelivered', value: 'RTODelivered' },
  { id: 'RTOUnDelivered', value: 'RTOUnDelivered' },
  { id: 'PackageNotReady', value: 'Package Not Ready' },
  { id: 'NoPackage', value: 'No Package' }
];

export const LocaleTypes = [
  { id: 'EN', value: 'EN' },
  { id: 'VN', value: 'VN' },
  { id: 'MY', value: 'MY' },
  { id: 'ID', value: 'ID' },
  { id: 'TH', value: 'TH' },
  { id: 'DE', value: 'DE' },
  { id: 'ZHHK', value: 'ZHHK' },
  { id: 'FRBE', value: 'FRBE' },
  { id: 'NL', value: 'NL' },
  { id: 'NLBE', value: 'NLBE' },
  { id: 'SV', value: 'SV' },
  { id: 'NO', value: 'NO' },
  { id: 'FI', value: 'FI' },
  { id: 'DA', value: 'DA' },
  { id: 'ZHTW', value: 'ZHTW' },
  { id: 'ZHMO', value: 'ZHMO' }
];

export const DamageTypes = [
  { id: 'AD', value: 'Drop Damage' },
  { id: 'BE', value: 'Liquid Damage' },
  { id: 'EW', value: 'Screen Damage' },
  { id: 'LD', value: 'Others' },
];

export const DamageTypesAD = [
  { id: 'AD', value: 'Drop Damage' },
  { id: 'EW', value: 'Screen Damage' },
  { id: 'LD', value: 'Others' },
];

export const DamageTypesEW = [
  { id: 'IW', value: 'Mechanical fault (Non Accidental)' },
  { id: 'IW-EF', value: 'Electrical fault (Non Accidental)' },
  { id: 'LD', value: 'Others' },
];

export const DamageTypesSETBatteryReplacement = [
  { id: 'BAT', value: 'Battery Replacement' }
];

export const DamageTypesTheft = [
  { id: 'ROBBERY', value: 'Robbery' },
  { id: 'SNATCH', value: 'Snatch' },
  { id: 'PICKPOCKET', value: 'Pickpocket' },
  { id: 'OTHER', value: 'Other' },
];

export const DamageTypesCrackedScreen = [
  { id: 'SCREEN', value: 'Screen Damage' }
];

export const DamageTypesWA = [
  { id: 'WA', value: 'Others' }
];

export const VAS_MBG_TAT_TH = [
  { id: 'None', value: 'None' },
  { id: '1', value: '1 day' },
  { id: '4', value: '4 days' },
  { id: '7', value: '7 days' },
  { id: '10', value: '10 days' },

];

export const VAS_MBG_TAT_VN = [
  { id: 'None', value: 'None' },
  { id: '1', value: '1 day' },
  { id: '2', value: '2 days' },
  { id: '5', value: '5 days' },
  { id: 'Refund', value: 'Refund'}
];

export const VAS_MBG_TAT_MY = [
  { id: 'None', value: 'None' },
  { id: '1', value: '1 day' },
  { id: '2', value: '2 days' },
  { id: '3', value: '3 days' },
  { id: '5', value: '5 days' },
  { id: '10', value: '10 days' },
];

export const VAS_MBG_TAT_PH = [
  { id: 'None', value: 'None' },
  { id: '1', value: '1 day' },
  { id: '2', value: '2 days' },
  { id: '3', value: '3 days' },
  { id: '7', value: '7 days' },
  { id: '10', value: '10 days' },
  { id: '14', value: '14 days' },
];

export const VAS_MBG_TAT_ID = [
  { id: 'None', value: 'None' },
  { id: '1', value: '1 day' },
  { id: '2', value: '2 days' },
  { id: '3', value: '3 days' },
  { id: '5', value: '5 days' },
  { id: '6', value: '6 days' },
  { id: '7', value: '7 days' },
  { id: '8', value: '8 days' },
  { id: '10', value: '10 days' },
  { id: '14', value: '14 days' },
  { id: '15', value: '15 days' },
  { id: '19', value: '19 days' },
];

export const ClaimSources = [
  'Email',
  'Phone Call',
  'Facebook',
  'Whatsapp',
  'Viber',
  'LINE',
  'ZALO',
];

export const ALLOWED_FILE_TYPES = [
  'jpg',
  'jpeg',
  'png',
  'xls',
  'csv',
  'pdf',
  'doc',
  'docx',
  'xlsx',
  'msg'
];

export const AIEnabledCountries = [
  'PH',
  'VN',
  'TH',
  'ID'
];

export const PAYMENT_LINK = {
  ENABLE_FOR: ['CS_001--CSS_003', 'CS_002--CSS_007', 'CS_002--CSS_003', 'CS_002--CSS_009'],
};

export class PartnerAddress {
  address1: string;
  address2: string;
  addressType: string;
  city: string;
  state: string;
  ward?: string;
  district?: string;
  countryCode: string;
  postalCode: string;
}

export class PartnerBranch {
  partnerType: string;
  branchName: string;
  partnerBranchCode: string;
  address: PartnerAddress;
}

export class PartnerContact {
  partnerContactName: string;
  partnerContactTitle: string;
  partnerContactDepartment: string;
  partnerContactEmail: {
    primary: string;
    alternate: string;
  };
  partnerContactNumber: {
    primary: string;
    alternate: string;
  };
}

export class PartnerARC {
  _id?: string;

  countryCode: string;
  partnerName: string;
  companyName: string;
  partnerCode: string;
  authorizedBrands: string;
  thirdPartyBrands: string;

  addresses: PartnerAddress[];
  partnerBranch: PartnerBranch[];
  partnerContact: PartnerContact[];

  longitude: string;
  latitude: string;
}

export class PartnerPriorityARC {
  arcName: string;
  city: string;
  postalCode: string;
  status: string;
  isAutoSelection?: boolean;

  partnerShortInfo: {
    address: PartnerAddress;
    partnerBranch: PartnerBranch;
    partnerContact: PartnerContact;
    authorizedBrands: string;
    thirdPartyBrands: string;
    latitude?: string;
    longitude?: string;
    repairPartnerId?: string;
    repairPartnerName?: string;
  };
}

export class PartnerPriorityLSP {
  pickupPriority: string;
  pickupPriorityCode?: string;
  deliveryPriority: string;
  deliveryPriorityCode?: string;
}

export const COUNTRY_MAPPING = {
  'PH': 'Philippines',
  'MY': 'Malaysia',
  'VN': 'Vietnam',
  'ID': 'Indonesia',
  'HK': 'Hong Kong',
  'TH': 'Thailand',
  'TW': 'Taiwan',
  'AT': 'Austria',
};

export enum DiagnosticOutcome {
  ADA = "ADA",
  LDA = "LDA",
  BER = "BER",
  THEFT = "THEFT"
};

export enum ClaimType {
  CS = "Cracked Screen",
  EW = "EW"
};

export enum Coverage {
  ADLD = "ADLD",
  THEFT = "THEFT",
  CS = "CS",
  EW = "EW"
};

export enum ExcessFeeFlag {
  P = "P",
  N = "N",
  F = "F"
};

// SCEU-192: list of country and checker function should disable product rule
const countriesExcludeFromRule = ['PH', 'BE', 'NL', 'SE', 'DK', 'NO', 'FI', 'VN', 'ID', 'MY', 'TH', 'HK', 'IE', 'AT', 'SG'];
export function checkPrdRule({ country }: ClaimDocument): boolean {
  return countriesExcludeFromRule.includes(country);
}

const consumerElectDeviceTypes = ["microwave", "vacuum cleaner", "air purifier"];
export function checkConsumerElectronicDeviceType({ deviceType }: ClaimDocument): boolean {
  return consumerElectDeviceTypes.includes(deviceType.toLowerCase())
}

export function chkCEDeviceViaPartnerID({ partnerId }: ClaimDocument): boolean {
  return (partnerId === PolicyEnum.PARTNER_SAMSUNG_INDONESIA_CE);
}

function sortCouriersByName(couriers) {
  if (couriers == null || couriers == undefined) {
    return;
  }
  couriers.sort((first, second): number => {
    if (first.id < second.id) return -1;
    else if (first.id > second.id) return 1;
    else return 0;
  });
  return couriers;
}

const SETWearables = ["SES_TW_WEARABLE_2Y_UPFRONT", "SES_TW_WEARABLE_2Y_MONTHLY", "SES_TW_WEARABLE_D2C_2Y_UPFRONT", "SES_TW_PREMIUM_2Y", "SES_TW_PREMIUM_MONTHLY_2Y", "SES_TW_PREMIUM_UPFRONT_2Y"];
export function isSETWearables( partnerProductCode : string): boolean {
  return SETWearables.includes(partnerProductCode)
}

const autoPopulateExcessFeeCountries = ["ID"];
export function shouldAutoPopulateExcessFee(country: string): boolean {
  return country ? autoPopulateExcessFeeCountries.includes(country) : false;
}
