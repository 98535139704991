<mat-dialog-content class="mat-typography">
  <mat-card style="display: contents !important;">
    <div class="logo"></div>
    <div class="login-content">
      <mat-card-header class="card-title">
        <mat-card-title class="align-center">Reset Your Password</mat-card-title>
      </mat-card-header>
      <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
        <mat-card-content>

          <div fxLayout="row" fxLayoutGap="30px">
            <div fxFlex>
              <mat-form-field class="forgot-password-field">
                <mat-label>
                  <mat-icon>mail</mat-icon>
                  Email
                </mat-label>
                <input type="text" formControlName="email" matInput />
                <mat-error *ngIf="forgotPasswordForm.get('email').hasError('emailPattern')">
                  Incorrect email address format
                </mat-error>
                <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
                  Email address cannot be empty
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="row" fxLayoutGap="30px">
            <div fxFlex>
              <mat-form-field class="forgot-password-field">
                <mat-label>
                  <mat-icon>account_circle</mat-icon>
                  User Name
                </mat-label>
                <input type="text" formControlName="username" matInput name="username" />
                <mat-error *ngIf="forgotPasswordForm.get('username').hasError('required')">
                  Username cannot be empty
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>

        <mat-card-actions>
          <div fxLayout="row" fxLayoutAlign="space-evently center">
            <button fxFlex color="accent" mat-raised-button type="submit">Submit</button>
            <button fxFlex mat-dialog-close color="accent" mat-raised-button type="button">Dismiss</button>
          </div>
        </mat-card-actions>
      </form>

      <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" color="#FFFFFF" size="large" type="ball-fussion">
        <p style="color: white"> Loading... </p>
      </ngx-spinner>
      
    </div>
  </mat-card>
</mat-dialog-content>