<div mat-dialog-content>
  <p [innerHTML]="title"></p>
  <mat-form-field appearance="outline">
    <mat-label>{{subtitle}}</mat-label>
    <textarea matInput [placeholder]="placeholder" [value]="inputContent" (input)="inputContent=$event.target.value" maxlength="1500" style="height:200px"></textarea>
  </mat-form-field>
</div>
<div class="buttonsRow" mat-dialog-actions>
  <button (click)="onNoClick()" mat-button mat-raised-button type="button">Cancel</button>
  <button (click)="submit()" mat-button mat-raised-button type="submit" style="background-color:#2EB5C7;">Submit</button>
</div>
