import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {LoginComponent} from './components/user/login/login.component';
import {HomeComponent} from './components/home/home.component';
import {AppRoutingConstants} from './app-routing.constants';
import {ChangePasswordComponent} from './components/user/change-password/change-password.component';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  { path: AppRoutingConstants.APP_LOGIN, component: LoginComponent, canActivate: [AuthGuard], outlet: 'loginRouter' },
  { path: AppRoutingConstants.APP_LOGIN_DEFAULT, component: LoginComponent, canActivate: [AuthGuard], outlet: 'loginRouter' },
  { path: AppRoutingConstants.HOME, component: HomeComponent },
  { path: AppRoutingConstants.CHANGE_PASSWORD, component: ChangePasswordComponent },
  { path: AppRoutingConstants.FORGOT_PASSWORD_RESET, component: ChangePasswordComponent },
  
  {
    path: 'product',
    loadChildren: () => import('./product/product.module').then(m => m.ProductModule)
  },
  {
    path: 'policy',
    loadChildren: () => import('./policy/policy.module').then(m => m.PolicyModule)
  },
  {
    path: 'claim',
    loadChildren: () => import('./claim/claim.module').then(m => m.ClaimModule)
  },
  {
    path: 'audit',
    loadChildren: () => import('./audit/audit.module').then(m => m.AuditModule)
  },
  {
    path: 'customer',
    loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule)
  },
  {
    path: 'likeForSimilar',
    loadChildren: () => import('./like-for-similar/like-for-similar.module').then(m => m.LikeForSimilarModule)
  },
  {
    path: 'userMgmt',
    loadChildren: () => import('./user-management/user.module').then(m => m.UserModule)
  },
  {
    path: 'checker',
    loadChildren: () => import('./user-management/user.module').then(m => m.UserModule)
  },
  {
    path: 'approval',
    loadChildren: () => import('./approval-dashboard/approval.module').then(m => m.ApprovalModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule)
  },
  {
    path: 'maker-checker',
    loadChildren: () => import('./maker-checker/maker-checker.module').then(m => m.MakerCheckerModule)
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'corrected' })]
})
export class AppRoutingModule {
}
