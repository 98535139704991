import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { AppConstants } from '../../../app.constants';
import { AppService } from '../../../app.service';
import { Groups } from '../../../model/groups';
import { LoginCredentials } from '../../../model/login.credentials';
import { KeycloakService } from 'keycloak-angular';


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public showTabs: Observable<String>;
  public showPolicyTab: Observable<String>;
  public showClaimTab: Observable<String>;
  public showProductTabSubject: BehaviorSubject<String>;
  public showProductTab: Observable<String>;
  public hasAdminAccess: Observable<String>;
  public showUserManagementTabSubject: BehaviorSubject<String>;
  public showUserManagementTab: Observable<String>;
  private showTabsSubject: BehaviorSubject<String>;
  private showPolicyTabSubject: BehaviorSubject<String>;
  private hasAdminAccessSubject: BehaviorSubject<String>;
  private showClaimTabSubject: BehaviorSubject<String>;
  public showPartnersTab: Observable<String>;
  private showPartnersTabSubject: BehaviorSubject<String>;
  public showAppLandingPage: BehaviorSubject<string>;
  public showDigiFailedOnboarding: BehaviorSubject<string>;
  public showDigiOnboardingApp: BehaviorSubject<string>;
  public showMYOnboardingApp: BehaviorSubject<string>;

  constructor(
    private _http: HttpClient,
    private groups: Groups,
    private appService: AppService,
    protected readonly keycloak: KeycloakService
  ) {
    this.showTabsSubject = new BehaviorSubject<String>(
      JSON.parse(localStorage.getItem(AppConstants.ENABLE_LOGIN))
    );
    this.showTabs = this.showTabsSubject.asObservable();
    this.showPolicyTabSubject = new BehaviorSubject<String>(
      JSON.parse(localStorage.getItem(AppConstants.ENABLE_POLICY_TAB))
    );
    this.showPolicyTab = this.showPolicyTabSubject.asObservable();
    this.showClaimTabSubject = new BehaviorSubject<String>(
      JSON.parse(localStorage.getItem(AppConstants.ENABLE_CLAIM_TAB))
    );
    this.showClaimTab = this.showClaimTabSubject.asObservable();
    this.showProductTabSubject = new BehaviorSubject<String>(
      JSON.parse(localStorage.getItem(AppConstants.ENABLE_PRODUCTS_TAB))
    );
    this.hasAdminAccessSubject = new BehaviorSubject<String>(
      JSON.parse(localStorage.getItem(AppConstants.IS_ADMIN))
    );
    this.showUserManagementTabSubject = new BehaviorSubject<String>(
      JSON.parse(localStorage.getItem(AppConstants.ENABLE_USER_MANAGEMENT_TAB))
    );
    this.showPartnersTabSubject = new BehaviorSubject<String>(
      JSON.parse(localStorage.getItem(AppConstants.ENABLE_PARTNERS_TAB))
    );
    this.showProductTab = this.showProductTabSubject.asObservable();
    this.hasAdminAccess = this.hasAdminAccessSubject.asObservable();

    this.showUserManagementTab = this.showUserManagementTabSubject.asObservable();
    this.showPartnersTab = this.showPartnersTabSubject.asObservable();
    this.showAppLandingPage = new BehaviorSubject<string>(
      AppConstants.TRUE
    )
    this.showDigiOnboardingApp = new BehaviorSubject<string>(
      AppConstants.FALSE
    )
    this.showDigiFailedOnboarding = new BehaviorSubject<string>(
      AppConstants.FALSE
    )
    this.showMYOnboardingApp = new BehaviorSubject<string>(
      AppConstants.FALSE
    )
  }

  /*Get User Details*/
  getUser(data: LoginCredentials): Observable<any> {
    return this.getObservableData()
      .pipe(
        map(response => {
          if (response) {
            this.setlocalStorageForLogin(response, null);
            this.showTabsSubject.next(AppConstants.TRUE);
            if (typeof response.partnerId != undefined && response.partnerId) {
              localStorage.setItem(AppConstants.PARTNER_ID, response.partnerId);
              localStorage.setItem(
                AppConstants.ENABLE_PARTNERS_TAB,
                AppConstants.TRUE
              );
              this.showPartnersTabSubject.next(AppConstants.TRUE);
            } else {
              let roles:string[]=response.role.split(',');
              if (
                response &&
                response.gidNumber &&
                this.groups.policyGroups.includes(response.gidNumber) &&
                Number(roles[0])>0
              ) {
                localStorage.setItem(
                  AppConstants.ENABLE_POLICY_TAB,
                  AppConstants.TRUE
                );
                this.showPolicyTabSubject.next(AppConstants.TRUE);
              }
              if (
                response &&
                response.gidNumber &&
                this.groups.administrators.includes(response.gidNumber) &&
                Number(roles[0])>0
              ) {
                localStorage.setItem(
                  AppConstants.IS_ADMIN,
                  AppConstants.TRUE
                );
                this.hasAdminAccessSubject.next(AppConstants.TRUE);
              }
              for (let i = 0; i < this.groups.claimGroups.length; i++) {
                if (
                  response &&
                  response.gidNumber &&
                  this.groups.claimGroups[i].includes(response.gidNumber) &&
                  Number(roles[1])>0
                ) {
                  localStorage.setItem(
                    AppConstants.ENABLE_CLAIM_TAB,
                    AppConstants.TRUE
                  );
                  this.showClaimTabSubject.next(AppConstants.TRUE);
                  break;
                }
              }
              for (let i = 0; i < this.groups.productGroups.length; i++) {
                if (
                  response &&
                  response.gidNumber &&
                  this.groups.productGroups[i].includes(response.gidNumber) &&
                  Number(roles[2])>0
                ) {
                  localStorage.setItem(
                    AppConstants.ENABLE_PRODUCTS_TAB,
                    AppConstants.TRUE
                  );
                  this.showProductTabSubject.next(AppConstants.TRUE);
                  break;
                }
              }
              for (let i = 0; i < this.groups.userManagementGroups.length; i++) {
                if (
                  response &&
                  response.gidNumber &&
                  this.groups.userManagementGroups[i].includes(response.gidNumber) &&
                  roles.length>3 && 
                  Number(roles[3])>0
                ) {
                  localStorage.setItem(
                    AppConstants.ENABLE_USER_MANAGEMENT_TAB,
                    AppConstants.TRUE
                  );
                  this.showUserManagementTabSubject.next(AppConstants.TRUE);
                  //this.setCheckerAllowedFields(roles);
                  break;
                }
              }
            }
          }
          return response;
        })
      );
  }

  parseJwt(accessToken) {
    const base64Url = accessToken.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  setlocalStorageForLogin(response: any, token: any) {
    localStorage.setItem(AppConstants.LOGGED_IN_USER, (<string>response.username));
    localStorage.setItem(AppConstants.ROLE, response.role);
    localStorage.setItem(AppConstants.GROUP, response.gidNumber);
    localStorage.setItem(AppConstants.COUNTRY_CODE, response.postalAddress);
    localStorage.setItem(AppConstants.HIDE_HEADERS_TAB,
      (response.authUpdationRequired) ? AppConstants.TRUE : AppConstants.FALSE);
    localStorage.setItem(AppConstants.USER_FIRST_LOGIN,(response.firstLogin ? AppConstants.TRUE : AppConstants.FALSE));
    localStorage.setItem(AppConstants.ENABLE_LOGIN, AppConstants.TRUE);
    localStorage.setItem(AppConstants.JWT_TOKEN, AppConstants.BLANK_STRING);
    localStorage.setItem(AppConstants.MAIL, response.mail);
  }


  getObservableData(): Observable<any> {
    let userDetails:any;
    try {
      userDetails = this.keycloak.getKeycloakInstance().tokenParsed;
      console.log('User details', JSON.stringify(userDetails))
      
    } catch (e){
      console.log('Failed to load user details', e);
    }    
    return of(userDetails);
}

  /*setCheckerAllowedFields(roles: string[]) {
    let checkerCanApprove:string[];
    if (roles.length > 4) {
      for (let i = 4; i < roles.length; i++) { 
        checkerCanApprove.push(AppConstants.CHECKER_APPROVING_RIGHTS[roles[i]]);
      }
    }
  }*/
}
